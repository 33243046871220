import React from 'react'
import DashboardSearch from './DashboardSearch'
import Header from '../../../../common/Header'
import Footer from '../../../../common/Footer'
import { Grid } from '@mui/material'
import DashboardAi from './Aianalysis/DashboardAi'
import DashboardPopular from './Textanalysis/DashboardPopular'
import DashboardRelated from './Aianalysis/DashboardRelated'
import DashboardTool from './Textanalysis/DashboardTool'



const HomeMore = () => {
  return (
    <>
     <Header/>
    <Grid container direction="column">
      <Grid item>
      
      </Grid>
      <DashboardSearch/>
      <DashboardTool />
      {/* <DashboardAi/> */}
    

      {/* Tools and Contentlist side by side */}
      <Grid container spacing={3} sx={{ mt: 3, px: 3 }}>
        <Grid item xs={12} md={9}>
          
          
        </Grid>
        <Grid item xs={12} md={3}>
          {/* <DashboardPopular />
          <DashboardRelated /> */}
        </Grid>
      </Grid>

      
      
     

      <Grid item>
       
      </Grid>
    </Grid>
    <Footer />
    </>
  )
}

export default HomeMore