// import React, { useEffect, useState } from 'react'
// import WorkspaceFirst from './WorkspaceFirst'
// import { apiList, invokeApi } from '../../../services/apiServices';
// import { config } from '../../../config/config';
// import { useCookies } from 'react-cookie';
// import WorkspaceTwo from './WorkspaceTwo';

// const UserDashboard = () => {
//     const [cookies] = useCookies();
//     const [workspaces, setWorkspaces] = useState(false);

//     const fetchWorkspaceData = async () => {
//         try {
//             const params = {};
//             const response = await invokeApi(
//                 config.getMyCollege + apiList.getWorkspaces,
//                 params,
//                 cookies
//             );
//             if (response?.status === 200) {
//                 // console.log("workspace Added Succesfully");
//                 const workspaceLength = response.data.workspaces;
//                 if (workspaceLength.length < 1) {
//                     fetchWorkspaceAccess();
//                 } else {
//                     setWorkspaces(true)
//                 }
//             } else {
//                 console.error("Failed to get workspace:", response);
//             }
//         } catch (error) {
//             console.error("Error get workspace:", error);
//         }
//     };
//     const fetchWorkspaceAccess = async () => {
//         try {
//             const params = {};
//             const response = await invokeApi(
//                 config.getMyCollege + apiList.getAllWorkspaceAccess,
//                 params,
//                 cookies
//             );
//             if (response?.status === 200) {
//                 // console.log("workspace Added Succesfully");
//                 const workspaceLength = response.data.workspaceAccess;
//                 if (workspaceLength.length < 1) {
//                     setWorkspaces(true)
//                 }
//             } else {
//                 console.error("Failed to get workspace:", response);
//             }
//         } catch (error) {
//             console.error("Error get workspace:", error);
//         }
//     };

//     useEffect(() => {
//         fetchWorkspaceData();
//     }, [])

//     if (workspaces) {
//         return (
//             <>
//                 <WorkspaceFirst />
//             </>
//         )
//     }
//     else {
//         return (
//             <>
//                 <WorkspaceTwo />
//             </>
//         )

//     }

// }

// export default UserDashboard







import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Grid } from '@mui/material';
import { ThreeDots } from 'react-loader-spinner'; // Import the spinner component
import WorkspaceFirst from './WorkspaceFirst';
import WorkspaceTwo from './WorkspaceTwo';
import { apiList, invokeApi } from '../../../services/apiServices';
import { config } from '../../../config/config';

const UserDashboard = () => {
    const [cookies] = useCookies();
    const [workspaces, setWorkspaces] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const fetchWorkspaceData = async () => {
        try {
            const params = {};
            const response = await invokeApi(
                config.getMyCollege + apiList.getWorkspaces,
                params,
                cookies
            );
            if (response?.status === 200) {
                const workspaceLength = response.data.workspaces;
                if (workspaceLength.length < 1) {
                    fetchWorkspaceAccess();
                } else {
                    setWorkspaces(true);
                    setIsLoading(false); // Set loading to false when data is ready
                }
            } else {
                console.error("Failed to get workspace:", response);
            }
        } catch (error) {
            console.error("Error getting workspace:", error);
        }
    };

    const fetchWorkspaceAccess = async () => {
        try {
            const params = {};
            const response = await invokeApi(
                config.getMyCollege + apiList.getAllWorkspaceAccess,
                params,
                cookies
            );
            if (response?.status === 200) {
                const workspaceLength = response.data.workspaceAccess;
                if (workspaceLength.length < 1) {
                    setWorkspaces(true);
                }
                setIsLoading(false); // Set loading to false when data is ready
            } else {
                console.error("Failed to get workspace:", response);
            }
        } catch (error) {
            console.error("Error getting workspace:", error);
        }
    };

    useEffect(() => {
        fetchWorkspaceData();
    }, []);

    // Inline CSS for loader
    const loaderStyles = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#f0f4f8', // Light background
    };

    const spinnerStyles = {
        animation: 'pulse 2s ease-in-out infinite', // Pulse effect
    };

    // Inline keyframe animation (cannot be added directly in the JSX, so use a style tag for the keyframe)
    const keyframesStyle = (
        <style>
            {`
                @keyframes pulse {
                    0% { transform: scale(1); }
                    50% { transform: scale(1.2); }
                    100% { transform: scale(1); }
                }
            `}
        </style>
    );

    // Show custom loading animation when loading
    if (isLoading) {
        return (
            <>
                {keyframesStyle}
                <Grid style={loaderStyles}>
                    <ThreeDots color="#FF6347" height={100} width={100} style={spinnerStyles} />
                </Grid>
            </>
        );
    }

    return workspaces ? <WorkspaceFirst /> : <WorkspaceTwo />;
};

export default UserDashboard;

