import React, { useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Box,
  Modal,
  Fade,
  Backdrop,
} from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { useTheme } from "../../common/ThemeContext";

// Adjusted data for the chart to create a 90-degree turn effect
const data = [
  { date: "1 Oct", traffic: 0 },
  { date: "2 Oct", traffic: 20 }, // Go up first
  { date: "3 Oct", traffic: 20 }, // Horizontal line at the top
  { date: "4 Oct", traffic: 20 },
  { date: "5 Oct", traffic: 20 }, // Slight upward movement
  { date: "6 Oct", traffic: 20 }, // Move right
];

const detailedData = [
  { date: "1 Oct", traffic: 0 },
  { date: "2 Oct", traffic: 20 },
  { date: "3 Oct", traffic: 20 }, // Move upward, then right
  { date: "4 Oct", traffic: 20 },
  { date: "5 Oct", traffic: 20 },
  { date: "6 Oct", traffic: 20 },
];

const EstimatedTraffic = () => {
  const [open, setOpen] = useState(false);
  const { theme } = useTheme(); // Use the theme context

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Grid item xs={12} sm={6} md={12}>
        <Card
          sx={{
            backgroundColor:
              theme.palette.mode !== "dark" ? "#2a252f" : "#ffffff",
            border:
              theme.palette.mode !== "dark" ? "1px solid #4c4452" : "none",
            borderRadius: 2,
            color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000000",
            height: "25vh",
            cursor: "pointer",
            transition: "transform 0.3s ease",
            "&:hover": {
              transform: "translateY(-5px)",
            },
          }}
          onClick={handleOpen}
        >
          <CardContent>
            <Typography
              variant="body2"
              sx={{ fontSize: "16px", marginBottom: "8px" }}
            >
              Estimated traffic
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h3" sx={{ fontSize: "32px" }}>
                22.5k
              </Typography>
              <Box
                sx={{
                  backgroundColor: "#1abc9c",
                  borderRadius: "4px",
                  padding: "2px 6px",
                  marginLeft: "8px",
                  display: "flex",
                  alignItems: "center",
                  color:'#fff'
                }}
              >
                <ArrowUpwardIcon fontSize="small" />
                <Typography variant="body2" sx={{ marginLeft: "4px" }}>
                  22.5k
                </Typography>
              </Box>
            </Box>

            {/* Line Chart without dots */}
            <Box sx={{ marginTop: "1px", height: "10vh" }}>
              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={data}>
                  <XAxis dataKey="date" hide />
                  <YAxis hide domain={[0, 40]} />
                  <Tooltip />
                  <Line
                    type="linear"
                    dataKey="traffic"
                    stroke="#9b87f2"
                    strokeWidth={2}
                    dot={false} // No dots in the main card
                    animationDuration={1000}
                    animationEasing="ease-in-out"
                  />
                </LineChart>
              </ResponsiveContainer>
            </Box>
          </CardContent>
        </Card>
      </Grid>

      {/* Modal for Detailed View */}
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "80%",
              backgroundColor:
                theme.palette.mode !== "dark" ? "#2d2839" : "#ffffff",
              borderRadius: 2,
              boxShadow: 24,
              p: 4,
              color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000000",
            }}
          >
            {/* Modal Header */}
            <Typography variant="h6" sx={{ mb: 2 }}>
              Estimated Traffic Details
            </Typography>

            {/* Detailed Graph Content */}
            <Box
              sx={{
                width: "100%",
                height: "300px",
                backgroundColor:
                  theme.palette.mode !== "dark" ? "#1b162b" : "#f5f5f5",
                padding: "16px",
                borderRadius: "8px",
                position: "relative",
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  color: "#e3e0e6",
                  position: "absolute",
                  left: "10px",
                  top: "10px",
                }}
              >
                2 Oct 2024
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: "#e3e0e6",
                  position: "absolute",
                  right: "10px",
                  top: "10px",
                }}
              >
                Estimated traffic: 22.5k
              </Typography>

              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={detailedData}>
                  <XAxis dataKey="date" hide />
                  <YAxis hide domain={[0, 40]} />
                  <Tooltip />
                  <Line
                    type="linear" // Changed to linear for straight lines
                    dataKey="traffic"
                    stroke="#9b87f2"
                    strokeWidth={2}
                    dot={{ r: 4, fill: "#9b87f2" }} // Show dots in the modal
                    animationDuration={1000}
                    animationEasing="ease-in-out"
                  />
                </LineChart>
              </ResponsiveContainer>
            </Box>

            {/* Graph Bottom Details */}
            <Box sx={{ mt: 3 }}>
              <Typography
                variant="body2"
                sx={{
                  color: theme.palette.mode !== "dark" ? "#7e7c86" : "#555",
                }}
              >
                1 Oct - 6 Oct
              </Typography>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default EstimatedTraffic;
