import React, { useState } from 'react';
import { TextField, Button, MenuItem, Grid, Typography } from '@mui/material';

const KeywordSearch = ({ onKeywordSubmit }) => {
  const [keyword, setKeyword] = useState('');

  const handleSearchClick = () => {
    if (keyword.trim() !== '') {
      onKeywordSubmit(keyword); // Trigger parent callback with the keyword
    }
  };

  return (
    <Grid
      component="form"
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 2,
        width: '100%',
        backgroundColor: '#2a252f',
        p: '4rem 2.7rem',
        borderRadius: 2,
        mt: 5,
      }}
    >
      {/* Left Side: Title and Keyword Input */}
      <Grid sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
        <Typography variant="h6" sx={{ mb: 1, color: '#d1d1d1' }}>
          Get a detailed keyword overview
        </Typography>
        <TextField
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)} // Update keyword state
          placeholder='Enter a Keyword[i.e."meal delivery"]'
          variant="outlined"
          InputProps={{
            sx: { color: 'white', border: '1px solid #4c4452' },
          }}
          sx={{
            width: '100%',
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '1px solid #4c4452',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#ff9d3a',
              },
            },
          }}
        />
      </Grid>

      {/* Middle Section: Language */}
      <Grid sx={{ display: 'flex', flexDirection: 'column', minWidth: '150px' }}>
        <Typography variant="h6" sx={{ mb: 1, color: '#d1d1d1' }}>
          Language
        </Typography>
        <TextField
          select
          defaultValue="English"
          variant="outlined"
          sx={{
            width: '100%',
            border: '1px solid #4c4452',
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '1px solid #4c4452',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#ff9d3a',
              },
            },
          }}
          InputProps={{
            sx: { color: '1px solid #4c4452' },
          }}
          SelectProps={{
            MenuProps: {
              PaperProps: {
                sx: {
                  backgroundColor: '#2a252f',
                  color: 'white',
                },
              },
            },
          }}
        >
          <MenuItem value="English">English</MenuItem>
        </TextField>
      </Grid>

      {/* Middle Section: Location */}
      <Grid sx={{ display: 'flex', flexDirection: 'column', minWidth: '150px' }}>
        <Typography variant="h6" sx={{ mb: 1, color: '#d1d1d1' }}>
          Location
        </Typography>
        <TextField
          select
          defaultValue="India"
          variant="outlined"
          sx={{
            width: '100%',
            border: '1px solid #4c4452',
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '1px solid #4c4452',
              },
              '&:hover fieldset': {
                borderColor: 'gray',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#ff9d3a',
              },
            },
          }}
          InputProps={{
            sx: { color: 'white' },
          }}
          SelectProps={{
            MenuProps: {
              PaperProps: {
                sx: {
                  backgroundColor: '#2a252f',
                  color: 'white',
                },
              },
            },
          }}
        >
          <MenuItem value="India">India</MenuItem>
        </TextField>
      </Grid>

      {/* Right Side: Search Button */}
      <Grid>
        <Button
          variant="contained"
          color="warning"
          onClick={handleSearchClick} // Call the search function
          sx={{
            height: '56px',
            mt: 5,
          }}
        >
          SEARCH
        </Button>
      </Grid>
    </Grid>
  );
};

export default KeywordSearch;
