import React from 'react';
import { Box, Typography, Button, IconButton, Grid } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import EditIcon from '@mui/icons-material/Edit';
import DownloadIcon from '@mui/icons-material/Download';

const KeywordOverview = () => {
  return (
    <Box sx={{ padding: '0rem 2.5rem' }}>
      <Grid container alignItems="center" justifyContent="space-between">
        {/* Keyword Overview Section */}
        <Grid item xs="auto">
          <Typography variant="h4" sx={{ fontWeight: 'bold',color:'#d1d1d1' }}>
            Keyword Overview
            <Typography variant="h4" component="span" sx={{ marginLeft: '5px' }}>
            : engineering
          </Typography>
          </Typography>
         
        </Grid>

        {/* Buttons and Icons Section */}
        <Grid item xs="auto">
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <Button
                variant="outlined"
                endIcon={<ArrowDropDownIcon />}
                sx={{ borderColor: '#ff6600', color: '#ff6600' }}
              >
                ADD TO LIST
              </Button>
            </Grid>

            <Grid item>
              <Button
                variant="outlined"
                sx={{
                  backgroundColor: '#fff2e6',
                  borderColor: '#ff6600',
                  color: '#ff6600',
                  fontWeight: 'bold',
                }}
              >
                GENERATE CONTENT WITH AI
              </Button>
            </Grid>

            {/* Icons */}
            <Grid item >
              <IconButton sx={{ color: '#ff6600',border: '1px solid #4c4452' }}>
                <EditIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton sx={{ color: '#ff6600',border: '1px solid #4c4452' }}>
                <DownloadIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default KeywordOverview;
