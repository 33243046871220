import React from 'react';
import { Grid, Box, Typography, List, ListItem, useMediaQuery } from '@mui/material';
import Footer from '../../common/Footer';
import Header from '../../common/home/Header';

const PrivacyPolicy = () => {
  const isMobile = useMediaQuery("(min-width:360px) and (max-width:500px)");

  return (
    <Grid
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        overflowY: 'auto',
        overflowX: 'hidden',
        background: 'linear-gradient(to right, rgba(226, 255, 255, 0.26), rgba(0, 180, 194, 0.16))',
      }}
    >
      <Header />
      <Box sx={{ flexGrow: 1, px: 10, marginTop: "50px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Typography
              variant="h2"
              sx={{
                marginBottom: 4,
                fontSize: { xs: '1.5rem', md: '2.25rem' },
                fontWeight: 700,
                textAlign: 'center',
                color: '#343f52',
              }}
            >
              Terms and Conditions for SEOMitra
            </Typography>

            {/* Section 1 */}
            <Typography variant="h3" sx={{ marginBottom: 2, fontSize: { xs: '1.2rem', md: '1.325rem' }, fontWeight: 700, color: '#343f52' }}>
              1. Use of Services
            </Typography>
            <Typography variant="h4" sx={{ marginBottom: 4, fontSize: { xs: '0.8rem', md: '1rem' }, fontWeight: 400, color: '#60697b', lineHeight: '1.5rem' }}>
              SEOMitra provides SEO tools and services designed to improve search engine rankings, website traffic, and conversion rates. By accessing and using our website, you acknowledge and agree to the following:
              <List sx={{ marginLeft: 2, marginTop: 1, listStyleType: 'disc', paddingLeft: 2 }}>
                <ListItem>You must not misuse our website or services.</ListItem>
                <ListItem>You agree to all applicable laws & regulations.</ListItem>
                <ListItem>You are responsible for the content & data you provide while using our services.</ListItem>
              </List>
            </Typography>

            {/* Section 2 */}
            <Typography variant="h3" sx={{ marginBottom: 2, fontSize: { xs: '1.2rem', md: '1.325rem' }, fontWeight: 700, color: '#343f52' }}>
              2. Use of Personal Information
            </Typography>
            <Typography variant="h4" sx={{ marginBottom: 4, fontSize: { xs: '0.8rem', md: '1rem' }, fontWeight: 400, color: '#60697b', lineHeight: '1.5rem' }}>
              When you subscribe to our newsletter or use our services, we may collect personal information such as your name, email address, phone number, and company details. We use this information to:
              <List sx={{ marginLeft: 2, marginTop: 1, listStyleType: 'disc', paddingLeft: 2 }}>
                <ListItem>Provide and improve our services.</ListItem>
                <ListItem>Send updates, promotional offers, and relevant information (with your consent).</ListItem>
                <ListItem>Communicate with you about service-related matters.</ListItem>
              </List>
              We will not sell, rent, or lease your personal information to third parties but may share it with trusted partners to help provide our services, such as sending emails, processing payments, and customer support.
            </Typography>

            {/* Section 3 */}
            <Typography variant="h3" sx={{ marginBottom: 2, fontSize: { xs: '1.2rem', md: '1.325rem' }, fontWeight: 700, color: '#343f52' }}>
              3. Restrictions on Use
            </Typography>
            <Typography variant="h4" sx={{ marginBottom: 4, fontSize: { xs: '0.8rem', md: '1rem' }, fontWeight: 400, color: '#60697b', lineHeight: '1.5rem' }}>
              By using our services, you agree not to:
              <List sx={{ marginLeft: 2, marginTop: 1, listStyleType: 'disc', paddingLeft: 2 }}>
                <ListItem>Copy, distribute, or reproduce any content from SEOMitra for commercial purposes without prior consent.</ListItem>
                <ListItem>Create derivative works of or reverse-engineer any part of the website.</ListItem>
                <ListItem>Use bots, scraping tools, or automated software to download or access content from our website.</ListItem>
                <ListItem>Engage in any activities that could damage, disable, or impair the functionality of our services.</ListItem>
              </List>
            </Typography>

            {/* Section 4 */}
            <Typography variant="h3" sx={{ marginBottom: 2, fontSize: { xs: '1.2rem', md: '1.325rem' }, fontWeight: 700, color: '#343f52' }}>
              4. Disclaimer
            </Typography>
            <Typography variant="h4" sx={{ marginBottom: 4, fontSize: { xs: '0.8rem', md: '1rem' }, fontWeight: 400, color: '#60697b', lineHeight: '1.5rem' }}>
              SEOMitra provides SEO tools and resources for general guidance only. We strive to offer accurate and effective services, but we do not guarantee the accuracy or results of using our tools. We recommend consulting with SEO professionals for specific advice.
            </Typography>

            {/* Section 5 */}
            <Typography variant="h3" sx={{ marginBottom: 2, fontSize: { xs: '1.2rem', md: '1.325rem' }, fontWeight: 700, color: '#343f52' }}>
              5. Security of Personal Information
            </Typography>
            <Typography variant="h4" sx={{ marginBottom: 4, fontSize: { xs: '0.8rem', md: '1rem' }, fontWeight: 400, color: '#60697b', lineHeight: '1.5rem' }}>
              SEOMitra uses advanced security measures to protect your personal information, including encryption, secure servers, and access control. However, no system is completely secure, and we cannot guarantee the absolute security of your data.
            </Typography>

            {/* Section 6 */}
            <Typography variant="h3" sx={{ marginBottom: 2, fontSize: { xs: '1.2rem', md: '1.325rem' }, fontWeight: 700, color: '#343f52' }}>
              6. Data Retention
            </Typography>
            <Typography variant="h4" sx={{ marginBottom: 4, fontSize: { xs: '0.8rem', md: '1rem' }, fontWeight: 400, color: '#60697b', lineHeight: '1.5rem' }}>
              We retain personal information for as long as necessary to fulfill the purposes for which it was collected, comply with legal obligations, or resolve disputes.
            </Typography>

            {/* Section 7 */}
            <Typography variant="h3" sx={{ marginBottom: 2, fontSize: { xs: '1.2rem', md: '1.325rem' }, fontWeight: 700, color: '#343f52' }}>
              7. Changes to Terms
            </Typography>
            <Typography variant="h4" sx={{ marginBottom: 4, fontSize: { xs: '0.8rem', md: '1rem' }, fontWeight: 400, color: '#60697b', lineHeight: '1.5rem' }}>
              SEOMitra may update these terms from time to time. When we make significant changes, we will notify you and update the effective date on this page.
            </Typography>

            {/* Section 8 */}
            <Typography variant="h3" sx={{ marginBottom: 2, fontSize: { xs: '1.2rem', md: '1.325rem' }, fontWeight: 700, color: '#343f52' }}>
              8. Limitation of Liability
            </Typography>
            <Typography variant="h4" sx={{ marginBottom: 4, fontSize: { xs: '0.8rem', md: '1rem' }, fontWeight: 400, color: '#60697b', lineHeight: '1.5rem' }}>
              SEOMitra will not be held liable for any damages resulting from the use of our services. This includes, but is not limited to, loss of data, business interruption, or any indirect or consequential damages.
            </Typography>

            {/* Section 9 */}
            <Typography variant="h3" sx={{ marginBottom: 2, fontSize: { xs: '1.2rem', md: '1.325rem' }, fontWeight: 700, color: '#343f52' }}>
              9. Governing Law
            </Typography>
            <Typography variant="h4" sx={{ marginBottom: 4, fontSize: { xs: '0.8rem', md: '1rem' }, fontWeight: 400, color: '#60697b', lineHeight: '1.5rem' }}>
              These Terms and Conditions are governed by the laws of [Your Country]. Any legal disputes will be resolved in the courts of [Your City/Region].
            </Typography>

            {/* Section 10 */}
            <Typography variant="h3" sx={{ marginBottom: 2, fontSize: { xs: '1.2rem', md: '1.325rem' }, fontWeight: 700, color: '#343f52' }}>
              10. Contact Us
            </Typography>
            <Typography variant="h4" sx={{ marginBottom: 4, fontSize: { xs: '0.8rem', md: '1rem' }, fontWeight: 400, color: '#60697b', lineHeight: '1.5rem' }}>
              If you have any questions or concerns about these Terms and Conditions, please contact us at: <strong>Email: contact@seomitra.com</strong>
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </Grid>
  );
};

export default PrivacyPolicy;
