import React from 'react';
import {
  Box,
  Card,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  Link,
  IconButton,
  Tooltip,
  Grid,
} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const data = [
  { keyword: 'getcollegeadmission', volume: '1.0k' },
  { keyword: 'getcollegeadmission.com', volume: '390' },
  { keyword: 'getmyuni bangalore', volume: '110' },
  { keyword: 'getmyuni education services', volume: '70' },
  { keyword: 'getmymarks', volume: '70' },
];

const sections = [
  {
    title: 'Suggestions ',
    items: [
      { keyword: 'getcollegeadmission', volume: '1.0k' },
      { keyword: 'getcollegeadmission.com', volume: '390' },
      { keyword: 'getcollegeadmission', volume: '1.0k' },
      { keyword: 'getcollegeadmission.com', volume: '390' },
    ],
  },
  {
    title: 'Questions ',
    items: [
      { keyword: 'getmyuni bangalore', volume: '110' },
      { keyword: 'getmyuni education services', volume: '70' },
      { keyword: 'getcollegeadmission', volume: '1.0k' },
      { keyword: 'getcollegeadmission.com', volume: '390' },
    ],
  },
  {
    title: 'Prepositions ',
    items: [
      { keyword: 'getmymarks', volume: '70' },
      { keyword: 'getcollegeadmission', volume: '1.0k' },
      { keyword: 'getcollegeadmission', volume: '1.0k' },
      { keyword: 'getcollegeadmission.com', volume: '390' },
    ],
  },
  {
    title: 'Comparisons ',
    items: [
      { keyword: 'getcollegeadmission.com', volume: '390' },
      { keyword: 'getmyuni bangalore', volume: '110' },
      { keyword: 'getcollegeadmission', volume: '1.0k' },
      { keyword: 'getcollegeadmission.com', volume: '390' },
    ],
  },
];

const KeywordIdeas = () => {
  return (
    <Grid container spacing={4} sx={{ p: 4 }}>
      {/* Mapping over the sections array to create four cards in the same line */}
      {sections.map((section, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <Card sx={{ padding: 3, border: '1px solid #4c4452', backgroundColor: '#2a252f' }}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
              <Typography variant="body1" sx={{ color: '#d1d1d1',fontWeight:'bold' }}>
                {section.title}
              </Typography>
              <Tooltip title="Suggestions based on keyword research">
                <IconButton size="small">
                  <HelpOutlineIcon fontSize="small" sx={{ color: '#d1d1d1' }} />
                </IconButton>
              </Tooltip>
            </Box>

            {/* Card title with the index */}
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
              <Typography variant="h4" sx={{ color: '#d1d1d1' }}>
                {index + 1}
              </Typography>
              <Typography variant="body2" sx={{ color: '#d1d1d1' }}>
                Total Volume: {section.items.reduce((acc, curr) => acc + parseFloat(curr.volume.replace('k', '')) * 1000, 0).toLocaleString()}
              </Typography>
            </Box>

            <Divider sx={{ backgroundColor: '#4c4452' }} />

            {/* List of keywords and their volumes */}
            <List>
              {section.items.map((item, idx) => (
                <ListItem sx={{ padding: 0, justifyContent: 'space-between' }} key={idx}>
                  <ListItemText
                    primary={item.keyword}
                    primaryTypographyProps={{ variant: 'body1', sx: { color: '#d1d1d1' } }}
                  />
                  <Typography variant="body2" sx={{ color: '#d1d1d1' }}>
                    Vol. {item.volume}
                  </Typography>
                </ListItem>
              ))}
            </List>

            <Box mt={2}>
              <Link href="#" underline='none' sx={{ color: '#d1d1d1', fontWeight: 'bold' }}>
                View All (17)
              </Link>
            </Box>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default KeywordIdeas;
