import React from 'react';
import { Box, Button, Typography, TextField, Grid, Card, CircularProgress } from '@mui/material';
import { useTheme } from "../../../../../common/ThemeContext";


const AiChecker = () => {

    const { theme } = useTheme();

    return (
        <Grid container spacing={4} justifyContent="center" alignItems="flex-start" p={3} sx={{ height: '90vh' }}>
            {/* Left Side: AiChecker Section */}
            <Grid item xs={12} md={8}>
                <Grid
                    borderRadius={4}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    p={3}
                    sx={{
                        backgroundColor: theme.palette.mode !== "dark" ? "#312b36" : "#fff", border:
                            theme.palette.mode !== "dark"
                                ? "1px solid #4a4a4a"
                                : "1px solid #f2ebf0", borderRadius: 2
                    }}
                >
                    <Typography variant="body1" mb={2} align="center" sx={{ color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000", }}>
                        Start by pasting, typing, choosing a URL, or uploading a file...
                    </Typography>

                    <Grid display="flex" gap={2} mb={2}>
                        <Button
                            variant="outlined"
                            sx={{
                                borderColor: 'purple',
                                color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000",
                                textTransform: 'none',
                                borderStyle: 'dashed',
                                width: '200px',
                                height: '50px',
                                borderColor: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000",
                            }}
                        >
                            COPY FROM URL
                        </Button>
                        <Button
                            variant="outlined"
                            sx={{
                                borderColor: 'purple',
                                color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000",
                                textTransform: 'none',
                                borderStyle: 'dashed',
                                width: '200px',
                                height: '50px',
                                borderColor: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000",
                            }}
                        >
                            UPLOAD FILE
                        </Button>
                    </Grid>

                    <TextField
                        multiline
                        rows={10}
                        variant="outlined"
                        placeholder=" "
                        sx={{
                            width: '100%',
                            borderColor: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000",
                            backgroundColor: theme.palette.mode !== "dark" ? "#1c1c1e" : "#fbf9fb",
                            color: '#fff',
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    border: theme.palette.mode !== "dark" ? "1px solid #4a4a4a" : "1px solid #4a4a4a",
                                },
                                '&:hover fieldset': {
                                    border: theme.palette.mode !== "dark" ? "1px solid #4a4a4a" : "1px solid #4a4a4a", 
                                },
                            },
                        }}
                        InputProps={{
                            style: {
                                borderRadius: '5px',
                                padding: '10px',
                            },
                            disableUnderline: false,
                        }}
                    />

                    <Grid display="flex" justifyContent="center" gap={2} mt={2}>
                        <Button variant="contained" sx={{ backgroundColor: theme.palette.mode !== "dark" ? "#1c1c1e" : "#fbf9fb", color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000", }}>
                            DETECT AI CONTENT
                        </Button>
                        <Button variant="contained" sx={{ backgroundColor: theme.palette.mode !== "dark" ? "#1c1c1e" : "#fbf9fb", color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000", }}>
                            CHECK PLAGIARISM
                        </Button>
                    </Grid>
                </Grid>
            </Grid>

            {/* Right Side: AI Detector Card Section */}
            <Grid item xs={12} md={4}>
                <Box
                    borderRadius={4}

                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                        p: 4, backgroundColor: theme.palette.mode !== "dark" ? "#312b36" : "#fff", border:
                            theme.palette.mode !== "dark"
                                ? "1px solid #4a4a4a"
                                : "1px solid #f2ebf0", borderRadius: 2
                    }}
                >
                    <Typography variant="h5" gutterBottom sx={{ color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000",}}>
                        Is it Human?
                    </Typography>
                    <Typography variant="body1" sx={{ textAlign: 'center',  color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000", mb: 3 }}>
                        Our <strong>artificial intelligence detection software</strong> tells you if what you have reads like it is
                        written by a human or if it sounds robotic. The AI Detector tries to forecast if your content is written by an
                        LLM, or humanly written. With robotic-sounding content placing you in a position of inauthenticity, the need for
                        a ChatGPT Detector is higher than ever.
                    </Typography>
                    <Card
                        variant="outlined"
                        sx={{
                            width: '100%',
                            height: 135,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000",
                            backgroundColor: theme.palette.mode !== "dark" ? "#1c1c1e" : "#fbf9fb",
                            borderRadius: 2,
                            border:
                            theme.palette.mode !== "dark"
                                ? "1px solid #4a4a4a"
                                : "1px solid #f2ebf0",
                        }}
                    >
                        <Typography variant="h6" sx={{ fontSize: 15,color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000", }}>
                            Results from the AI Detector will show here
                        </Typography>
                        <CircularProgress />
                    </Card>
                </Box>
            </Grid>
        </Grid>
    );
};

export default AiChecker;

