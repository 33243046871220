import React from 'react';
import { Box, Typography, IconButton, Link, Grid } from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { useTheme } from "../common/ThemeContext";


const DashBoardFooter = () => {

  const { theme } = useTheme();


  return (
    <Grid
      sx={{
         backgroundColor: theme.palette.mode !== "dark" ? "#1c1c1e" : "#ffff", 
        color: '#B5AAB4', 
        height:'50px',
        padding: '16px 0',
        display: 'flex',
        justifyContent: 'flex-end', 
        alignItems: 'center',
        paddingRight:2
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
      <Link href="#" underline="none" color="inherit" sx={{fontSize:14,color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000",}}>
          Blog
        </Link>
        <Link href="#" underline="none" color="inherit" sx={{fontSize:14,color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000",}}>
          Affiliate program
        </Link>
        <Link href="#" underline="none" color="inherit" sx={{fontSize:14,color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000",}}>
          API
        </Link>
        <Link href="#" underline="none" color="inherit" sx={{fontSize:14,color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000",}}>
          Help center
        </Link>
        <IconButton color="inherit">
          <MailOutlineIcon  sx={{color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000",}}/>
        </IconButton>
       
      </Box>
    </Grid>
  );
};

export default DashBoardFooter;
