import React, { useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Box,
  Tooltip,
  Modal,
  Fade,
  Backdrop,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { useTheme } from "../../common/ThemeContext";

const Positionchanges = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { theme } = useTheme();

  const options = {
    chart: {
      type: "column",
      height: 120,
      width: 40,
      backgroundColor: "transparent",
    },
    title: {
      text: null,
    },
    xAxis: {
      visible: false,
    },
    yAxis: {
      visible: false,
    },
    plotOptions: {
      series: {
        stacking: "normal",
        borderWidth: 0,
        pointWidth: 15,
      },
    },
    series: [
      {
        name: "Positive Change",
        data: [80],
        color: "#1abc9c",
      },
      {
        name: "Negative Change",
        data: [-70],
        color: "#e74c3c",
      },
    ],
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
  };

  return (
    <>
      <Grid container spacing={2}>
        {/* Position Changes Card */}
        <Grid item xs={12} sm={6} md={12}>
          <Card
            sx={{
              cursor: "pointer",
              backgroundColor:
                theme.palette.mode !== "dark" ? "#2a252f" : "#ffffff",
              border:
                theme.palette.mode !== "dark" ? "1px solid #4c4452" : "none",
              borderRadius: 2,
              color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000000",
              height: "25vh",
              padding: "2px",
              transition: "transform 0.3s ease",
              "&:hover": {
                transform: "translateY(-5px)",
              },
            }}
            onClick={handleOpen} // Open modal on click
          >
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="body2" sx={{ fontSize: "16px" }}>
                  Position changes
                </Typography>
                <Tooltip title="Shows position changes">
                  <HelpOutlineIcon
                    sx={{
                      fontSize: "16px",
                      color: theme.palette.mode !== "dark" ? "#bb9dbf" : "#555",
                    }}
                  />
                </Tooltip>
              </Box>
              <Box
                sx={{ display: "flex", alignItems: "center", marginTop: "8px" }}
              >
                <Typography variant="h3" sx={{ fontSize: "48px" }}>
                  12
                </Typography>
                <Box
                  sx={{
                    backgroundColor: "#e74c3c",
                    borderRadius: "6px",
                    padding: "4px 8px",
                    marginLeft: "8px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <ArrowDropDownIcon fontSize="small" sx={{ color: "#fff" }} />
                </Box>
              </Box>
              {/* Highcharts Vertical Column */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "-30px",
                }}
              >
                <Box sx={{ height: "100px", width: "40px" }}>
                  <HighchartsReact highcharts={Highcharts} options={options} />
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Modal for Position Changes Detailed View */}
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "80%",
              borderRadius: 2,
              boxShadow: 24,
              p: 4,
              backgroundColor:
                theme.palette.mode !== "dark" ? "#2d2839" : "#fff",
              color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000",
            }}
          >
            {/* Modal Header */}
            <Typography variant="h6" sx={{ mb: 2 }}>
              Position Changes Details
            </Typography>

            {/* Detailed Highcharts Content */}
            <Box
              sx={{
                width: "100%",
                height: "300px",
                backgroundColor:
                  theme.palette.mode !== "dark" ? "#1b162b" : "#f5f5f5",
                padding: "16px",
                borderRadius: "8px",
              }}
            >
              <HighchartsReact highcharts={Highcharts} options={options} />
            </Box>

            {/* Additional Details */}
            <Box sx={{ mt: 3 }}>
              <Typography variant="body2" sx={{ color: "#7e7c86" }}>
                Detailed analysis of position changes from 1 Oct to 10 Oct.
              </Typography>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default Positionchanges;
