import React, { useEffect, useState } from "react";
import {
    Box,
    Grid,
    Typography,
    Button,
    Card,
    CardContent,
    LinearProgress,
} from "@mui/material";
import Tier1image from "../tier1backlinks/PremiumTier1image";
import Tier3image from "./PremiumTier3image";
import Cookies from "js-cookie";
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';


const pricingPlans = [
    {
        id: 9,
        title: "3T Standard",
        price: "₹ 2,199/-",
        details: [
            "Backlinks from Blog Sites",
            "Total 3,200 Backlinks",
            "200 Tier 1 Backlinks",
            "600 Tier 2 Backlinks",
            "2,400 Tier 3 Backlinks",
            "For 1 Website URL",
            "For up to 10 Keywords",
            "100% DoFollow Backlinks",
            "Permanent Backlinks",
            "Free Premium Indexing",
            "Detailed Backlinks Report",
            "White Label Report",
            "Report in 30 Working Days",
        ],
        buttonText: "Add to cart",
        backgroundColor: "#ffffff",
        textColor: "#000000",
        buttonColor: "#FF9800",
    },
    {
        id: 10,
        title: "3T Advanced",
        price: "₹ 3,999/-",
        details: [
            "Backlinks from Blog Sites",
            "Total 9,600 Backlinks",
            "600 Tier 1 Backlinks",
            "1,800 Tier 2 Backlinks",
            "7,200 Tier 3 Backlinks",
            "For 1 Website URL",
            "For up to 10 Keywords",
            "100% DoFollow Backlinks",
            "Permanent Backlinks",
            "Free Premium Indexing",
            "Detailed Backlinks Report",
            "White Label Report",
            "Report in 30 Working Days",
        ],
        buttonText: "Add to cart",
        backgroundColor: "#ffffff",
        textColor: "#000000",
        buttonColor: "#FF9800",
    },
    {
        id: 11,
        title: "3T Premium",
        price: "₹ 5,999/-",
        details: [
            "Backlinks from Blog Sites",
            "Total 19,200 Backlinks",
            "1,200 Tier 1 Backlinks",
            "3,600 Tier 2 Backlinks",
            "14,400 Tier 3 Backlinks",
            "For 1 Website URL",
            "For up to 10 Keywords",
            "100% DoFollow Backlinks",
            "Permanent Backlinks",
            "Free Premium Indexing",
            "Detailed Backlinks Report",
            "White Label Report",
            "Report in 30 Working Days",
        ],
        buttonText: "Add to cart",
        backgroundColor: "#ffffff",
        textColor: "#000000",
        buttonColor: "#FF9800",
    },
    {
        id: 12,
        title: "3T Ultimate",
        price: "₹ 9,999/-",
        details: [
            "Backlinks from Blog Sites",
            "Total 38,400 Backlinks",
            "2,400 Tier 1 Backlinks",
            "7,200 Tier 2 Backlinks",
            "28,800 Tier 3 Backlinks",
            "For 1 Website URL",
            "For up to 10 Keywords",
            "100% DoFollow Backlinks",
            "Permanent Backlinks",
            "Free Premium Indexing",
            "Detailed Backlinks Report",
            "White Label Report",
            "Report in 30 Working Days",
        ],
        buttonText: "Add to cart",
        backgroundColor: "#ffffff",
        textColor: "#000000",
        buttonColor: "#FF9800",
    },
];

const PricingCard = ({ plan, isFirstCard }) => {

    const [inCart, setInCart] = useState(false); // State to track if the item is in the cart

    useEffect(() => {
        // Check if the item is in the cart when the component mounts
        const existingCart = Cookies.get("cart") ? JSON.parse(Cookies.get("cart")) : [];
        const isItemInCart = existingCart.some((item) => item.id === plan.id);
        setInCart(isItemInCart); // Update state based on cookies
    }, [plan.id]);

    const handleAddToCart = () => {
        const existingCart = Cookies.get("cart") ? JSON.parse(Cookies.get("cart")) : [];
        const isItemInCart = existingCart.some((item) => item.id === plan.id);

        if (!isItemInCart) {
            // Add the item to the cart
            const updatedCart = [...existingCart, { id: plan.id, title: plan.title, price: plan.price }];
            Cookies.set("cart", JSON.stringify(updatedCart), { expires: 7 }); // Expires in 7 days
            setInCart(true); // Update state
        }
    };

    const handleRemoveFromCart = () => {
        const existingCart = Cookies.get("cart") ? JSON.parse(Cookies.get("cart")) : [];
        const updatedCart = existingCart.filter((item) => item.id !== plan.id);
        Cookies.set("cart", JSON.stringify(updatedCart), { expires: 7 }); // Update cookies
        setInCart(false); // Update state
    };
    return (
        <Card
            sx={{
                backgroundColor: isFirstCard ? '#002966' : plan.backgroundColor,
                color: isFirstCard ? '#ffffff' : plan.textColor,
                height: "auto",
                borderRadius: "0px",
                boxShadow: "0 4px 15px rgba(0, 0, 0, 0.3)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                position: "relative",
                padding: "20px",
            }}
        >
            <CardContent>
                <Typography variant="h6" fontWeight="bold" sx={{ mt: 2 }}>
                    {plan.title}
                </Typography>
                <Typography
                    sx={{
                        fontSize: '16px',
                        textDecoration: 'line-through',
                        textDecorationStyle: 'solid',
                        textDecorationColor: 'red',
                        textDecorationThickness: '2px',
                        whiteSpace: 'nowrap',
                        fontWeight: 600
                    }}
                >
                    ₹ {(parseFloat(plan.price.replace(/[^0-9.-]+/g, ""))) + (parseFloat(plan.price.replace(/[^0-9.-]+/g, "")) * 0.30)} /-

                </Typography>

                <Typography variant="h4" fontWeight="bold">
                    {plan.price}
                </Typography>
                <Box sx={{ paddingTop: "3px", paddingBottom: "10px" }}>
                    {inCart ? (
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: "red",
                                color: "#ffffff",
                                width: "100%",
                                padding: "10px",
                                borderRadius: "5px",
                                textTransform: "none",
                                textAlign: "center",
                                fontWeight: "bold",

                            }}
                            onClick={() => handleRemoveFromCart()}
                        >
                            Remove from Cart
                        </Button>
                    ) : (
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: isFirstCard ? '#ff9800' : plan.buttonColor,
                                color: "#ffffff",
                                width: "100%",
                                padding: "10px",
                                borderRadius: "5px",
                                textTransform: "none",
                                textAlign: "center",
                                fontWeight: "bold",

                            }}
                            onClick={() => handleAddToCart()}
                        >
                            {plan.buttonText} <ShoppingCartOutlinedIcon />
                        </Button>
                    )}
                </Box>
            </CardContent>

            {/* Details will be stacked below the button */}
            <CardContent>
                {plan.details.map((detail, index) => (
                    <Typography
                        key={index}
                        sx={{ fontSize: "14px", lineHeight: "20px", marginBottom: "10px" }}
                    >
                        - {detail}
                    </Typography>
                ))}
            </CardContent>
        </Card>
    );
};

const PremiumBacklinksPricing3 = () => {
    return (
        <>
            <Box
                sx={{
                    background: "linear-gradient(135deg, #002966 30%, #005AE2 90%)",
                    padding: "40px 0",
                    color: "#ffffff",
                }}
            >
                <Tier3image />

                <Box sx={{ mx: "auto", maxWidth: "1200px", px: 3, mb: 5 }}>

                </Box>
                <Grid container justifyContent="center" alignItems="stretch">
                    {pricingPlans.map((plan, index) => (
                        <Grid item xs={12} sm={6} md={2.4} key={index}>
                            <PricingCard plan={plan} isFirstCard={index === 0} />
                        </Grid>
                    ))}
                </Grid>

            </Box>
        </>
    );
};

export default PremiumBacklinksPricing3;
