import React, { useState } from 'react';
import {
  Box, Button, Typography, Stack, SvgIcon, Dialog,
} from '@mui/material';
import GroupIcon from '@mui/icons-material/Group';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import UserForm from './UserForm';
import { useTheme } from "../../../../../common/ThemeContext";


const InviteFirstUser = () => {
  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  
  const { theme } = useTheme();


  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      paddingTop={10}
     sx={{  backgroundColor: theme.palette.mode !== "dark" ? "#1c1c1e" : "#fbf9fb",}}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        p={3}
        sx={{
          textAlign: 'center',
        }}
      >
        <Box
          sx={{
            width: 80,
            height: 80,
            backgroundColor: '#6f42c1',
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            mb: 2,
          }}
        >
          <SvgIcon component={GroupIcon} sx={{ fontSize: 40, color: '#fff' }} />
        </Box>
        <Typography variant="h5" fontWeight="bold" gutterBottom sx={{color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000",}}>
          Feeling lonely?
          <br />
          Let’s change that
        </Typography>
        <Typography variant="body1" color="textSecondary" mb={2} sx={{ width: '550px' ,color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000",}}>
          Did you know you can add users to your account and assign them to specific projects?
        </Typography>
        <Button
          variant="contained"
          color="warning"
          endIcon={<ArrowForwardIcon />}
          onClick={handleOpenDialog}
          sx={{ textTransform: 'none', fontWeight: 'bold', px: 4 }}
        >
          Invite my first user
        </Button>
      </Box>

      <UserForm open={openDialog} onClose={handleCloseDialog} />
    </Box>
  );
};

export default InviteFirstUser;
