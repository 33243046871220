import React from 'react';
import { Box, CssBaseline, Grid } from '@mui/material';
import { useTheme } from '../../common/ThemeContext';
import KeywordHome from '../keywords/KeywordHome';
import Dashboardheader from '../common/DashboardHeader';
import KeywordSideicon from '../common/KeywordSideicon';
import { useLocation } from 'react-router-dom';


const Keywords = () => {
    const { theme } = useTheme();
    const location = useLocation();

    return (
        <>
            <Grid sx={{ display: "flex", width: "100%", px: 1 }}>
            <Dashboardheader pathname={location.pathname} />
            <KeywordSideicon />

                {/* Main Content */}
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        bgcolor: theme.palette.mode !== 'dark' ? '#1c191f' : '#f8f3f7',
                        color: '#fff',
                        p: 3,
                        ml: -2,
                        marginTop: 13,
                    }}
                >
                    <KeywordHome />
                    {/* <DashBoardFooter /> */}
                </Box>
            </Grid>
        </>
    );
};

export default Keywords;
