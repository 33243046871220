import React from 'react'
import Improvesite from './Improvesite'
import SeoReport from './SeoReport'
import TabContentSeo from './TabContentSeo'
import { Grid } from '@mui/material'
import Header from '../../../common/home/Header'
import Footer from '../../../common/Footer'

const SeoAuditHome = () => {
  return (
    <Grid>
        <Header/>
        <SeoReport/>
        <Improvesite/>
        <TabContentSeo/>
        <Footer/>
    </Grid>
  )
}

export default SeoAuditHome