import React from 'react';
import { Typography, Button, Box, Grid } from '@mui/material';
import exampleImage from '../../../../assets/Screenshot.png'; 

function Improvesite() {
  return (   
      <Grid sx={{ display: 'flex',   }} >
        {/* Main Section */}
        <Grid item xs={12}>
          <Grid container spacing={4}  sx={{ mt: 4, p: 2, backgroundColor: '#f0f4ff', borderRadius: '5px' }}>
            {/* Left Image Section */}
            <Grid item xs={12} md={6} textAlign={'right'}>
              <Box
                component="img"
                src={exampleImage} 
                alt="Descriptive Alt Text"
                sx={{ width: '80%', height: 'auto', borderRadius: '5px 0 0 5px',alignItems:'center', }}
              />
            </Grid>

            {/* Right Content Section */}
            <Grid item xs={12} md={6} textAlign={'left'}>
              <Typography variant="h5" component="h2" gutterBottom>
                Improve Your Site With Our DIY SEO Tool
              </Typography>
              <Typography variant="body1" color="textSecondary" gutterBottom >
                Review unlimited pages. Crawl your whole site for problems and get clear,
                 easy to follow recommendations with guides for your CMS. Monitor your keyword rankings and get 
                 regular updates on your site's performance.Review unlimited pages. Crawl your whole site for problems and get clear,
                 easy to follow recommendations with guides for your CMS. Monitor your keyword rankings and get 
                 regular updates on your site's performance.Review unlimited pages. Crawl your whole site for problems and get clear,
                 easy to follow recommendations with guides for your CMS. Monitor your keyword rankings and get 
                 regular updates on your site's performance.
              </Typography>
              <Button variant="contained" color="primary" sx={{mt:2}}>
                Learn More - DIY SEO
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
  );
}

export default Improvesite;
