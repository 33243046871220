import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";

export const FunFacts = ({
    colleges = 1000,
    students = 800,
    advisorRating = 640,
}) => {
    // States for animated values
    const [animatedColleges, setColleges] = useState(0);
    const [animatedStudents, setStudents] = useState(0);
    const [animatedAdvisorRating, setAdvisorRating] = useState(0);

    useEffect(() => {
        // Define target values and increments
        const endColleges = colleges;
        const endStudents = students;
        const endAdvisorRating = advisorRating;

        const incrementColleges = Math.ceil(endColleges / 100);
        const incrementStudents = Math.ceil(endStudents / 100);
        const incrementRating = Math.ceil(endAdvisorRating / 100);

        let start = 0;

        const interval = setInterval(() => {
            start += 1;
            setColleges((prev) => (prev < endColleges ? prev + incrementColleges : endColleges));
            setStudents((prev) => (prev < endStudents ? prev + incrementStudents : endStudents));
            setAdvisorRating((prev) => (prev < endAdvisorRating ? prev + incrementRating : endAdvisorRating));

            if (start >= 100) {
                clearInterval(interval);
            }
        }, 10);

        // Cleanup interval on component unmount
        return () => clearInterval(interval);
    }, [colleges, students, advisorRating]);

    return (
        <Box sx={{ backgroundColor: "white", padding: 6 }}>
            <Typography
                variant="h4"
                
                sx={{ fontWeight: 'bold', mb: 2, color: '#1f1f25', textAlign: 'center' ,fontSize:'36px',}}
            >
                Our Fun Facts
            </Typography>
            <Grid container justifyContent="center" spacing={4}>

                {/* Registered Colleges */}
                <Grid item xs={6} sm={6} md={4} textAlign="center">
                    <Typography
                        variant="h5"
                        component="div"
                        fontWeight="bold"
                        sx={{
                            fontSize: {
                                xs: "1.5rem",
                                sm: "2rem",
                                md: "72px",
                            },
                            color: "#f9004d",
                            fontWeight: 600,
                        }}
                    >
                        {animatedColleges}+
                    </Typography>
                    <Typography variant="body1" sx={{ color: "#5d5d62", fontSize:'18px', lineHeight:'30px' }}>
                        5+ years of experience Since 2016, we've created best-in-class
                        digital marketing campaigns for hundreds of businesses across many
                        industries.
                    </Typography>
                </Grid>

                {/* Happy Students */}
                <Grid item xs={6} sm={6} md={4} textAlign="center">
                    <Typography
                        variant="h5"
                        component="div"
                        fontWeight="bold"
                        sx={{
                            fontSize: {
                                xs: "1.5rem",
                                sm: "2rem",
                                md: "72px",
                            },
                            color: "#f9004d",
                            fontWeight: 600,
                        }}
                    >
                        {animatedStudents}+
                    </Typography>
                    <Typography variant="body1" sx={{ color: "#5d5d62" , fontSize:'18px', lineHeight:'30px'}}>
                        We have worked with over 40 clients in various industries such as
                        edtech, real estate, tourism, etc and spent the past few years
                        refining our process to deliver the best possible services.
                    </Typography>
                </Grid>

                {/* Advisor Rating */}
                <Grid item xs={6} sm={6} md={4} textAlign="center">
                    <Typography
                        variant="h5"
                        component="div"
                        fontWeight="bold"
                        sx={{
                            fontSize: {
                                xs: "1.5rem",
                                sm: "2rem",
                                md: "72px",
                            },
                            color: "#f9004d",
                            fontWeight: 600,
                        }}
                    >
                        {animatedAdvisorRating}+
                    </Typography>
                    <Typography variant="body1" sx={{ color: "#5d5d62", fontSize:'18px', lineHeight:'30px' }}>
                        We've worked with clients across the globe to help them improve
                        their leads and sales. From startups to enterprise companies, it's
                        all about helping businesses succeed.
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
};
