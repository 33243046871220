import React from 'react';
import { Grid, Box, Typography, List, ListItem, useMediaQuery } from '@mui/material';
import Footer from '../../common/Footer';
import Header from '../../common/home/Header';

const PrivacyPolicy = () => {
  const isMobile = useMediaQuery("(min-width:360px) and (max-width:500px)");

  return (
    <Grid sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', overflowY: 'auto', overflowX: 'hidden', background: 'linear-gradient(to right, rgba(226, 255, 255, 0.26), rgba(0, 180, 194, 0.16))' }}>
      <Header />
      <Box sx={{ flexGrow: 1, px: 10, marginTop: "50px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>

            {/* Refund Policy Section */}
            <Typography variant="h2" sx={{ marginBottom: 4, fontSize: { xs: '1.5rem', md: '2.25rem' }, fontWeight: 700, textAlign: 'center', color: '#343f52' }}>
              Refund Policy
            </Typography>

            <Typography variant="h4" sx={{ marginBottom: 4, fontSize: { xs: '0.8rem', md: '1rem' }, fontWeight: 400, color: '#60697b', lineHeight: '1.5rem' }}>
              At SEOMitra, we are committed to providing high-quality SEO services to our clients. We understand that there may be circumstances where a refund is necessary. Please review the following refund policy for the services we provide.
            </Typography>

            {/* Section 1 */}
            <Typography variant="h3" sx={{ marginBottom: 2, fontSize: { xs: '1.2rem', md: '1.325rem' }, fontWeight: 700, color: '#343f52' }}>
              1. Refund Eligibility
            </Typography>
            <Typography variant="h4" sx={{ marginBottom: 4, fontSize: { xs: '0.8rem', md: '1rem' }, fontWeight: 400, color: '#60697b', lineHeight: '1.5rem' }}>
              Refunds may be issued under the following circumstances:
              <List sx={{ marginLeft: 2, marginTop: 1, listStyleType: 'disc', paddingLeft: 2 }}>
                <ListItem sx={{ display: 'list-item', padding: 0 }}>
                  <strong>Services not delivered as promised:</strong> If we fail to deliver the SEO services or products purchased, you may request a refund within 15 days of purchase.
                </ListItem>
                <ListItem sx={{ display: 'list-item', padding: 0 }}>
                  <strong>Payment errors:</strong> In the event that you are charged incorrectly, please contact us immediately, and we will process a refund within 6-7 business days.
                </ListItem>
              </List>
            </Typography>

            {/* Section 2 */}
            <Typography variant="h3" sx={{ marginBottom: 2, fontSize: { xs: '1.2rem', md: '1.325rem' }, fontWeight: 700, color: '#343f52' }}>
              2. Non-Refundable Services
            </Typography>
            <Typography variant="h4" sx={{ marginBottom: 4, fontSize: { xs: '0.8rem', md: '1rem' }, fontWeight: 400, color: '#60697b', lineHeight: '1.5rem' }}>
              Please note the following services are non-refundable:
              <List sx={{ marginLeft: 2, marginTop: 1, listStyleType: 'disc', paddingLeft: 2 }}>
                <ListItem sx={{ display: 'list-item', padding: 0 }}>
                  <strong>Subscription-based services:</strong> Any recurring subscription services, such as ongoing SEO monitoring, are non-refundable after the first 15 days of service.
                </ListItem>
                <ListItem sx={{ display: 'list-item', padding: 0 }}>
                  <strong>Custom SEO projects:</strong> Once work on a custom SEO strategy has begun, refunds will not be provided, as the services are personalized to your business needs.
                </ListItem>
              </List>
            </Typography>

            {/* Section 3 */}
            <Typography variant="h3" sx={{ marginBottom: 2, fontSize: { xs: '1.2rem', md: '1.325rem' }, fontWeight: 700, color: '#343f52' }}>
              3. Refund Request Process
            </Typography>
            <Typography variant="h4" sx={{ marginBottom: 4, fontSize: { xs: '0.8rem', md: '1rem' }, fontWeight: 400, color: '#60697b', lineHeight: '1.5rem' }}>
              To request a refund, please contact our support team at <strong>contact@seomitra.com</strong> with the following details:
              <List sx={{ marginLeft: 2, marginTop: 1, listStyleType: 'disc', paddingLeft: 2 }}>
                <ListItem sx={{ display: 'list-item', padding: 0 }}>Order number and date of purchase</ListItem>
                <ListItem sx={{ display: 'list-item', padding: 0 }}>The service or product you purchased</ListItem>
                <ListItem sx={{ display: 'list-item', padding: 0 }}>The reason for the refund request</ListItem>
              </List>
              We will review your request and respond within 15 business days. If approved, refunds will be processed to the original method of payment.
            </Typography>

            {/* Section 4 */}
            <Typography variant="h3" sx={{ marginBottom: 2, fontSize: { xs: '1.2rem', md: '1.325rem' }, fontWeight: 700, color: '#343f52' }}>
              4. Timeframe for Refunds
            </Typography>
            <Typography variant="h4" sx={{ marginBottom: 4, fontSize: { xs: '0.8rem', md: '1rem' }, fontWeight: 400, color: '#60697b', lineHeight: '1.5rem' }}>
              Refunds, if applicable, will be processed within <strong>[X]</strong> business days from the approval of your request. The amount will be credited to your original payment method.
            </Typography>

            {/* Section 5 */}
            <Typography variant="h3" sx={{ marginBottom: 2, fontSize: { xs: '1.2rem', md: '1.325rem' }, fontWeight: 700, color: '#343f52' }}>
              5. Modifications to the Refund Policy
            </Typography>
            <Typography variant="h4" sx={{ marginBottom: 4, fontSize: { xs: '0.8rem', md: '1rem' }, fontWeight: 400, color: '#60697b', lineHeight: '1.5rem' }}>
              SEOMitra reserves the right to modify this Refund Policy at any time. Any changes will be posted on this page, and the new policy will be effective immediately after posting.
            </Typography>

            {/* Section 6 */}
            <Typography variant="h3" sx={{ marginBottom: 2, fontSize: { xs: '1.2rem', md: '1.325rem' }, fontWeight: 700, color: '#343f52' }}>
              6. Contact Us
            </Typography>
            <Typography variant="h4" sx={{ marginBottom: 4, fontSize: { xs: '0.8rem', md: '1rem' }, fontWeight: 400, color: '#60697b', lineHeight: '1.5rem' }}>
              If you have any questions or concerns about our refund policy, please contact us at:
              <List sx={{ marginLeft: 2, marginTop: 1, listStyleType: 'disc', paddingLeft: 2 }}>
                <ListItem sx={{ display: 'list-item', padding: 0 }}>
                  <strong>Email:</strong> <a href="mailto:contact@seomitra.com">contact@seomitra.com</a>
                </ListItem>
                <ListItem sx={{ display: 'list-item', padding: 0 }}>
                  <strong>Phone:</strong> [Your Contact Number]
                </ListItem>
                <ListItem sx={{ display: 'list-item', padding: 0 }}>
                  <strong>Address:</strong> [Insert Address]
                </ListItem>
              </List>
            </Typography>


          </Grid>
        </Grid>
      </Box>
      <Footer />
    </Grid>
  );
}

export default PrivacyPolicy;
