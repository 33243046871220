import React, { useEffect, useState } from "react";
import { Box, Container, Typography, Grid, Button } from "@mui/material";
import image from "../../../../../assets/cat.png";
import image1 from "../../../../../assets/star.png";
import image2 from "../../../../../assets/banner-01.png";

import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { config } from "../../../../../config/config";
import { apiList, invokeApi } from "../../../../../services/apiServices";
import { useNavigate } from "react-router-dom";
function Pricing() {
  const annualStyle = {
    color: "inherit", // Default color
    transition: "color 0.3s",
  };
  const [ourPackages, setOurPackages] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOurPackages = async () => {
      const params = {};
      try {
        const response = await invokeApi(
          config.getMyCollege + apiList.getOurPackages,
          params
        );
        console.log(params);
        if (response?.status === 200) {
          setOurPackages(response.data.packages);
          console.log(response.data);
        } else {
          console.error("Failed to fetch data:", response);
        }
      } catch (error) {
        console.error("Error during data fetch:", error);
      }
    };

    fetchOurPackages();
  }, []);

  const handleGetStarted=(id)=>{
    sessionStorage.setItem("packageId",id)
    navigate('/register');
  }

  // const pricingData = [
  //     {
  //         title: "Lite",
  //         description: "Essential data for small businesses and hobby projects.",

  //         price: "$129",
  //         frequency: "/mo",
  //         buttonText: "Get started",
  //         features: [
  //             "5 projects",
  //             "6 months of history",
  //             "1 user included",
  //             "Up to 2 additional users at $40 /mo per user",
  //             "500 credits per user",
  //             "750 tracked keywords",
  //         ],
  //         color: "#ff8800", // Button color for Lite plan
  //         title1: "Included in Lite:",
  //         features1: [
  //             "Dashboard",
  //             "Site Explorer",
  //             "Keywords Explorer",
  //             "Site Audit",
  //             "Rank Tracker",
  //             "Competitive Analysis",
  //             "SERP history",
  //             "Page inspect",
  //             "Local SEO ",
  //             "Always-on audit ",
  //             "Ahrefs Web Analytics"

  //         ],
  //     },
  //     {
  //         title: "Standard",
  //         description: "Perfect for freelance SEOs and marketing consultants.",
  //         price: "$249",
  //         frequency: "/mo",
  //         buttonText: "Get started",
  //         features: [
  //             "20 projects",
  //             "2 years of history",
  //             "1 user included",
  //             "Up to 5 additional users at $60 /mo per user",
  //             "Unlimited credits per user",
  //             "2,000 tracked keywords",
  //         ],
  //         color: "#ff8800", // Button color for Standard plan
  //         title1: 'All in Lite, plus:',
  //         features1: ["Portfolios New",
  //             "Content Explorer",
  //             "Batch Analysis",
  //             "SERP comparison",
  //             "More in Site Explorer",
  //             "Site structure",
  //             "Outgoing links",
  //             "Calendar view",
  //             "Linking authors New",
  //             "Linking topics Soon",
  //             "More in Keywords Explorer",
  //             "AI suggestions New",
  //             "Keyword clusters New",
  //             "Search Intents New",
  //             "Translations New",
  //             "SERP updates",
  //             "More in Rank Tracker",
  //             "Import historical data Soon",
  //             "AI Content Helper New",
  //         ],

  //     },
  //     {
  //         title: "Advanced",
  //         description: "More tools and data for lean in-house marketing teams.",
  //         price: "$449",
  //         frequency: "/mo",
  //         buttonText: "Get started",
  //         features: [
  //             "50 projects",
  //             "5 years of history",
  //             "1 user included",
  //             "Up to 10 additional users at $80 /mo per user",
  //             "Unlimited credits per user",
  //             "5,000 tracked keywords",
  //         ],
  //         color: "#ff8800", // Button color for Advanced plan
  //         title1: 'All in Standard, plus:',
  //         features1: ["Portfolios New",
  //             "Looker Studio integration",
  //             "Web Explorer",
  //             "Images, Video, News search volume ",
  //             "Agency directory listing",
  //             "More in Site Explorer:",
  //             "•Forecasting",
  //             "•Segmentation ",
  //             "More in Site Audit:",
  //             "•HTTP authentication",
  //             "Instant recrawl",
  //             "More in Rank Tracker:",
  //             "•Local Ranking Heatmaps",
  //             "Report Builder",

  //         ],

  //     },
  //     {
  //         title: "Enterprise",
  //         description: "Ideal for agencies and enterprises. ",
  //         price: "$14,990",
  //         frequency: "/yr",
  //         buttonText: "Talk to us",
  //         features: [
  //             "100 projects",
  //             "Unlimited history",
  //             "3 users included",
  //             "Unlimited additional users at $1,000 /yr per user",
  //             "Unlimited credits per user",
  //             "10,000 tracked keywords",
  //         ],
  //         color: "#ff8800", // Button color for Enterprise plan
  //         title1: 'All in Advanced, plus:',
  //         features1: ["API:",
  //             "•Ahrefs API v3",
  //             "•Rank Tracker (free) New",
  //             "•Web Explorer",
  //             "AI Content Grader",
  //             "Pay by invoice",
  //             "Access management",
  //             "Audit log ",
  //             "SSO",
  //             "More in Site Audit:",
  //             "•Patches ",
  //             "More in Report Builder:",
  //             "•Advanced widgets",
  //             "•Report sharing",
  //             "•Report scheduling",
  //             "•PDF reports",
  //             "Ads Analytics  Soon",

  //         ],

  //     },
  // ];

  return (
    <Grid
      container
      sx={{
        background: "linear-gradient(135deg, #002966 30%, #005AE2 90%)",
        overflow: "hidden",
      }}
    >
      <Container maxWidth="lg">
        <Grid container alignItems="center">
          <Grid item xs={12}>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <Typography
                variant="h4"
                style={{
                  fontWeight: "bold",
                  marginBottom: "16px",
                  color: "white",
                  fontFamily: '"Ahrefs", "sans-serif", "Zen"',
                  fontSize: "92px",
                  lineHeight: "80px",
                  fontWeight: 400,
                  paddingTop: 50,
                }}
              >
                Plans & Pricing
              </Typography>
              <Typography
                variant="body1"
                style={{
                  color: "white",
                  fontFamily:
                    '"Inter", "Helvetica", "Arial", "sans-serif", "Zen"',
                  fontSize: "18px",
                  lineHeight: "29px",
                }}
              >
                We’ve been crawling the entire web 24/7 since 2010, indexing and{" "}
                <br /> structuring petabytes of information. Get access to these
                insights with <br /> an Ahrefs subscription and use them to
                improve your business.
              </Typography>

              <Typography
                variant="body1"
                sx={{
                  fontFamily:
                    '"Inter", "Helvetica", "Arial", "sans-serif", "Zen"',
                  fontSize: "32px",
                  lineHeight: "84px",
                  fontWeight: "400",
                  marginTop: "30px",
                  color: "white",
                }}
              >
                <span
                  style={{
                    color: "#ff8800",
                    borderBottom: "3px solid transparent",
                    borderBottomColor: "#ff8800",
                  }}
                >
                  Pay monthly
                </span>
                <span> / </span>
                <span
                  style={annualStyle}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.color = "#ff8800")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.color = "inherit")
                  }
                >
                  Pay annually, get 2 months free [Coming Soon.... ]
                </span>
              </Typography>
            </Box>
          </Grid>
        </Grid>

        {/* Pricing section */}
        <Grid container justifyContent="center" style={{ marginTop: "50px" }}>
          {ourPackages.map((plan, index) => (
            <Grid item xs={12} md={3} key={index}>
              <Box
                sx={{
                  backgroundColor: "#fff",
                  padding: "20px",
                  boxShadow: "0 4px 15px rgba(0, 0, 0, 0.3)",
                  textAlign: "left",

                  height: "100%",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: '"Ahrefs", "sans-serif", "Zen"',
                    fontSize: "22px",
                    lineHeight: "28px",
                    fontWeight: 700,
                  }}
                >
                  {plan.name}
                </Typography>
                {/* <Typography variant="body1" sx={{ margin: "10px 0", fontSize: "16px", lineHeight: "24px", fontWeight: 400, color: '#6d6d6d' }}>
                                    {plan.description}
                                </Typography> */}
                <Typography
                  variant="body1"
                  sx={{
                    margin: "10px 0",
                    fontSize: "16px",
                    lineHeight: "24px",
                    fontWeight: 400,
                    color: "#6d6d6d",
                  }}
                >
                  start at
                </Typography>
                <Typography
                  variant="h4"
                  sx={{ fontWeight: "bold", fontSize: "48px" }}
                >
                  ₹ {plan.price}
                  <Typography
                    component="span"
                    sx={{ fontSize: "20px", marginLeft: "5px" }}
                  >
                    /{plan.priceType}
                  </Typography>
                </Typography>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#ff8800",
                    color: "#fff",
                    fontWeight: "bold",
                    marginTop: "20px",
                    padding: "10px 20px",
                    "&:hover": {
                      backgroundColor: "#ff8800",
                      opacity: 0.9,
                    },
                  }}
                  onClick={()=>handleGetStarted(plan.id)}
                >
                  GET STARTED
                </Button>
                <Typography
                  sx={{
                    borderBottom: "3px solid transparent",
                    borderBottomColor: "#28ae88",
                    marginTop: "20px",
                  }}
                ></Typography>

                <Box
                  sx={{
                    marginTop: "20px",
                    textAlign: "left",
                    padding: "0 10px",
                  }}
                >
                  <Typography
                   variant="body2"
                      sx={{
                        marginBottom: "8px",
                        fontSize: "17px",
                        lineHeight: "29px",
                        "&:hover": {
                          backgroundColor: "lightGrey",
                          cursor: "pointer",
                        },
                        padding: "5px", // Optional: Add padding for better hover effect
                      }}>
                        {plan.keywords} Keywords
                      </Typography>
                  {plan.features.split(", ").map((feature, i) => (
                    <Typography
                      key={i}
                      variant="body2"
                      sx={{
                        marginBottom: "8px",
                        fontSize: "17px",
                        lineHeight: "29px",
                        "&:hover": {
                          backgroundColor: "lightGrey",
                          cursor: "pointer",
                        },
                        padding: "5px", // Optional: Add padding for better hover effect
                      }}
                    >
                      {feature.trim()}
                    </Typography>
                  ))}
                </Box>

                {/* <Box sx={{ marginTop: "20px", textAlign: "left", padding: "0 10px" }}>
                                    {plan.features.map((feature, i) => (
                                        <Typography
                                            key={i}
                                            variant="body2"
                                            sx={{
                                                marginBottom: "8px",
                                                fontSize: "17px",
                                                lineHeight: "29px",
                                                '&:hover': {
                                                    backgroundColor: 'lightGrey',
                                                    cursor: 'pointer',
                                                },
                                                padding: '5px', // Optional: Add padding for better hover effect
                                            }}
                                        >
                                            {feature}
                                        </Typography>
                                    ))}
                                </Box>

                                <Typography sx={{ borderBottom: '1px solid transparent', borderBottomColor: "lightGrey", marginTop: '20px' }}>

                                </Typography>
                                <Typography variant="h6" sx={{
                                    fontFamily: '"Ahrefs", "sans-serif", "Zen"',
                                    fontSize: '20px',
                                    lineHeight: '28px',
                                    fontWeight: 700,
                                    marginTop: '20px'
                                }}>
                                    {plan.title1}
                                </Typography>
                                <Box sx={{ marginTop: "20px", textAlign: "left", padding: "0 10px", }}>
                                    {plan.features1.map((feature, i) => (
                                        <Typography key={i} variant="body2" sx={{
                                            fontSize: "17px",
                                            lineHeight: "29px",
                                            '&:hover': {
                                                backgroundColor: 'lightGrey',
                                                cursor: 'pointer',
                                            },
                                        }}>
                                            {feature}
                                        </Typography>
                                    ))}
                                </Box>*/}
              </Box>
            </Grid>
          ))}
        </Grid>
        <Grid container sx={{ padding: "30px 0" }}>
          <Grid
            item
            xs={12}
            md={12}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Box
              sx={{
                backgroundColor: "#003fc1",

                color: "white",
                padding: "20px 30px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Grid item xs={12} md={1}>
                <img
                  src={image1}
                  alt="cat image"
                  style={{ height: "50px", width: "60px", alignItems: "right" }}
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <Box>
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: "22px",
                      fontWeight: "bold",
                      display: "inline-block",
                    }}
                  >
                    Starter
                  </Typography>
                  <Box
                    sx={{
                      backgroundColor: "#ffcc00",
                      display: "inline-block",
                      padding: "0 8px",

                      marginLeft: "10px",
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: "#000",
                    }}
                  >
                    New
                  </Box>
                  <Typography
                    variant="body2"
                    sx={{
                      marginTop: "8px",
                      fontSize: "15px",
                      lineHeight: "24px",
                      color: "#8fa3d7",
                    }}
                  >
                    Limited access to Site Explorer, Keywords Explorer, and Site
                    Audit for those who are just starting out.
                  </Typography>
                  <Typography
                    variant="h6"
                    style={annualStyle}
                    onMouseEnter={(e) =>
                      (e.currentTarget.style.color = "#ff8800")
                    }
                    onMouseLeave={(e) =>
                      (e.currentTarget.style.color = "inherit")
                    }
                    sx={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      display: "inline-block",
                    }}
                  >
                    Learn more ↗
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                md={3}
                sx={{ position: "relative", bottom: "30%", left: "5%" }}
              >
                <img
                  src={image}
                  alt="cat image"
                  style={{
                    height: "140px",
                    width: "90px",
                    alignItems: "right",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Box
                  display="flex"
                  alignItems="center"
                  sx={{ textAlign: "right", gap: "30px" }}
                >
                  <Typography
                    variant="h4"
                    sx={{ fontSize: "36px", fontWeight: "bold" }}
                  >
                    $29
                    <Typography
                      component="span"
                      sx={{ fontSize: "16px", marginLeft: "5px" }}
                    >
                      /mo
                    </Typography>
                  </Typography>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#ff8800",
                      color: "#fff",
                      fontWeight: "bold",
                      marginLeft: "10px",
                      padding: "10px 20px",
                      fontSize: "14px",
                      "&:hover": {
                        backgroundColor: "#ff8800",
                        opacity: 0.9,
                      },
                    }}
                  >
                    Get started
                  </Button>
                </Box>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Grid containers sx={{ padding: "50px 0" }}>
          <Grid
            item
            xs={12}
            md={12}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Box
              sx={{
                backgroundColor: "#003fc1",

                color: "white",
                padding: "20px 30px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Grid item xs={12} md={6}>
                <Button
                  variant="outlined"
                  sx={{
                    backgroundColor: "#054ada",
                    color: "white",

                    fontWeight: "700",
                    marginLeft: "10px",

                    fontSize: "18px",

                    lineHeight: "24px",
                  }}
                >
                  Webmaster Tools
                </Button>
                <Typography
                  sx={{
                    fontWeight: "400",
                    fontSize: "48px",
                    lineHeight: "48px",
                    marginTop: "20px",
                  }}
                >
                  Get started for free
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "400",
                    fontSize: "18px",
                    lineHeight: "28px",
                    marginTop: "20px",
                  }}
                >
                  Try Ahrefs for as long as you like with our Webmaster Tools.
                  Get free limited access to Site Explorer and Site Audit. It’s
                  absolutely free for website owners.{" "}
                </Typography>

                <Button
                  variant="outlined"
                  sx={{
                    backgroundColor: "#ff8800",
                    color: "white",
                    borderColor: "#5385EE", // Replace colorLink with borderColorLink
                    fontWeight: "bold",
                    marginLeft: "10px", // Add space between price and button
                    padding: "10px 20px",
                    fontSize: "14px",
                    marginTop: "20px",
                    lineHeight: "24px",
                    boxSizing: "border-box",
                  }}
                >
                  Get Started
                </Button>
                <Button
                  variant="outlined"
                  sx={{
                    backgroundColor: "#003fc1",
                    color: "white",
                    borderColor: "#5385EE", // Replace colorLink with borderColorLink
                    fontWeight: "bold",
                    marginLeft: "10px", // Add space between price and button
                    padding: "10px 20px",
                    fontSize: "15px",
                    marginTop: "20px",
                    lineHeight: "24px",
                    boxSizing: "border-box",
                    "&:hover": {
                      color: "#F80",
                      borderColor: "#F80", // Replace colorLink with borderColorLink in hover state
                      opacity: 0.9,
                    },
                  }}
                >
                  Learn More ↗
                </Button>
              </Grid>

              <Grid
                item
                xs={12}
                md={6}
                sx={{ position: "relative", left: "3%" }}
              >
                <img src={image2} alt="rocket" height="auto" width="100%" />
              </Grid>
            </Box>
          </Grid>
        </Grid>

        {/* <Grid container sx={{  padding: "50px 0" }}>

                    <Grid item xs={12} md={12} style={{ display: 'flex', justifyContent: 'center' }}>
                        <Box
                            sx={{
                                backgroundColor: "#003fc1",

                                color: "white",
                                padding: "20px 30px",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "100%",
                            }}
                        >
                            <Grid item xs={12} md={6}>
                                <Typography sx={{ fontWeight: '400', fontSize: '48px', lineHeight: '48px', marginTop: '20px' }}>
                                    Introducing Report Builder, AI Content Helper and more
                                </Typography>
                                <Typography sx={{ fontWeight: '400', fontSize: '18px', lineHeight: '28px', marginTop: '20px' }}>
                                    In September, we introduced our very exciting new AI Content Helper tool, customizable report builder, filter presets in Keywords Explorer, and more.
                                </Typography>
                                <Typography sx={{ fontWeight: '400', fontSize: '18px', lineHeight: '28px', marginTop: '20px' }}>
                                    Here's a 3-minute walkthrough of everything new.
                                </Typography>
                                <Button
                                    variant="outlined"

                                    sx={{
                                        backgroundColor: "#003fc1",
                                        color: 'white',
                                        borderColor: "#5385EE", // Replace colorLink with borderColorLink
                                        fontWeight: "bold",
                                        marginLeft: "10px", // Add space between price and button
                                        padding: "10px 20px",
                                        fontSize: "18px",
                                        marginTop: "20px",
                                        lineHeight: "24px",
                                        boxSizing: "border-box",
                                        "&:hover": {
                                            color: '#F80',
                                            borderColor: "#F80", // Replace colorLink with borderColorLink in hover state
                                            opacity: 0.9,
                                        },
                                    }}

                                >
                                    Watch more
                                </Button>
                                <Button
                                    variant="outlined"
                                    sx={{
                                        backgroundColor: "#003fc1",
                                        color: 'white',
                                        borderColor: "#5385EE", // Replace colorLink with borderColorLink
                                        fontWeight: "bold",
                                        marginLeft: "10px", // Add space between price and button
                                        padding: "10px 20px",
                                        fontSize: "18px",
                                        marginTop: "20px",
                                        lineHeight: "24px",
                                        boxSizing: "border-box",
                                        "&:hover": {
                                            color: '#F80',
                                            borderColor: "#F80", // Replace colorLink with borderColorLink in hover state
                                            opacity: 0.9,
                                        },
                                    }}

                                >
                                    Read post
                                </Button>



                            </Grid>

                            <Grid item xs={12} md={6}>
                                <iframe
                                    width="100%"
                                    height="315"
                                    src="https://www.youtube.com/embed/CfpsMkRSsVg"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                ></iframe>
                            </Grid>


                        </Box>
                    </Grid>

                </Grid> */}
      </Container>
    </Grid>
  );
}

export default Pricing;
