import React from 'react';
import { Grid, Card, CardContent, Typography, Box, Paper } from '@mui/material';
import { FaPenAlt, FaRegFileAlt, FaKeyboard, FaHeading, FaParagraph, FaClipboardCheck, FaLightbulb, FaBook, FaBookOpen, FaEnvelopeOpenText, FaCheckCircle } from 'react-icons/fa';
import backgroundImage from '../../../../../..//assets/BannerImage1.jpg';

const tools = [
    { name: 'AI Essay Writer', icon: <FaPenAlt /> },
    { name: 'AI Writer', icon: <FaKeyboard /> },
    { name: 'AI Text Generator', icon: <FaRegFileAlt /> },
    { name: 'Title Generator', icon: <FaHeading /> },
    { name: 'Paragraph Generator', icon: <FaParagraph /> },
    { name: 'Essay Title Generator', icon: <FaClipboardCheck /> },
    { name: 'Plot Generator', icon: <FaLightbulb /> },
    { name: 'Thesis Statement Generator', icon: <FaBook /> },
    { name: 'AI Story Generator', icon: <FaBookOpen /> },
    { name: 'Conclusion Generator', icon: <FaCheckCircle /> },
    { name: 'AI Email Writer', icon: <FaEnvelopeOpenText /> },
];

function DashboardAi() {
    return (
        <Grid>
            {/* <Grid md={12}
                sx={{
                    mt: 4,
                    backgroundImage: `url(${backgroundImage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    color: 'white',
                    padding: '20px 10px',
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontFamily: 'Arial, sans-serif',
                }}
            >
                <Box sx={{ textAlign: 'center' }}>
                    <Typography variant="h6" sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: 30 }}>
                        AI Analysis Tools
                    </Typography>

                </Box>
            </Grid> */}
            <Grid container spacing={3} sx={{paddingTop:2}}>
                {/* Left section - Grid of tools */}
                <Grid item xs={12} md={12}>
                    <Paper elevation={3} sx={{ padding: 4, boxShadow: '0 4px 20px rgba(255, 72, 0, 0.1)',mb:2 }}>

                        <Grid container spacing={3}>

                            {tools.map((tool, index) => (
                                <Grid item xs={6} sm={4} md={3} key={index}>
                                    {/* Card with animation on hover */}
                                    <Card
                                        sx={{
                                            textAlign: 'center',
                                            padding: 1,
                                            cursor: 'pointer',
                                            borderRadius: '0px 20px 0px 20px',
                                            boxShadow: '0 4px 20px rgba(255, 72, 0, 0.1)',
                                            height: '70px',
                                            width: '70px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            margin: '0 auto',
                                            position: 'relative',
                                            '&::before, &::after': {
                                                content: '""',
                                                position: 'absolute',
                                                borderRadius: '0px 20px 0px 20px',
                                                width: '0',
                                                height: '0',
                                                background: 'transparent',
                                                border: '4px solid transparent',
                                                transition: 'width 0.8s ease, height 0.8s ease, border-color 0.8s ease',
                                            },
                                            '&::before': {
                                                top: 0,
                                                left: 0,
                                                borderTopColor: 'transparent',
                                                borderLeftColor: 'transparent',
                                                borderRadius: '0px 20px 0px 20px',
                                            },
                                            '&::after': {
                                                bottom: 0,
                                                right: 0,
                                                borderBottomColor: 'transparent',
                                                borderRightColor: 'transparent',
                                            },
                                            '&:hover::before': {
                                                width: '100%',
                                                height: '100%',
                                                borderTopColor: '#f75c20a6',
                                                borderLeftColor: '#f75c20a6',
                                            },
                                            '&:hover::after': {
                                                width: '100%',
                                                height: '100%',
                                                borderBottomColor: '#f75c20a6',
                                                borderRightColor: '#f75c20a6',
                                            },
                                        }}
                                    >
                                        <CardContent sx={{ padding: 0, borderRadius: 2 }}>
                                            <Box sx={{ fontSize: 30, color: '#f75c20a6', mt: 4 }}>
                                                {tool.icon}
                                            </Box>
                                        </CardContent>
                                    </Card>

                                    {/* Text Content Below the Card */}
                                    <Typography variant="subtitle1" sx={{ textAlign: 'center', fontSize: '14px', mt: 1, fontWeight: 'bold' }}>
                                        {tool.name}
                                    </Typography>
                                </Grid>
                            ))}
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default DashboardAi;
