import React from 'react'
import BacklinksPricing2 from './PremiumBacklinksPricing2'


const PremiumTier2BacklinksHome = () => {
  return (
    <>
    
    {/* <PremiumBanner/> */}
   
    <BacklinksPricing2/>
    
    </>
  )
}

export default PremiumTier2BacklinksHome