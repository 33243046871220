import React, { useEffect, useState } from 'react';
import { Container, TextField, Button, Typography, CircularProgress, Box, Paper, Grid } from '@mui/material';
import { AiOutlinePaperClip, AiOutlineCloudUpload } from 'react-icons/ai';
import Header from '../../../common/Header';
import Plagiarismcontent from './Plagiarismcontent';

const TextToSpeech = () => {
  const [inputText, setInputText] = useState('');
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (e) => {
    setInputText(e.target.value);
  };

  const handleTextToSpeech = () => {
    setIsLoading(true);

    try {
      const speech = new SpeechSynthesisUtterance(inputText);
      window.speechSynthesis.speak(speech);

      speech.onstart = () => {
        setIsSpeaking(true);
        setIsLoading(false);
      };

      speech.onend = () => {
        setIsSpeaking(false);
      };

      speech.onerror = (error) => {
        console.error('Speech synthesis error:', error);
        setIsSpeaking(false);
        setIsLoading(false);
      };
    } catch (error) {
      console.error('An error occurred with Text-to-Speech:', error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <Grid
        sx={{
          background: '#fff',
          p: 4,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff9f7',
        }}
      >
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} md={9}>
            <Paper elevation={6} sx={{ padding: '20px', boxShadow: '0 4px 20px rgba(255, 72, 0, 0.1)' }}>
              <Typography variant="h5" color="primary" align="center" gutterBottom>
                Text to Speech
              </Typography>

              <TextField
                label="Enter your text here..."
                variant="outlined"
                fullWidth
                multiline
                rows={10}
                value={inputText}
                onChange={handleInputChange}
                sx={{
                  backgroundColor: '#f5f5f5',
                  borderRadius: '10px',
                }}
              />

              <Box textAlign="center" mt={2}>
                <Button
                  variant="contained"
                  onClick={handleTextToSpeech}
                  disabled={!inputText || isSpeaking || isLoading}
                  size="large"
                  sx={{
                    backgroundColor: '#ff6f61',
                    color: '#fff',
                    '&:hover': {
                      backgroundColor: '#ff8b7f',
                    },
                  }}
                >
                  {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Convert to Speech'}
                </Button>
              </Box>
            </Paper>
          </Grid>

          {/* Ad Section */}
          <Grid item xs={12} md={3}>
            <Paper elevation={6} sx={{ padding: '20px', textAlign: 'center', boxShadow: '0 4px 20px rgba(255, 72, 0, 0.1)' }}>
              <Typography variant="h6">Register To Get Your Pass</Typography>
              <Typography variant="body2" color="textSecondary">
                Global leaders: Tetra Pak, Givaudan, Apical, Cargill, Krones, IFFCO exhibit in 2024
              </Typography>
              <Button variant="contained" color="primary" sx={{ mt: 2 }}>
                Sign Up
              </Button>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <Plagiarismcontent />
    </>
  );
};

export default TextToSpeech;
