import React, { useState } from "react";
import { Grid, Tabs, Tab, Box, CssBaseline } from "@mui/material";
import AddDomainsTab from "./AddDomainsTab";
import AddKeywordsTab from "./AddKeywordsTab";
import AddSearchEnginesTab from "./AddSearchEnginesTab";
import SetUpScheduleTab from "./SetUpScheduleTab";
import { useTheme } from "../../../../../common/ThemeContext";
import DashBoardFooter from "../../../../../common/DashBoardFooter";
import ProjectSummaryCard from "./ProjectSummaryCard";
import Dashboardsideicon from "../../../DashboardMainPages/dashboarddetails/Dashboardsideicon";
import Dashboardheader from "../../../DashboardMainPages/dashboarddetails/Dashboardheader";

const AddWebTabHome = () => {
  const [tab, setTab] = useState(0);
  const [ownDomains, setOwnDomains] = useState([]);
  const [competitorDomains, setCompetitorDomains] = useState([]);
  const [keywords, setKeywords] = useState([]);
  const [searchEngines, setSearchEngines] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const { theme } = useTheme();
  const [completedTabs, setCompletedTabs] = useState({});

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const handleSaveAndContinue = () => {
    setCompletedTabs((prev) => ({ ...prev, [tab]: true }));
    if (tab < 4) {
      setTab(tab + 1);
    }
  };

  const handleBack = () => {
    if (tab > 0) {
      setTab(tab - 1);
    }
  };

  const addSchedule = () => {
    const newId = schedules.length ? schedules[schedules.length - 1].id + 1 : 1;
    const newSchedule = {
      id: newId,
      selectedDays: [],
      time: new Date(),  // Initialize with a Date object
      isActive: true
    };
    setSchedules((prevSchedules) => [...prevSchedules, newSchedule]);
  };

  return (
    <>
      <CssBaseline />
      <Grid container sx={{ minHeight: "100vh", flexDirection: "column" }}>
        <Grid item xs={12} sx={{ marginTop: 2, flexGrow: 0 }}>
          <Dashboardheader />
        </Grid>

        <Grid item container sx={{ flexGrow: 1 }}>
          {/* Sidebar */}
          <Grid item sx={{ width: '80px' }}>
            <Dashboardsideicon />
          </Grid>

          {/* Main Content */}
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              backgroundColor: theme.palette.mode !== "dark" ? "#1c1c1e" : "#fbf9fb",
              color: '#fff',
              ml: -2,
              marginTop: 13,
              pb: 8
            }}
          >
            <Grid container direction="column" sx={{ padding: 4 }}>
              <Grid item sx={{ mb: 3, width: "100%" }}>
                <Tabs
                  value={tab}
                  onChange={handleTabChange}
                  textColor="primary"
                  indicatorColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                  sx={{ minHeight: "36px" }}
                >
                  {["Add Domains", "Add Keywords", "Add Search Engines", "Set Up Schedule", "Confirm"].map((label, index) => (
                    <Tab key={index} label={label} />
                  ))}
                </Tabs>
              </Grid>

              {tab === 0 && (
                <AddDomainsTab
                  ownDomains={ownDomains}
                  setOwnDomains={setOwnDomains}
                  competitorDomains={competitorDomains}
                  setCompetitorDomains={setCompetitorDomains}
                  handleNext={handleSaveAndContinue}
                  handleBack={handleBack}
                />
              )}
              {tab === 1 && (
                <AddKeywordsTab
                  keywords={keywords}
                  setKeywords={setKeywords}
                  handleNext={handleSaveAndContinue}
                  handleBack={handleBack}
                />
              )}
              {tab === 2 && (
                <AddSearchEnginesTab
                  searchEngines={searchEngines}
                  setSearchEngines={setSearchEngines}
                  handleNext={handleSaveAndContinue}
                  handleBack={handleBack}
                />
              )}
              {tab === 3 && (
                <SetUpScheduleTab
                  schedules={schedules}
                  setSchedules={setSchedules}
                  addSchedule={addSchedule} // Pass the addSchedule function
                  handleNext={handleSaveAndContinue}
                  handleBack={handleBack}
                />
              )}
              {tab === 4 && (
                <ProjectSummaryCard
                  ownDomains={ownDomains.length}
                  competitorDomains={competitorDomains.length}
                  keywords={keywords.length}
                  searchEngines={searchEngines}
                  schedules={schedules} // Make sure this is the correct prop name
                  handleBack={handleBack}
                />
              )}
            </Grid>
          </Box>
        </Grid>

        {/* Fixed Footer */}
        <DashBoardFooter
          sx={{
            position: "fixed",
            bottom: 0,
            width: "100%",
            backgroundColor: theme.palette.background.paper,
            zIndex: 1300,
          }}
        />
      </Grid>
    </>
  );
};

export default AddWebTabHome;
