// Import necessary modules
import React from 'react';
import { Box, Typography, Card, CardContent, Divider, LinearProgress, Grid, Button, IconButton } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import LockIcon from '@mui/icons-material/Lock';
import { styled } from '@mui/material/styles';
import { useTheme } from "../../../../../common/ThemeContext";


// Define custom styles
const StyledCard = styled(Card)(({ theme }) => ({
    backgroundColor: theme.palette.grey[900],
    color: theme.palette.grey[50],
    borderRadius: theme.spacing(1),
    boxShadow: 'none',
}));

const ProgressText = styled(Typography)({
    fontSize: '0.8rem',
    color: '#8d8d8d',
});

const FeatureBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
    border: `1px solid ${theme.palette.success.main}`,
}));

// Data for usage and features
const usageData = [
    { label: "Keywords", current: 10, total: 500 },
    { label: "Websites", current: 1, total: 10 },
    { label: "Daily Keyword Explorer lookups", current: 0, total: 10 },
];

const featuresData = [
    { label: "Competitors per website", value: "5", icon: <CheckCircleOutlineIcon color="success" /> },
    { label: "Local rank tracking", icon: <CheckCircleOutlineIcon color="success" /> },
    { label: "Chart exports", icon: <CheckCircleOutlineIcon color="success" /> },
    { label: "API access", icon: <LockIcon color="disabled" />, locked: true },
    { label: "White-labeled reports", icon: <LockIcon color="disabled" />, locked: true },
    { label: "Project permissions", icon: <LockIcon color="disabled" />, locked: true },
    { label: "API access", icon: <LockIcon color="disabled" />, locked: true },
    { label: "White-labeled reports", icon: <LockIcon color="disabled" />, locked: true },
    { label: "Project permissions", icon: <LockIcon color="disabled" />, locked: true },
    { label: "API access", icon: <LockIcon color="disabled" />, locked: true },
    { label: "White-labeled reports", icon: <LockIcon color="disabled" />, locked: true },
    { label: "Project permissions", icon: <LockIcon color="disabled" />, locked: true },
];

function CurrentPlan() {

    const { theme } = useTheme();


    return (
        <Grid sx={{
            p: 4, backgroundColor: theme.palette.mode !== "dark" ? "#312b36" : "#fff", color: 'white', border:
                theme.palette.mode !== "dark"
                    ? "1px solid #4a4a4a"
                    : "1px solid #f2ebf0", borderRadius: 2
        }}>
            {/* Header */}
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h6" sx={{ color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000",fontWeight:'bold'}}>Your current plan</Typography>
                <Button variant="text" sx={{  color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000", }}>Update plan</Button>
            </Box>

            {/* Current Plan Card */}
            <StyledCard sx={{
                mt: 2, p: 2, backgroundColor: theme.palette.mode !== "dark" ? "#1c1c1e" : "#fbf9fb", border:
                    theme.palette.mode !== "dark"
                        ? "1px solid #4a4a4a"
                        : "1px solid #f2ebf0", borderRadius: 2
            }}>
                <CardContent>
                    <Typography variant="h6" sx={{ color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000",}}>Basic 500 (TRIAL - 6 days left)</Typography>
                    <Typography variant="h5" sx={{ color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000",}}>$49 / month</Typography>
                </CardContent>
            </StyledCard>

            {/* Usage Section */}
            <Box mt={4}>
                <Typography variant="h6" sx={{ color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000",fontWeight:'bold'}}>Your usage</Typography>
                <Grid container spacing={2} mt={1}>
                    {usageData.map((usage, index) => (
                        <Grid item xs={12} sm={6} key={index}>
                            <StyledCard sx={{
                                backgroundColor: theme.palette.mode !== "dark" ? "#1c1c1e" : "#fbf9fb", border:
                                    theme.palette.mode !== "dark"
                                        ? "1px solid #4a4a4a"
                                        : "1px solid #f2ebf0", borderRadius: 2
                            }}>
                                <CardContent>
                                    <Typography sx={{ color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000",}}>{usage.label}</Typography>
                                    <ProgressText >{`${usage.current} of ${usage.total}`}</ProgressText>
                                    <LinearProgress variant="determinate" value={(usage.current / usage.total) * 100} />
                                </CardContent>
                            </StyledCard>
                        </Grid>
                    ))}
                </Grid>
            </Box>

            {/* Features Section */}
            <Box mt={4}>
                <Typography variant="h6" sx={{ color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000",fontWeight:'bold'}}>Your features</Typography>
                <Grid container spacing={2} mt={1}>
                    {featuresData.map((feature, index) => (
                        <Grid item xs={4} key={index}>
                            {feature.locked ? (
                                <StyledCard sx={{
                                    backgroundColor: theme.palette.mode !== "dark" ? "#1c1c1e" : "#fbf9fb", border:
                                        theme.palette.mode !== "dark"
                                            ? "1px solid #4a4a4a"
                                            : "1px solid #f2ebf0", borderRadius: 2
                                }}>
                                    <CardContent sx={{ display: 'flex', alignItems: 'center', gap: 1, color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000", }}>
                                        {feature.icon}
                                        <Typography sx={{ color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000",}}>{feature.label}</Typography>
                                    </CardContent>
                                </StyledCard>
                            ) : (
                                <FeatureBox sx={{ color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000",}}>
                                    {feature.icon}
                                    <Typography >{feature.label}</Typography>
                                    {feature.value && <Typography >{feature.value}</Typography>}
                                </FeatureBox>
                            )}
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Grid>
    );
}

export default CurrentPlan;
