
// import React, { useState, useEffect } from "react";
// import {
//   Grid,
//   Box,
//   Card,
//   CardContent,
//   Typography,
//   IconButton,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   TextField,
//   Button,
//   MenuItem,
//   InputAdornment,
// } from "@mui/material";
// import { useCookies } from "react-cookie";
// import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
// import LanguageIcon from "@mui/icons-material/Language";
// import FolderIcon from "@mui/icons-material/Folder";
// import RefreshIcon from "@mui/icons-material/Refresh";
// import LocalOfferIcon from "@mui/icons-material/LocalOffer";
// import GroupIcon from "@mui/icons-material/Group";
// import GoogleIcon from "@mui/icons-material/Google";
// import { apiList, invokeApi } from "../../../services/apiServices";
// import { config } from "../../../config/config";
// import { useLocation, useNavigate, useParams } from "react-router-dom";
// import HomeTab from "../../pages/Dashboard/DashboardHome/tabsections/HomeTab";
// import Dashboardheader from "../common/DashboardHeader";

// const domainRegex = /^[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/;

// const Projects = () => {
//   const [cookies] = useCookies();
//   const navigate = useNavigate();
//   const { id } = useParams();
//   const location = useLocation();

//   // console.log("Parldlsk==>", id);

//   const [open, setOpen] = useState(false);
//   const [groupDialogOpen, setGroupDialogOpen] = useState(false);
//   const [domainName, setDomainName] = useState("");
//   const [projectName, setProjectName] = useState("");
//   const [projectGroup, setProjectGroup] = useState("No group");
//   const [newGroupName, setNewGroupName] = useState("");
//   const [projectGroups, setProjectGroups] = useState([]);
//   const [projects, setProjects] = useState([]);
//   const [groupId, setGroupId] = useState(null);
//   const [projectsData, setProjectsData] = useState([]);
//   console.log("Group Id:", groupId);
//   const [error, setError] = useState("");
//   const [homeTabPopUp, setHomeTabPopUp] = useState(false);

//   const handleOpen = () => setOpen(true);
//   const handleClose = () => setOpen(false);

//   const handleGroupDialogOpen = () => setGroupDialogOpen(true);
//   const handleGroupDialogClose = () => setGroupDialogOpen(false);

//   const handleAddProject = async () => {
//     if (!domainName || !domainRegex.test(domainName)) {
//       setError("Please enter a valid domain name with a valid extension.");
//       return;
//     }
//     if (!projectName) {
//       setError("Please enter a project name.");
//       return;
//     }
//     if (projectGroup === "No group" || !projectGroup) {
//       setError("Please select a project group.");
//       return;
//     }

//     setError("");
//     await fetchAddProjectData();
//     handleClose();
//   }

//   const handleCreateGroup = async () => {

//     if (!newGroupName) {
//       setError("Please enter a new group name.");
//       return;
//     }

//     setError("");

//     if (newGroupName) {
//       await fetchUpdateGroup(newGroupName);
//       setNewGroupName("");
//       fetchGroupsData();
//     }
//     handleGroupDialogClose();
//   };

//   const fetchAddProjectData = async () => {
//     const params = {
//       groupId: groupId,
//       name: projectName
//     };
//     try {
//       const response = await invokeApi(
//         config.getMyCollege + apiList.addProject,
//         params,
//         cookies
//       );
//       if (response?.status === 200) {
//         console.log("Response Dataaaa--->", response.data.id)
//         fetchAddDomainData(response.data.id);
//       } else {
//         console.error("Failed to fetch data:", response);
//       }
//     } catch (error) {
//       console.error("Error during data fetch:", error);
//     }
//   };

//   const fetchAddDomainData = async (projectId) => {
//     const params = {
//       projectId: projectId,
//       domain: domainName
//     };
//     try {
//       const response = await invokeApi(
//         config.getMyCollege + apiList.addDomain,
//         params,
//         cookies
//       );
//       if (response?.status === 200) {
//         console.log("Domain Added Successfully");
//         navigate('/addweb-tabhome');
//       } else {
//         console.error("Failed to fetch data:", response);
//       }
//     } catch (error) {
//       console.error("Error during data fetch:", error);
//     }
//   };

//   const fetchGroupsData = async () => {
//     const params = {};
//     try {
//       const response = await invokeApi(
//         config.getMyCollege + apiList.getGroups,
//         params,
//         cookies
//       );
//       if (response?.status === 200) {
//         setProjectGroups(response.data.groups);
//       } else {
//         console.error("Failed to fetch data:", response);
//       }
//     } catch (error) {
//       console.error("Error during data fetch:", error);
//     }
//   };

//   const fetchProjectsData = async () => {
//     const params = {};
//     try {
//       const response = await invokeApi(
//         config.getMyCollege + apiList.getProjects,
//         params,
//         cookies
//       );
//       if (response?.status === 200) {
//         setProjects(response.data.projects);
//       } else {
//         console.error("Failed to fetch data:", response);
//       }
//     } catch (error) {
//       console.error("Error during data fetch:", error);
//     }
//   };

//   useEffect(() => {
//     fetchProjectsData();
//     fetchGroupsData();
//     fetchGetProjectsData();
//   }, []);

//   const fetchUpdateGroup = async (groupName) => {
//     const params = { name: groupName };
//     try {
//       const response = await invokeApi(
//         config.getMyCollege + apiList.addGroup,
//         params,
//         cookies
//       );
//       if (response?.status === 200) {
//         setProjectGroup(newGroupName);
//         setGroupId(response.data.id);
//       } else {
//         console.error("Failed to create group:", response);
//       }
//     } catch (error) {
//       console.error("Error during data fetch:", error);
//     }
//   };

//   const fetchGetProjectsData = async () => {
//     try {
//       const params = {
//         wsId: id
//       };
//       const response = await invokeApi(
//         config.getMyCollege + apiList.getProjects,
//         params,
//         cookies
//       );
//       if (response?.status === 200) {
//         const projectslength = response.data.projects;
//         console.log("lengthsldk=>", projectslength.length);
//         if (projectslength.length < 1) {
//           setHomeTabPopUp(!homeTabPopUp);
//         } else {
//           setProjectsData(response.data.projects)
//         }
//       } else {
//         console.error("Failed to get projects:", response);
//       }
//     } catch (error) {
//       console.error("Error get projects:", error);
//     }
//   };

//   useEffect(() => {
//     if (homeTabPopUp) {
//       handleOpen();
//     }
//   }, [homeTabPopUp])

//   const handleNavigateDomainPage=(id)=>{
//     navigate(`/domain/${id}`)
//   }

//   return (
//     <Grid>
//         <Dashboardheader pathname={location.pathname} />
//         <Box sx={{ padding: 2,mt:15 }}>
//         <Grid container spacing={2}>
//           {/* Add New Project Card */}
//           <Grid item xs={3}>
//             <Card
//               onClick={handleOpen}
//               sx={{
//                 display: "flex",
//                 justifyContent: "center",
//                 alignItems: "center",
//                 height: 200,
//                 cursor: "pointer",
//               }}
//             >
//               <AddCircleOutlineOutlinedIcon sx={{ fontSize: "100px", color: "#757575" }} />
//             </Card>
//           </Grid>

//           {/* Existing Project Cards */}
//           {projectsData.map((card, index) => (
//             <Grid item xs={3} key={index}>
//               <Card sx={{ height: 200 }} onClick={()=>{handleNavigateDomainPage(card.id)}}>
//                 <CardContent>
//                   <Box sx={{ display: "flex", justifyContent: "space-between" }}>
//                     <Typography variant="h6">{card.name}</Typography>
//                     <IconButton size="small">
//                       <RefreshIcon />
//                     </IconButton>
//                   </Box>
//                   <Typography variant="body2" color="text.secondary">
//                     Last updated: {card.updatedDate}
//                   </Typography>
//                   <Typography variant="button" color="primary" sx={{ mt: 1 }}>
//                     FINISH SETUP
//                   </Typography>
//                   <Box sx={{ display: "flex", gap: 1, mt: 2 }}>
//                     <IconButton size="small"><LanguageIcon /></IconButton>
//                     <IconButton size="small"><LocalOfferIcon /></IconButton>
//                     <IconButton size="small"><GroupIcon /></IconButton>
//                     <IconButton size="small"><GoogleIcon /></IconButton>
//                   </Box>
//                 </CardContent>
//               </Card>
//             </Grid>
//           ))}
//         </Grid>

//         {/* Popup Dialog for Adding New Project */}

//         <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth sx={{ borderRadius: "8px", boxShadow: 3 }}>
//           <HomeTab />
//         </Dialog>

//         {/* Popup Dialog for Creating New Group */}
//         <Dialog open={groupDialogOpen} onClose={handleGroupDialogClose} maxWidth="xs" fullWidth sx={{ borderRadius: "8px", boxShadow: 3 }}>
//           <DialogTitle sx={{ fontWeight: 600, fontSize: "20px", textAlign: "center", padding: "20px" }}>Create Group</DialogTitle>
//           <DialogContent sx={{ padding: "16px" }}>
//             <TextField
//               fullWidth
//               label="New Group Name"
//               variant="standard"
//               value={newGroupName}
//               onChange={(e) => setNewGroupName(e.target.value)}
//               error={!!error && error.includes("new group name")}
//               helperText={error && error.includes("new group name") ? error : ""}
//               InputProps={{
//                 startAdornment: (
//                   <InputAdornment position="start">
//                     <FolderIcon color="action" />
//                   </InputAdornment>
//                 ),
//                 style: { fontSize: "14px" },
//               }}
//               sx={{ "& .MuiInputBase-root": { borderRadius: "8px" } }}
//             />
//           </DialogContent>
//           <DialogActions sx={{ padding: "16px" }}>
//             <Button onClick={handleGroupDialogClose} variant="outlined" sx={{ borderRadius: "8px", width: "100%", fontWeight: "600", color: "text.secondary" }}>
//               Cancel
//             </Button>
//             <Button onClick={handleCreateGroup} variant="contained" sx={{ borderRadius: "8px", width: "100%", fontWeight: "600" }}>
//               Create
//             </Button>
//           </DialogActions>
//         </Dialog>
//       </Box>
//     </Grid>
//   );
// };

// export default Projects;










import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Card,
  CardContent,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Badge,
  InputAdornment,
} from "@mui/material";
import { useCookies } from "react-cookie";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import LanguageIcon from "@mui/icons-material/Language";
import FolderIcon from "@mui/icons-material/Folder";
import RefreshIcon from "@mui/icons-material/Refresh";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import GroupIcon from "@mui/icons-material/Group";
import SearchIcon from '@mui/icons-material/Search';
import { apiList, invokeApi } from "../../../services/apiServices";
import { config } from "../../../config/config";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import HomeTab from "../../pages/Dashboard/DashboardHome/tabsections/HomeTab";
import Dashboardheader from "../common/DashboardHeader";
import { useTheme } from "../../common/ThemeContext";

const domainRegex = /^[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/;

const Projects = () => {
  const [cookies] = useCookies();
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();

  // console.log("Parldlsk==>", id);

  const [open, setOpen] = useState(false);
  const [groupDialogOpen, setGroupDialogOpen] = useState(false);
  const [domainName, setDomainName] = useState("");
  const [projectName, setProjectName] = useState("");
  const [projectGroup, setProjectGroup] = useState("No group");
  const [newGroupName, setNewGroupName] = useState("");
  const [projectGroups, setProjectGroups] = useState([]);
  const [projects, setProjects] = useState([]);
  const [groupId, setGroupId] = useState(null);
  const { theme } = useTheme();
  const [projectsData, setProjectsData] = useState([]);
  console.log("Group Id:", groupId);
  const [error, setError] = useState("");
  const [homeTabPopUp, setHomeTabPopUp] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleGroupDialogOpen = () => setGroupDialogOpen(true);
  const handleGroupDialogClose = () => setGroupDialogOpen(false);

  const handleAddProject = async () => {
    if (!domainName || !domainRegex.test(domainName)) {
      setError("Please enter a valid domain name with a valid extension.");
      return;
    }
    if (!projectName) {
      setError("Please enter a project name.");
      return;
    }
    if (projectGroup === "No group" || !projectGroup) {
      setError("Please select a project group.");
      return;
    }

    setError("");
    await fetchAddProjectData();
    handleClose();
  }

  const handleCreateGroup = async () => {

    if (!newGroupName) {
      setError("Please enter a new group name.");
      return;
    }

    setError("");

    if (newGroupName) {
      await fetchUpdateGroup(newGroupName);
      setNewGroupName("");
      fetchGroupsData();
    }
    handleGroupDialogClose();
  };

  const fetchAddProjectData = async () => {
    const params = {
      groupId: groupId,
      name: projectName
    };
    try {
      const response = await invokeApi(
        config.getMyCollege + apiList.addProject,
        params,
        cookies
      );
      if (response?.status === 200) {
        console.log("Response Dataaaa--->", response.data.id)
        fetchAddDomainData(response.data.id);
      } else {
        console.error("Failed to fetch data:", response);
      }
    } catch (error) {
      console.error("Error during data fetch:", error);
    }
  };

  const fetchAddDomainData = async (projectId) => {
    const params = {
      projectId: projectId,
      domain: domainName
    };
    try {
      const response = await invokeApi(
        config.getMyCollege + apiList.addDomain,
        params,
        cookies
      );
      if (response?.status === 200) {
        console.log("Domain Added Successfully");
        navigate('/addweb-tabhome');
      } else {
        console.error("Failed to fetch data:", response);
      }
    } catch (error) {
      console.error("Error during data fetch:", error);
    }
  };

  const fetchGroupsData = async () => {
    const params = {};
    try {
      const response = await invokeApi(
        config.getMyCollege + apiList.getGroups,
        params,
        cookies
      );
      if (response?.status === 200) {
        setProjectGroups(response.data.groups);
      } else {
        console.error("Failed to fetch data:", response);
      }
    } catch (error) {
      console.error("Error during data fetch:", error);
    }
  };

  const fetchProjectsData = async () => {
    const params = {};
    try {
      const response = await invokeApi(
        config.getMyCollege + apiList.getProjects,
        params,
        cookies
      );
      if (response?.status === 200) {
        setProjects(response.data.projects);
      } else {
        console.error("Failed to fetch data:", response);
      }
    } catch (error) {
      console.error("Error during data fetch:", error);
    }
  };

  useEffect(() => {
    fetchProjectsData();
    fetchGroupsData();
    fetchGetProjectsData();
  }, []);

  const fetchUpdateGroup = async (groupName) => {
    const params = { name: groupName };
    try {
      const response = await invokeApi(
        config.getMyCollege + apiList.addGroup,
        params,
        cookies
      );
      if (response?.status === 200) {
        setProjectGroup(newGroupName);
        setGroupId(response.data.id);
      } else {
        console.error("Failed to create group:", response);
      }
    } catch (error) {
      console.error("Error during data fetch:", error);
    }
  };

  const fetchGetProjectsData = async () => {
    try {
      const params = {
        wsId: id
      };
      const response = await invokeApi(
        config.getMyCollege + apiList.getAllProjectTracking,
        params,
        cookies
      );
      if (response?.status === 200) {
        const projectslength = response.data.projects;
        console.log("lengthsldk=>", projectslength.length);
        if (projectslength.length < 1) {
          setHomeTabPopUp(!homeTabPopUp);
        } else {
          setProjectsData(response.data.projects)
        }
      } else {
        console.error("Failed to get projects:", response);
      }
    } catch (error) {
      console.error("Error get projects:", error);
    }
  };

  useEffect(() => {
    if (homeTabPopUp) {
      handleOpen();
    }
  }, [homeTabPopUp])

  const handleNavigateDomainPage = (id) => {
    navigate(`/domain/${id}`)
  }

  const formatDate = (dateString) => {
    if (!dateString) return "";

    const date = new Date(dateString);

    const dateOptions = {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
    };

    const formattedDate = date.toLocaleDateString("en-US", dateOptions);

    return `${formattedDate}`;
  };

  return (
    <Grid>
      <Dashboardheader pathname={location.pathname} />
      <Box sx={{ padding: 2, mt: 13, backgroundColor: theme.palette.mode !== "dark" ? '#1d1922' : '#f8f3f7', height: '83vh' }}>
        <Grid container spacing={2}>
          {/* Add New Project Card */}
          <Grid item xs={3}>
            <Card
              onClick={handleOpen}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: 200,
                cursor: "pointer",
                boxShadow: '0 4px 20px rgba(255, 72, 0, 0.1)',
              }}
            >
              <AddCircleOutlineOutlinedIcon sx={{ fontSize: "100px", color: "#757575" }} />
            </Card>
          </Grid>

          {/* Existing Project Cards */}
          {projectsData.map((card, index) => (
            <Grid item xs={3} key={index}>
              <Card sx={{ height: 200, boxShadow: '0 4px 20px rgba(255, 72, 0, 0.1)', }} onClick={() => { handleNavigateDomainPage(card.id) }}>
                <CardContent>
                  <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', fontSize: 22 }}>{card.project}</Typography>
                    {/* <IconButton size="small">
                      <RefreshIcon />
                    </IconButton> */}
                  </Box>
                  <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 'bold', fontSize: 13 }}>
                    {formatDate(card.createdDate)} created
                  </Typography>

                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
                    {/* First Row: Domain and Keyword Sections */}
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        gap: 2,
                        width: '100%',
                      }}
                    >
                      {/* Domain Section */}
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1,
                          border: '1px solid #fe8a00',
                          borderRadius: 2,
                          padding: '8px',
                          flex: 1, // Ensures equal width
                        }}
                      >
                        <LanguageIcon />
                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                          Domain: {card.domains}
                        </Typography>
                      </Box>

                      {/* Keyword Section */}
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1,
                          border: '1px solid #007bfe',
                          borderRadius: 2,
                          padding: '8px',
                          flex: 1, // Ensures equal width
                        }}
                      >
                        <LocalOfferIcon />
                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                          Keyword: {card.keywords}
                        </Typography>
                      </Box>
                    </Box>

                    {/* Second Row: Search Section */}
                    {/* <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        border: '1px solid #28a745',
                        borderRadius: 2,
                        padding: '8px',
                      }}
                    >
                      <SearchIcon />
                      <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                        Search Engine: 8
                      </Typography>
                    </Box> */}
                  </Box>







                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

        {/* Popup Dialog for Adding New Project */}

        <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth sx={{ borderRadius: "8px", boxShadow: 3 }}>
          <HomeTab />
        </Dialog>

        {/* Popup Dialog for Creating New Group */}
        <Dialog open={groupDialogOpen} onClose={handleGroupDialogClose} maxWidth="xs" fullWidth sx={{ borderRadius: "8px", boxShadow: 3 }}>
          <DialogTitle sx={{ fontWeight: 600, fontSize: "20px", textAlign: "center", padding: "20px" }}>Create Group</DialogTitle>
          <DialogContent sx={{ padding: "16px" }}>
            <TextField
              fullWidth
              label="New Group Name"
              variant="standard"
              value={newGroupName}
              onChange={(e) => setNewGroupName(e.target.value)}
              error={!!error && error.includes("new group name")}
              helperText={error && error.includes("new group name") ? error : ""}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FolderIcon color="action" />
                  </InputAdornment>
                ),
                style: { fontSize: "14px" },
              }}
              sx={{ "& .MuiInputBase-root": { borderRadius: "8px" } }}
            />
          </DialogContent>
          <DialogActions sx={{ padding: "16px" }}>
            <Button onClick={handleGroupDialogClose} variant="outlined" sx={{ borderRadius: "8px", width: "100%", fontWeight: "600", color: "text.secondary" }}>
              Cancel
            </Button>
            <Button onClick={handleCreateGroup} variant="contained" sx={{ borderRadius: "8px", width: "100%", fontWeight: "600" }}>
              Create
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Grid>
  );
};

export default Projects;
