import React from 'react';
import { Box, CssBaseline, Grid } from '@mui/material';
import { useTheme } from "../../../../../common/ThemeContext";
import Dashboardheader from '../../../DashboardMainPages/dashboarddetails/Dashboardheader';
import Dashboardsideicon from '../../../DashboardMainPages/dashboarddetails/Dashboardsideicon';
import ReferalPoint from './ReferalPoint';
import DashBoardFooter from '../../../../../common/DashBoardFooter';



const RefaralPointHome = () => {
    const { theme } = useTheme();


    return (
        <>
            <CssBaseline /> 
            <Grid container>
                <Grid item xs={12} sx={{ marginTop: 2, flexGrow: 2 }}> 
                    <Dashboardheader />
                </Grid>
            
                <Grid item xs={12} sx={{ display: 'flex', flexGrow: 1 }}> 
                    {/* Sidebar */}
                    <Grid item sx={{ width: '80px' }}>
                        <Dashboardsideicon />
                    </Grid>

                    {/* Main Content */}
                    <Grid
                        component="main"
                        sx={{
                            backgroundColor: theme.palette.mode !== "dark" ? "#1c1c1e" : "#fbf9fb",
                            flexGrow: 1,
                        
                            ml: -2,
                            marginTop: 13, 
                           
                        }}
                    >
                        <ReferalPoint/>
                        <DashBoardFooter/>
                    </Grid>
                </Grid>
                
            </Grid>
        </>
    );
};

export default RefaralPointHome;
