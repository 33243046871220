import React from "react";
import {
    Box,
    Button,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from "@mui/material";
import Footer from "../../../common/Footer";
import xlfiles from "../../../../assets/7122_21060.xlsx";
import docxfiles from "../../../../assets/getmycollege-2-.docx";
import Header1 from "../../../setup/common/Header";
import useFetch from "../../useFetch";
import Header2 from "../../../common/home/Header";
import { useLocation } from "react-router-dom";


const OrderDetailsSection = () => {
    const { name, roles } = useFetch();
    const location = useLocation();
    const orderData = location.state.row;


    // Handle file download (file is served from the public directory)
    const handleDownload = () => {
        const filePath = xlfiles; // Relative path to the public directory file
        const link = document.createElement("a");
        link.href = filePath;
        link.download = "7122_21060.xlsx"; // File name for download
        link.click();
    };

    const handleDocxDownload = () => {
        const filePath = docxfiles; // Relative path to the public directory file
        const link = document.createElement("a");
        link.href = filePath;
        link.download = "getmycollege-2-.docx"; // File name for download
        link.click();
    };

    const renderHeader = () => {
        if (roles && roles.length > 0) {
            if (roles.includes("Admin")) {
                return <Header1 />;
            } else {
                return <Header2 />;
            }
        }
        // Optional fallback in case `roles` is undefined or empty
        return null;
    };

    return (
        <>
            {renderHeader()}
            <Box
                sx={{
                    backgroundColor: "#f8f9fd",
                    padding: 5,
                    borderRadius: 3,
                    maxWidth: "900px",
                    margin: "auto",
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                }}
            >
                {/* Order Info */}
                <Typography variant="body1" sx={{ marginBottom: 3, fontSize: "1rem" }}>
                    Payment ID : <Typography component="span" sx={{ fontWeight: "bold", color: "#7e22ce" }}>{orderData.order}</Typography>{" "}
                    was placed on{" "}
                    <Typography component="span" sx={{ fontWeight: "bold" }}>
                        {orderData.createdDate}.   
                    </Typography>{" "}
                    The Order will be completed by {" "}
                    <Typography component="span" sx={{ fontWeight: "bold", color: "red" }}>
                     Dec-29-2024
                    </Typography>
                    .
                </Typography>

                {/* Report Download Section */}
                <Box sx={{ marginBottom: 4 }}>
                    <Typography variant="h6" sx={{ fontWeight: "bold", marginBottom: 1, fontSize: "1rem" }}>
                        Report Download
                    </Typography>
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: "#5e2ced",
                            color: "#fff",
                            textTransform: "capitalize",
                            marginRight: 2,
                            "&:hover": { backgroundColor: "#6b21a8" },
                        }}
                        onClick={handleDownload} // Trigger download on button click
                    >
                        Report Download
                    </Button>
                </Box>

                {/* Order Details Table */}
                <Typography variant="h6" sx={{ fontWeight: "bold", marginBottom: 2, fontSize: "1rem" }}>
                    Order Details
                </Typography>
                <TableContainer component={Paper} sx={{ marginBottom: 3 }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ fontWeight: "bold", color: "#374151" }}>Product</TableCell>
                                <TableCell sx={{ fontWeight: "bold", color: "#374151" }}>Details</TableCell>
                                <TableCell sx={{ fontWeight: "bold", color: "#374151" }}>Price</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ fontWeight: "bold", borderBottom: "none" }}>
                                    {/* {orderData.total}  */}
                                   5,000 Backlinks - 1 Tier Package × 1
                                </TableCell>
                                <TableCell sx={{ borderBottom: "none", color: "#374151" }}>
                                    <Typography>
                                        Website URL / Page URL:{" "}
                                        <a
                                            href="https://www.getmycollege.com"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{ color: "#5e2ced", textDecoration: "none" }}
                                        >
                                            https://www.getmycollege.com
                                        </a>
                                    </Typography>
                                    <Typography sx={{ color: "#6b7280", fontSize: "0.9rem", marginTop: 1 }}>
                                        Keyword 1: get my university
                                    </Typography>
                                    <Typography sx={{ color: "#6b7280", fontSize: "0.9rem" }}>
                                        Keyword 2: get university
                                    </Typography>
                                    <Typography sx={{ color: "#6b7280", fontSize: "0.9rem" }}>
                                        Keyword 3: get my admission
                                    </Typography>
                                    <Typography sx={{ color: "#6b7280", fontSize: "0.9rem" }}>
                                        Keyword 4: get college admission
                                    </Typography>
                                    <Typography sx={{ color: "#6b7280", fontSize: "0.9rem" }}>
                                        Keyword 5: my colleges
                                    </Typography>
                                    <Typography sx={{ color: "#6b7280", fontSize: "0.9rem" }}>
                                        Keyword 6: get collage
                                    </Typography>
                                    <Typography sx={{ color: "#6b7280", fontSize: "0.9rem" }}>
                                        Keyword 7: engineering colleges in basavanagudi
                                    </Typography>
                                    <Typography sx={{ color: "#6b7280", fontSize: "0.9rem" }}>
                                        Image URL:{" "}
                                        <a
                                            href="https://www.getmycollege.com/static/media/GMC-logo.15fdaee886680b06fcfd.png"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{ color: "#5e2ced", textDecoration: "none" }}
                                        >
                                            View Image
                                        </a>
                                    </Typography>
                                    <Typography sx={{ color: "#6b7280", fontSize: "0.9rem" }}>
                                        YouTube URL:{" "}
                                        <a
                                            href="https://www.youtube.com/shorts/Mj5H_lvQyfM"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{ color: "#5e2ced", textDecoration: "none" }}
                                        >
                                            Watch Video
                                        </a>
                                    </Typography>
                                    <Typography sx={{ color: "#6b7280", fontSize: "0.9rem", marginTop: 1 }}>
                                        Article Document:{" "}
                                        <Box onClick={handleDocxDownload}>
                                            <a
                                                href="#"
                                                download
                                                style={{ color: "#5e2ced", textDecoration: "none" }}
                                            >
                                                getmycollege-2-.docx
                                            </a>
                                        </Box>

                                    </Typography>
                                </TableCell>
                                <TableCell sx={{ borderBottom: "none", fontWeight: "bold" }}>{orderData.paidAmt}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                {/* Subtotal, Payment Method, and Total */}
                <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: 2 }}>
                    <Typography sx={{ fontWeight: "bold", color: "#6b7280" }}>Subtotal:</Typography>
                    <Typography sx={{ fontWeight: "bold", color: "#6b7280" }}>{orderData.paidAmt}</Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: 2 }}>
                    <Typography sx={{ fontWeight: "bold", color: "#6b7280" }}>Payment Method:</Typography>
                    <Typography sx={{ fontWeight: "bold", color: "#6b7280" }}>
                        Credit Card/Debit Card/NetBanking/UPI
                    </Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: 4 }}>
                    <Typography sx={{ fontWeight: "bold", color: "#374151" }}>Total:</Typography>
                    <Typography sx={{ fontWeight: "bold", color: "#374151" }}>{orderData.paidAmt}</Typography>
                </Box>

                {/* Order Again Button */}
                <Box sx={{ textAlign: "center" }}>
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: "#7e22ce",
                            color: "#fff",
                            textTransform: "capitalize",
                            fontSize: "1rem",
                            padding: "10px 30px",
                            "&:hover": { backgroundColor: "#6b21a8" },
                        }}
                    >
                        Order Again
                    </Button>
                </Box>
            </Box>
            <Footer />
        </>
    );
};

export default OrderDetailsSection;
