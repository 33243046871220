import React from 'react'
import PremiumBanner from '../tier1backlinks/PremiumBanner'
import BacklinksPricing from '../tier1backlinks/PremiumBacklinksPricing'
import BacklinksPricing3 from './PremiumBacklinksPricing3'


const PremiumTier3BacklinksHome = () => {
  return (
    <>
    
    {/* <PremiumBanner/> */}
   
    <BacklinksPricing3/>
    
    </>
  )
}

export default PremiumTier3BacklinksHome