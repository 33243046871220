import React from "react";
import { Box, Grid, Typography, Divider, TextField } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import { LinkedIn } from "@mui/icons-material";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Link, useNavigate } from "react-router-dom";
import logo1 from '../../assets/razorpay.png';
import logo from '../../assets/logo-white.png';
import { useCookies } from "react-cookie";
import { config } from "../../config/config";

function Footer() {
  const navigate = useNavigate();
  const [cookies, , removeCookie] = useCookies([config.cookieName]);

  return (
    <Grid sx={{ backgroundColor: '#292565', }}>
      <Box
        display="flex"
        flexDirection="row"
        gap={2}
        padding="30px"
      >
        {/* Email Section */}
        <Typography
          sx={{
            flex: 1,
            color: 'white',
            textAlign: 'center',
            border: '1px solid white',
            padding: '10px',
          }}
        >
          Email: support@seomitra.com
        </Typography>

        {/* Support Section */}
        <Typography
          sx={{
            flex: 1,
            color: 'white',
            textAlign: 'center',
            border: '1px solid white',
            padding: '10px',
          }}
        >
          Support: Create Support Ticket
        </Typography>
      </Box>
      <Grid container   >
        <Grid
          item
          md={2}
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "20px",
            // ml: 1
          }}
        >
          <Box sx={{ textAlign: "left" }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <img src={logo} alt="Logo" style={{ height: "90%", width: "90%", marginRight: "10px" }} />
            </Box>
            <Typography
              sx={{
                width: "100%",
                fontSize: "18px",
                color: "white",
                fontWeight: 600,
                fontFamily: "open sans, sans-serif",
                marginTop: "20px",
                textAlign: "center",
              }}
            >
              SEO Mitra
            </Typography>
          </Box>


        </Grid>

        <Grid
          item
          md={2}
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "20px",
            // ml: 1
          }}
        >
          <Box sx={{ textAlign: "left" }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  color: "white",
                  fontFamily: "open sans, sans-serif",
                }}
              >
                Quick Links
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                marginTop: "10px",
                textAlign: "left",
              }}
            >
              <Typography onClick={() => navigate("/")} sx={{ color: "white", fontSize: "14px", marginTop: "5px", cursor: "pointer", "&:hover": { color: "#c92e94" }, fontWeight: 'bold', lineHeight: '1.8rem' }}>
                Home
              </Typography>
              <Typography onClick={() => navigate("/monthly-backlinks")} sx={{ color: "white", fontSize: "14px", marginTop: "5px", cursor: "pointer", "&:hover": { color: "#c92e94" }, fontWeight: 'bold', lineHeight: '1.8rem' }}>
                Pricing & Package
              </Typography>
              <Typography onClick={() => navigate("/monthly-backlinks")} sx={{ color: "white", fontSize: "14px", marginTop: "5px", cursor: "pointer", "&:hover": { color: "#c92e94" }, fontWeight: 'bold', lineHeight: '1.8rem' }}>
                Buy SEO Backlinks
              </Typography>
              <Typography onClick={() => navigate("/about-us")} sx={{ color: "white", fontSize: "14px", marginTop: "5px", cursor: "pointer", "&:hover": { color: "#c92e94" }, fontWeight: 'bold', lineHeight: '1.8rem' }}>
                About Us
              </Typography>
              <Typography onClick={() => navigate("/contact-us")} sx={{ color: "white", fontSize: "14px", marginTop: "5px", cursor: "pointer", "&:hover": { color: "#c92e94" }, fontWeight: 'bold', lineHeight: '1.8rem' }}>
                Contact Us
              </Typography>
            </Box>
          </Box>


        </Grid>

        <Grid
          item
          md={2}
          xs={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "20px",
          }}
        >
          <Box  >
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: "bold",
                color: "white",
                fontFamily: "open sans, sans-serif",
              }}
            >
              Resources
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                marginTop: "10px",
                textAlign: "left",
              }}
            >
              <Typography sx={{ color: "white", fontSize: "14px", marginTop: "5px", cursor: "pointer", "&:hover": { color: "#c92e94" }, fontWeight: 'bold', lineHeight: '1.8rem' }}>
                How to buy backlinks
              </Typography>
              <Typography sx={{ color: "white", fontSize: "14px", marginTop: "5px", cursor: "pointer", "&:hover": { color: "#c92e94" }, fontWeight: 'bold', lineHeight: '1.8rem' }}>
                Free website SEO audit
              </Typography>
              <Typography sx={{ color: "white", fontSize: "14px", marginTop: "5px", cursor: "pointer", "&:hover": { color: "#c92e94" }, fontWeight: 'bold', lineHeight: '1.8rem' }}>
                Cancel order
              </Typography>
              <Typography sx={{ color: "white", fontSize: "14px", marginTop: "5px", cursor: "pointer", "&:hover": { color: "#c92e94" }, fontWeight: 'bold', lineHeight: '1.8rem' }}>
                Request support
              </Typography>
              <Typography sx={{ color: "white", fontSize: "14px", marginTop: "5px", cursor: "pointer", "&:hover": { color: "#c92e94" }, fontWeight: 'bold', lineHeight: '1.8rem' }}>
                Blogs
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid
          item
          md={2}
          xs={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <Box >
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: "bold",
                color: "white",
                fontFamily: "open sans, sans-serif",
              }}
            >
              Account
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                marginTop: "10px",
                textAlign: "left",

              }}
            >
              {!(
                !!cookies[config.cookieName] &&
                !!cookies[config.cookieName].token &&
                !!cookies[config.cookieName].loginUserId
              ) && (
                  <>
                    <Typography
                      sx={{
                        color: "white",
                        fontSize: "14px",
                        marginTop: "5px",
                        cursor: "pointer",
                        "&:hover": { color: "#c92e94" },
                        fontWeight: 'bold',
                        lineHeight: '1.8rem'
                      }}
                      onClick={() => navigate("/login")}
                    >
                      Login
                    </Typography>
                    <Typography
                      sx={{
                        color: "white",
                        fontSize: "14px",
                        marginTop: "5px",
                        cursor: "pointer",
                        "&:hover": { color: "#c92e94" },
                        fontWeight: 'bold',
                        lineHeight: '1.8rem'
                      }}
                      onClick={() => navigate("/register")}
                    >
                      Register
                    </Typography>
                  </>
                )
              }

              <Typography sx={{ color: "white", fontSize: "14px", marginTop: "5px", cursor: "pointer", "&:hover": { color: "#c92e94" }, fontWeight: 'bold', lineHeight: '1.8rem' }}>
                My Account
              </Typography>
              <Typography sx={{ color: "white", fontSize: "14px", marginTop: "5px", cursor: "pointer", "&:hover": { color: "#c92e94" }, fontWeight: 'bold', lineHeight: '1.8rem' }}>
                Track Orders
              </Typography>
              <Typography sx={{ color: "white", fontSize: "14px", marginTop: "5px", cursor: "pointer", "&:hover": { color: "#c92e94" }, fontWeight: 'bold', lineHeight: '1.8rem' }}>
                View Reports
              </Typography>

            </Box>
          </Box>
        </Grid>

        <Grid
          item
          md={2}
          xs={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "20px",
          }}
        >
          <Box >
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: "bold",
                color: "white",
                fontFamily: "open sans, sans-serif",
              }}
            >
              Others
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                marginTop: "10px",
                textAlign: "left",
              }}
            >
              <Typography onClick={() => navigate("/faq")} sx={{ color: "white", fontSize: "14px", marginTop: "5px", cursor: "pointer", "&:hover": { color: "#c92e94" }, fontWeight: 'bold', lineHeight: '1.8rem' }}>
                FAQ
              </Typography>
              <Typography onClick={() => navigate("/privacy-policy")} sx={{ color: "white", fontSize: "14px", marginTop: "5px", cursor: "pointer", "&:hover": { color: "#c92e94" }, fontWeight: 'bold', lineHeight: '1.8rem' }}>
                Privacy Policy
              </Typography>
              <Typography onClick={() => navigate("/terms-and-condition")} sx={{ color: "white", fontSize: "14px", marginTop: "5px", cursor: "pointer", "&:hover": { color: "#c92e94" }, fontWeight: 'bold', lineHeight: '1.8rem' }}>
                Terms & Conditions
              </Typography>
              <Typography onClick={() => navigate("/cancellation-refund-policy")} sx={{ color: "white", fontSize: "14px", marginTop: "5px", cursor: "pointer", "&:hover": { color: "#c92e94" }, fontWeight: 'bold', lineHeight: '1.8rem' }}>
                Refund Policy
              </Typography>
              <Typography onClick={() => navigate("/")} sx={{ color: "white", fontSize: "14px", marginTop: "5px", cursor: "pointer", "&:hover": { color: "#c92e94" }, fontWeight: 'bold', lineHeight: '1.8rem' }}>
                Work with us
              </Typography>

            </Box>
          </Box>
        </Grid>

        <Grid
          item
          md={2}
          xs={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "20px",
          }}
        >
          <img src={logo1} alt="Logo" style={{ height: "100%", width: "100%", marginRight: "10px", backgroundColor: 'white' }} />

        </Grid>

        <Grid item xs={12}>
          <Divider sx={{ backgroundColor: "white", marginTop: "20px" }} />
        </Grid>
        <Grid item xs={12} sx={{ padding: "20px" }}>
          <Typography sx={{ color: "white", textAlign: "center", fontSize: "14px" }}>
            Copyright © 2016 seomitra.com. All Rights Reserved.
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Footer;