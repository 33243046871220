import React, { useEffect } from 'react'
import Header from '../../../../common/Header'
import Footer from '../../../../common/Footer'
import PremiumTier1BacklinksHome from './tier1backlinks/PremiumTier1BacklinksHome';
import PremiumTier2BacklinksHome from './tier2backlinks/PremiumTier2BacklinksHome';
import PremiumTier3BacklinksHome from './tier3backlinks/PremiumTier3BacklinksHome';

const PremiumBacklinksHome = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

  return (
    <>
    <Header/>
    <PremiumTier1BacklinksHome/>
    {/* <PremiumTier2BacklinksHome/>
    <PremiumTier3BacklinksHome/> */}
    <Footer/>

    </>
  )
}

export default PremiumBacklinksHome