import React, { useEffect, useState } from 'react';
import { Box, Button, Container, TextField, Typography, Tabs, Tab, MenuItem, Select, InputLabel, FormControl, Grid, ListItem, InputAdornment, IconButton } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import SearchIcon from '@mui/icons-material/Search';
import { useTheme } from "../../../../common/ThemeContext";

const CustomTab = (props) => (
  <Tab
    {...props}
    sx={{
      textTransform: 'none',
      color: '#000',
      fontWeight: 'bold',
      '&.Mui-selected': {
        color: '#ff9800',
      },
    }}
  />
);

const DashboardSearch = () => {
  const { theme } = useTheme();
  const [tabIndex, setTabIndex] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [blogTitles, setBlogTitles] = useState([]);
  const [selectedBlogTitle, setSelectedBlogTitle] = useState('');
  const [searchBlogValue, setSearchBlogValue] = useState("");

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleSearchChange = (event) => {
    setSearchBlogValue(event.target.value);
  };

  // Mock data for categories and blogs
  const categories = ['Technology', 'Health', 'Finance'];
  const blogs = [
    // Example blogs array content
  ];

  const filteredBlogs = blogs.filter((blog) =>
    blog.blogTitle.toLowerCase().includes(searchBlogValue.toLowerCase().trim())
  );

  const handleBlogNavigate = (ogUrl) => {
    console.log(`Navigating to blog: ${ogUrl}`);
  };

  const subCategories = selectedCategory ? [...new Set(blogs.filter(blog => blog.category === selectedCategory).map(blog => blog.subCategory))] : [];

  useEffect(() => {
    if (selectedSubCategory) {
      const filteredBlogTitles = blogs
        .filter(blog => blog.category === selectedCategory && blog.subCategory === selectedSubCategory)
        .map(blog => ({ blogTitle: blog.blogTitle, ogUrl: blog.ogUrl }));
      setBlogTitles(filteredBlogTitles);
    } else {
      setBlogTitles([]);
    }
  }, [selectedSubCategory, selectedCategory]);

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    setSelectedSubCategory('');
  };

  const handleSubCategoryChange = (event) => {
    setSelectedSubCategory(event.target.value);
  };

  const handleBlogTitleChange = (event) => {
    setSelectedBlogTitle(event.target.value);
  };

  const handleSearchClick = () => {
    console.log(`Searching for: ${searchBlogValue}`);
  };

  return (
    <Grid sx={{ position: 'relative', overflow: 'hidden' }}>
      <Container maxWidth="md" sx={{ textAlign: 'center', py: 8, position: 'relative', zIndex: 2 }}>
        <Typography variant="h4" component="h1" sx={{
          fontWeight: 'bold', mb: 2, fontWeight: "600",
          fontSize: { xs: "20px", sm: "25px", md: "30px" },
          lineHeight: "34px",
          color: theme.palette.text.primary,
        }}>
        Search Which Tool You Want
        </Typography>

        <Box sx={{ backgroundColor: 'transparent', width: '100%', mx: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

          {/* First Search Section (Tabs) */}
          <Box sx={{
            bgcolor: theme.palette.mode !== "dark" ? "#2B2B3F" : "#ffffff", borderRadius: '50px', boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)', width: { md: '30%', xs: '100%' }, height: '85px', zIndex: 2, position: 'relative'
          }}>
            <Tabs
              value={tabIndex}
              onChange={handleChange}
              centered
              textColor="inherit"
              TabIndicatorProps={{ style: { backgroundColor: '#ff9800', height: '3px' } }}
            >
              <CustomTab icon={<HomeIcon />} label="All" />
            </Tabs>
          </Box>

          {/* Second Search Section (Conditional Rendering) */}
          <Box sx={{
            bgcolor: theme.palette.mode !== "dark" ? "#2B2B3F" : "#ffffff", p: 2, borderRadius: '50px', boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)', width: '100%', mt: '-1px', position: 'relative', zIndex: 1
          }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, flexWrap: 'wrap', color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000000", }}>
              {tabIndex === 0 ? (
                <>
                  <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <TextField
                      variant="outlined"
                      placeholder="Search what you want"
                      fullWidth
                      value={searchBlogValue}
                      onChange={handleSearchChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={handleSearchClick} sx={{ color: theme.palette.primary.main }}>
                              <SearchIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      sx={{
                        borderRadius: '50px',
                        flex: 1,
                        minWidth: '200px',
                        height: '56px',
                        '& .MuiOutlinedInput-root': { borderRadius: '50px' }
                      }}
                    />
                  </Box>

                  {/* Render the filtered blog results */}
                  {filteredBlogs.map((blog, index) => (
                    <ListItem key={index}>
                      <Typography sx={{ color: "#000", cursor: "pointer" }} onClick={() =>
                        handleBlogNavigate(blog.ogUrl)
                      }>
                        {blog.blogTitle}
                      </Typography>
                    </ListItem>
                  ))}
                </>
              ) : (
                <>
                  <FormControl sx={{ flex: 1, minWidth: '150px', borderRadius: '50px' }}>
                    <InputLabel>Category</InputLabel>
                    <Select
                      value={selectedCategory}
                      onChange={handleCategoryChange}
                      label="Category"
                      sx={{ borderRadius: '50px' }}
                    >
                      {categories.map((category, index) => (
                        <MenuItem key={index} value={category}>
                          {category}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl sx={{ flex: 1, minWidth: '150px', borderRadius: '50px' }}>
                    <InputLabel>Sub Category</InputLabel>
                    <Select
                      value={selectedSubCategory}
                      onChange={handleSubCategoryChange}
                      label="Sub Category"
                      sx={{ borderRadius: '50px' }}
                      disabled={!selectedCategory}
                    >
                      {subCategories.map((subCategory, index) => (
                        <MenuItem key={index} value={subCategory}>
                          {subCategory}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl sx={{ flex: 1, minWidth: '150px', borderRadius: '50px' }}>
                    <InputLabel>Blog Title</InputLabel>
                    <Select
                      value={selectedBlogTitle}
                      onChange={handleBlogTitleChange}
                      label="Blog Title"
                      sx={{ borderRadius: '50px' }}
                      disabled={!selectedSubCategory}
                    >
                      {blogTitles.map((blog, index) => (
                        <MenuItem
                          sx={{ cursor: "pointer" }}
                          key={index}
                          value={blog.blogTitle}
                          onClick={() => handleBlogNavigate(blog.ogUrl)}
                        >
                          {blog.blogTitle}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </>
              )}
            </Box>
          </Box>

        </Box>
      </Container>
    </Grid>
  );
};

export default DashboardSearch;
