import React from 'react'
import PremiumBanner from './PremiumBanner'
import Footer from '../../../../../common/Footer'
import Header from '../../../../../common/Header'
import BacklinksPricing from './PremiumBacklinksPricing'


const PremiumTier1BacklinksHome = () => {
  return (
    <>
   
    {/* <PremiumBanner/> */}
   
    <BacklinksPricing/>
   
    </>
  )
}

export default PremiumTier1BacklinksHome