import React, { useState } from 'react';
import { Box, Card, CardContent, Typography, Grid, Avatar, Divider, Radio } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import { useTheme } from "../../../../../common/ThemeContext";


function ConnectedServices() {
    // State to hold the selected service
    const [selectedService, setSelectedService] = useState(null);

    const handleRadioChange = (value) => {
        setSelectedService(value);
    };

    const { theme } = useTheme();


    return (
        <Grid sx={{ p: 4, backgroundColor: theme.palette.mode !== "dark" ? "#1c1c1e" : "#fbf9fb", color: '#fff', }}>
            <Grid sx={{
                backgroundColor: theme.palette.mode !== "dark" ? "#312b36" : "#fff", color: '#ffffff', p: 3, border:
                    theme.palette.mode !== "dark"
                        ? "1px solid #4a4a4a"
                        : "1px solid #f2ebf0", borderRadius: 2
            }}>
                <Typography variant="h6" sx={{ fontWeight: 'bold', color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000", mb: 2 }}>
                    Your connected services
                </Typography>

                <Divider sx={{ my: 3, color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000", width: '100%' }} />
                <Grid container spacing={2} sx={{ mt: 3 }}>
                    {/* Google Service Card */}
                    <Grid item xs={12} sm={4}>
                        <Card sx={{
                            backgroundColor: theme.palette.mode !== "dark" ? "#1c1c1e" : "#fbf9fb", color: '#fff', color: '#ffffff', p: 2, border:
                                theme.palette.mode !== "dark"
                                    ? "1px solid #4a4a4a"
                                    : "1px solid #f2ebf0", borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.3)'
                        }}>
                            <CardContent sx={{ textAlign: 'center' }}>
                                <Grid container alignItems="center" spacing={2}>
                                    <Grid item>
                                        <Avatar
                                            sx={{
                                                bgcolor: 'transparent',
                                                border: '1px solid #4c4452',
                                                width: 50,
                                                height: 50,
                                                borderRadius: 2,
                                            }}
                                        >
                                            <GoogleIcon sx={{ color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000", }} />
                                        </Avatar>
                                    </Grid>
                                    <Grid item xs>
                                        <Typography variant="h6" sx={{ fontWeight: 'bold', textAlign: 'left', fontSize: 16, color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000", }}>
                                            Google
                                        </Typography>
                                        <Typography variant="body2" sx={{ color: '#8f839b', marginTop: 0.5, textAlign: 'left' }}>
                                            You’re connected
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Radio
                                            checked={selectedService === 'google'}
                                            onChange={() => handleRadioChange('google')}
                                            sx={{
                                                '&.Mui-checked': {
                                                    color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000",
                                                },
                                                '&.MuiRadio-root': {
                                                    color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000",
                                                },
                                            }}

                                        />
                                    </Grid>
                                </Grid>

                                <Divider sx={{ my: 2, color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#fff", }} />
                                <Typography variant="body2" sx={{ mt: 2, color: '#8f839b', textAlign: 'left' }}>
                                    Allows you to log in through your Google account, one less password to keep track of.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>

                    {/* Search Console Service Card */}
                    <Grid item xs={12} sm={4}>
                        <Card sx={{
                            backgroundColor: theme.palette.mode !== "dark" ? "#1c1c1e" : "#fbf9fb", color: '#fff', color: '#ffffff', p: 2, border:
                                theme.palette.mode !== "dark"
                                    ? "1px solid #4a4a4a"
                                    : "1px solid #f2ebf0", borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.3)'
                        }}>
                            <CardContent sx={{ textAlign: 'center' }}>
                                <Grid container alignItems="center" spacing={2}>
                                    <Grid item>
                                        <Avatar
                                            sx={{
                                                bgcolor: 'transparent',
                                                border: '1px solid #4c4452',
                                                width: 50,
                                                height: 50,
                                                borderRadius: 2,
                                            }}
                                        >
                                            <GoogleIcon sx={{ color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000", }} />
                                        </Avatar>
                                    </Grid>
                                    <Grid item xs>
                                        <Typography variant="h6" sx={{ fontWeight: 'bold', textAlign: 'left', fontSize: 16, color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000", }}>
                                            Search Console
                                        </Typography>
                                        <Typography variant="body2" sx={{ color: '#8f839b', marginTop: 0.5, textAlign: 'left' }}>
                                            Connect & import keywords
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Radio
                                            checked={selectedService === 'search-console'}
                                            onChange={() => handleRadioChange('search-console')}
                                            sx={{
                                                '&.Mui-checked': {
                                                    color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000",
                                                },
                                                '&.MuiRadio-root': {
                                                    color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000",
                                                },
                                            }}
                                        />
                                    </Grid>
                                </Grid>

                                <Divider sx={{ my: 2, color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000", }} />
                                <Typography variant="body2" sx={{ mt: 2, color: '#8f839b', textAlign: 'left' }}>
                                    Import keywords and other data directly from Google Search Console to Wincher.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>

                    {/* Data Studio Service Card */}
                    <Grid item xs={12} sm={4}>
                        <Card sx={{
                            backgroundColor: theme.palette.mode !== "dark" ? "#1c1c1e" : "#fbf9fb", color: '#fff', color: '#ffffff', p: 2, border:
                                theme.palette.mode !== "dark"
                                    ? "1px solid #4a4a4a"
                                    : "1px solid #f2ebf0", borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.3)'
                        }}>
                            <CardContent sx={{ textAlign: 'center' }}>
                                <Grid container alignItems="center" spacing={2}>
                                    <Grid item>
                                        <Avatar
                                            sx={{
                                                bgcolor: 'transparent',
                                                border: '1px solid #4c4452',
                                                width: 50,
                                                height: 50,
                                                borderRadius: 2,
                                            }}
                                        >
                                            <GoogleIcon sx={{ color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000", }} />
                                        </Avatar>
                                    </Grid>
                                    <Grid item xs>
                                        <Typography variant="h6" sx={{ fontWeight: 'bold', textAlign: 'left', fontSize: 16, color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000", }}>
                                            Data Studio
                                        </Typography>
                                        <Typography variant="body2" sx={{ color: '#8f839b', marginTop: 0.5, textAlign: 'left' }}>
                                            Connect to your data
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Radio
                                            checked={selectedService === 'data-studio'}
                                            onChange={() => handleRadioChange('data-studio')}
                                            sx={{
                                                '&.Mui-checked': {
                                                    color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000",
                                                },
                                                '&.MuiRadio-root': {
                                                    color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000",
                                                },
                                            }}
                                        />
                                    </Grid>
                                </Grid>

                                <Divider sx={{ my: 2, color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000", }} />
                                <Typography variant="body2" sx={{ mt: 2, color: '#8f839b', textAlign: 'left' }}>
                                    Connect to Google Data Studio to create custom dashboards and reports.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default ConnectedServices;
