import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import img1 from '../../../assets/Jamboree.jpg'
import img2 from '../../../assets/Deliveroo-Logo.png'
import img3 from '../../../assets/mc.png'
import img4 from '../../../assets/HCL-1-1.png'
import img5 from '../../../assets/physics-wallah.png'
import img6 from '../../../assets/cocaCola.png'

const Organizations = () => {
    return (
        <Box sx={{ backgroundColor: 'white', py: 6 }}>
            {/* Text Section */}
            <Box sx={{ textAlign: 'center', mb: 4 }}>
                <Typography
                    variant="h4"
                    component="h2"
                    sx={{
                        fontWeight: 'bold',
                        maxWidth: 800,
                        color: '#333',
                        margin: '0 auto',
                    }}
                >
                    Trusted by Over 5k+ Businesses for Backlink and Keyword Tracking Services
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        color: '#666',
                        maxWidth: 800,
                        margin: '0 auto',
                        mt: 3, 
                    }}
                >
                    From Digital Marketing Agencies, SEO Experts, E-commerce Websites, Content Creators, and Bloggers to Enterprises and Startups, everyone trusts us for reliable backlink services and advanced keyword tracking tools. Boost your online presence with SEO Mitra!
                </Typography>

            </Box>

            {/* Images Section */}
            <Grid
                container
                spacing={3} // Gap between images
                sx={{ justifyContent: 'center', maxWidth: 1200, margin: '0 auto' }}
            >
                {/* Row 1 Images */}
                <Grid item xs={6} sm={4} md={2}>
                    <Box
                        component="img"
                        src={img1} // Replace with your image URL
                        alt="Organization 1"
                        sx={{ width: '100%', borderRadius: 2 }}
                    />
                </Grid>
                <Grid item xs={6} sm={4} md={2}>
                    <Box
                        component="img"
                        src={img2}
                        alt="Organization 3"
                        sx={{ width: '100%', borderRadius: 2 }}
                    />
                </Grid>
                <Grid item xs={6} sm={4} md={2}>
                    <Box
                        component="img"
                        src={img3}
                        alt="Organization 2"
                        sx={{ width: '100%', borderRadius: 2, height: '70px' }}
                    />
                </Grid>
                <Grid item xs={6} sm={4} md={2}>
                    <Box
                        component="img"
                        src={img4}
                        alt="Organization 4"
                        sx={{ width: '100%', borderRadius: 2 }}
                    />
                </Grid>
                <Grid item xs={6} sm={4} md={2}>
                    <Box
                        component="img"
                        src={img5}
                        alt="Organization 5"
                        sx={{ width: '100%', borderRadius: 2 }}
                    />
                </Grid>
                <Grid item xs={6} sm={4} md={2}>
                    <Box
                        component="img"
                        src={img6}
                        alt="Organization 6"
                        sx={{ width: '100%', borderRadius: 2 }}
                    />
                </Grid>
            </Grid>

        </Box>
    );
};

export default Organizations;
