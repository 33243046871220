import React, { useEffect } from 'react';
import { Grid, Card, CardContent, Typography, Box, Paper } from '@mui/material';
import { AiOutlineRetweet, AiOutlineCheckCircle, AiOutlineCalculator, AiOutlineFileSearch } from 'react-icons/ai';
import { MdSpellcheck, MdTextFields, MdReorder, MdTranslate, MdAutorenew, MdChangeHistory } from 'react-icons/md';
import { BiParagraph, BiCog, BiImage, BiMicrophone, BiText } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import Banner from './Banner';

const tools = [
    { name: 'Plagiarism Checker', icon: <AiOutlineFileSearch />, link: '/plagiarism-checker' },
    { name: 'Article Rewriter', icon: <AiOutlineRetweet />, link:'/article-rewriter' },
    { name: 'Free Grammar Checker', icon: <AiOutlineCheckCircle />,link:'/Grammar-Checker' },
    { name: 'Word Counter', icon: <AiOutlineCalculator />,link:'/word-counter' },
    { name: 'Spell Checker', icon: <MdSpellcheck />,link:'/spell-checker' },
    { name: 'Paraphrasing Tool', icon: <BiParagraph />,link:'/paraphrasing-tool' },
    { name: 'MD5 Generator', icon: <BiCog /> ,link:'/md5-generator'},
    { name: 'Uppercase To Lowercase', icon: <MdTextFields />,link:'/upper-to-lower' },
    { name: 'Word Combiner', icon: <MdReorder />,link:'/word-combiner' },
    { name: 'Image To Text Converter', icon: <BiImage />,link:'/image-to-text' },
    { name: 'Translate English To English', icon: <MdTranslate />,link:'/translate' },
    { name: 'Text To Image', icon: <BiImage />,link:'/text-to-image' },
    { name: 'Text To Speech', icon: <BiMicrophone />,link:'/text-to-speech' },
    { name: 'JPG To Word', icon: <BiText /> },
    { name: 'Small Text Generator', icon: <MdTextFields />,link:'/small-text-generator' },
    { name: 'Online Text Editor', icon: <AiOutlineRetweet /> },
    { name: 'Reverse Text Generator', icon: <MdAutorenew />, link:'/reverse-text-generator' },
    { name: 'Speech To Text', icon: <BiMicrophone /> },
    { name: 'Sentence Rephraser', icon: <MdReorder /> },
    { name: 'AI Content Detector', icon: <BiCog /> },
    { name: 'Paragraph Rewriter', icon: <BiParagraph /> },
    { name: 'Sentence Checker', icon: <AiOutlineCheckCircle /> },
    { name: 'Rewording Tool', icon: <MdChangeHistory /> },
    { name: 'Punctuation Checker', icon: <MdSpellcheck /> },
    { name: 'Essay Checker', icon: <AiOutlineFileSearch /> },
    { name: 'Paper Checker', icon: <BiText /> },
    { name: 'Online Proofreader', icon: <AiOutlineCheckCircle /> },
    { name: 'Word Changer', icon: <MdReorder /> },
    { name: 'Sentence Rewriter', icon: <MdAutorenew /> },
    { name: 'Essay Rewriter', icon: <AiOutlineRetweet /> },
    { name: 'Text Summarizer', icon: <AiOutlineFileSearch /> },
    { name: 'Paragraph Rephrase Tool', icon: <BiParagraph /> },
    { name: 'Paraphrase Generator', icon: <AiOutlineRetweet /> },
    { name: 'Sentence Changer', icon: <MdChangeHistory /> },
    { name: 'OCR', icon: <BiImage /> },
    { name: 'Image Translator', icon: <MdTranslate /> },
    { name: 'ChatGPT Detector', icon: <BiCog /> },
    { name: 'Citation Generator', icon: <MdTextFields /> },
];

function ToolsGrid() {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <>
        <Banner/>
        <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
                <Paper elevation={3} sx={{ padding: 4,  boxShadow: '0 4px 20px rgba(255, 72, 0, 0.1)',  }}>
                    
                    <Grid container spacing={3}>
                        {tools.map((tool, index) => (
                            <Grid item xs={6} sm={4} md={3} key={index}>
                                <Link to={tool.link || '#'} style={{ textDecoration: 'none' }}>
                                    <Card
                                        sx={{
                                            textAlign: 'center',
                                            padding: 1,
                                            cursor: 'pointer',
                                            borderRadius: '0px 20px 0px 20px',
                                            boxShadow: '0 4px 20px rgba(255, 72, 0, 0.1)',
                                            height: '70px',
                                            width: '70px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            margin: '0 auto',
                                            position: 'relative',
                                            '&::before, &::after': {
                                                content: '""',
                                                position: 'absolute',
                                                borderRadius: '0px 20px 0px 20px',
                                                width: '0',
                                                height: '0',
                                                background: 'transparent',
                                                border: '4px solid transparent',
                                                transition: 'width 0.8s ease, height 0.8s ease, border-color 0.8s ease',
                                            },
                                            '&::before': {
                                                top: 0,
                                                left: 0,
                                                borderTopColor: 'transparent',
                                                borderLeftColor: 'transparent',
                                                borderRadius: '0px 20px 0px 20px',
                                            },
                                            '&::after': {
                                                bottom: 0,
                                                right: 0,
                                                borderBottomColor: 'transparent',
                                                borderRightColor: 'transparent',
                                            },
                                            '&:hover::before': {
                                                width: '100%',
                                                height: '100%',
                                                borderTopColor: '#f75c20a6',
                                                borderLeftColor: '#f75c20a6',
                                            },
                                            '&:hover::after': {
                                                width: '100%',
                                                height: '100%',
                                                borderBottomColor: '#f75c20a6',
                                                borderRightColor: '#f75c20a6',
                                            },
                                        }}
                                    >
                                        <CardContent sx={{ padding: 0, borderRadius: 2 }}>
                                            <Box sx={{ fontSize: 40, color: '#f75c20a6', mt: 4 }}>
                                                {tool.icon}
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Link>
                                <Typography variant="subtitle1" sx={{ textAlign: 'center', fontSize: '14px', mt: 1, fontWeight: 'bold' }}>
                                    {tool.name}
                                </Typography>
                            </Grid>
                        ))}
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
        </>
    );
}

export default ToolsGrid;
