import React from 'react'

import BacklinksPricing4 from './PremiumBacklinksPricing4'


const PremiumTier4BacklinksHome = () => {
  return (
    <>
    
    {/* <PremiumBanner/> */}
   
    <BacklinksPricing4/>
    
    </>
  )
}

export default PremiumTier4BacklinksHome