import React, { useEffect, useState } from 'react';
import { Container, TextField, Button, Typography, CircularProgress, Box, Paper, Grid, MenuItem, Select } from '@mui/material';
import { AiOutlinePaperClip, AiOutlineCloudUpload } from 'react-icons/ai';
import Plagiarismcontent from './Plagiarismcontent';
import GaugeChart from 'react-gauge-chart';
import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import Header from '../../../common/home/Header';
import Footer from '../../../common/Footer';

Chart.register(...registerables);

const PlagiarismChecker = () => {
  const [inputText, setInputText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState(null);

  const [barData, setBarData] = useState({
    labels: ['Errors'],
    datasets: [
      {
        label: 'Errors',
        data: [0],
        backgroundColor: '#90CAF9',
      },
    ],
  });

  const handleInputChange = (e) => {
    setInputText(e.target.value);
  };

  const handleCheckPlagiarism = async () => {
    setIsLoading(true);
    setResult(null);

    try {
      await new Promise((resolve) => setTimeout(resolve, 2000));

      const mockResponse = {
        plagiarismPercentage: Math.floor(Math.random() * 100), 
        details: 'This text contains some plagiarized content.',
        errorCount: Math.floor(Math.random() * 100),
      };

      setResult(mockResponse);

      // Update bar chart data
      setBarData({
        labels: ['Errors'],
        datasets: [
          {
            label: 'Errors',
            data: [mockResponse.errorCount],
            backgroundColor: '#90CAF9',
          },
        ],
      });
    } catch (error) {
      setResult({ error: 'An error occurred while checking for plagiarism' });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <Grid sx={{ background: '#fff', p: 4, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#fff9f7' }}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} md={9}>
            <Paper elevation={6} sx={{ padding: '20px', boxShadow: '0 4px 20px rgba(255, 72, 0, 0.1)' }}>
              <Typography variant="h5" color="primary" align="center" gutterBottom sx={{ fontWeight: 'bold', color: '#000' }}>
                Plagiarism Checker
              </Typography>

              <TextField
                label="Copy and paste your text here to detect plagiarized content..."
                variant="outlined"
                fullWidth
                multiline
                rows={10}
                value={inputText}
                onChange={handleInputChange}
                sx={{
                  backgroundColor: '#f5f5f5',
                  borderRadius: '10px',
                }}
              />

              <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
                <Box display="flex" gap={1}>
                  <Button startIcon={<AiOutlinePaperClip />} />
                  <Button startIcon={<AiOutlineCloudUpload />} />
                </Box>

                <Typography variant="body2">Words Limit/Search: {inputText.split(' ').length}/1000</Typography>
                <Select value="URL" variant="outlined" sx={{ minWidth: 120 }}>
                  <MenuItem value="URL">Check by URL</MenuItem>
                  <MenuItem value="Text">Check by Text</MenuItem>
                </Select>
              </Box>

              <Box textAlign="center" mt={2}>
                <Button
                  variant="contained"
                  onClick={handleCheckPlagiarism}
                  disabled={!inputText || isLoading}
                  size="large"
                  sx={{
                    backgroundColor: '#ff6f61',
                    color: '#fff',
                    '&:hover': {
                      backgroundColor: '#ff8b7f',
                    },
                  }}
                >
                  {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Check Now'}
                </Button>
              </Box>
            </Paper>
          </Grid>

          {/* Gauge Section */}
          <Grid item xs={12} md={3}>
            <Paper elevation={3} sx={{ padding: 2, borderRadius: '10px', boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography variant="h6" color="primary" gutterBottom sx={{ fontWeight: 'bold', color: '#000' }}>
                  Plagiarism Gauge
                </Typography>

                {/* Using GaugeChart component from react-gauge-chart */}
                <GaugeChart 
                  id="gauge-chart"
                  nrOfLevels={30}
                  colors={['#FF0000', '#FF8C00', '#00FF00']} 
                  percent={result ? result.plagiarismPercentage / 100 : 0} 
                  style={{ width: '300px', height: '300px' }}
                  arcPadding={0.02}
                  needleColor="#FF0000"
                  textColor="#000000"
                  animDelay={300} 
                />

              
              </Box>

              <Box sx={{mt:-7 }}>
                <Bar data={barData} />
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <Plagiarismcontent />
      <Footer/>
    </>
  );
};

export default PlagiarismChecker;
