import React, { useEffect, useState } from "react";
import {
    Box, Button, TextField, List, ListItem, IconButton, Typography, Menu, MenuItem
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SaveIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Close";
import { useTheme } from "../../../../../common/ThemeContext";
import { apiList, invokeApi } from "../../../../../../services/apiServices";
import { config } from "../../../../../../config/config";
import { useCookies } from "react-cookie";

const domainRegex = /^[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/; // Domain validation regex


const AddDomainsTab = ({ ownDomains, setOwnDomains, competitorDomains, setCompetitorDomains, handleNext }) => {
    const [cookies] = useCookies();
    const { theme } = useTheme();

    const [anchorEl, setAnchorEl] = useState(null);
    const [editIndex, setEditIndex] = useState(null);
    const [domainErrors, setDomainErrors] = useState({});


    const fetchAllDomainData = async () => {
        const params = {};
        try {
            const response = await invokeApi(
                config.getMyCollege + apiList.getAllDomains,
                params,
                cookies
            );
            if (response?.status === 200) {
                console.log("Domain", response.data.domains);
                const domainsData = response.data.domains.map((domain, index) => ({
                    value: domain.domain,
                    isSaved: true,
                    projectId: domain.projectId,
                    domainId: domain.domainId,
                    isDefault: index === 0  // Set first index as default
                }));
                setOwnDomains(domainsData);
            } else {
                console.error("Failed to fetch data:", response);
            }
        } catch (error) {
            console.error("Error during data fetch:", error);
        }
    };

    const fetchCompeteDomainsData = async () => {
        const params = {};
        try {
            const response = await invokeApi(
                config.getMyCollege + apiList.getCompeteDomains,
                params,
                cookies
            );
            if (response?.status === 200) {
                console.log("Domain", response.data.domains);
                const domainsData = response.data.domains.map((domain, index) => ({
                    value: domain.domain,
                    isSaved: true,
                    projectId: domain.projectId,
                    domainId: domain.domainId
                }));
                setCompetitorDomains(domainsData);
            } else {
                console.error("Failed to fetch data:", response);
            }
        } catch (error) {
            console.error("Error during data fetch:", error);
        }
    };

    useEffect(() => {
        fetchAllDomainData();
        fetchCompeteDomainsData();
    }, []);

    const handleAddDomain = (setDomains, domains) => {
        if (!domains.length || domains[domains.length - 1].isSaved) {
            setDomains([...domains, { value: "", isSaved: false }]);
        }
    };

    const handleDomainChange = (index, event, setDomains, domains) => {
        const newDomains = [...domains];
        const newDomainErrors = { ...domainErrors };
        const value = event.target.value;

        // Validate domain against regex
        if (!domainRegex.test(value)) {
            newDomainErrors[index] = "Invalid domain format.";
        } else {
            delete newDomainErrors[index]; // Remove error if valid
        }

        newDomains[index].value = value;
        setDomains(newDomains);
        setDomainErrors(newDomainErrors);
    };

    const fetchAddDomainData = async (domainName) => {
        const params = {
            projectId: 121913,
            domain: domainName
        };
        try {
            const response = await invokeApi(
                config.getMyCollege + apiList.addDomain,
                params,
                cookies
            );
            if (response?.status === 200) {
                console.log("Domain Added Successfully");
                // navigate('/addweb-tabhome');
            } else {
                console.error("Failed to fetch data:", response);
            }
        } catch (error) {
            console.error("Error during data fetch:", error);
        }
    };

    const fetchAddCompeteDomainData = async (domainName) => {
        const params = {
            projectId: 121913,
            domain: domainName
        };
        try {
            const response = await invokeApi(
                config.getMyCollege + apiList.addCompeteDomain,
                params,
                cookies
            );
            if (response?.status === 200) {
                console.log("Domain Added Successfully");
                // navigate('/addweb-tabhome');
            } else {
                console.error("Failed to fetch data:", response);
            }
        } catch (error) {
            console.error("Error during data fetch:", error);
        }
    };

    const handleSaveDomain = (index, setDomains, domains, domainType) => {
        if (domains[index].value.trim() && !domainErrors[index]) {
            domains[index].isSaved = true;

            // Determine the correct API to call based on domainType
            if (domainType === 'own') {
                fetchAddDomainData(domains[index].value);  // Call for own domain
            } else if (domainType === 'competitor') {
                fetchAddCompeteDomainData(domains[index].value);  // Call for competitor domain
            }

            setDomains([...domains]);
            setEditIndex(null);
        }
    };


    const handleEditDomain = (index) => {
        setEditIndex(index);
    };

    const fetchDeleteDomainData = async (projectId, domainId) => {
        const params = {
            "projectId": projectId,
            "domainId": domainId
        };
        try {
            const response = await invokeApi(
                config.getMyCollege + apiList.deleteDomain,
                params,
                cookies
            );
            if (response?.status === 200) {
                console.log("Domain Deleted Successfully");
                // Optionally refetch domains to update the list
            } else {
                console.error("Failed to delete domain:", response);
            }
        } catch (error) {
            console.error("Error during domain deletion:", error);
        }
    };


    // const handleDeleteDomain = (index, setDomains, domains) => {
    //     setDomains(domains.filter((_, i) => i !== index));
    // };

    const handleDeleteDomain = (index, setDomains, domains, domain) => {
        console.log("Domain  ====>", domains)
        // Call API to delete domain
        fetchDeleteDomainData(domain.projectId, domain.domainId);
        // Remove from the state
        setDomains(domains.filter((_, i) => i !== index));
    };


    const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
    const handleMenuClose = () => setAnchorEl(null);

    return (
        <Box sx={{
            padding: 3, backgroundColor: theme.palette.mode !== "dark" ? "#312b36" : "#fff", border:
                theme.palette.mode !== "dark"
                    ? "1px solid #4a4a4a"
                    : "1px solid #f2ebf0", borderRadius: "8px"
        }}>
            <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1, color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000" }}>
                Manage Domains
            </Typography>
            <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
                <Button variant="text" onClick={() => handleAddDomain(setOwnDomains, ownDomains)} sx={{
                    color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000", border: "1px solid",
                    borderColor: theme.palette.mode !== "dark" ? "#4a4a4a" : "#f2ebf0",
                }}>+ Add Own Domain</Button>
                <Button variant="text" onClick={() => handleAddDomain(setCompetitorDomains, competitorDomains)} sx={{
                    color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000", border: "1px solid",
                    borderColor: theme.palette.mode !== "dark" ? "#4a4a4a" : "#f2ebf0",
                }}>+ Add Competitor</Button>
            </Box>

            <DomainList
                title={`Own Domains (${ownDomains.length})`}
                domains={ownDomains}
                setDomains={setOwnDomains}
                handleMenuOpen={handleMenuOpen}
                handleMenuClose={handleMenuClose}
                anchorEl={anchorEl}
                editIndex={editIndex}
                handleEditDomain={handleEditDomain}
                handleDomainChange={handleDomainChange}
                handleSaveDomain={(index) => handleSaveDomain(index, setOwnDomains, ownDomains, 'own')} // Pass 'own'
                handleDeleteDomain={handleDeleteDomain}
                theme={theme}
                domainErrors={domainErrors} // Pass domain errors

            />
            <DomainList
                title={`Competitor Domains (${competitorDomains.length})`}
                domains={competitorDomains}
                setDomains={setCompetitorDomains}
                handleMenuOpen={handleMenuOpen}
                handleMenuClose={handleMenuClose}
                anchorEl={anchorEl}
                editIndex={editIndex}
                handleEditDomain={handleEditDomain}
                handleDomainChange={handleDomainChange}
                handleSaveDomain={(index) => handleSaveDomain(index, setCompetitorDomains, competitorDomains, 'competitor')} // Pass 'competitor'
                handleDeleteDomain={handleDeleteDomain}
                theme={theme}
                domainErrors={domainErrors}
            />

            <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
                <Button variant="contained" color="primary" sx={{
                    color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000",
                    backgroundColor: theme.palette.mode !== "dark" ? "#131314" : "#fbf9fb",
                }}
                    onClick={() => { handleNext() }}
                >
                    Save and Continue
                </Button>
            </Box>
        </Box>
    );
};

const DomainList = ({
    title,
    domains,
    setDomains,
    handleMenuOpen,
    handleMenuClose,
    anchorEl,
    handleDomainChange,
    handleSaveDomain,
    handleDeleteDomain,
    handleEditDomain,
    editIndex,
    theme,
    domainErrors
}) => (
    <>
        {domains.length > 0 && (
            <>
                <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 1, color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000" }}>
                    {title}
                </Typography>
                <List>
                    {domains.map((domain, index) => (
                        <React.Fragment key={index}>
                            <ListItem sx={{
                                display: "flex", alignItems: "center", padding: "8px 0", backgroundColor: theme.palette.mode !== "dark" ? "#131314" : "#fbf9fb", border:
                                    theme.palette.mode !== "dark"
                                        ? "1px solid #4a4a4a"
                                        : "1px solid #f2ebf0", borderRadius: 2, mb: 1, p: 2
                            }}>
                                {domain.isDefault && (
                                    <Typography variant="caption" sx={{ color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000", fontWeight: "bold", mr: 1 }}>
                                        Default
                                    </Typography>
                                )}
                                {editIndex === index || !domain.isSaved ? (
                                    <>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            placeholder="Enter domain"
                                            value={domain.value}
                                            onChange={(e) => handleDomainChange(index, e, setDomains, domains)}
                                            error={Boolean(domainErrors[index])} // Set error if there is one
                                            helperText={domainErrors[index] || ""}
                                            sx={{ input: { color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000", } }}
                                        />
                                        <IconButton color="primary" onClick={() => handleSaveDomain(index, setDomains, domains)} sx={{
                                            ml: 1, mr: 1, border:
                                                theme.palette.mode !== "dark"
                                                    ? "1px solid #4a4a4a"
                                                    : "1px solid #f2ebf0",
                                        }}>
                                            <SaveIcon />
                                        </IconButton>
                                        <IconButton color="error" onClick={() => handleDeleteDomain(index, setDomains, domains, domain)} sx={{
                                            border:
                                                theme.palette.mode !== "dark"
                                                    ? "1px solid #4a4a4a"
                                                    : "1px solid #f2ebf0",
                                        }}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </>
                                ) : (
                                    <>
                                        <Typography variant="body1" sx={{
                                            color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000", fontStyle: "italic", flexGrow: 1, ml: 1
                                        }}>
                                            {domain.value}
                                        </Typography>
                                        <IconButton onClick={() => handleEditDomain(index)} sx={{ color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000" }}>
                                            <MoreVertIcon />
                                        </IconButton>
                                        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                                            <MenuItem onClick={() => handleEditDomain(index)} sx={{ color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000" }}>Edit</MenuItem>
                                            <MenuItem onClick={() => { handleDeleteDomain(index, setDomains, domains, domain); handleMenuClose(); }} sx={{ color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000" }}>Delete</MenuItem>
                                        </Menu>
                                    </>
                                )}
                            </ListItem>
                        </React.Fragment>
                    ))}
                </List>
            </>
        )}
    </>
);

export default AddDomainsTab;
