// import React, { useState } from 'react';
// import { TextField } from '@mui/material';
// import { Box, Container, Grid, Typography, Paper, Button, CircularProgress } from '@mui/material';

// const HeadlineAnalyzer = () => {
//   const [inputText, setInputText] = useState('');
//   const [score, setScore] = useState(null);
//   const [error, setError] = useState(''); // State to hold the error message

//   const handleAnalyze = () => {
//     if (!inputText.trim()) {
//       setError('Please enter more text.');
//       setScore(null); // Reset score if input is invalid
//     } else {
//       setError(''); // Clear error if input is valid
//       const generatedScore = Math.floor(Math.random() * 100) + 1;
//       setScore(generatedScore);
//     }
//   };

//   return (
//     <Box sx={{ py: 6 , backgroundColor: '#ffffff', }}>
//       <Container>
//         {/* First Section with Background Color */}
//         <Box sx={{  }}>
//           <Box sx={{ textAlign: 'center', mb: 4 }}>
//             <Typography variant="h4" component="h2" sx={{ fontWeight: 'bold', mb: 2, color: '#212121' }}>
//               Free Headline Analyzer Tool
//             </Typography>
//             <Typography variant="body1" sx={{ color: '#212121', maxWidth: 600, margin: '0 auto' }}>
//               Our Headline Analyzer tool enables you to write irresistible SEO headlines that drive traffic, shares, and rank better in search results.
//             </Typography>
//           </Box>

//           {/* Search Box and Button */}
//           <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
//             <TextField
//               variant="outlined"
//               placeholder="Enter your headline here..."
//               value={inputText}
//               onChange={(e) => setInputText(e.target.value)}
//               sx={{
//                 backgroundColor: 'white',
//                 borderRadius: '4px',
//                 width: '60%',
//                 maxWidth: '500px',
//                 marginRight: 2,
//               }}
//             />
//             <Button
//               variant="contained"
//               onClick={handleAnalyze}
//               sx={{
//                 backgroundColor: '#00a15e',
//                 color: 'white',
//                 fontSize: '17px',
//                 fontWeight: '450',
//                 '&:hover': { backgroundColor: '#249162' },
//               }}
//             >
//               Analyze
//             </Button>
//           </Box>

//           {/* Error Message */}
//           {error && (
//             <Typography variant="body2" sx={{ color: 'red', textAlign: 'center', mt: 2 }}>
//               {error}
//             </Typography>
//           )}
//         </Box>
//       </Container>

//       {/* Three-Step Process Section */}
//       <Box sx={{ py: 6, backgroundColor: '#ffffff', mt: 6 }}>
//         <Container>
//           <Grid container spacing={4} justifyContent="center" alignItems="center">
//             {/* Step 1 */}
//             <Grid item xs={12} md={4} sx={{ textAlign: 'center' }}>
//               <img src="/path-to-icon1.svg" alt="Submit Your Headline Icon" style={{ marginBottom: '10px' }} />
//               <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
//                 Submit Your Headline
//               </Typography>
//               <Typography variant="body2" sx={{ color: '#555' }}>
//                 Submit your desired SEO headline on our analyzer tool and check if it has the emotional value to engage your users.
//               </Typography>
//             </Grid>

//             {/* Step 2 */}
//             <Grid item xs={12} md={4} sx={{ textAlign: 'center' }}>
//               <img src="/path-to-icon2.svg" alt="Review and Grade Icon" style={{ marginBottom: '10px' }} />
//               <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
//                 Review and Grade
//               </Typography>
//               <Typography variant="body2" sx={{ color: '#555' }}>
//                 Our tool will review your headline and provide suggestions to help you earn the highest number of click-throughs.
//               </Typography>
//             </Grid>

//             {/* Step 3 */}
//             <Grid item xs={12} md={4} sx={{ textAlign: 'center' }}>
//               <img src="/path-to-icon3.svg" alt="Refine and Done Icon" style={{ marginBottom: '10px' }} />
//               <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
//                 Refine and Done!
//               </Typography>
//               <Typography variant="body2" sx={{ color: '#555' }}>
//                 Tweak and refine the headline based on our suggestions until you find the best one for increased engagement!
//               </Typography>
//             </Grid>
//           </Grid>
//         </Container>
//       </Box>
//       <Box sx={{ backgroundColor: '#f2f8fd' }}>
//         {/* Analysis Results Section */}
//         {score !== null && (
//           <Box mt={6}>
//             <Typography variant="h5" sx={{ color: '#333', textAlign: 'center', mb: 4, fontSize: '25px', fontWeight: '600' }}>
//               Your Headline Analysis Results
//             </Typography>

//             <Grid container spacing={4} justifyContent="center">
//               {/* Analysis Details */}
//               <Grid item xs={12} md={6} sx={{ backgroundColor: '#f2f7fd' }}>
//                 <Paper elevation={3} sx={{ padding: 3 }}>
//                   <Typography variant="subtitle1" sx={{ color: '#666', textAlign: 'center' }}>
//                     Headline Analysis for:
//                   </Typography>
//                   <Typography variant="h6" sx={{ textAlign: 'center', fontWeight: 'bold', mt: 1, mb: 2 }}>
//                     "{inputText || 'Your Headline Here'}"
//                   </Typography>
//                   <Typography variant="subtitle2" sx={{ textAlign: 'center', color: '#666', mb: 2 }}>
//                     Overall Site Score
//                   </Typography>
//                   <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
//                     <CircularProgress
//                       variant="determinate"
//                       value={score}
//                       size={100}
//                       thickness={4}
//                       sx={{
//                         color: score >= 60 ? '#76c7c0' : '#ff6f61',
//                       }}
//                     />
//                     <Typography variant="h5" sx={{ ml: 2, color: score >= 60 ? '#76c7c0' : '#ff6f61', fontWeight: 'bold' }}>
//                       {score}/100
//                     </Typography>
//                   </Box>
//                   <Typography variant="body2" sx={{ textAlign: 'center', color: '#666', mt: 2 }}>
//                     A very good score is between 60 and 80. For best results, you should strive for 70 and above.
//                   </Typography>
//                 </Paper>
//               </Grid>

//               {/* Score History */}
//               <Grid item xs={12} md={3} sx={{ marginTop: '30px' }}>
//                 <Paper elevation={3} sx={{ padding: 3, textAlign: 'center' }}>
//                   <Typography variant="subtitle1" sx={{ color: '#333', fontWeight: 'bold', mb: 2 }}>
//                     Score History
//                   </Typography>
//                   <Typography variant="body2" sx={{ color: '#ff6f61', fontWeight: 'bold', mb: 1 }}>
//                     {score} - {inputText || 'Your Headline Here'}
//                   </Typography>
//                   <Typography variant="body2" sx={{ color: '#ff6f61', fontWeight: 'bold', mb: 1 }}>
//                     {/* Add previous entries if needed */}
//                     26 - Previous Headline
//                   </Typography>
//                   <Button variant="outlined" color="primary" sx={{ mt: 2 }}>
//                     Clear Score History
//                   </Button>
//                 </Paper>
//               </Grid>
//             </Grid>
//           </Box>
//         )}
//       </Box>
//     </Box>
//   );
// };

// export default HeadlineAnalyzer;





// import React, { useState, useRef } from 'react';
// import { TextField, Box, Container, Grid, Typography, Paper, Button, CircularProgress, Fade } from '@mui/material';
// import { motion } from 'framer-motion'; // Import framer-motion for animation

// const HeadlineAnalyzer = () => {
//   const [inputText, setInputText] = useState('');
//   const [score, setScore] = useState(null);
//   const [error, setError] = useState('');
//   const resultsRef = useRef(null);

//   const handleAnalyze = () => {
//     if (!inputText.trim()) {
//       setError('Please enter more text.');
//       setScore(null);
//     } else {
//       setError('');
//       const generatedScore = Math.floor(Math.random() * 100) + 1;
//       setScore(generatedScore);

//       setTimeout(() => {
//         const offsetTop = resultsRef.current.getBoundingClientRect().top + window.pageYOffset - 100;
//         window.scrollTo({ top: offsetTop, behavior: 'smooth' });
//       }, 100);
//     }
//   };

//   return (
//     <Box sx={{ py: 6, backgroundColor: '#fafafa' }}>
//       <Container>
//         <Box sx={{ textAlign: 'center', mb: 4 }}>
//           <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 2, color: '#212121' }}>
//             Free Headline Analyzer Tool
//           </Typography>
//           <Typography variant="body1" sx={{ color: '#212121', maxWidth: 600, mx: 'auto' }}>
//             Our Headline Analyzer tool enables you to write irresistible SEO headlines that drive traffic, shares, and rank better in search results.
//           </Typography>
//         </Box>

//         <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
//           <TextField
//             variant="outlined"
//             placeholder="Enter your headline here..."
//             value={inputText}
//             onChange={(e) => setInputText(e.target.value)}
//             sx={{
//               backgroundColor: 'white',
//               borderRadius: '4px',
//               width: '60%',
//               maxWidth: '500px',
//               mr: 2,
//             }}
//           />
//           <Button
//             variant="contained"
//             onClick={handleAnalyze}
//             sx={{
//               backgroundColor: '#00a15e',
//               color: 'white',
//               fontSize: '17px',
//               fontWeight: 450,
//               '&:hover': { backgroundColor: '#249162' },
//             }}
//           >
//             Analyze
//           </Button>
//         </Box>

//         {error && (
//           <Typography variant="body2" sx={{ color: 'red', textAlign: 'center', mt: 2 }}>
//             {error}
//           </Typography>
//         )}
//       </Container>

//       <Box sx={{ py: 6, backgroundColor: '#ffffff', mt: 6 }}>
//         <Container>
//           <Grid container spacing={4} justifyContent="center" alignItems="center">
//             {['Submit Your Headline', 'Review and Grade', 'Refine and Done!'].map((step, index) => (
//               <Grid key={index} item xs={12} md={4} sx={{ textAlign: 'center' }}>
//                 <img src={`/path-to-icon${index + 1}.svg`} alt={`${step} Icon`} style={{ marginBottom: '10px' }} />
//                 <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
//                   {step}
//                 </Typography>
//                 <Typography variant="body2" sx={{ color: '#555' }}>
//                   {index === 0
//                     ? 'Submit your desired SEO headline on our analyzer tool and check if it has the emotional value to engage your users.'
//                     : index === 1
//                     ? 'Our tool will review your headline and provide suggestions to help you earn the highest number of click-throughs.'
//                     : 'Tweak and refine the headline based on our suggestions until you find the best one for increased engagement!'}
//                 </Typography>
//               </Grid>
//             ))}
//           </Grid>
//         </Container>
//       </Box>

//       <Box ref={resultsRef} sx={{ backgroundColor: '#f2f8fd' }}>
//         {score !== null && (
//           <Fade in={true} timeout={500}>
//             <Container sx={{ py: '30px' }}>
//               <motion.div
//                 initial={{ opacity: 0, y: 50 }}
//                 animate={{ opacity: 1, y: 0 }}
//                 transition={{ duration: 0.5 }}
//               >
//                 <Typography variant="h5" sx={{ color: '#333', textAlign: 'center', mb: 4, fontSize: '25px', fontWeight: 600 }}>
//                   Your Headline Analysis Results
//                 </Typography>

//                 <Grid container spacing={4} justifyContent="center">
//                   <Grid item xs={12} md={6}>
//                     <Paper elevation={4} sx={{ p: 4,  borderRadius: '15px' }}>
//                       <Typography variant="subtitle1" sx={{ color: '#666', textAlign: 'center' }}>
//                         Headline Analysis for:
//                       </Typography>
//                       <Typography variant="h6" sx={{ textAlign: 'center', fontWeight: 'bold', mt: 1, mb: 2 }}>
//                         "{inputText || 'Your Headline Here'}"
//                       </Typography>
//                       <Typography variant="subtitle2" sx={{ textAlign: 'center', color: '#666', mb: 2 }}>
//                         Overall Site Score
//                       </Typography>
//                       <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
//                         <motion.div
//                           initial={{ scale: 0 }}
//                           animate={{ scale: 1 }}
//                           transition={{ duration: 0.5, ease: 'easeInOut' }}
//                         >
//                           <CircularProgress
//                             variant="determinate"
//                             value={score}
//                             size={120}
//                             thickness={4}
//                             sx={{
//                               color: score >= 60 ? '#76c7c0' : '#ff6f61',
//                             }}
//                           />
//                         </motion.div>
//                         <Typography
//                           variant="h4"
//                           sx={{ ml: 2, color: score >= 60 ? '#76c7c0' : '#ff6f61', fontWeight: 'bold' }}
//                         >
//                           {score}/100
//                         </Typography>
//                       </Box>
//                       <Typography variant="body2" sx={{ textAlign: 'center', color: '#666', mt: 2 }}>
//                         A very good score is between 60 and 80. For best results, strive for 70 and above.
//                       </Typography>
//                     </Paper>
//                   </Grid>

//                   <Grid item xs={12} md={3}>
//                     <Paper elevation={4} sx={{ p: 3, textAlign: 'center', borderRadius: '15px' }}>
//                       <Typography variant="subtitle1" sx={{ color: '#333', fontWeight: 'bold', mb: 2 }}>
//                         Score History
//                       </Typography>
//                       <Typography variant="body2" sx={{ color: '#ff6f61', fontWeight: 'bold', mb: 1 }}>
//                         {score} - {inputText || 'Your Headline Here'}
//                       </Typography>
//                       <Button variant="outlined" color="primary" sx={{ mt: 2 }}>
//                         Clear Score History
//                       </Button>
//                     </Paper>
//                   </Grid>
//                 </Grid>
//               </motion.div>
//             </Container>
//           </Fade>
//         )}
//       </Box>
//     </Box>
//   );
// };

// export default HeadlineAnalyzer;






import React, { useState, useRef } from 'react';
import { TextField, Box, Container, Grid, Typography, Paper, Button, CircularProgress, Fade } from '@mui/material';
import { motion } from 'framer-motion';

const HeadlineAnalyzer = () => {
  const [inputText, setInputText] = useState('');
  const [score, setScore] = useState(null);
  const [scoreHistory, setScoreHistory] = useState([]); // State to store score history
  const [wordBalance, setWordBalance] = useState({
    common: 0,
    uncommon: 0,
    emotional: 0,
    power: 0,
  });
  const [error, setError] = useState('');
  const resultsRef = useRef(null);

  const handleAnalyze = () => {
    if (!inputText.trim()) {
      setError('Please enter more text.');
      setScore(null);
    } else {
      setError('');
      const generatedScore = Math.floor(Math.random() * 100) + 1;
      setScore(generatedScore);

      // Update score history
      setScoreHistory((prevHistory) => [...prevHistory, generatedScore]);

      // Simulated Word Balance Analysis (Random values)
      setWordBalance({
        common: Math.floor(Math.random() * 30) + 20,
        uncommon: Math.floor(Math.random() * 20) + 10,
        emotional: Math.floor(Math.random() * 20) + 5,
        power: Math.floor(Math.random() * 15) + 5,
      });

      setTimeout(() => {
        const offsetTop = resultsRef.current.getBoundingClientRect().top + window.pageYOffset - 100;
        window.scrollTo({ top: offsetTop, behavior: 'smooth' });
      }, 100);
    }
  };

  return (
    <Box sx={{ py: 6, backgroundColor: '#fafafa' }}>
      <Container>
        <Box sx={{ textAlign: 'center', mb: 4 }}>
          <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 2, color: '#212121' }}>
            Free Headline Analyzer Tool
          </Typography>
          <Typography variant="body1" sx={{ color: '#212121', maxWidth: 600, mx: 'auto' }}>
            Our Headline Analyzer tool enables you to write irresistible SEO headlines that drive traffic, shares, and rank better in search results.
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <TextField
            variant="outlined"
            placeholder="Enter your headline here..."
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            sx={{
              backgroundColor: 'white',
              borderRadius: '4px',
              width: '60%',
              maxWidth: '500px',
              mr: 2,
            }}
          />
          <Button
            variant="contained"
            onClick={handleAnalyze}
            sx={{
              backgroundColor: '#00a15e',
              color: 'white',
              fontSize: '17px',
              fontWeight: 450,
              '&:hover': { backgroundColor: '#249162' },
            }}
          >
            Analyze
          </Button>
        </Box>

        {error && (
          <Typography variant="body2" sx={{ color: 'red', textAlign: 'center', mt: 2 }}>
            {error}
          </Typography>
        )}
      </Container>

      <Box sx={{ py: 6, backgroundColor: '#ffffff', mt: 6 }}>
        <Container>
          <Grid container spacing={4} justifyContent="center" alignItems="center">
            {['Submit Your Headline', 'Review and Grade', 'Refine and Done!'].map((step, index) => (
              <Grid key={index} item xs={12} md={4} sx={{ textAlign: 'center' }}>
                <img src={`/path-to-icon${index + 1}.svg`} alt={`${step} Icon`} style={{ marginBottom: '10px' }} />
                <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
                  {step}
                </Typography>
                <Typography variant="body2" sx={{ color: '#555' }}>
                  {index === 0
                    ? 'Submit your desired SEO headline on our analyzer tool and check if it has the emotional value to engage your users.'
                    : index === 1
                    ? 'Our tool will review your headline and provide suggestions to help you earn the highest number of click-throughs.'
                    : 'Tweak and refine the headline based on our suggestions until you find the best one for increased engagement!'}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      <Box ref={resultsRef} sx={{ backgroundColor: '#f2f8fd' }}>
        {score !== null && (
          <Fade in={true} timeout={500}>
            <Container sx={{ py: '30px' }}>
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <Typography variant="h5" sx={{ color: '#333', textAlign: 'center', mb: 4, fontSize: '25px', fontWeight: 600 }}>
                  Your Headline Analysis Results
                </Typography>

                <Grid container spacing={4} justifyContent="center">
                  <Grid item xs={12} md={6}>
                    <Paper elevation={4} sx={{ p: 4, borderRadius: '15px' }}>
                      <Typography variant="subtitle1" sx={{ color: '#666', textAlign: 'center' }}>
                        Headline Analysis for:
                      </Typography>
                      <Typography variant="h6" sx={{ textAlign: 'center', fontWeight: 'bold', mt: 1, mb: 2 }}>
                        "{inputText || 'Your Headline Here'}"
                      </Typography>
                      <Typography variant="subtitle2" sx={{ textAlign: 'center', color: '#666', mb: 2 }}>
                        Overall Site Score
                      </Typography>
                      <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
                        <motion.div
                          initial={{ scale: 0 }}
                          animate={{ scale: 1 }}
                          transition={{ duration: 0.5, ease: 'easeInOut' }}
                        >
                          <CircularProgress
                            variant="determinate"
                            value={score}
                            size={120}
                            thickness={4}
                            sx={{
                              color: score >= 60 ? '#76c7c0' : '#ff6f61',
                            }}
                          />
                        </motion.div>
                        <Typography
                          variant="h4"
                          sx={{ ml: 2, color: score >= 60 ? '#76c7c0' : '#ff6f61', fontWeight: 'bold' }}
                        >
                          {score}/100
                        </Typography>
                      </Box>
                      <Typography variant="body2" sx={{ textAlign: 'center', color: '#666', mt: 2 }}>
                        A very good score is between 60 and 80. For best results, strive for 70 and above.
                      </Typography>
                    </Paper>
                  </Grid>

                  {/* Word Balance Section */}
                  <Grid item xs={12} md={6}>
                    <Paper elevation={4} sx={{ p: 4, borderRadius: '15px', textAlign: 'center' }}>
                      <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                        Word Balance
                      </Typography>
                      <Typography variant="body2" sx={{ color: '#666', mb: 2 }}>
                        See how your headline scores across different types of words.
                      </Typography>
                      <Box sx={{ display: 'flex', justifyContent: 'space-around', mt: 2 }}>
                        {['Common', 'Uncommon', 'Emotional', 'Power'].map((type, index) => (
                          <Box key={index} sx={{ textAlign: 'center' }}>
                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#333' }}>
                              {type}
                            </Typography>
                            <Typography
                              variant="h5"
                              sx={{ fontWeight: 'bold', color: wordBalance[type.toLowerCase()] >= 20 ? '#76c7c0' : '#ff6f61' }}
                            >
                              {wordBalance[type.toLowerCase()]}%
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                    </Paper>
                  </Grid>
                </Grid>

                {/* Score History Section */}
                <Box sx={{ mt: 6, textAlign: 'center' }}>
                  <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                    Score History
                  </Typography>
                  <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: 2 }}>
                    {scoreHistory.map((historyScore, index) => (
                      <Paper key={index} elevation={3} sx={{ p: 2, borderRadius: '10px', width: 80, textAlign: 'center' }}>
                        <Typography variant="body2" sx={{ color: '#333', fontWeight: 'bold' }}>
                          {historyScore}
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#666' }}>
                          /100
                        </Typography>
                      </Paper>
                    ))}
                  </Box>
                </Box>
              </motion.div>
            </Container>
          </Fade>
        )}
      </Box>
    </Box>
  );
};

export default HeadlineAnalyzer;
