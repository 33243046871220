import React, { useEffect } from 'react';
import Tools from './tools/ToolsGrid';
import { Grid } from '@mui/material';
import Footer from '../../common/Footer';

import { About } from './About';
import Header from '../../common/home/Header';


const AboutHome = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Grid container direction="column" sx={{ backgroundColor: '#fff9f7' }}>
      <Grid item>
        <Header />

      </Grid>
      
      <About />
     
      {/* Tools and Contentlist side by side */}
      {/* <Grid container spacing={3} sx={{ mt: 3, px: 3 }}>
        <Grid item xs={12} md={9}>
          <Tools />
          <AiTools />
        </Grid>
        <Grid item xs={12} md={3}>
          <Populartool />
          <Relatedtool />
        </Grid>
      </Grid> */}


      



      <Grid item>
        <Footer />
      </Grid>
    </Grid>
  );
}

export default AboutHome;
