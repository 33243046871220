import React from 'react';
import { Card, CardContent, Typography, Box, IconButton, Grid } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import GaugeChart from 'react-gauge-chart';

const Seodifficulty = () => {
  const difficulty = 46 / 100; // 

  return (
    <Grid>
      <Card sx={{ backgroundColor: '#2a252f', border: '1px solid #4c4452' }}>
        <CardContent>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6" sx={{ fontWeight: 600, color: '#d1d1d1' }}>
              SEO DIFFICULTY
            </Typography>
            <IconButton size="small">
              <RefreshIcon fontSize="small" sx={{ color: '#d1d1d1' }} />
            </IconButton>
          </Box>

          {/* Gauge Chart */}
          <Box display="flex" justifyContent="center" style={{ marginTop: 10 }}>
            <GaugeChart
              id="gauge-chart"
              nrOfLevels={30}
              percent={difficulty}
              colors={['#ed6c02', '#E0E0E0']}
              arcPadding={0.02}
              needleColor="#505050"
              arcWidth={0.3}
              hideText
            />
          </Box>

          {/* Value in the center */}
          <Typography variant="h4" sx={{ marginTop: '-100px', fontWeight: 600, color: '#d1d1d1' }}>
            32
          </Typography>

          {/* Low - High labels */}
          <Box display="flex" justifyContent="space-between" style={{ marginTop: 10 }}>
            <Typography variant="body2" sx={{ color: '#ffffff' }}>
              Low
            </Typography>
            <Typography variant="body2" sx={{ color: '#ffffff' }}>
              High
            </Typography>
          </Box>

          {/* Last Updated */}
          <Typography variant="body2" sx={{ marginTop: 2, color: '#ffffff' }}>
            Last Updated: 6 Months
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default Seodifficulty;
