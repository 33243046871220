import React, { useEffect, useState } from 'react';
import {
  Box, Typography, Button, Checkbox, IconButton, Menu, MenuItem,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Paper, TextField, Divider, Dialog, DialogActions, DialogContent,
  DialogTitle, Select, MenuItem as SelectMenuItem
} from '@mui/material';
import { Add, MoreVert } from '@mui/icons-material';
import { useTheme } from "../../../../../common/ThemeContext";
import { apiList, invokeApi } from '../../../../../../services/apiServices';
import { config } from '../../../../../../config/config';
import { useCookies } from 'react-cookie';

const AddKeywordsTab = ({ keywords, setKeywords, handleNext, handleBack }) => {
  const { theme } = useTheme();
  const [cookies] = useCookies();

  const [categoryId, setCategoryId] = useState(null);
  console.log("Category Id->", categoryId);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [newKeyword, setNewKeyword] = useState('');
  const [newCategory, setNewCategory] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [categories, setCategories] = useState([]); // Default category
  console.log("Categories  ======>", categories);
  const [categoryDialogOpen, setCategoryDialogOpen] = useState(false);
  const [selectedKeywords, setSelectedKeywords] = useState([]);
  const [headerMenuAnchor, setHeaderMenuAnchor] = useState(null);
  const [keywordMenuAnchor, setKeywordMenuAnchor] = useState(null);
  const [editingKeyword, setEditingKeyword] = useState(null);

  const handleHeaderMenuClick = (event) => {
    setHeaderMenuAnchor(event.currentTarget);
  };

  const handleHeaderMenuClose = () => {
    setHeaderMenuAnchor(null);
  };

  const handleImportColumnData = () => {
    console.log("Importing column data...");
    handleHeaderMenuClose();
  };

  const handleClearColumnData = () => {
    setKeywords([]);
    handleHeaderMenuClose();
  };

  const handleAddKeywordClick = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setNewKeyword('');
    setEditingKeyword(null);
    setSelectedCategory('Main'); // Reset to default category
  };

  const fetchAddKeywordData = async (keywordName) => {
    const params = {
      projectId: 121913,
      categoryId: categoryId,
      keyword: keywordName,
    };
    try {
      const response = await invokeApi(
        config.getMyCollege + apiList.addKeyword,
        params,
        cookies
      );
      if (response?.status === 200) {
        console.log("Keyword Added Successfully");
        fetchKeywordsData();
      } else {
        console.error("Failed to fetch data:", response);
      }
    } catch (error) {
      console.error("Error during data fetch:", error);
    }
  };

  const fetchKeywordsData = async () => {
    const params = {};
    try {
      const response = await invokeApi(
        config.getMyCollege + apiList.getKeywords,
        params,
        cookies
      );
      if (response?.status === 200) {
        console.log("Keywords Successfully");
        setKeywords(response.data.keywords)
      } else {
        console.error("Failed to fetch data:", response);
      }
    } catch (error) {
      console.error("Error during data fetch:", error);
    }
  };

  const fetchRenameKeywordsData = async () => {
    const params = {
      projectId: 121913,
      keywordId: editingKeyword,
      keyword: newKeyword,
    };
    try {
      const response = await invokeApi(
        config.getMyCollege + apiList.renameKeyword,
        params,
        cookies
      );
      if (response?.status === 200) {
        console.log("Keywords Successfully");
        fetchKeywordsData();
      } else {
        console.error("Failed to fetch data:", response);
      }
    } catch (error) {
      console.error("Error during data fetch:", error);
    }
  };

  // const fetchUpdateCategoryData = async () => {
  //   const params = {
  //     projectId: 121913,
  //     categoryId: categoryId,
  //     name: selectedCategory,
  //   };
  //   try {
  //     const response = await invokeApi(
  //       config.getMyCollege + apiList.updateCategory,
  //       params,
  //       cookies
  //     );
  //     if (response?.status === 200) {
  //       console.log("Keywords Successfully");
  //       fetchKeywordsData();
  //     } else {
  //       console.error("Failed to fetch data:", response);
  //     }
  //   } catch (error) {
  //     console.error("Error during data fetch:", error);
  //   }
  // };


  const fetchAddCategoryData = async () => {
    const params = {
      projectId: 121913,
      name: newCategory
    };
    try {
      const response = await invokeApi(
        config.getMyCollege + apiList.addCategory,
        params,
        cookies
      );
      if (response?.status === 200) {
        const newCategoryId = response.data.id;
        fetchCategoriesData();
        setCategoryId(newCategoryId);
        fetchKeywordsData();
        console.log("Domain Added Successfully");
      } else {
        console.error("Failed to fetch data:", response);
      }
    } catch (error) {
      console.error("Error during data fetch:", error);
    }
  };

  const fetchCategoriesData = async () => {
    const params = {};
    try {
      const response = await invokeApi(
        config.getMyCollege + apiList.getCategories,
        params,
        cookies
      );
      if (response?.status === 200) {
        console.log("Successfully");
        setCategories(response.data.categories)
      } else {
        console.error("Failed to fetch data:", response);
      }
    } catch (error) {
      console.error("Error during data fetch:", error);
    }
  };

  useEffect(() => {
    fetchCategoriesData();
    fetchKeywordsData();
  }, [])

  const handleAddKeyword = () => {
    if (newKeyword.trim()) {

      if (editingKeyword) {
        fetchRenameKeywordsData();
        // fetchUpdateCategoryData();
      } else {
        fetchAddKeywordData(newKeyword);
      }
    }
    handleDialogClose();
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      setSelectedKeywords(keywords.map((keyword) => keyword.id));
    } else {
      setSelectedKeywords([]);
    }
  };

  const handleCheckboxClick = (id) => {
    setSelectedKeywords((prevSelected) =>
      prevSelected.includes(id) ? prevSelected.filter((kId) => kId !== id) : [...prevSelected, id]
    );
  };

  const handleKeywordMenuClick = (event, id) => {
    setKeywordMenuAnchor({ anchor: event.currentTarget, id });
  };

  const handleKeywordMenuClose = () => {
    setKeywordMenuAnchor(null);
  };

  const handleEditKeyword = (id, text, category) => {
    setNewKeyword(text);
    setEditingKeyword(id);
    setSelectedCategory(category || 'Main');
    setDialogOpen(true);
    handleKeywordMenuClose();
  };

  const fetchDeleteData = async (projectId, keywordId) => {
    const params = {
      projectId: projectId,
      keywordId: keywordId
    };
    try {
      const response = await invokeApi(
        config.getMyCollege + apiList.deleteKeyword,
        params,
        cookies
      );
      if (response?.status === 200) {
        console.log("Deleted Successfully");
        fetchKeywordsData();
      } else {
        console.error("Failed to fetch data:", response);
      }
    } catch (error) {
      console.error("Error during data fetch:", error);
    }
  };

  const handleDeleteKeyword = (projectId, keywordId) => {
    fetchDeleteData(projectId, keywordId);
    // setKeywords(keywords.filter((kw) => kw.keywordId !== id));
    handleKeywordMenuClose();
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    const selectedCategory = categories.find(group => group.name === event.target.value);
    setCategoryId(selectedCategory?.categoryId || null); // assuming `groupId` is the correct field in your object
  };

  const handleAddCategoryClick = () => {
    setCategoryDialogOpen(true);
  };

  const handleCategoryDialogClose = () => {
    setCategoryDialogOpen(false);
    setNewCategory('');
  };

  const handleAddCategory = () => {
    if (newCategory.trim() && !categories.some(cat => cat.name === newCategory.trim())) {
      const newCategoryObj = { id: Date.now(), name: newCategory.trim() };
      // setCategories([...categories, newCategoryObj]); // Add to categories state directly
      setSelectedCategory(newCategory.trim()); // Set the new category as selected in dropdown
      fetchAddCategoryData(); // Persist the new category in backend
    }
    handleCategoryDialogClose();
  };

  return (
    <Box
      sx={{
        p: 3,
        borderRadius: 2,
        boxShadow: 1,
        flexDirection: 'column',
        textAlign: 'left',
        backgroundColor: theme.palette.mode !== "dark" ? "#312b36" : "#fff",
        border: theme.palette.mode !== "dark" ? "1px solid #4a4a4a" : "1px solid #f2ebf0",
      }}
    >
      {/* Top section with Add Keywords button */}
      <Box display="flex" justifyContent="space-between" mb={2}>
        <Button
          variant="text"
          startIcon={<Add />}
          onClick={handleAddKeywordClick}
          sx={{
            textTransform: 'uppercase',
            color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000",
            fontWeight: 'bold',
            border: theme.palette.mode !== "dark" ? "1px solid #4a4a4a" : "1px solid #f2ebf0",
          }}
        >
          Add Keywords
        </Button>

      </Box>

      <Divider />

      {/* Keywords Table */}
      <TableContainer
        component={Paper}
        variant="outlined"
        sx={{
          mt: 2,
          backgroundColor: theme.palette.mode !== "dark" ? "#312b36" : "#fff",
          border: theme.palette.mode !== "dark" ? "1px solid #4a4a4a" : "1px solid #f2ebf0",
        }}
      >
        <Table aria-label="keywords table">
          <TableHead>
            <TableRow>
              {/* <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={selectedKeywords.length > 0 && selectedKeywords.length < keywords.length}
                  checked={keywords.length > 0 && selectedKeywords.length === keywords.length}
                  onChange={handleSelectAllClick}
                />
              </TableCell> */}
              <TableCell sx={{ fontWeight: 'bold', color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000" }}>
                Keyword
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold', color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000" }}>
                Category
              </TableCell>
              {/* <TableCell align="right">
                <IconButton onClick={handleHeaderMenuClick} sx={{ color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000" }}>
                  <MoreVert />
                </IconButton>
                <Menu
                  anchorEl={headerMenuAnchor}
                  open={Boolean(headerMenuAnchor)}
                  onClose={handleHeaderMenuClose}
                >
                  <MenuItem onClick={handleImportColumnData} sx={{ color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000" }}>
                    Import Column Data
                  </MenuItem>
                  <MenuItem onClick={handleClearColumnData} sx={{ color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000" }}>
                    Clear Column Data
                  </MenuItem>
                </Menu>
              </TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {categories.map((category) => (
              <React.Fragment key={category.id}>
                <TableRow>
                  {keywords.filter(k => k.categoryId === category.categoryId).length > 0 && (
                    <TableCell colSpan={4} sx={{ fontWeight: 'bold', color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000" }}>
                      {category.name} ({keywords.filter(k => k.categoryId === category.categoryId).length})
                    </TableCell>
                  )}

                </TableRow>
                {keywords.filter(k => k.categoryId === category.categoryId).map((keyword) => (
                  <TableRow key={keyword.id} sx={{ borderBottom: 'none' }}>
                    {/* <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedKeywords.includes(keyword.id)}
                        onChange={() => handleCheckboxClick(keyword.id)}
                      />
                    </TableCell> */}
                    <TableCell sx={{ color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000" }}>
                      {keyword.keyword}
                    </TableCell>
                    <TableCell sx={{ color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000" }}>
                      {category.name}
                    </TableCell>
                    <TableCell align="right">
                      <IconButton onClick={(e) => handleKeywordMenuClick(e, keyword.id)} sx={{ color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000" }}>
                        <MoreVert />
                      </IconButton>
                      <Menu
                        anchorEl={keywordMenuAnchor?.anchor}
                        open={Boolean(keywordMenuAnchor && keywordMenuAnchor.id === keyword.id)}
                        onClose={handleKeywordMenuClose}
                      >
                        <MenuItem onClick={() => handleEditKeyword(keyword.keywordId, keyword.keyword, category.name)} sx={{ color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000" }}>
                          Edit
                        </MenuItem>
                        <MenuItem onClick={() => handleDeleteKeyword(keyword.projectId, keyword.keywordId)} sx={{ color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000" }}>
                          Delete
                        </MenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                ))}
              </React.Fragment>
            ))}
          </TableBody>

        </Table>
      </TableContainer>

      {/* Keyword Dialog */}
      <Dialog open={dialogOpen} fullWidth onClose={handleDialogClose}>
        <DialogTitle>{editingKeyword ? "Edit Keyword" : "Add Keyword"}</DialogTitle>
        <DialogContent>
          {!editingKeyword && (
            <Box mt={2} display="flex" alignItems="center">
              <Typography variant="body2" color="textSecondary" sx={{ mr: 1 }}>Category:</Typography>
              <Select
                value={selectedCategory}
                onChange={handleCategoryChange}
                fullWidth
                variant="outlined"
                sx={{ flexGrow: 1, mr: 1 }}
                oncl
              >
                {categories.map((category, index) => (
                  <SelectMenuItem key={index} value={category.name}>
                    {category.name}
                  </SelectMenuItem>
                ))}
              </Select>
              <IconButton
                onClick={handleAddCategoryClick}
                sx={{
                  color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000",
                  p: '10px',
                }}
              >
                <Add />
              </IconButton>
            </Box>
          )}


          <TextField
            label="Keyword"
            value={newKeyword}
            onChange={(e) => setNewKeyword(e.target.value)}
            fullWidth
            margin="dense"
          />

        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={handleAddKeyword} color="primary">
            {editingKeyword ? "Update" : "Add"}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Category Dialog */}
      <Dialog open={categoryDialogOpen} onClose={handleCategoryDialogClose}>
        <DialogTitle>Add New Category</DialogTitle>
        <DialogContent>
          <TextField
            label="New Category"
            value={newCategory}
            onChange={(e) => setNewCategory(e.target.value)}
            fullWidth
            margin="dense"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCategoryDialogClose}>Cancel</Button>
          <Button onClick={handleAddCategory} color="primary">Add</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AddKeywordsTab;
