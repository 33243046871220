import React, { useEffect, useState, useRef } from "react";

import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  TextField,
  Button,
  Box,
  Avatar,
  Popover,
  List,
  ListItem,
  ListItemText,
  Divider,
  Stack,
  Modal,
  Grid,
  ClickAwayListener,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import AutoFixHighOutlinedIcon from "@mui/icons-material/AutoFixHighOutlined";
import DoneAllOutlinedIcon from "@mui/icons-material/DoneAllOutlined";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import WordCountIcon from "@mui/icons-material/Assessment";
import CodeIcon from "@mui/icons-material/Code";
import PlagiarismIcon from "@mui/icons-material/ReportProblem";
import ImageIcon from "@mui/icons-material/Image";
import MapIcon from "@mui/icons-material/Map";
import SchoolIcon from "@mui/icons-material/School";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EditIcon from "@mui/icons-material/Edit";
import SpellcheckIcon from "@mui/icons-material/Spellcheck";
import logo from "../../../assets/seo-mitra-logo.png";
import { useNavigate } from "react-router-dom";
import { useTheme } from "../../common/ThemeContext";
import { useCookies } from "react-cookie";
import { config } from "../../../config/config";
import useFetch from "../../pages/useFetch";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../../global/redux/action";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import AddIcon from "@mui/icons-material/Add";

function Header() {
  // Separate states for popovers
  const { name, roles } = useFetch();
  const [cookies, , removeCookie] = useCookies([config.cookieName]);
  const [avatarAnchorEl, setAvatarAnchorEl] = useState(null);
  const [toolsAnchorEl, setToolsAnchorEl] = useState(null);
  const { theme, toggleTheme } = useTheme();
  const navigate = useNavigate();
  const globalState = useSelector((state) => state);
  const { userData, userError } = globalState.userReducer;
  const dispatch = useDispatch();
  const [anchorSeoToolsEl, setAnchorSeoToolsEl] = useState(null);
  const [optionsVisible, setOptionsVisible] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  console.log(name, "stud");
  console.log(roles, "rol");

  useEffect(() => {
    if (userError) {
      alert(
        "Something went wrong while fetching user details. Please try again later!"
      );
    }
  }, [userError]);

  useEffect(() => {
    if (cookies[config.cookieName]?.loginUserId && !userData?.users) {
      dispatch(
        getUser({ id: cookies[config.cookieName].loginUserId, cookies })
      );
    }
  }, [dispatch, cookies, userData]);

  const handleLogout = () => {
    removeCookie(config.cookieName);
    // navigate("/");
    handleAvatarPopoverClose();
    window.location.replace("/");
  };

  const handleAvatarPopoverOpen = (event) => {
    setAvatarAnchorEl(event.currentTarget);
  };

  const handleAvatarPopoverClose = () => {
    setAvatarAnchorEl(null);
  };

  const openAvatarPopover = Boolean(avatarAnchorEl);

  const handleToolsPopoverOpen = (event) => {
    setToolsAnchorEl(event.currentTarget);
  };

  const handleToolsPopoverClose = () => {
    setToolsAnchorEl(null);
  };

  const handleSEOToolsClick = (event) => {
    setAnchorSeoToolsEl(event.currentTarget);
  };

  const handleSeoToolsClose = () => {
    setAnchorSeoToolsEl(null);
  };

  const options = ["Website 1", "Website 2", "Website 3", "Website 4"];

  const filteredOptions = options.filter((option) =>
    option.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const scrollContainer = useRef(null);
  const seoToolsOpen = Boolean(anchorSeoToolsEl);
  const id = "simple-popover";

  const openToolsPopover = Boolean(toolsAnchorEl);

  const handleNavigation = (path) => {
    navigate(path);
    handleAvatarPopoverClose();
    handleToolsPopoverClose();
  };

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const openMenu = (event) => setMenuAnchorEl(event.currentTarget);
  const closeMenu = () => setMenuAnchorEl(null);

  const isMenuOpen = Boolean(menuAnchorEl);

  const [backLinksAnchorEl, setbackLinksAnchorEl] = useState(null);

  const handleBackLinksopen = (event) =>
    setbackLinksAnchorEl(event.currentTarget);
  const handleBackLinksclose = () => setbackLinksAnchorEl(null);

  const backLinksOpen = Boolean(backLinksAnchorEl);

  const avatarMenuItems = [
    // {
    //   label: "Upgrade Now",
    //   icon: (
    //     <Button variant="outlined" sx={{ color: "orange" }}>
    //       Upgrade Now
    //     </Button>
    //   ),
    //   path: "/upgrade",
    //   isButton: true,
    // },
    // {
    //   label: "My Profile",
    //   icon: <PermIdentityOutlinedIcon fontSize="small" />,
    //   path: "/profile",
    // },
    // {
    //   label: "Plan & Billing",
    //   icon: <CreditCardOutlinedIcon fontSize="small" />,
    //   path: "/plan-billing",
    // },
    // {
    //   label: "Referral Points",
    //   icon: <NotificationsOutlinedIcon fontSize="small" />,
    //   path: "/referral-point",
    // },
    // {
    //   label: "Manage User",
    //   icon: <NotificationsOutlinedIcon fontSize="small" />,
    //   path: "/manage-user",
    // },
    {
      label: "My Order",
      icon: <CreditCardOutlinedIcon fontSize="small" />,
      path: "/my-order",
    },
    {
      label: "Log Out",
      icon: <LogoutOutlinedIcon fontSize="small" />,
      action: handleLogout,
    },
  ];

  const toolsmenuItems = [
    {
      label: "AI Content Outliner",
      icon: <AutoFixHighOutlinedIcon fontSize="small" />,
      path: "/aiContentOutliner",
    },
    {
      label: "On-Page SEO Checker",
      icon: <DoneAllOutlinedIcon fontSize="small" />,
      path: "/onPageSeoChecker",
    },
    {
      label: "Word Count",
      icon: <WordCountIcon fontSize="small" />,
      path: "/wordcounthome",
    },
    {
      label: "Code Formatter",
      icon: <CodeIcon fontSize="small" />,
      path: "/codeFormatter",
    },
    {
      label: "Plagiarism Checker",
      icon: <PlagiarismIcon fontSize="small" />,
      path: "/plagiarismChecker",
    },
    {
      label: "Free Image Hosting",
      icon: <ImageIcon fontSize="small" />,
      path: "/freeImageHosting",
    },
    {
      label: "Site Map Checker",
      icon: <MapIcon fontSize="small" />,
      path: "/siteMapChecker",
    },
    {
      label: "AI Detector",
      icon: <SchoolIcon fontSize="small" />,
      path: "/aiDetector",
    },
    {
      label: "Da Pa Checker",
      icon: <CheckCircleIcon fontSize="small" />,
      path: "/daPaChecker",
    },
    {
      label: "Paraphrase Tool",
      icon: <EditIcon fontSize="small" />,
      path: "/paraphraseTool",
    },
    {
      label: "Grammarly Online Check",
      icon: <SpellcheckIcon fontSize="small" />,
      path: "/grammarlyCheck",
    },
  ];

  const menuItems = [
    { label: "Work Space", path: "/workspace" },
    { label: "Paragrafh Tool", path: "/paragrafh-tool" },
    { label: "Ai Checker", path: "/ai-checker" },
    // { label: "Add Website", path: "/addweb-tabhome" },
    { label: "Keyword Explorer", path: "/keywordHome" },
    { label: "Headline Analyzer", path: "/headline-analyzer" },
  ];

  const handleRelatedToolClick = () => {
    navigate("/related-tool");
  };

  const menuRef = useRef(null);

  const handleScroll = (direction) => {
    const scrollAmount = 150;
    if (menuRef.current) {
      menuRef.current.scrollTo({
        left:
          direction === "left"
            ? menuRef.current.scrollLeft - scrollAmount
            : menuRef.current.scrollLeft + scrollAmount,
        behavior: "smooth",
      });
    }
  };

  return (
    <AppBar
      position="static"
      sx={{
        background: theme.palette.background.default,
        color: theme.palette.text.primary,
      }}
    >
      <Toolbar>
        <Grid
          container
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Grid
            md={3.7}
            item
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "left",
            }}
          >
            {/* Logo */}
            <img
              src={logo}
              alt="Wincher Logo"
              style={{
                width: "80px",
                height: "auto",
                marginRight: "8px",
                textAlign: "left",
                // cursor: "pointer",
              }}
              // onClick={() => navigate("/user-dashboard")}
            />

            {/* Search bar */}
            {/* <Box sx={{ flexGrow: 1, position: "relative" }}>
              <Box
                sx={{
                  flexGrow: 1,
                  display: "flex",
                  alignItems: "center",
                  border: "1px solid #8e7a8a",
                  borderRadius: 2,
                  padding: "0 8px",
                  maxWidth: "260px",
                  height: "40px",
                  ml: 2,
                }}
              >
                <SearchIcon sx={{ color: "#d5d5d5" }} />
                <TextField
                  variant="standard"
                  placeholder="Search Which Tool You Want"
                  value={searchQuery}
                  onFocus={() => setOptionsVisible(true)}
                  onBlur={() => setTimeout(() => setOptionsVisible(false), 200)}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  InputProps={{
                    disableUnderline: true,
                    sx: { color: "#ffff", fontWeight: "bold" },
                  }}
                  sx={{ flexGrow: 1, ml: 1 }}
                />
              </Box>

             
              {optionsVisible && searchQuery && (
                <List
                  sx={{
                    position: "absolute",
                    left: 16,
                    top: "45px",
                    width: "270px",
                    backgroundColor: "white",
                    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                    borderRadius: "4px",
                    zIndex: 1000,
                  }}
                >
                  {filteredOptions.length ? (
                    filteredOptions.map((option, index) => (
                      <ListItem
                        button
                        key={index}
                        onClick={() => {
                          setSearchQuery(option);
                          setOptionsVisible(false);
                        }}
                      >
                        <ListItemText primary={option} />
                      </ListItem>
                    ))
                  ) : (
                    <ListItem sx={{ justifyContent: "center" }}>
                      <Button
                        variant="contained"
                        onClick={handleRelatedToolClick}
                        sx={{
                          textTransform: "none",
                          backgroundColor: "#1d1922",
                          fontWeight: "bold",
                          width: "240px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <AddIcon sx={{ marginRight: "8px" }} />{" "}
                       
                        Request New Tool
                      </Button>
                    </ListItem>
                  )}
                </List>
              )}
            </Box> */}
          </Grid>

          <Grid
            md={5.8}
            item
            sx={{
              display: "flex",
              flexDirection: 'row',
              alignItems: "center",
              justifyContent: "center",
              // borderLeft: "2px solid #30303d",
              // borderRight: "2px solid #30303d",
            }}
          >
            {/* <IconButton
              onClick={() => handleScroll("left")}
              sx={{
                position: "relative",
                zIndex: 500,
                borderRadius: "50%", // Keep circular shape
                backgroundColor: "#2a252f", // Button background color
                color: "#ffffff", // Icon color
                border: "1px solid #30303d", // Border style
                display: "flex", // Ensuring the button behaves as a flex container
                alignItems: "center", // Vertically center the icon
                justifyContent: "center", // Horizontally center the icon
                width: "40px", // Set the size of the button
                height: "40px", // Set the size of the button
              }}
              aria-label="scroll-left"
            >
              <ArrowBackIosIcon fontSize="small" />
            </IconButton> */}
            <Grid
              ref={menuRef}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "left",
                overflowX: "scroll",
                scrollbarWidth: "none",
              }}
            >
             
              {/* <Grid
                sx={{
                  color: "#b9b9b9",
                  textAlign: "center",
                  display: "flex",
                  height: "60px",
                  minWidth: "150px",
                  alignItems: "center",
                  justifyContent: "center",
                  // paddingLeft: 2,
                  borderLeft: `3px solid ${theme.palette.background.paper}`,
                  cursor: "pointer",
                  background: theme.palette.background.default,
                  transition: "ease-in-out 100ms",
                  "&:hover": {
                    color: "#fff",
                  },
                  "&:active": {
                    background: "#161616",
                    color: "#fff",
                  },
                }}
                onClick={() => navigate("/dashboard")}
              >
                Rank Tracker
                
              </Grid> */}

              {/* seo tools */}
              {/* <Grid
                sx={{
                  color: "#b9b9b9",
                  textAlign: "center",
                  display: "flex",
                  height: "60px",
                  width: "300px",
                  alignItems: "center",
                  justifyContent: "center",
                  px: "1rem",
                  minWidth: "150px",
                  borderLeft: `3px solid ${theme.palette.background.paper}`,
                  cursor: "pointer",
                  background: theme.palette.background.default,
                  transition: "ease-in-out 100ms",
                  "&:hover": {
                    color: "#fff",
                  },
                  "&:active": {
                    background: "#161616",
                    color: "#fff",
                    "&:hover": {
                      color: "#fff",
                    },
                    "& svg": {
                      color: "orange",
                    },
                  },
                }}
                onClick={handleSEOToolsClick}
              >
                SEO Tools
                <KeyboardArrowDownIcon
                  sx={{
                    marginLeft: 1,
                    color: "#b9b9b9",
                    transition: "color 100ms ease-in-out",
                    "&:hover": {
                      color: "#ffffff",
                    },
                  }}
                />
              </Grid> */}

              {/* <Popover
                id={id}
                open={seoToolsOpen}
                anchorEl={anchorSeoToolsEl}
                onClose={handleSeoToolsClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <List
                  sx={{
                    backgroundColor:
                      theme.palette.mode !== "dark" ? "#000000" : "#000000",
                    cursor: "pointer",
                  }}
                >
                  {[
                    { text: "Site Audit", path: "/site-audit" },
                    { text: "Site Explorer", path: "/site-explore" },
                    { text: "Traffic Checker", path: "/traffic-checker" },
                    { text: "Keyword Research", path: "/keyword-research" },
                    { text: "Backlinks Explorer", path: "/backlinks-explore" },
                  ].map((item, index) => (
                    <ListItem
                      key={item.text}
                      button
                      onClick={() => {
                        handleSeoToolsClose();
                        navigate(item.path);
                      }}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        borderRadius: "8px",
                        borderBottom: "1px solid #ccc",
                        padding: "8px 16px",
                        "&:hover": {
                          bgcolor: theme.palette.action.hover,
                        },
                        "&:last-child": {
                          borderBottom: "none",
                        },
                      }}
                    >
                     
                      <Box
                        sx={{
                          width: "28px",
                          height: "28px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "50%",
                          bgcolor: '#ed6c02',
                          color: "#fff",
                          fontWeight: "bold",
                          marginRight: "12px",
                          fontSize: 13
                        }}
                      >
                        {(index + 1).toString().padStart(2, "0")}
                      </Box>

                     
                      <ListItemText
                        primary={item.text}
                        sx={{ color: "#b9b9b9" }}
                      />
                    </ListItem>
                  ))}
                </List>



              </Popover> */}

              {/* Content tools button */}
              {/* <Grid
                sx={{
                  color: "#b9b9b9",
                  textAlign: "center",
                  display: "flex",
                  height: "60px",
                  alignItems: "center",
                  justifyContent: "center",
                  px: "1rem",
                  minWidth: "170px",
                  borderLeft: `3px solid ${theme.palette.background.paper}`,
                  cursor: "pointer",
                  background: theme.palette.background.default,
                  transition: "ease-in-out 100ms",
                  "&:hover": {
                    color: "#fff",
                  },
                  "&:active": {
                    background: "#161616",
                    color: "#fff",
                    "&:hover": {
                      color: "#fff",
                    },
                    "& svg": {
                      color: "orange",
                    },
                  },
                }}
                onClick={openMenu}
              >
                Content Tools
                <KeyboardArrowDownIcon
                  sx={{
                    marginLeft: 1,
                    color: "#b9b9b9",
                    transition: "color 100ms ease-in-out",
                  }}
                />
              </Grid> */}
              {/* Popover with Menu Items */}
              {/* <Popover
                sx={{ cursor: "pointer" }}
                open={isMenuOpen}
                anchorEl={menuAnchorEl}
                onClose={closeMenu}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <List
                  sx={{
                    bgcolor: theme.palette.background.default,
                    cursor: "pointer",
                  }}
                >
                  {menuItems.map((item, index) => (
                    <ListItem
                      button
                      key={index}
                      onClick={() => {
                        navigate(item.path);
                        closeMenu();
                      }}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        borderRadius: "8px",
                        borderBottom: "1px solid #ccc",
                        padding: "8px 16px",
                        "&:hover": {
                          bgcolor: theme.palette.action.hover,
                        },
                        "&:last-child": {
                          borderBottom: "none",
                        },
                      }}
                    >
                     
                      <Box
                        sx={{
                          width: "28px",
                          height: "28px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "50%",
                          bgcolor: '#ed6c02',
                          color: "#fff",
                          fontWeight: "bold",
                          fontSize: 13,
                          marginRight: "12px",
                        }}
                      >
                        {(index + 1).toString().padStart(2, "0")}
                      </Box>

                     
                      <ListItemText
                        primary={item.label}
                        sx={{ color: "#b9b9b9" }}
                      />
                    </ListItem>
                  ))}
                </List>
              </Popover> */}

              {/* Keyword tools */}
              {/* <Grid
                sx={{
                  color: "#b9b9b9",
                  textAlign: "center",
                  display: "flex",
                  height: "60px",
                  alignItems: "center",
                  justifyContent: "center",
                  px: "1rem",
                  minWidth: "190px",
                  borderLeft: `3px solid ${theme.palette.background.paper}`,
                  cursor: "pointer",
                  background: theme.palette.background.default,
                  transition: "ease-in-out 100ms",
                  "&:hover": {
                    color: "#fff",
                  },
                  "&:active": {
                    background: "#161616",
                    color: "#fff",
                    "&:hover": {
                      color: "#fff",
                    },
                    "& svg": {
                      color: "orange",
                    },
                  },
                }}
                onClick={() => navigate("/keywordHome")}
              >
                Keyword Tools
                <KeyboardArrowDownIcon
                  sx={{
                    marginLeft: 1,
                    color: "#b9b9b9",
                    transition: "color 100ms ease-in-out",
                    "&:hover": {
                      color: "#ffffff",
                    },
                  }}
                />
              </Grid> */}

              {/* other tools */}
              {/* <Grid
                sx={{
                  color: "#b9b9b9",
                  textAlign: "center",
                  display: "flex",
                  height: "60px",
                  minWidth: "160px",
                  alignItems: "center",
                  justifyContent: "center",
                  px: "1rem",
                  borderLeft: `3px solid ${theme.palette.background.paper}`,
                  cursor: "pointer",
                  background: theme.palette.background.default,
                  transition: "ease-in-out 100ms",
                  "&:hover": {
                    background: "#161616",
                    color: "#fff",
                  },
                  "&:hover .MuiSvgIcon-root.buildIcon": {
                    color: "orange",
                  },
                }}
                onClick={handleToolsPopoverOpen}
              >
                Other Tools
                <KeyboardArrowDownIcon
                  sx={{
                    marginLeft: 1,
                    color: "#b9b9b9",
                    transition: "color 100ms ease-in-out",
                  }}
                />
              </Grid> */}
              {/* Tools Popover */}
              {/* <Popover
                open={openToolsPopover}
                anchorEl={toolsAnchorEl}
                onClose={handleToolsPopoverClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                sx={{ top: "12px", }}
              >




                <List sx={{ backgroundColor: "black", }}>
                  {toolsmenuItems.map((item, index) => (
                    <ListItem
                      key={index}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        borderRadius: "8px", // Rounded corners for the list item
                        padding: "8px 16px", // Add some padding to space out content
                        cursor: "pointer",
                        borderBottom: "1px solid #ccc", // Bottom border for separation
                        "&:hover": {
                          bgcolor: theme.palette.action.hover, // Hover effect for background
                          color: "orange", // Change text color on hover
                        },
                        "&:last-child": {
                          borderBottom: "none", // Remove the border for the last item
                        },
                      }}
                      onClick={() => {
                        navigate(item.path);
                        handleToolsPopoverClose();
                      }}
                    >
                     
                      <Box
                        sx={{
                          width: "28px",
                          height: "28px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "50%",
                          bgcolor: '#ed6c02',
                          color: "#fff",
                          fontWeight: "bold",
                          marginRight: "12px",
                          fontSize: 13,
                        }}
                      >
                        {(index + 1).toString().padStart(2, "0")} 
                      </Box>

                    
                      <ListItemText
                        primary={item.label}
                        primaryTypographyProps={{
                          sx: {
                            fontSize: "12px",
                            ml: "10px", // Margin-left for spacing between the number and label
                            color: "#b9b9b9", // Text color (light gray for contrast on black)
                          },
                        }}
                      />
                    </ListItem>
                  ))}
                </List>

              </Popover> */}


              {/* More Tools */}
              {/* <Grid
                sx={{
                  color: "#b9b9b9",
                  textAlign: "center",
                  display: "flex",
                  height: "60px",
                  alignItems: "center",
                  justifyContent: "center",
                  px: "1rem",
                  minWidth: "130px",
                  borderLeft: `3px solid ${theme.palette.background.paper}`,
                  borderRight: `3px solid ${theme.palette.background.paper}`,
                  cursor: "pointer",
                  background: theme.palette.background.default,
                  transition: "ease-in-out 100ms",

                  "&:hover": {
                    color: "#fff",
                  },
                  "&:active": {
                    background: "#161616",
                    color: "#fff",
                    "&:hover": {
                      color: "#fff",
                    },
                    "& svg": {
                      color: "orange",
                    },
                  },
                }}
                onClick={() => navigate("/more")}
              >
                More ...
                
              </Grid> */}
            </Grid>
            {/* <IconButton
              onClick={() => handleScroll("right")}
              sx={{
                position: "relative",
                zIndex: 500,
                // ml: "550px",
                color: "#ffffff",
                borderRadius: "50%",
                backgroundColor: "#2a252f",
                padding: "8px",
                border: "1px solid #30303d",
              }}
              aria-label="scroll-right"
            >
              <ArrowForwardIosIcon fontSize="small" />
            </IconButton> */}
          </Grid>

          <Grid item md={2.5} sx={{ display: "flex", flexDirection: "row" }}>
            {/* Backlinks tools */}
            <Grid>
              <Box
                sx={{
                  color: "#b9b9b9",
                  textAlign: "center",
                  display: "flex",
                  height: "60px",
                  minWidth: "100px",
                  alignItems: "center",
                  justifyContent: "center",
                  px: "1rem",
                  borderLeft: `3px solid ${theme.palette.background.paper}`,
                  cursor: "pointer",
                  background: theme.palette.background.default,
                  transition: "ease-in-out 100ms",
                  "&:hover": {
                    color: "#fff",
                  },
                  "&:active": {
                    background: "#161616",
                    color: "#fff",
                    "&:hover": {
                      color: "#fff",
                    },
                    "& svg": {
                      color: "orange",
                    },
                  },
                }}
                // onClick={handleBackLinksopen}
                onClick={()=>{
                  navigate("/monthly-backlinks")
                }}
              >
                Buy Backlinks
              </Box>

              <Popover
                id={id}
                open={backLinksOpen}
                anchorEl={backLinksAnchorEl}
                onClose={handleBackLinksclose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <List
                  sx={{
                    padding: 0,
                    bgcolor: theme.palette.background.default,
                    cursor: "pointer",
                  }}
                >
                  {[
                    { label: "Monthly Backlinks", path: "/monthly-backlinks" },
                    { label: "Premium Backlinks", path: "/premium-backlinks-home" },
                  ].map((item, index) => (           
                    <ListItem
                      button
                      key={index}   
                      onClick={() => {             
                        handleBackLinksclose();
                        navigate(item.path);
                      }}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        borderRadius: "8px",
                        borderBottom: "1px solid #ccc",
                        padding: "8px 16px",
                        "&:hover": {
                          bgcolor: theme.palette.action.hover,
                        },
                        "&:last-child": {
                          borderBottom: "none",
                        },
                      }}
                    >
                      {/* Circular Number Design */}
                      <Box
                        sx={{
                          width: "28px",
                          height: "28px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "50%",
                          bgcolor: '#ed6c02',
                          color: "#fff",
                          fontWeight: "bold",
                          fontSize: 13,
                          marginRight: "12px",
                        }}
                      >
                        {(index + 1).toString().padStart(2, "0")}
                      </Box>

                      {/* List Item Text */}
                      <ListItemText
                        primary={item.label}
                        sx={{ color: "#b9b9b9" }}
                      />
                    </ListItem>
                  ))}
                </List>

              </Popover>
            </Grid>

            <Grid
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                px: "1rem",
                borderLeft: `3px solid ${theme.palette.background.paper}`,
                cursor: "pointer",
                background: theme.palette.background.default,
                transition: "ease-in-out 100ms",
                "&:hover": {
                  color: "#fff",
                },
                "&:active": {
                  background: "#161616",
                  "&:hover": { color: "#b9b9b9" },
                },
              }}
              onClick={handleAvatarPopoverOpen}
            >
              <Avatar sx={{ bgcolor: "#4CAF50" }}>S</Avatar>
              <KeyboardArrowDownIcon sx={{ marginLeft: 1 }} />
            </Grid>
            {/* Avatar Popover */}
            <Popover
              open={openAvatarPopover}
              anchorEl={avatarAnchorEl}
              onClose={handleAvatarPopoverClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              sx={{ top: "12px" }}
            >
              <Box sx={{ px: "20px", py: "10px" }}>
                <List>
                  {avatarMenuItems.map((item, index) => (
                    <ListItem
                      key={index}
                      sx={{ cursor: "pointer", "&:hover": { color: "orange" } }}
                      onClick={() =>
                        item.action
                          ? item.action()
                          : handleNavigation(item.path)
                      }
                    >
                      {item.isButton ? (
                        item.icon // Render button directly if it is a button item
                      ) : (
                        <>
                          {item.icon}
                          <ListItemText
                            primary={item.label}
                            primaryTypographyProps={{
                              sx: { fontSize: "12px", ml: "10px" },
                            }}
                          />
                        </>
                      )}
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Popover>

            {/* Dark mode toggle */}
            <Box
              sx={{
                position: "relative",
                cursor: "pointer",
                color: "#b9b9b9",
                textAlign: "center",
                display: "flex",
                height: "100%",
                width: "1px",
                alignItems: "center",
                justifyContent: "center",
                px: "1rem",
                borderLeft: `3px solid ${theme.palette.background.paper}`,
                background: theme.palette.background.default,
                transition: "ease-in-out 100ms",
                "&:hover": {
                  color: "#fff",
                },
                "&:active": {
                  background: "#161616",
                  "&:hover": {
                    color: "#b9b9b9",
                  },
                },
              }}
              onClick={toggleTheme}
            >
              {theme.palette.mode === "dark" ? (
                <DarkModeIcon />
              ) : (
                <WbSunnyIcon />
              )}{" "}
              {/* Icon changes based on theme */}
            </Box>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

export default Header;