import { Grid } from '@mui/material'
import React from 'react'
import Header from '../../../../common/Header'
import Keyworddetail from './Keyworddetail'
import SearchHome from './searchkeyword/SearchHome';

const KeywordHome = () => {
  return (
    <Grid container direction="column" sx={{ backgroundColor: '#2a252f' }}>
      {/* Header Section */}
      <Grid item>
        <Header />
      </Grid>

      {/* Keyword Detail Section */}
      <Grid item sx={{ mt: 10 }}>
        <Keyworddetail />
      </Grid>

      {/* Keyword Overview Section */}
      <Grid item sx={{ mt: 4 }}>
        <SearchHome/>
      </Grid>
    </Grid>
  )
}

export default KeywordHome
