import React from 'react';
import { Box, CssBaseline, Grid } from '@mui/material';
import Header from '../../../../../common/Header';
import Dashboardheader from '../../dashboarddetails/Dashboardheader';
import Dashboardsideicon from '../../dashboarddetails/Dashboardsideicon';
import WordCount from './WordCount';


const Wordcounthome = () => {
    return (
        <>
            <CssBaseline /> {/* Ensures consistent baseline styling */}
            <Grid container>
                {/* Main Header (renders first) */}
                <Header/>

                {/* Dashboard Header (renders directly below the Main Header) */}
                <Grid item xs={12} sx={{ marginTop: 2,flexGrow:2 }}> {/* Adjust margin as needed */}
                    <Dashboardheader/>
                </Grid>

                {/* Content Area including Sidebar and Main Content */}
                <Grid item xs={12} sx={{ display: 'flex', height: 'calc(100vh - 64px)', flexGrow: 1 }}> {/* Adjust for the total height */}
                    {/* Sidebar */}
                    <Grid item sx={{ width: '80px' }}>
                        <Dashboardsideicon />
                    </Grid>

                    {/* Main Content */}
                    <Box
                        component="main"
                        sx={{
                            flexGrow: 1,
                            bgcolor: '#1c191f',
                            color: '#fff',
                            p: 3,
                            marginTop: 3, 
                            
                        }}
                    >
                        <WordCount/>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default Wordcounthome;
