import React, { useState } from "react";
import {
    Box,
    Typography,
    Checkbox,
    FormControlLabel,
    Tabs,
    Tab,
    MenuItem,
    Select,
    FormControl,
    Grid,
} from "@mui/material";
import {
    AreaChart,
    Area,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    BarChart,
    Bar,
    LineChart,
    Line,
} from "recharts";

const data = [
    { name: "Jan", traffic: 1.2 },
    { name: "Feb", traffic: 1.3 },
    { name: "Mar", traffic: 1.5 },
    { name: "Apr", traffic: 1.8 },
    { name: "May", traffic: 2.0 },
    { name: "Jun", traffic: 2.1 },
    { name: "Jul", traffic: 2.3 },
    { name: "Aug", traffic: 2.6 },
];

const competitorsData = [
    { name: "Competitor A", value: 120 },
    { name: "Competitor B", value: 100 },
    { name: "Competitor C", value: 150 },
    { name: "Competitor D", value: 90 },
    { name: "Competitor E", value: 130 },
    { name: "Competitor F", value: 160 },
    { name: "Competitor G", value: 80 },
    { name: "Competitor H", value: 200 },
];

const locationData = [
    { name: "Location 1", traffic: 150 },
    { name: "Location 2", traffic: 200 },
    { name: "Location 3", traffic: 170 },
    { name: "Location 4", traffic: 120 },
    { name: "Location 5", traffic: 90 },
];

const PerformanceChart = () => {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Grid sx={{  bgcolor: '#2a252f', borderRadius: 2, }}>
            {/* Tabs */}
            <Grid sx={{ display: "flex", gap: 2, mb: 2 }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="Metrics Tabs"
                    sx={{
                        borderRadius: 1,
                        border: '1px solid #4c4452',
                        '& .MuiTab-root': {
                            color: '#d1d1d1',
                        },
                        '& .Mui-selected': {
                            color: '#ff7300 !important', 
                        },
                    }}
                    TabIndicatorProps={{
                        style: {
                            backgroundColor: '#ff7300', 
                        },
                    }}
                >
                    <Tab label="Metrics" />
                    <Tab label="Competitors" />
                    <Tab label="Locations" />
                </Tabs>



                <FormControl sx={{ minWidth: 120, borderRadius: 1 }}>
                    <Select
                        labelId="years-select-label"
                        defaultValue="2Y"
                        sx={{
                            color: '#d1d1d1',
                            border: '1px solid #4c4452',
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: '#4c4452',
                                },
                                '&:hover fieldset': {
                                    borderColor: 'gray',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: '#ff9d3a',
                                },
                            },
                        }}
                    >
                        <MenuItem value="1M">1M</MenuItem>
                        <MenuItem value="6M">6M</MenuItem>
                        <MenuItem value="1Y">1Y</MenuItem>
                        <MenuItem value="2Y">2Y</MenuItem>
                        <MenuItem value="All">All</MenuItem>
                    </Select>
                </FormControl>

                <FormControl sx={{ minWidth: 120, borderRadius: 1 }}>
                    <Select
                        labelId="view-select-label"
                        defaultValue="Monthly"
                        sx={{
                            color: '#d1d1d1',
                            border: '1px solid #4c4452',
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: '#4c4452',
                                },
                                '&:hover fieldset': {
                                    borderColor: 'gray',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: '#ff9d3a',
                                },
                            },
                        }}
                    >
                        <MenuItem value="Daily">Daily</MenuItem>
                        <MenuItem value="Weekly">Weekly</MenuItem>
                        <MenuItem value="Monthly">Monthly</MenuItem>
                    </Select>
                </FormControl>
            </Grid>

            {/* Performance Checkboxes */}
            <Grid sx={{ display: "flex", gap: 2, mb: 2 }}>
                <FormControlLabel
                    control={<Checkbox sx={{
                        color: '#d1d1d1',
                        '&.Mui-checked': {
                            color: '#ff7300', 
                        },
                    }} />}
                    label="Referring domains"
                    sx={{ color: '#d1d1d1' }}
                />
                <FormControlLabel
                    control={<Checkbox sx={{
                        color: '#d1d1d1',
                        '&.Mui-checked': {
                            color: '#ff7300',
                        },
                    }} />}
                    label="Avg. Domain Rating"
                    sx={{ color: '#d1d1d1' }}
                />
                <FormControlLabel
                    control={<Checkbox sx={{
                        color: '#d1d1d1',
                        '&.Mui-checked': {
                            color: '#ff7300',
                        },
                    }} />}
                    label="Avg. URL Rating"
                    sx={{ color: '#d1d1d1' }}
                />
                <FormControlLabel
                    control={<Checkbox defaultChecked sx={{
                        color: '#d1d1d1',
                        '&.Mui-checked': {
                            color: '#ff7300',
                        },
                    }} />}
                    label="Avg. organic traffic"
                    sx={{ color: '#d1d1d1' }}
                />
                <FormControlLabel
                    control={<Checkbox sx={{
                        color: '#d1d1d1',
                        '&.Mui-checked': {
                            color: '#ff7300',
                        },
                    }} />}
                    label="Avg. organic traffic value"
                    sx={{ color: '#d1d1d1' }}
                />
            </Grid>

            {/* Chart or other content based on selected tab */}
            {value === 0 && (
                <ResponsiveContainer width="100%" height={300}>
                    <AreaChart data={data}>
                        <defs>
                            <linearGradient id="colorTraffic" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#ff7300" stopOpacity={0.8} />
                                <stop offset="95%" stopColor="#ff7300" stopOpacity={0} />
                            </linearGradient>
                        </defs>
                        <CartesianGrid strokeDasharray="3 3" stroke="#555" />
                        <XAxis dataKey="name" stroke="#fff" />
                        <YAxis domain={[1, 3]} stroke="#fff" />
                        <Tooltip />
                        <Area
                            type="monotone"
                            dataKey="traffic"
                            stroke="#ff7300"
                            fillOpacity={1}
                            fill="url(#colorTraffic)"
                        />
                    </AreaChart>
                </ResponsiveContainer>
            )}
            {value === 1 && (
                <ResponsiveContainer width="100%" height={300}>
                    <BarChart data={competitorsData}>
                        <CartesianGrid strokeDasharray="3 3" stroke="#555" />
                        <XAxis dataKey="name" stroke="#fff" />
                        <YAxis stroke="#fff" />
                        <Tooltip />
                        <Bar dataKey="value" fill="#ff7300" barSize={15} /> 
                    </BarChart>
                </ResponsiveContainer>
            )}
            {value === 2 && (
                <ResponsiveContainer width="100%" height={300}>
                    <LineChart data={locationData}>
                        <CartesianGrid strokeDasharray="3 3" stroke="#555" />
                        <XAxis dataKey="name" stroke="#fff" />
                        <YAxis stroke="#fff" />
                        <Tooltip />
                        <Line type="monotone" dataKey="traffic" stroke="#ff7300" />
                    </LineChart>
                </ResponsiveContainer>
            )}

            {/* Traffic Labels */}
            <Typography align="right" sx={{ mt: 1, fontWeight: "bold", color: '#fff' }}>
                Avg. organic traffic
            </Typography>
        </Grid>
    );
};

export default PerformanceChart;
