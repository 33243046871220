import { Grid } from '@mui/material';
import React, { useState } from 'react';
import KeywordOverview from './KeywordOverview';
import KeywordVolumeSearch from './KeywordVolumeSearch';
import KeywordSearch from '../searchkeyword/KeywordSearch';
import Seodifficulty from './Seodifficulty';
import Paiddifficlty from './Paiddifficlty';
import Chartkeyword from '../searchkeyword/Chartkeyword';
import PeopleBox from './PeopleBox';
import KeywordIdeas from './KeywordIdeas';
import TrafficLocation from './TrafficLocation';
import ContentIdeas from './ContentIdeas';

const SearchHome = () => {
    const [keywordEntered, setKeywordEntered] = useState(false);

    
    const handleKeywordSubmit = (value) => {
        if (value && value.trim() !== '') {
            setKeywordEntered(true); 
        }
    };

    return (
        <Grid container spacing={2}>
            {/* Keyword search component */}
            <Grid item xs={12}>
                <KeywordSearch onKeywordSubmit={handleKeywordSubmit} />
            </Grid>

            {/* Conditionally render the rest of the components based on keywordEntered */}
            {keywordEntered && (
                <>
                    <Grid item xs={12}>
                        <KeywordOverview />
                    </Grid>

                    <Grid container spacing={2} sx={{ padding: 6 }}>
                        <Grid item xs={12} md={8.5}>
                            <KeywordVolumeSearch />
                        </Grid>
                        <Grid item container xs={12} md={3.5} spacing={2}>
                            <Grid item xs={12}>
                                <Seodifficulty />
                            </Grid>
                            <Grid item xs={12}>
                                <Paiddifficlty />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container spacing={4} sx={{ padding: 6 }}>
                        <Grid item xs={12} md={8.5}>
                            <Chartkeyword />
                        </Grid>
                        <Grid item container xs={12} md={3.5} spacing={2}>
                            <TrafficLocation />
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <PeopleBox />
                    </Grid>

                    <Grid item xs={12}>
                        <KeywordIdeas />
                    </Grid>

                    <Grid item xs={12}>
                        <ContentIdeas />
                    </Grid>
                </>
            )}
        </Grid>
    );
};

export default SearchHome;
