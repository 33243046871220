import React from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';

const Order = () => {
    return (
        <>
            <Grid sx={{ position: 'relative', height: '40vh', overflow: 'hidden' }}>
                {/* Background Section */}
                <Grid
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundImage: 'linear-gradient(to bottom, #271e2a, #332837, #4d3753)',
                        zIndex: 1,
                    }}
                />

                {/* Dark Layer Overlay */}
                <Grid
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(0, 0, 0, 0.2)',
                        zIndex: 2,
                    }}
                />

                {/* Content Section */}
                <Grid
                    sx={{
                        position: 'relative',
                        zIndex: 3,
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: '#fff',
                        textAlign: 'center',
                        padding: '0 20px',
                    }}
                >
                    {/* Main Heading - split into two lines */}
                    <Typography
                        variant="h2"
                        sx={{
                            fontWeight: 'bold',
                            mb: 0,
                            lineHeight: '1.2em',
                            fontSize: { xs: '2rem', sm: '3rem', md: '4rem' },
                        }}
                    >
                        Orders
                    </Typography>


                    {/* Button section */}
                    {/* <Box>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: '#FF8C32',
                                color: '#fff',
                                fontWeight: 'bold',
                                mr: 2,
                                border: '2px solid #FF7B00',
                                px: { xs: 3, md: 4 },
                                py: { xs: 1, md: 1.5 },
                                fontSize: { xs: '0.875rem', md: '1rem' },
                                '&:hover': {
                                    backgroundColor: '#FF7B00',
                                },
                            }}
                        >
                            Try Seo Mitra
                        </Button>
                        <Button
                            variant="outlined"
                            sx={{
                                borderColor: '#fff',
                                border: 2,
                                fontWeight: 'bold',
                                color: '#fff',
                                px: { xs: 3, md: 4 },
                                py: { xs: 1, md: 1.5 },
                                fontSize: { xs: '0.875rem', md: '1rem' },
                                '&:hover': {
                                    borderColor: '#FF8C32',
                                    color: '#FF8C32',
                                },
                            }}
                        >
                            Our features
                        </Button>
                    </Box> */}
                </Grid>
            </Grid>


        </>
    );
};

export default Order;
