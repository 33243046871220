import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Paper,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import xlfiles from "../../../../assets/7122_21060.xlsx";
import useFetch from "../../useFetch";
import { apiList, invokeApi } from "../../../../services/apiServices";
import { config } from "../../../../config/config";
import { useCookies } from "react-cookie";

const OrderDetails = () => {
    const { name, roles, email } = useFetch();
    console.log("Email Name==>", email)
    const navigate = useNavigate();
    const [cookies] = useCookies();
    const userId = cookies[config.cookieName]?.loginUserId;
    const [orderRowData, setOrderRowData] = useState([]);

    useEffect(() => {
        const handleTableData = async () => {
            const params = {
                createdBy: userId
            };

            try {
                const response = await invokeApi(
                    config.getMyCollege + apiList.getPayments,
                    params,
                    cookies
                );

                if (response?.status === 200) {
                    setOrderRowData(response.data.payment)
                    // alert("Paid Successfully!")
                    // // navigate("/done-payment");
                    // navigate("/my-order");
                } else {
                    console.error("Something went wrong. Please try again later!!");
                    alert("Something went wrong. Please try again later!!");
                }
            } catch (error) {
                console.error("Error during data fetch:", error);
                alert("Something went wrong. Please try again later!!");
            }
        };

        handleTableData();
    }, [userId])

    const calculateReportDate = (createdDate) => {
        // Parse the incoming date string
        const date = new Date(createdDate);

        // Add 30 days to the parsed date
        date.setDate(date.getDate() + 30);

        // Format the date as needed (e.g., YYYY-MM-DD)
        const formattedDate = date.toISOString().split("T")[0]; // Extract only the date part

        return formattedDate;
    };


    const rows = [
        { order: "pay_PCOiNk992vGD1R", date: "Wed Oct-23-2024, 01:01pm", status: "Completed", total: "₹ 30,000/-", color: "#4caf50" },
        { order: "pay_PR2WdcorC8aaoJ", date: "Fri Nov-29-2024, 01:04pm", status: "Processing", total: "₹ 39,997/-", color: "red" },
        // { order: "#19849", date: "May 30, 2024", status: "Completed", total: "₹1,000.00 for 1 item" },
    ];

    const handleDownload = () => {
        const filePath = xlfiles; // Relative path to the public directory file
        const link = document.createElement("a");
        link.href = filePath;
        link.download = "7122_21060.xlsx"; // File name for download
        link.click();
    };

    return (
        <Box sx={{ padding: 10, backgroundColor: "#f8f9fd" }}>
            <TableContainer
                component={Paper}
                sx={{
                    borderRadius: 4,
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                    overflow: "hidden",
                }}
            >
                <Table>
                    {/* Table Header */}
                    <TableHead sx={{ backgroundColor: "#eef2f6" }}>
                        <TableRow>
                            <TableCell
                                sx={{
                                    fontWeight: "bold",
                                    color: "#4b5563",
                                    fontSize: "0.95rem",
                                    borderBottom: "2px solid #e5e7eb",
                                }}
                            >
                                Order
                            </TableCell>
                            <TableCell
                                sx={{
                                    fontWeight: "bold",
                                    color: "#4b5563",
                                    fontSize: "0.95rem",
                                    borderBottom: "2px solid #e5e7eb",
                                }}
                            >
                                Date
                            </TableCell>
                            <TableCell
                                sx={{
                                    fontWeight: "bold",
                                    color: "#4b5563",
                                    fontSize: "0.95rem",
                                    borderBottom: "2px solid #e5e7eb",
                                }}
                            >
                                Status
                            </TableCell>
                            <TableCell
                                sx={{
                                    fontWeight: "bold",
                                    color: "#4b5563",
                                    fontSize: "0.95rem",
                                    borderBottom: "2px solid #e5e7eb",
                                }}
                            >
                                Total
                            </TableCell>
                            <TableCell
                                sx={{
                                    fontWeight: "bold",
                                    color: "#4b5563",
                                    fontSize: "0.95rem",
                                    borderBottom: "2px solid #e5e7eb",
                                }}
                            >
                                Actions
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    {/* Table Body */}
                    {/* {email === "snistech2023@gmail.com" && ( */}
                    <TableBody>
                        {orderRowData.map((row, index) => (
                            <TableRow
                                key={index}
                                sx={{
                                    borderBottom: "2px solid #e5e7eb", // Border between rows
                                    "&:hover": { backgroundColor: "#f1f5f9" }, // Hover effect
                                }}
                            >
                                <TableCell>
                                    <Typography fontWeight="bold" color="#374151">
                                        {row.orderId}
                                    </Typography>
                                </TableCell>
                                <TableCell sx={{ color: "#6b7280" }}>{row.createdDate}</TableCell>
                                <TableCell>
                                    <Typography sx={{ color: row.updatedDate === "Processing"||row.updatedDate===null ? "red" : "#4caf50", fontWeight: "bold", }}>
                                        {row.updatedDate === null ? "Processing" : row.updatedDate}
                                    </Typography>
                                </TableCell>
                                <TableCell sx={{ color: "#6b7280" }}>{row.paidAmt}</TableCell>
                                <TableCell>

                                    Reports Available on {calculateReportDate(row.createdDate)}

                                    {/* <Button
                                        variant="contained"
                                        onClick={() => navigate("/order-details-section", { state: { row } })}
                                        sx={{
                                            backgroundColor: "#5e2ced",
                                            color: "#fff",
                                            textTransform: "capitalize",
                                            fontSize: "14px",
                                            fontWeight: 500,
                                            marginRight: 1,
                                            padding: "5px 15px",
                                            "&:hover": { backgroundColor: "#6b21a8" },


                                        }}
                                    >
                                        View
                                    </Button>
                                    <Button
                                        variant="contained"
                                        onClick={handleDownload}
                                        sx={{
                                            backgroundColor: "#5e2ced",
                                            color: "#fff",
                                            textTransform: "capitalize",
                                            fontSize: "14px",
                                            fontWeight: 500,
                                            padding: "5px 15px",
                                            "&:hover": { backgroundColor: "#6b21a8" },
                                        }}
                                    >
                                        Download Report
                                    </Button> */}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                    {/* )} */}

                </Table>
            </TableContainer>
        </Box>
    );
};

export default OrderDetails;
