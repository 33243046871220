import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { Box, Container, Typography, Grid } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import imageTop from '../../../../../assets/topimage.png'; 
import imageBottom from '../../../../../assets/bottomimage.png'; 
import existingImage from '../../../../../assets/Screenshot.png'; 

const theme = createTheme({
  palette: {
    background: {
      default: "#2a252f",
    },
    primary: {
      main: "#FFAC44", 
    },
    text: {
      primary: "#FFFFFF", 
      secondary: "#B8B4BB", 
    },
  },
  typography: {
    h5: {
      fontWeight: "bold",
    },
  },
});

function Keyworddetail() {
  const [ref, inView] = useInView({ threshold: 0.1 });
  const [imagesVisible, setImagesVisible] = useState(false);

  useEffect(() => {
    if (inView) {
      setImagesVisible(false); 
      const timer = setTimeout(() => {
        setImagesVisible(true); 
      }, 800); 

      return () => clearTimeout(timer);
    }
  }, [inView]);

  return (
    <ThemeProvider theme={theme}>
      <Grid
        sx={{
          backgroundColor: "background.default",
          overflow: "hidden", 
        }}
      >
        <Container maxWidth="lg">
          <Grid
            ref={ref}
            sx={{
              opacity: inView ? 1 : 0,
              transform: inView ? "translateY(0)" : "translateY(50px)",
              transition: "opacity 0.8s ease-out, transform 0.8s ease-out",
            }}
          >
            <Grid container spacing={4} alignItems="center">
              {/* Left Section */}
              <Grid item xs={12} md={5}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    height: "100%",
                  }}
                >
                  <Box
                    sx={{
                      width: 60,
                      height: 60,
                      backgroundColor: "#FFAC44",
                      borderRadius: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      mb: 2,
                    }}
                  >
                    <Typography variant="h5" color="text.primary">
                      1
                    </Typography>
                  </Box>
                  <Typography
                    variant="h4"
                    color="text.primary"
                    sx={{ fontWeight: "bold", mb: 2, color: '#d1d1d1' }}
                  >
                    Location-specific keyword research data
                  </Typography>
                  <Typography variant="body1" color="text.secondary">
                    Get accurate and relevant data for any keyword in any
                    location to help you evaluate each keyword’s potential.
                  </Typography>
                </Box>
              </Grid>

              {/* Right Section */}
              <Grid item xs={12} md={7}>
                <Box
                  sx={{
                    backgroundColor: "#2a252f",
                    borderRadius: 2,
                    padding: 2,
                    position: "relative",
                    overflow: "hidden", 
                  }}
                >
                  {/* Existing Image */}
                  <img
                    src={existingImage}
                    alt="Keyword Explorer Dashboard"
                    style={{
                      width: "100%",
                      maxWidth: "100%",
                      borderRadius: "8px",
                      opacity: inView ? 1 : 0,
                      transform: inView ? "translateY(0)" : "translateY(50px)",
                      transition: "opacity 0.8s ease-out, transform 0.8s ease-out",
                    }}
                  />
                  {/* Top Image */}
                  <Box
                    sx={{
                      position: "absolute",
                      top: imagesVisible ? "10%" : "30%",
                      width: '80%',
                      left: "70%",
                      transform: imagesVisible ? "translate(-50%, -10%)" : "translate(-50%, 50%)",
                      opacity: imagesVisible ? 1 : 0,
                      transition: "top 0.8s ease-out, transform 0.8s ease-out, opacity 0.8s ease-out",
                      zIndex: 1,
                    }}
                  >
                    <img
                      src={imageTop}
                      alt="Top Image"
                      style={{
                        width: "30%",
                        maxWidth: "30%", 
                        borderRadius: "8px",
                      }}
                    />
                  </Box>
                  {/* Bottom Image */}
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: imagesVisible ? "10%" : "30%",
                      left: "40%",
                      width: '80%',
                      transform: imagesVisible ? "translateX(-50%)" : "translateY(50%)",
                      opacity: imagesVisible ? 1 : 0,
                      transition: "bottom 0.8s ease-out, transform 0.8s ease-out, opacity 0.8s ease-out",
                      zIndex: 1,
                    }}
                  >
                    <img
                      src={imageBottom}
                      alt="Bottom Image"
                      style={{
                        width: "100%",
                        maxWidth: "100%", 
                        borderRadius: "8px",
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </ThemeProvider>
  );
}

export default Keyworddetail;
