import React from 'react';
import { Avatar, Grid, Typography, Chip, Button } from '@mui/material';
import { useTheme } from "../../../../../common/ThemeContext";


const UserList = () => {

  const { theme } = useTheme();


  return (
    <Grid sx={{p:2,backgroundColor: theme.palette.mode !== "dark" ? "#1c1c1e" : "#fbf9fb",}}>
    <Grid sx={{
      padding: '16px',
      backgroundColor: theme.palette.mode !== "dark" ? "#312b36" : "#fff",
      borderRadius: '8px',
      border: theme.palette.mode !== "dark" ? "1px solid #4a4a4a": "1px solid #f2ebf0", borderRadius: 2

    }}>
      {/* Owner Row */}
      <Grid container alignItems="center" style={{
        padding: '8px 0',
        borderBottom: '1px solid #e0e0e0',
        display: 'flex',
        justifyContent: 'space-between'
      }}>
        <Grid item sx={{ display: 'flex', alignItems: 'center', width: '75%' }}>
          <Avatar sx={{ bgcolor: '#9c27b0', marginRight: '15px',fontSize:15 }}>YB</Avatar>
          <Grid>
            <Typography variant="body1" style={{ fontWeight: 500,color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000", }}>Yana Bilokin</Typography>
            <Typography variant="body2" style={{ color: '#757575',color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000", }}>Owner</Typography>
          </Grid>
        </Grid>
        <Grid item sx={{ width: '25%', textAlign: 'right' }}>
          <Typography variant="body2" color="textSecondary">
            Active 2 minutes ago
          </Typography>
        </Grid>
      </Grid>

      {/* Project Member Row */}
      <Grid container alignItems="center" style={{ padding: '8px 0', display: 'flex', justifyContent: 'space-between' }}>
        <Grid item sx={{ display: 'flex', alignItems: 'center', width: '75%' }}>
          <Avatar sx={{ bgcolor: '#5c6bc0', marginRight: '15px',fontSize:15 }}>SS</Avatar>
          <Grid sx={{ display: 'flex', alignItems: 'center' }}>
            <Grid sx={{ marginRight: '8px' }}>
              <Typography variant="body1" style={{ fontWeight: 500,color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000", }}>Sam Smith</Typography>
              <Typography variant="body2" style={{ color: '#757575',color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000", }}>Project Member</Typography>
            </Grid>
          
          </Grid>
        </Grid>
        <Grid item sx={{ width: '25%', textAlign: 'right' }}>
          <Button variant="outlined" size='small' sx={{
            color: '#ff9800',
            borderColor: '#ff9800',
            borderRadius: '16px',
            fontWeight: 'bold',
            textTransform: 'none',
            padding: '2px 12px',
            fontSize: '0.875rem',
            mr:1
            
          }}>
            Tech sites
          </Button>

          <Button variant="outlined" size='small' sx={{
            color: '#ff9800',
            borderColor: '#ff9800',
            borderRadius: '16px',
            fontWeight: 'bold',
            textTransform: 'none',
            padding: '2px 12px',
            fontSize: '0.875rem'
          }}>
            Pending Invite
          </Button>
        </Grid>
      </Grid>
    </Grid>
    </Grid> 
  );
};

export default UserList;
