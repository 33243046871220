import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
} from '@mui/material';
import { useTheme } from "../../../../../common/ThemeContext";

const ParaphraseTool = () => {
  const [language, setLanguage] = useState("English (US)");
  const [tab, setTab] = useState(0);
  const [text, setText] = useState("");
  const [outputText, setOutputText] = useState("");
  const { theme } = useTheme();


  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
  };

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const handleTextChange = (event) => {
    setText(event.target.value);
  };

  const handleSampleText = () => {
    setText("Sample text provided for testing.");
  };

  const handlePasteText = () => {
    navigator.clipboard.readText().then((clipboardText) => setText(clipboardText));
  };

  const handleParaphrase = () => {
    setOutputText(text ? `${text}` : "");
  };

  return (
    <Grid sx={{ padding: 4, backgroundColor: theme.palette.mode !== "dark" ? "#1c1c1e" : "#fbf9fb", color: '#fff', }}>
      {/* Top Section - Language Selector */}
      <Grid sx={{ mb: 3, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
        <Select
          value={language}
          onChange={handleLanguageChange}
          variant="outlined"
          sx={{
            mb: 1, fontSize: '1rem', fontWeight: 'bold', borderRadius: 1,
            color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000",
            backgroundColor: theme.palette.mode !== "dark" ? "#312b36" : "#fff",
            border: theme.palette.mode !== "dark"
              ? "1px solid #4a4a4a"
              : "1px solid #f2ebf0",
          }}
        >
          <MenuItem value="English (US)">English (US)</MenuItem>
          <MenuItem value="French">French</MenuItem>
          <MenuItem value="Spanish">Spanish</MenuItem>
          <MenuItem value="German">German</MenuItem>
          <MenuItem value="All">All</MenuItem>
        </Select>

        {/* Tab Navigation */}
        <Grid sx={{}}>
          <Tabs
            value={tab}
            onChange={handleTabChange}
            textColor="primary"
            indicatorColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            sx={{
              minHeight: '36px',
              '& .MuiTab-root': {
                color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000",
              },
              '& .MuiTabs-indicator': {
                backgroundColor: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000",
              },
            }}
          >
            <Tab label="Standard" />
            <Tab label="Fluency" />
            <Tab label="Natural" />
            <Tab label="Formal" />
            <Tab label="Academic" />
            <Tab label="Simple" />
            <Tab label="Creative" />
            <Tab label="Expand" />
            <Tab label="Shorten" />
            <Tab label="Custom" />
          </Tabs>
        </Grid>

      </Grid>

      {/* Main Content Section - Wrapped in Card */}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card variant="outlined" sx={{
            borderRadius: 2, boxShadow: 3, backgroundColor: theme.palette.mode !== "dark" ? "#312b36" : "#fff",
            border: theme.palette.mode !== "dark"
              ? "1px solid #4a4a4a"
              : "1px solid #f2ebf0", borderRadius: 2
          }}>
            <CardHeader title="Paraphrase Tool" sx={{ color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000", }} />
            <CardContent>
              <Grid container spacing={3}>
                {/* Left Section (Text Input and Buttons) */}
                <Grid item xs={8}>
                  <Box
                    sx={{
                      padding: 3,
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 2,
                      border: theme.palette.mode !== "dark"
                        ? "1px solid #4a4a4a"
                        : "1px solid #f2ebf0",
                      borderRadius: 1,
                      backgroundColor: theme.palette.mode !== "dark" ? "#1c1c1e" : "#fbf9fb", color: '#fff',
                    }}
                  >
                    <TextField
                      label="Enter text here"
                      multiline
                      rows={6}
                      variant="outlined"
                      fullWidth
                      value={text}
                      onChange={handleTextChange}
                      sx={{
                        mb: 2,
                        backgroundColor: theme.palette.mode !== "dark" ? "#1c1c1e" : "#fbf9fb",
                        color: '#fff',
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            border: theme.palette.mode !== "dark" ? "1px solid #4a4a4a" : "1px solid #4a4a4a",
                            color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000",
                          },
                          '&:hover fieldset': {
                            border: theme.palette.mode !== "dark" ? "1px solid #4a4a4a" : "1px solid #4a4a4a",
                            color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000",
                          },
                          '& .MuiOutlinedInput-input': {
                            color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000",
                          },
                        },
                      }}
                    />

                    {/* Conditional rendering of buttons based on text input */}
                    {!text && (
                      <Box display="flex" justifyContent="center" gap={2} mb={2}>
                        <Button variant="outlined" onClick={handleSampleText} sx={{
                          padding: '8px 16px', fontSize: '0.9rem', color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000", border: theme.palette.mode !== "dark"
                            ? "1px solid #4a4a4a"
                            : "1px solid #f2ebf0",
                        }}>
                          Try Sample Text
                        </Button>
                        <Button variant="outlined" onClick={handlePasteText} sx={{
                          padding: '8px 16px', fontSize: '0.9rem', color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000", border: theme.palette.mode !== "dark"
                            ? "1px solid #4a4a4a"
                            : "1px solid #f2ebf0",
                        }}>
                          Paste Text
                        </Button>
                      </Box>
                    )}
                    <Button
                      variant="contained"
                      color="success"
                      onClick={handleParaphrase}
                      sx={{
                        width: '40%', alignSelf: 'center', padding: '10px 0', fontSize: '1rem', backgroundColor: theme.palette.mode !== "dark" ? "#312b36" : "#f76d1e", border: theme.palette.mode !== "dark"
                          ? "1px solid #4a4a4a"
                          : "",
                      }}
                    >
                      Paraphrase
                    </Button>
                  </Box>
                </Grid>

                {/* Right Section (Paraphrased Output) */}
                <Grid item xs={4}>
                  <Box
                    sx={{
                      p: 2,
                      border: theme.palette.mode !== "dark"
                        ? "1px solid #4a4a4a"
                        : "1px solid #f2ebf0",
                      borderRadius: 1,
                      backgroundColor: theme.palette.mode !== "dark" ? "#1c1c1e" : "#fbf9fb", color: '#fff',
                      height: '100%',
                    }}
                  >

                    <TextField
                      multiline
                      rows={6}
                      variant="outlined"
                      fullWidth
                      value={outputText}
                      InputProps={{
                        readOnly: true,
                      }}
                      sx={{
                        mb: 2, mt: 1, backgroundColor: theme.palette.mode !== "dark" ? "#1c1c1e" : "#fbf9fb", color: '#fff',
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            border: theme.palette.mode !== "dark" ? "1px solid #4a4a4a" : "1px solid #4a4a4a",
                            color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000",
                          },
                          '&:hover fieldset': {
                            border: theme.palette.mode !== "dark" ? "1px solid #4a4a4a" : "1px solid #4a4a4a",
                            color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000",
                          },
                          '& .MuiOutlinedInput-input': {
                            color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000",
                          },
                        },
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ParaphraseTool;

