import React, { useState } from 'react';
import { Box, Grid, TextField, Button, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import { useTheme } from "../../../../../common/ThemeContext";

const Profileinformation = () => {
    const [profile, setProfile] = useState({
        firstName: 'Gowtham',
        lastName: 'Patel',
        email: 'gowthampatel024@gmail.com',
        phoneNumber: '',
        language: 'English',
        currentPassword: '',
        newPassword: '',
        repeatNewPassword: ''
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setProfile((prevProfile) => ({
            ...prevProfile,
            [name]: value,
        }));
    };

    const { theme } = useTheme();

    return (
        <Grid sx={{ display: 'flex', justifyContent: 'center', p: 4, backgroundColor: theme.palette.mode !== "dark" ? "#1c1c1e" : "#fbf9fb", color: '#fff', }}>
            <Grid container spacing={4}>
                {/* Profile Information Section */}
                <Grid item xs={12} md={6}>
                    <Box sx={{
                        backgroundColor: theme.palette.mode !== "dark" ? "#312b36" : "#ffffff", p: 3, border:
                            theme.palette.mode !== "dark"
                                ? "1px solid #4a4a4a"
                                : "1px solid #f2ebf0",borderRadius:2
                    }}>
                        <Box component="h2" sx={{ fontSize: '1.2rem', fontWeight: 'bold', mb: 2, color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000", }}>Your profile information</Box>

                        {/* First Name and Last Name in the same row */}
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="First Name"
                                    variant="outlined"
                                    margin="normal"
                                    InputLabelProps={{ style: { color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000", } }}
                                    inputProps={{ style: { color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000", } }}
                                    sx={{
                                        backgroundColor: theme.palette.mode !== "dark" ? "#312b36" : "#ffffff",
                                        borderRadius: 1,
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                border:
                                                    theme.palette.mode !== "dark"
                                                        ? "1px solid #4a4a4a"
                                                        : "1px solid #f2ebf0"
                                            },
                                            '&:hover fieldset': {
                                                border:
                                                    theme.palette.mode !== "dark"
                                                        ? "1px solid #4a4a4a"
                                                        : "1px solid #f2ebf0"
                                            },
                                            '&.Mui-focused fieldset': {
                                                border:
                                                    theme.palette.mode !== "dark"
                                                        ? "1px solid #4a4a4a"
                                                        : "1px solid #f2ebf0"
                                            },
                                        },
                                    }}
                                    name="firstName"
                                    value={profile.firstName}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Last Name"
                                    variant="outlined"
                                    margin="normal"
                                    InputLabelProps={{ style: { color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000", } }}
                                    inputProps={{ style: { color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000", } }}
                                    sx={{
                                        backgroundColor: theme.palette.mode !== "dark" ? "#312b36" : "#ffffff",
                                        borderRadius: 1,
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                border:
                                                    theme.palette.mode !== "dark"
                                                        ? "1px solid #4a4a4a"
                                                        : "1px solid #f2ebf0"
                                            },
                                            '&:hover fieldset': {
                                                border:
                                                    theme.palette.mode !== "dark"
                                                        ? "1px solid #4a4a4a"
                                                        : "1px solid #f2ebf0"
                                            },
                                            '&.Mui-focused fieldset': {
                                                border:
                                                    theme.palette.mode !== "dark"
                                                        ? "1px solid #4a4a4a"
                                                        : "1px solid #f2ebf0"
                                            },
                                        },
                                    }}
                                    name="lastName"
                                    value={profile.lastName}
                                    onChange={handleChange}
                                />
                            </Grid>
                        </Grid>

                        {/* Remaining fields below */}
                        <TextField
                            fullWidth
                            label="Email"
                            variant="outlined"
                            margin="normal"
                            InputLabelProps={{ style: { color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000", } }}
                            inputProps={{ style: { color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000", } }}
                            sx={{
                                backgroundColor: theme.palette.mode !== "dark" ? "#312b36" : "#ffffff",
                                borderRadius: 1,
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        border:
                                            theme.palette.mode !== "dark"
                                                ? "1px solid #4a4a4a"
                                                : "1px solid #f2ebf0"
                                    },
                                    '&:hover fieldset': {
                                        border:
                                            theme.palette.mode !== "dark"
                                                ? "1px solid #4a4a4a"
                                                : "1px solid #f2ebf0"
                                    },
                                    '&.Mui-focused fieldset': {
                                        border:
                                            theme.palette.mode !== "dark"
                                                ? "1px solid #4a4a4a"
                                                : "1px solid #f2ebf0"
                                    },
                                },
                            }}
                            name="email"
                            value={profile.email}
                            onChange={handleChange}
                        />
                        <TextField
                            fullWidth
                            label="Phone number (optional)"
                            variant="outlined"
                            margin="normal"
                            InputLabelProps={{ style: { color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000", } }}
                            inputProps={{ style: { color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000", } }}
                            sx={{
                                backgroundColor: theme.palette.mode !== "dark" ? "#312b36" : "#ffffff",
                                borderRadius: 1,
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        border:
                                            theme.palette.mode !== "dark"
                                                ? "1px solid #4a4a4a"
                                                : "1px solid #f2ebf0"
                                    },
                                    '&:hover fieldset': {
                                        border:
                                            theme.palette.mode !== "dark"
                                                ? "1px solid #4a4a4a"
                                                : "1px solid #f2ebf0"
                                    },
                                    '&.Mui-focused fieldset': {
                                        border:
                                            theme.palette.mode !== "dark"
                                                ? "1px solid #4a4a4a"
                                                : "1px solid #f2ebf0"
                                    },
                                },
                            }}
                            name="phoneNumber"
                            value={profile.phoneNumber}
                            onChange={handleChange}
                        />
                        <FormControl fullWidth margin="normal" sx={{ bgcolor: '#3a3a3c', borderRadius: 1 }}>
                            <InputLabel sx={{ color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000", }}>Language</InputLabel>
                            <Select
                                variant="outlined"
                                value={profile.language}
                                label="Language"
                                sx={{
                                    color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000",
                                    backgroundColor: theme.palette.mode !== "dark" ? "#312b36" : "#ffffff",
                                    border: theme.palette.mode !== "dark" ? '1px solid #4c4452' : "4c4452",
                                    borderRadius: 1,
                                    '&:hover': {
                                        border:
                                            theme.palette.mode !== ""
                                                // ? "1px solid #4a4a4a"
                                                // : "1px solid #f2ebf0"
                                    },
                                    '&:hover fieldset': {
                                        border:
                                            theme.palette.mode !== "dark"
                                                ? "1px solid #4a4a4a"
                                                : "1px solid #f2ebf0"
                                    },
                                    '&.Mui-focused fieldset': {
                                        border:
                                            theme.palette.mode !== "dark"
                                                ? "1px solid #4a4a4a"
                                                : "1px solid #f2ebf0"
                                    },
                                    '& .MuiSvgIcon-root': {
                                        color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000",
                                    },
                                }}
                                onChange={handleChange}
                            >
                                <MenuItem value="English">English</MenuItem>
                                <MenuItem value="Hindi">Hindi</MenuItem>
                            </Select>
                        </FormControl>

                        <Button variant="contained" size='small' sx={{ bgcolor: '#ff6f00', color: '#fff', mt: 2 }}>Save</Button>
                    </Box>
                </Grid>

                {/* Password Section */}
                <Grid item xs={12} md={6}>
                    <Box sx={{  backgroundColor: theme.palette.mode !== "dark" ? "#312b36" : "#ffffff", p: 3, borderRadius: 2,border:
                                            theme.palette.mode !== "dark"
                                                ? "1px solid #4a4a4a"
                                                : "1px solid #f2ebf0" }}>
                        <Box component="h2" sx={{ fontSize: '1.2rem', fontWeight: 'bold', mb: 2,color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000",  }}>Your password</Box>
                        <TextField
                            fullWidth
                            label="Current password"
                            type="password"
                            variant="outlined"
                            margin="normal"
                            InputLabelProps={{ style: { color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000",  } }}
                            inputProps={{ style: { color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000",  } }}
                            sx={{
                                backgroundColor: theme.palette.mode !== "dark" ? "#312b36" : "#ffffff",
                                borderRadius: 1,
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        border:
                                            theme.palette.mode !== "dark"
                                                ? "1px solid #4a4a4a"
                                                : "1px solid #f2ebf0"
                                    },
                                    '&:hover fieldset': {
                                        border:
                                        theme.palette.mode !== "dark"
                                            ? "1px solid #4a4a4a"
                                            : "1px solid #f2ebf0"
                                    },
                                    '&.Mui-focused fieldset': {
                                        border:
                                            theme.palette.mode !== "dark"
                                                ? "1px solid #4a4a4a"
                                                : "1px solid #f2ebf0"
                                    },
                                },
                            }}
                            name="currentPassword"
                            value={profile.currentPassword}
                            onChange={handleChange}
                        />
                        <TextField
                            fullWidth
                            label="New password"
                            type="password"
                            variant="outlined"
                            margin="normal"
                            InputLabelProps={{ style: { color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000",  } }}
                            inputProps={{ style: { color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000",  } }}
                            sx={{
                                backgroundColor: theme.palette.mode !== "dark" ? "#312b36" : "#ffffff",
                                borderRadius: 1,
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        border:
                                            theme.palette.mode !== "dark"
                                                ? "1px solid #4a4a4a"
                                                : "1px solid #f2ebf0"
                                    },
                                    '&:hover fieldset': {
                                        border:
                                            theme.palette.mode !== "dark"
                                                ? "1px solid #4a4a4a"
                                                : "1px solid #f2ebf0"
                                    },
                                    '&.Mui-focused fieldset': {
                                        border:
                                            theme.palette.mode !== "dark"
                                                ? "1px solid #4a4a4a"
                                                : "1px solid #f2ebf0"
                                    },
                                },
                            }}
                            name="newPassword"
                            value={profile.newPassword}
                            onChange={handleChange}
                        />
                        <TextField
                            fullWidth
                            label="Repeat new password"
                            type="password"
                            variant="outlined"
                            margin="normal"
                            InputLabelProps={{ style: { color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000",  } }}
                            inputProps={{ style: { color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000",  } }}
                            sx={{
                                backgroundColor: theme.palette.mode !== "dark" ? "#312b36" : "#ffffff",
                                borderRadius: 1,
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        border:
                                            theme.palette.mode !== "dark"
                                                ? "1px solid #4a4a4a"
                                                : "1px solid #f2ebf0"
                                    },
                                    '&:hover fieldset': {
                                        border:
                                            theme.palette.mode !== "dark"
                                                ? "1px solid #4a4a4a"
                                                : "1px solid #f2ebf0"
                                    },
                                    '&.Mui-focused fieldset': {
                                        border:
                                            theme.palette.mode !== "dark"
                                                ? "1px solid #4a4a4a"
                                                : "1px solid #f2ebf0"
                                    },
                                },
                            }}
                            name="repeatNewPassword"
                            value={profile.repeatNewPassword}
                            onChange={handleChange}
                        />
                        <Button variant="contained" size='small' sx={{ bgcolor: '#ff6f00', color: '#fff', mt: 2 }}>Save</Button>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Profileinformation;
