import React from 'react';
import {
    Box,
    Typography,
    Switch,
    Divider,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    Avatar,
    Grid
} from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useTheme } from "../../../../../common/ThemeContext";


const ReferalPoint = () => {
    const { theme } = useTheme();
    const notifications = [
        { title: 'Ranking summary', description: 'Get a weekly summary for your starred and other important keywords.' },
        { title: 'Ranking successes', description: 'Get notified when your website increases in position.' },
        { title: 'Ranking warnings', description: 'Get notified when your website loses its position to a competitor.' },
        { title: 'News and updates', description: 'Get notified about our latest news and updates.' },
        { title: 'Billing', description: 'Get copies of receipts and invoices to your e-mail.' },
    ];

    return (
        <Grid sx={{
            p: 4
        }}>
            <Grid sx={{
                backgroundColor: theme.palette.mode !== "dark" ? "#312b36" : "#fff", p: 2, borderRadius: 4, border:
                    theme.palette.mode !== "dark"
                        ? "1px solid #4a4a4a"
                        : "1px solid #f2ebf0", borderRadius: 2
            }}>
                <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', mb: 2, color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000", fontWeight: 'bold' }}>
                    <NotificationsIcon sx={{ mr: 1, color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000", }} /> Notifications
                </Typography>
                <hr
                    style={{

                        backgroundColor: theme.palette.mode === "dark" ? "#4a4a4a" : "#1a1a1c",
                        border: "none",
                        height: "1.5px",
                    }}
                />


                <List>
                    {notifications.map((item, index) => (
                        <React.Fragment key={index}>
                            <ListItem>
                                <ListItemText
                                    primary={item.title}
                                    secondary={item.description}
                                    primaryTypographyProps={{ color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000", }}
                                    secondaryTypographyProps={{ color: 'gray' }}
                                />
                                <ListItemSecondaryAction>
                                    <Switch defaultChecked color="success" />
                                </ListItemSecondaryAction>
                            </ListItem>
                            <hr
                                style={{
                                    backgroundColor: theme.palette.mode === "dark" ? "#4a4a4a" : "#1a1a1c",
                                    border: "none",
                                    height: "1.5px",
                                }}
                            />
                        </React.Fragment>
                    ))}
                </List>
            </Grid>

            <Grid sx={{
                backgroundColor: theme.palette.mode !== "dark" ? "#312b36" : "#fff", p: 2, mt: 2, borderRadius: 4, border:
                    theme.palette.mode !== "dark"
                        ? "1px solid #4a4a4a"
                        : "1px solid #f2ebf0", borderRadius: 2
            }}>
                <Typography variant="subtitle1" sx={{ mt: 3, mb: 1, color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000", fontWeight: 'bold' }}>
                    Notifications per website
                </Typography>
                <ListItem sx={{
                    backgroundColor: theme.palette.mode !== "dark" ? "#1c1c1e" : "#fbf9fb", borderRadius: 4, p: 1.5, border:
                        theme.palette.mode !== "dark"
                            ? "1px solid #4a4a4a"
                            : "1px solid #f2ebf0", borderRadius: 2
                }}>
                    <Avatar src="/path/to/logo.png" alt="getmycollege.com" sx={{ mr: 2 }} />
                    <ListItemText
                        primary="getmycollege.com"
                        secondary="google.co.in - English"
                        primaryTypographyProps={{ color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000", }}
                        secondaryTypographyProps={{ color: 'gray' }}
                    />
                    <Box display="flex" alignItems="center">
                        <Typography variant="body2" color="gray" sx={{ mr: 2 }}>Device: Desktop</Typography>
                        <Typography variant="body2" color="gray" sx={{ mr: 2 }}>Location: India</Typography>
                        <Switch defaultChecked color="success" />
                    </Box>
                </ListItem>
            </Grid>
        </Grid>
    );
};

export default ReferalPoint;
