import React, { useState } from 'react';
import { Box, Typography, TextField, Grid, IconButton, Popover, Radio, FormControlLabel, InputBase ,Menu, MenuItem } from '@mui/material';
import SearchIcon from "@mui/icons-material/Search";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import DownloadIcon from '@mui/icons-material/Download';
import CheckIcon from '@mui/icons-material/Check';
import ListIcon from '@mui/icons-material/List';  // Another example icon for list items
import { useTheme } from '../../common/ThemeContext';

const Keywordcontent = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [selectedValue, setSelectedValue] = useState('include');
  const [selectedFilterValue, setSelectedFilterValue] = useState('all');
  const { theme } = useTheme(); // Get the theme object


  // Handle opening the popover for Groups
  const handlePopoverToggle = (event) => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  // Handle closing the popover
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  // Handle radio button change for Groups
  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
  };

  // Handle opening the popover for Filters
  const handleFilterPopoverToggle = (event) => {
    if (filterAnchorEl) {
      setFilterAnchorEl(null);
    } else {
      setFilterAnchorEl(event.currentTarget);
    }
  };

  // Handle closing the filter popover
  const handleFilterPopoverClose = () => {
    setFilterAnchorEl(null);
  };

  const filterOpen = Boolean(filterAnchorEl);

  // Handle radio button change for Filters
  const handleFilterRadioChange = (event) => {
    setSelectedFilterValue(event.target.value);
  };

  const [reportAnchorEl, setReportAnchorEl] = useState(null);
  const isReportMenuOpen = Boolean(reportAnchorEl); // Renamed to avoid conflict

  const handleClick = (event) => {
    setReportAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setReportAnchorEl(null);
  };


  return (
    <Grid container sx={{ backgroundColor:theme.palette.mode !== "dark"? '#1d1922':'#f8f3f7', padding: '2px', borderRadius: '8px' }}>
      <Grid item>
        <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', fontSize: 28, color:theme.palette.mode !== "dark"? '#d1d1d1':"#202020" }}>
          Keywords: Overview
        </Typography>
      </Grid>
      {/* Search Bar and Buttons */}
      <Grid container spacing={2} sx={{ alignItems: 'center', mb: 4 }}>
        {/* Search Bar */}
        <Grid item>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor:theme.palette.mode !== "dark"? '#2a252f':'#fff',
              borderRadius: 2,
              // border: '1px solid #f2ebf0',
              padding: '0 8px',
              height: '40px',
              width: '300px',
            }}
          >
            <SearchIcon sx={{ color: '#5f5269' }} />
            <TextField
              variant="standard"
              placeholder="Search"
              InputProps={{
                disableUnderline: true,
                sx: { color: '#5f5269', fontWeight: 'bold' },
              }}
              sx={{ flexGrow: 1, ml: 1 }}
            />
          </Box>
        </Grid>

        {/* Groups Button */}
        <Grid item>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              backgroundColor:theme.palette.mode !== "dark"? '#2a252f':'#fff',
              borderRadius: 2,
              // border: '1px solid #4c4452',
              padding: '0 12px',
              height: '40px',
              width: '130px',
              cursor: 'pointer',
            }}
            onClick={handlePopoverToggle}
          >
            <Typography sx={{ color:theme.palette.mode !== "dark"? '#e3e0e6':'#5f5269' }}>Groups</Typography>
            <ArrowDropDownIcon sx={{ color:theme.palette.mode !== "dark"? '#e3e0e6':'#5f5269', ml: 1 }} />
          </Box>

          {/* Popover that appears on click */}
          <Popover sx={{ mt: 1 }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
              color: '#1c191f'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={handlePopoverClose}
          >
            <Box
              sx={{
                padding: '8px 12px',
                backgroundColor:theme.palette.mode !== "dark"? '#2a252f':'#fff',
                color: '#e3e0e6',
                borderRadius: '2px',
                width: '220px',
                // border: '1px solid #4c4452',
              }}
            >
              {/* First line: Include and Exclude */}
              <Box sx={{ mb: 1 }}>
                <FormControlLabel
                  control={
                    <Radio
                      checked={selectedValue === 'include'}
                      onChange={handleRadioChange}
                      value="include"
                      sx={{
                        color:theme.palette.mode !== "dark"? '#e3e0e6':'#5f5269',
                        '&.Mui-checked': {
                          color: '#ff8f3b',
                        },
                      }}
                    />
                  }
                  label={
                    <Typography sx={{ color:theme.palette.mode !== "dark"? '#e3e0e6':'#5f5269', fontSize: '14px' }}>
                      Include
                    </Typography>
                  }
                />

                <FormControlLabel
                  control={
                    <Radio
                      checked={selectedValue === 'exclude'}
                      onChange={handleRadioChange}
                      value="exclude"
                      sx={{
                        color:theme.palette.mode !== "dark"? '#e3e0e6':'#5f5269',
                        '&.Mui-checked': {
                          color: '#ff8f3b',
                        },
                      }}
                    />
                  }
                  label={
                    <Typography sx={{ color:theme.palette.mode !== "dark"? '#e3e0e6':'#5f5269', fontSize: '14px' }}>
                      Exclude
                    </Typography>
                  }
                />
              </Box>

              {/* Second line: Search field */}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor:theme.palette.mode !== "dark"?  '#2a252f':'#fff',
                  border: '1px solid #4c4452',
                  borderRadius: 1,
                  padding: '4px 8px',
                  mb: 2,
                }}
              >
                <InputBase
                  placeholder="Search..."
                  sx={{
                    color:theme.palette.mode !== "dark"? '#e3e0e6':'#5f5269',
                    flex: 1,
                    fontSize: '14px',
                  }}
                />
                <IconButton sx={{ color:theme.palette.mode !== "dark"? '#e3e0e6':'#5f5269' }}>
                  <SearchIcon />
                </IconButton>
              </Box>

              {/* Apply button at the bottom */}
              <Box
                sx={{
                  backgroundColor: '#ff8f3b',
                  borderRadius: 1,
                  textAlign: 'center',
                  padding: '6px 0',
                  cursor: 'pointer',
                }}
              >
                <Typography sx={{ color: '#fff' }}>Apply</Typography>
              </Box>
            </Box>
          </Popover>
        </Grid>

        {/* Keyword Filter Button */}
        <Grid item>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor:theme.palette.mode !== "dark"?  '#2a252f':'#fff',
              borderRadius: 2,
              // border: '1px solid #4c4452',
              padding: '0 12px',
              height: '40px',
              width: '160px',
              cursor: 'pointer',
            }}
            onClick={handleFilterPopoverToggle}
          >
            <Typography sx={{ color:theme.palette.mode !== "dark"? '#e3e0e6':'#5f5269' }}>Keyword filter</Typography>
            <FilterAltOutlinedIcon sx={{ color:theme.palette.mode !== "dark"? '#e3e0e6':'#5f5269', ml: 1 }} />
          </Box>

          {/* Popover for Keyword Filters */}
          <Popover
            open={filterOpen}
            anchorEl={filterAnchorEl}
            onClose={handleFilterPopoverClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <Box
              sx={{
                padding: '8px 12px',
                backgroundColor:theme.palette.mode !== "dark"?  '#2a252f':'#fff',
                color:theme.palette.mode !== "dark"? '#e3e0e6':'#5f5269',
                borderRadius: '2px',
                width: '220px',
                // border: '1px solid #4c4452',
              }}
            >


              {/* Search Bar at the top */}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor:theme.palette.mode !== "dark"?  '#2a252f':'#fff',
                  borderRadius: 1,
                  // border: '1px solid #4c4452',
                  padding: '4px 8px',
                  mb: 2,
                }}
              >
                <SearchIcon sx={{ color: '#5f5269' }} />
                <InputBase
                  placeholder="Search filters"
                  sx={{
                    color:theme.palette.mode !== "dark"? '#e3e0e6':'#5f5269',
                    flex: 1,
                    fontSize: '14px',
                  }}
                />
              </Box>

              {/* Scrollable list of filters */}
              <Box
                sx={{
                  maxHeight: '200px',
                  overflowY: 'auto',
                }}
              >
                {/* Each filter item with left-side icon */}
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mb: 1,
                    cursor: 'pointer',
                  }}
                >
                  <CheckIcon sx={{ color: '#ff8f3b', mr: 1 }} />
                  <Typography sx={{ color:theme.palette.mode !== "dark"? '#e3e0e6':'#5f5269' }}>All</Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mb: 1,
                    cursor: 'pointer',
                  }}
                >
                  <ListIcon sx={{ color: '#ff8f3b', mr: 1 }} />
                  <Typography sx={{ color:theme.palette.mode !== "dark"? '#e3e0e6':'#5f5269' }}>High Volume</Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mb: 1,
                    cursor: 'pointer',
                  }}
                >
                  <ListIcon sx={{ color: '#ff8f3b', mr: 1 }} />
                  <Typography sx={{ color:theme.palette.mode !== "dark"? '#e3e0e6':'#5f5269' }}>Low CPC</Typography>
                </Box>
                {/* Add more items as needed */}
              </Box>

              {/* Apply Filter button */}
              <Box
                sx={{
                  backgroundColor: '#ff8f3b',
                  borderRadius: 1,
                  textAlign: 'center',
                  padding: '6px 0',
                  cursor: 'pointer',
                  mt: 2,
                }}
              >
                <Typography sx={{ color: '#fff' }}>Apply Filter</Typography>
              </Box>
            </Box>
          </Popover>
        </Grid>

        <Grid item sx={{ ml: 'auto' }}>
          {/* Report Button */}
          <IconButton
            size='small'
            sx={{
              color:theme.palette.mode !== "dark"? '#e3e0e6': '#5f5269',
              border: 'none',
              borderRadius: 0,
              padding: '7px',
              display: 'flex',
              alignItems: 'center',
              borderRadius:2
            }}
            onClick={handleClick}
          >
            <DownloadIcon sx={{ fontSize: 20, marginRight: '5px' }} />
            <Typography sx={{ fontSize: 16, fontWeight: 'bold' }}>
              Report
            </Typography>
          </IconButton>

          {/* Popup menu */}
          <Menu
            anchorEl={reportAnchorEl}
            open={isReportMenuOpen} // Renamed variable here as well
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            sx={{
              '.MuiMenu-paper': {
                backgroundColor: '#2c2b35',
                color: '#e3e0e6',
                padding: '10px',
                borderRadius: '8px',
              },
            }}
          >
            <MenuItem
              onClick={handleClose}
              sx={{
                color: '#e3e0e6',
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <DownloadIcon sx={{ marginRight: '8px' }} />
              Download PDF report
            </MenuItem>
            <MenuItem
              onClick={handleClose}
              sx={{
                color: '#e3e0e6',
                fontWeight: 'bold',
              }}
            >
              Customize report
            </MenuItem>
          </Menu>
        </Grid>

        {/* Add Keywords Button */}
        <Grid item>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor: '#ff8f3b',
              borderRadius: 2,
              padding: '0 12px',
              height: '35px',
              width: '130px',
              justifyContent: 'center',
            }}
          >
            <Typography sx={{ color: '#fff', fontSize: 15, fontWeight: 'bold' }}>Add keywords</Typography>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Keywordcontent;

