import React, { useState } from "react";
import {
    AppBar,
    Toolbar,
    Typography,
    IconButton,
    TextField,
    Box,
    Grid,
    Button,
    Menu,
    MenuItem,
    Popover,
    Avatar,
    List,
    ListItem,
    ListItemText,
} from "@mui/material";
import logo from '../../../assets/seo-mitra-logo.png';
import { useNavigate } from "react-router-dom";
import { useTheme } from "../ThemeContext";
import SearchIcon from "@mui/icons-material/Search";
import { useCookies } from "react-cookie";
import { config } from "../../../config/config";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";


function Header() {
    const { theme } = useTheme();
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [cookies, , removeCookie] = useCookies([config.cookieName]);

    const [avatarAnchorEl, setAvatarAnchorEl] = useState(null);

    const submenuItems = [
        { label: 'Word Count', path: '/word-counter' },
        { label: 'Plagiarism Checker', path: '/plagiarism-checker' },
        { label: 'Article Rewriter', path: '/article-rewriter' },
        { label: 'Grammar Checker', path: '/grammar-checker' },
        { label: 'MD5 Generator', path: '/md5-generator' },
        { label: 'Upper to Lower', path: '/upper-to-lower' },
        { label: 'Word Combiner', path: '/word-combiner' },
        { label: 'Text to Speech', path: '/text-to-speech' },
        { label: 'Reverse Text Generator', path: '/reverse-text-generator' },
    ];



    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setSearchTerm('');
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };


    const filteredSubmenuItems = submenuItems.filter((item) =>
        item.label.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleSubmenuNavigation = (path) => {
        navigate(path);
        handleMenuClose();
    };

    const handleAvatarPopoverOpen = (event) => {
        setAvatarAnchorEl(event.currentTarget);
    };

    const handleAvatarPopoverClose = () => {
        setAvatarAnchorEl(null);
    };

    const openAvatarPopover = Boolean(avatarAnchorEl);

    const handleLogout = () => {
        removeCookie(config.cookieName);
        // navigate("/");
        handleAvatarPopoverClose();
        window.location.replace("/");
    };

    const handleNavigation = (path) => {
        navigate(path);
        handleAvatarPopoverClose();
    };

    const avatarMenuItems = [
        {
            label: "My Order",
            icon: <CreditCardOutlinedIcon fontSize="small" />,
            path: "/my-order",
        },
        {
            label: "Log Out",
            icon: <LogoutOutlinedIcon fontSize="small" />,
            action: handleLogout,
        },
    ];


    if (
        !(
            !!cookies[config.cookieName] &&
            !!cookies[config.cookieName].token &&
            !!cookies[config.cookieName].loginUserId
        )
    ) {
        return (
            <AppBar
                position="static"
                sx={{
                    background: theme.palette.background.default,
                    color: theme.palette.text.primary,
                }}
            >
                <Toolbar>
                    <Grid
                        container
                        sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                        <Grid
                            item
                            sx={{
                                display: "flex",
                                alignItems: "center",
                            }}
                            md={4}
                        >
                            {/* Logo */}
                            <img
                                src={logo}
                                alt="Wincher Logo"
                                style={{
                                    width: '110px',
                                    height: 'auto',
                                    marginRight: '8px',
                                    cursor: 'pointer'
                                }}
                                onClick={() => navigate('/')}
                            />

                        </Grid>

                        <Grid
                            item
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: 'flex-end',
                                width: '100%',
                            }}
                            md={8}
                        >
                            {/* Navigation Items */}
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    mx: 2,
                                    color: "#b9b9b9",
                                    cursor: "pointer",
                                    "&:hover": {
                                        color: "#fff",
                                    },
                                }}
                                // onClick={() => navigate('/rank-tracker')}
                                onClick={() => navigate("/")}
                            >
                                Home
                            </Box>
                            {/* <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                mx: 2,
                                color: "#b9b9b9",
                                cursor: "pointer",
                                "&:hover": {
                                    color: "#fff",
                                },
                            }}
                        // onClick={() => navigate('/keywordHome')}
                        >
                            Keyword Explorer
                        </Box> */}
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    mx: 2,
                                    color: "#b9b9b9",
                                    cursor: "pointer",
                                    "&:hover": {
                                        color: "#fff",
                                    },
                                }}
                                // onClick={() => navigate('/seo-audit')}
                                onClick={() => {
                                    navigate("/monthly-backlinks")
                                }}
                            >
                                Backlinks
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    mx: 2,
                                    color: "#b9b9b9",
                                    cursor: "pointer",
                                    "&:hover": {
                                        color: "#fff",
                                    },
                                }}
                                onClick={() => navigate('/faq')}
                            >
                                FAQ
                            </Box>

                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    mx: 2,
                                    color: "#b9b9b9",
                                    cursor: "pointer",
                                    "&:hover": {
                                        color: "#fff",
                                    },
                                }}
                                onClick={() => navigate("/contact-us")}
                            >
                                Contact
                            </Box>

                            {/* Additional Tools Submenu */}
                            {/* <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                            sx={{ mt: 2 }}
                        >
                            <Box sx={{ p: 1 }}>
                                <TextField
                                    placeholder="Search Tools..."
                                    value={searchTerm}  // Ensures the text field displays the search term
                                    onChange={handleSearchChange}
                                    fullWidth
                                    variant="outlined"
                                />
                            </Box>
                            {filteredSubmenuItems.length > 0 ? (
                                filteredSubmenuItems.map((item, index) => (
                                    <MenuItem
                                        key={index}
                                        onClick={() => {
                                            handleMenuClose();
                                            // Handle navigation to submenu item path
                                        }}
                                    >
                                        {item.label}
                                    </MenuItem>
                                ))
                            ) : (
                                <MenuItem disabled>No results found</MenuItem>
                            )}
                        </Menu> */}

                            {/* Try for Free Button */}
                            <Button
                                variant="contained"
                                sx={{
                                    ml: 2,
                                    backgroundColor: theme.palette.primary.main,
                                    "&:hover": {
                                        backgroundColor: theme.palette.primary.dark,
                                    },
                                }}
                                onClick={() => navigate('/login')}
                            >
                                Login
                            </Button>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        );
    } else {
        return (
            <AppBar
                position="static"
                sx={{
                    background: theme.palette.background.default,
                    color: theme.palette.text.primary,
                }}
            >
                <Toolbar>
                    <Grid
                        container
                        sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                        <Grid
                            item
                            sx={{
                                display: "flex",
                                alignItems: "center",
                            }}
                            md={4}
                        >
                            {/* Logo */}
                            <img
                                src={logo}
                                alt="Wincher Logo"
                                style={{
                                    width: '110px',
                                    height: 'auto',
                                    marginRight: '8px',
                                    cursor: 'pointer'
                                }}
                                onClick={() => navigate('/')}
                            />

                        </Grid>

                        <Grid
                            item
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: 'flex-end',
                                width: '100%',
                            }}
                            md={8}
                        >
                            {/* Navigation Items */}
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    mx: 2,
                                    color: "#b9b9b9",
                                    cursor: "pointer",
                                    "&:hover": {
                                        color: "#fff",
                                    },
                                }}
                            // onClick={() => navigate('/rank-tracker')}
                            >
                                Home
                            </Box>
                            {/* <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                mx: 2,
                                color: "#b9b9b9",
                                cursor: "pointer",
                                "&:hover": {
                                    color: "#fff",
                                },
                            }}
                        // onClick={() => navigate('/keywordHome')}
                        >
                            Keyword Explorer
                        </Box> */}
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    mx: 2,
                                    color: "#b9b9b9",
                                    cursor: "pointer",
                                    "&:hover": {
                                        color: "#fff",
                                    },
                                }}
                                // onClick={() => navigate('/seo-audit')}
                                onClick={() => {
                                    navigate("/monthly-backlinks")
                                }}
                            >
                                Backlinks
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    mx: 2,
                                    color: "#b9b9b9",
                                    cursor: "pointer",
                                    "&:hover": {
                                        color: "#fff",
                                    },
                                }}
                                onClick={() => navigate('/faq')}
                            >
                                FAQ
                            </Box>

                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    mx: 2,
                                    color: "#b9b9b9",
                                    cursor: "pointer",
                                    "&:hover": {
                                        color: "#fff",
                                    },
                                }}
                                onClick={() => navigate("/contact-us")}
                            >
                                Contact
                            </Box>

                            {/* Additional Tools Submenu */}
                            {/* <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                            sx={{ mt: 2 }}
                        >
                            <Box sx={{ p: 1 }}>
                                <TextField
                                    placeholder="Search Tools..."
                                    value={searchTerm}  // Ensures the text field displays the search term
                                    onChange={handleSearchChange}
                                    fullWidth
                                    variant="outlined"
                                />
                            </Box>
                            {filteredSubmenuItems.length > 0 ? (
                                filteredSubmenuItems.map((item, index) => (
                                    <MenuItem
                                        key={index}
                                        onClick={() => {
                                            handleMenuClose();
                                            // Handle navigation to submenu item path
                                        }}
                                    >
                                        {item.label}
                                    </MenuItem>
                                ))
                            ) : (
                                <MenuItem disabled>No results found</MenuItem>
                            )}
                        </Menu> */}

                            {/* Try for Free Button */}
                            <Grid
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    px: "1rem",
                                    borderLeft: `3px solid ${theme.palette.background.paper}`,
                                    cursor: "pointer",
                                    background: theme.palette.background.default,
                                    transition: "ease-in-out 100ms",
                                    "&:hover": {
                                        color: "#fff",
                                    },
                                    "&:active": {
                                        background: "#161616",
                                        "&:hover": { color: "#b9b9b9" },
                                    },
                                }}
                                onClick={handleAvatarPopoverOpen}
                            >
                                <Avatar sx={{ bgcolor: "#4CAF50" }}>S</Avatar>
                                <KeyboardArrowDownIcon sx={{ marginLeft: 1 }} />
                            </Grid>
                            {/* Avatar Popover */}
                            <Popover
                                open={openAvatarPopover}
                                anchorEl={avatarAnchorEl}
                                onClose={handleAvatarPopoverClose}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                }}
                                sx={{ top: "12px" }}
                            >
                                <Box sx={{ px: "20px", py: "10px" }}>
                                    <List>
                                        {avatarMenuItems.map((item, index) => (
                                            <ListItem
                                                key={index}
                                                sx={{ cursor: "pointer", "&:hover": { color: "orange" } }}
                                                onClick={() =>
                                                    item.action
                                                        ? item.action()
                                                        : handleNavigation(item.path)
                                                }
                                            >
                                                {item.isButton ? (
                                                    item.icon // Render button directly if it is a button item
                                                ) : (
                                                    <>
                                                        {item.icon}
                                                        <ListItemText
                                                            primary={item.label}
                                                            primaryTypographyProps={{
                                                                sx: { fontSize: "12px", ml: "10px" },
                                                            }}
                                                        />
                                                    </>
                                                )}
                                            </ListItem>
                                        ))}
                                    </List>
                                </Box>
                            </Popover>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        );
    }
}

export default Header;
