import React, { useEffect } from 'react';
import { Grid, Box, Typography, Card, CardContent, TextField, Button, useMediaQuery } from '@mui/material';

import bannerImage from '../../../assets/BannerImage.jpg'
import { useLocation, useNavigate } from "react-router-dom";
import Footer from '../../common/Footer';
import Header from '../../common/home/Header';

const Contact = () => {
    const isMobile = useMediaQuery("(min-width:360px) and (max-width:500px)");

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', overflowY: 'auto', overflowX: 'hidden', background: 'linear-gradient(to right, rgba(226, 255, 255, 0.26), rgba(0, 180, 194, 0.16))' }}>
            <Header />
            <Box
                sx={{
                    height: isMobile ? '2vh' : '40vh',
                    background: `url(${bannerImage}) center/cover no-repeat`,
                    backgroundSize: 'cover',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: 'white',
                    textAlign: 'center',
                    borderRadius: 0,
                    padding: '20px',
                }}
            >
                {/* Banner content */}
                <Typography variant="h3" sx={{ marginBottom: '10px', fontWeight: 'bold', fontSize: { xs: '24px', sm: '32px', md: '48px' } }}>
                    CONTACT US
                </Typography>
                <Typography
                    variant="h2"
                    sx={{
                        width: "100%",
                        fontWeight: "600",
                        fontSize: { xs: "16px", sm: "20px", md: "24px" },
                        lineHeight: { xs: "24px", sm: "30px", md: "36px" },
                        textAlign: "center",
                    }}
                >
                    Unlock Your Business Potential. Contact Us Now!
                </Typography>
            </Box>
            <Box sx={{ padding: '20px', justifyContent: 'center', color: 'white' }}>
                <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={12} md={6}>
                        <Box sx={{ background: 'rgba(255, 255, 255, 0.9)', marginBottom: '20px', padding: '20px' }}>
                            <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '20px', textAlign: 'center', color: 'black' }}>CONTACT FORM</Typography>
                            <Box component="form" noValidate autoComplete="off">
                                <TextField
                                    fullWidth
                                    label="Name"
                                    variant="outlined"
                                    margin="normal"
                                />
                                <TextField
                                    fullWidth
                                    label="Phone Number"
                                    variant="outlined"
                                    margin="normal"
                                />
                                <TextField
                                    fullWidth
                                    label="Email"
                                    variant="outlined"
                                    margin="normal"
                                />
                                <TextField
                                    fullWidth
                                    label="Message"
                                    variant="outlined"
                                    multiline
                                    rows={4}
                                    margin="normal"
                                />
                                <Button variant="contained" color="secondary" sx={{ marginTop: '10px' }}>
                                    Submit
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                    {/* <Grid item xs={12} md={6}>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Box sx={{ background: 'rgba(255, 255, 255, 0.9)', padding: '20px', display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'space-between', alignItems: 'center' }}>
                                <Box sx={{ marginBottom: { xs: '20px', md: 0 } }}>
                                    <Typography variant="body1" sx={{ textAlign: { xs: 'center', md: 'left' }, color: 'black' }}>
                                        <strong>ADDRESS</strong><br />
                                        #2 1st Floor, Svs Apartment, 12A Main Road, <br />
                                        BTM 1st Stage, BTM Layout,  <br />
                                        Near Metro Station,  <br />
                                        Bangalore - 560029
                                        <br />

                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography variant="body1" sx={{ textAlign: { xs: 'center', md: 'left', color: 'black' } }}>
                                        <strong>PHONE</strong><br />
                                        <a href="tel:+91 8073979129" style={{ color: '#00b4c2', textDecoration: 'none' }}>+91 8073979129</a>
                                    </Typography>
                                    <Typography variant="body1" sx={{ textAlign: { xs: 'center', md: 'left', color: 'black' } }}>
                                        <strong>EMAIL</strong><br />
                                        <a href="mailto:seomitraofficial@gmail.com" style={{ color: '#00b4c2', textDecoration: 'none' }}>seomitraofficial@gmail.com</a>
                                    </Typography>
                                </Box>
                            </Box>
                            <Box sx={{ background: 'rgba(255, 255, 255, 0.9)', padding: '20px', marginBottom: '20px' }}>
                                <Box sx={{ position: 'relative', width: '100%', height: 0, paddingTop: '56.25%' }}>
                                    <iframe
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31111.27282300563!2d77.59199031726179!3d12.913563424040115!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae14fc91a93031%3A0xf0afe62576cbdc3f!2sBTM%20Layout%2C%20Bengaluru%2C%20Karnataka!5e0!3m2!1sen!2sin!4v1716895947532!5m2!1sen!2sin"
                                        title="Google Map"
                                        width="100%"
                                        height="100%"
                                        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', border: 0 }}
                                        allowFullScreen=""
                                        loading="lazy"
                                    ></iframe>
                                </Box>
                            </Box>
                        </Box>
                    </Grid> */}
                </Grid>
            </Box>
            <Footer />
        </div>
    );
}

export default Contact;
