import React from 'react';
import { Card, CardContent, Typography, Grid, Box, Divider, Chip } from '@mui/material';

const Paiddifficulty = () => {
  return (
    <Grid>
      <Card sx={{ backgroundColor: '#2a252f', border: '1px solid #4c4452' }}>
        <CardContent>
          <Grid container spacing={2} alignItems="center">
            {/* Paid Difficulty Section */}
            <Grid item xs={6}>
              <Typography variant="subtitle1" fontWeight="bold" sx={{ color: '#d1d1d1',fontSize:10 }} gutterBottom>
                PAID DIFFICULTY
              </Typography>
              <Box display="flex" alignItems="center">
                <Typography variant="h3" fontWeight="bold" sx={{ marginRight: 1, color: '#d1d1d1',fontSize:30 }}>
                  2
                </Typography>
                <Chip label="EASY" sx={{ backgroundColor: '#d1d1d1', color: '#000', fontWeight: 'bold',fontSize:10 }} />
              </Box>
            </Grid>

            {/* Divider */}
            <Divider orientation="vertical" flexItem sx={{ height: '100%' }} />

            {/* CPC Section */}
            <Grid item xs={5}>
              <Typography variant="subtitle1" fontWeight="bold" sx={{ color: '#d1d1d1',fontSize:10 }} gutterBottom>
                COST PER CLICK (CPC)
              </Typography>
              <Typography variant="h3" fontWeight="bold" sx={{ color: '#d1d1d1',fontSize:30 }}>
                ₹0.00
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default Paiddifficulty;
