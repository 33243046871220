import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import Footer from '../../../common/Footer';
import Order from './Order';
import OrderDetails from './OrderDetails';
import Header1 from '../../../setup/common/Header';
import useFetch from '../../useFetch';
import Header2 from '../../../common/home/Header';

const Backlink = () => {
    const { name, roles } = useFetch();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const renderHeader = () => {
        if (roles && roles.length > 0) {
            if (roles.includes("Admin")) {
                return <Header1 />;
            } else {
                return <Header2 />;
            }
        }
        // Optional fallback in case `roles` is undefined or empty
        return null;
    };

    return (
        <Grid container direction="column" sx={{ backgroundColor: '#fff9f7' }}>
            {/* Render the appropriate header */}
            <Grid item>
                {renderHeader()}
            </Grid>

            {/* Other components */}
            <Grid item>
                <Order />
            </Grid>

            <Grid item>
                <OrderDetails />
            </Grid>

            {/* Footer */}
            <Grid item>
                <Footer />
            </Grid>
        </Grid>
    );
};

export default Backlink;
