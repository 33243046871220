import React from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Tabs,
  Tab,
  Paper,
  Link,
  Button,
  TablePagination,
  Grid,
} from "@mui/material";

// Sample data for the table
const organicRows = [
  { location: "US", traffic: "764.7K", share: "29.8%", keywords: "140.5K" },
  { location: "IN", traffic: "476.8K", share: "18.6%", keywords: "52.1K" },
  { location: "GB", traffic: "237.1K", share: "9.2%", keywords: "30.2K" },
  { location: "PK", traffic: "163.5K", share: "6.4%", keywords: "11.4K" },
  { location: "RU", traffic: "87.4K", share: "3.4%", keywords: "34.8K" },
];

const paidRows = [
  { location: "US", traffic: "364.7K", share: "25.1%", keywords: "80.5K" },
  { location: "IN", traffic: "276.8K", share: "15.6%", keywords: "45.3K" },
  { location: "GB", traffic: "137.1K", share: "8.9%", keywords: "25.6K" },
  { location: "PK", traffic: "113.5K", share: "5.4%", keywords: "9.1K" },
  { location: "RU", traffic: "57.4K", share: "3.0%", keywords: "21.3K" },
];

const TrafficLocation = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [tabValue, setTabValue] = React.useState(0);

  // Handle tab change
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setPage(0); // Reset page to 0 when tab changes
  };

  // Handle pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Determine which rows to display based on selected tab
  const rows = tabValue === 0 ? organicRows : paidRows;

  return (
    <Box sx={{ p: 4, border: '1px solid #4c4452', overflowX: 'auto', maxWidth: '100%' }}>
      {/* Tabs */}
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        centered
        TabIndicatorProps={{
          style: { backgroundColor: "#ed6c02" }, 
        }}
        sx={{
          ".MuiTab-root": {
            color: "#d1d1d1",
          },
          ".Mui-selected": {
            color: "#ed6c02", 
          },
        }}
      >
        <Tab label="Organic Traffic" />
        <Tab label="Paid Traffic" />
      </Tabs>

      {/* Table Title */}
      <Typography variant="h6" sx={{ mt: 2, color: "#d1d1d1",fontWeight:'bold' }}>
        Traffic by location
      </Typography>

      {/* Table */}
      <TableContainer component={Paper} sx={{ mt: 2, backgroundColor: '#2a252f', border: '1px solid #4c4452', overflowX: 'auto' }}>
        <Table sx={{ borderCollapse: 'collapse', minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ backgroundColor: '#2a252f', borderBottom: '1px solid #4c4452', color: '#d1d1d1' }}>
                <strong>Location</strong>
              </TableCell>
              <TableCell align="right" sx={{ backgroundColor: '#2a252f', borderBottom: '1px solid #4c4452', color: '#d1d1d1' }}>
                <strong>Traffic</strong>
              </TableCell>
              <TableCell align="right" sx={{ backgroundColor: '#2a252f', borderBottom: '1px solid #4c4452', color: '#d1d1d1' }}>
                <strong>Share</strong>
              </TableCell>
              <TableCell align="right" sx={{ backgroundColor: '#2a252f', borderBottom: '1px solid #4c4452', color: '#d1d1d1' }}>
                <strong>Keywords</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
              <TableRow key={row.location}>
                <TableCell component="th" scope="row" sx={{ backgroundColor: '#2a252f', borderBottom: '1px solid #4c4452', color: '#d1d1d1' }}>
                  {row.location}
                </TableCell>
                <TableCell align="right" sx={{ backgroundColor: '#2a252f', borderBottom: '1px solid #4c4452', color: '#d1d1d1' }}>
                  {row.traffic}
                </TableCell>
                <TableCell align="right" sx={{ backgroundColor: '#2a252f', borderBottom: '1px solid #4c4452', color: '#d1d1d1' }}>
                  {row.share}
                </TableCell>
                <TableCell align="right" sx={{ backgroundColor: '#2a252f', borderBottom: '1px solid #4c4452', color: '#d1d1d1' }}>
                  <Link href="#" underline="hover" sx={{ color: '#fff' }}>
                    {row.keywords}
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination */}
      {/* <TablePagination
        component="div"
        count={rows.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{ color: '#d1d1d1' }} // Pagination text color
      /> */}

      {/* Compare Button */}
      <Grid sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}>
        <Button startIcon={<span>&#9664;</span>} sx={{ color: "grey.600" }} />
        <Typography sx={{ color: "#d1d1d1",mt:1 }}>Compare top 5 on chart</Typography>
        <Button endIcon={<span>&#9654;</span>} sx={{ color: "grey.600" }} />
      </Grid>
    </Box>
  );
};

export default TrafficLocation;
