import React from "react";
import Faq from "./Faq";
import Pricing from "./Pricing";
import Header from "../../../../common/home/Header";
import Footer from "../../../../common/Footer";

const Pricingdetailhome = () => {
  return (
    <>
      <Header />
      <Pricing />
      <Faq />
      <Footer />
    </>
  );
};

export default Pricingdetailhome;
