import React, { useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Box,
  Modal,
  Fade,
  Backdrop,
} from "@mui/material";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { useTheme } from "../../common/ThemeContext";

const data = [
  { date: "1 Oct", position: 50 },
  { date: "2 Oct", position: 0 },
  { date: "3 Oct", position: 0 },
  { date: "4 Oct", position: 0 },
  { date: "5 Oct", position: 0 },
  { date: "6 Oct", position: 0 },
];

const AveragePosition = () => {
  const [open, setOpen] = useState(false);
  const { theme } = useTheme(); // Get the theme object

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      {/* Main Card */}
      <Grid item xs={12} sm={6} md={12}>
        <Card
          sx={{
            backgroundColor:
              theme.palette.mode !== "dark" ? "#2a252f" : "#ffffff", // Light mode background
              border:theme.palette.mode !== "dark" ? '1px solid #4c4452':"none", 

            borderRadius: "12px",
            color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000000", // Light mode text color
            padding: "2px",
            height: "25vh",
            position: "relative",
            cursor: "pointer",
            transition: "transform 0.3s ease",
            "&:hover": {
              transform: "translateY(-5px)",
            },
          }}
          onClick={handleOpen}
        >
          <CardContent>
            {/* Header */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  fontSize: "16px",
                  color: theme.palette.mode !== "dark" ? "#d0ced3" : "#333333",
                }}
              >
                Average position
              </Typography>
            </Box>

            {/* Main Value & Indicator */}
            <Box
              sx={{ display: "flex", alignItems: "center", marginTop: "8px" }}
            >
              <Typography
                variant="h3"
                sx={{ fontSize: "20px", fontWeight: "bold" }}
              >
                18.5
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "16px",
                  backgroundColor: "#e74c3c",
                  borderRadius: "8px",
                  padding: "4px 8px",
                  color:'#fff'
                }}
              >
                <ArrowDownwardIcon fontSize="small" />
                <Typography
                  variant="body2"
                  sx={{ marginLeft: "4px", fontSize: "16px", }}
                >
                  17.5
                </Typography>
              </Box>
            </Box>
          </CardContent>

          {/* Line Chart with Recharts */}
          <Box
            sx={{
              position: "absolute",
              bottom: "16px",
              left: "16px",
              right: "16px",
              height: "25%",
              overflow: "hidden",
            }}
          >
            <ResponsiveContainer width="100%" height={40}>
              <LineChart data={data}>
                <Line
                  type="monotone"
                  dataKey="position"
                  stroke="#9b87f2"
                  strokeWidth={2}
                  dot={false}
                />
                <XAxis dataKey="date" hide domain={[10, 20]} />
                <YAxis hide domain={[10, 0]} />
              </LineChart>
            </ResponsiveContainer>
          </Box>
        </Card>
      </Grid>

      {/* Modal for Detailed View */}
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "80%",
              backgroundColor:
                theme.palette.mode !== "dark" ? "#2d2839" : "#ffffff", // Light mode background
              borderRadius: 2,
              boxShadow: 24,
              p: 4,
              color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000000", // Light mode text color
            }}
          >
            {/* Modal Header */}
            <Typography variant="h6" sx={{ mb: 2 }}>
              Average Position Details
            </Typography>

            {/* Detailed Graph Content */}
            <Box
              sx={{
                width: "100%",
                height: "300px",
                backgroundColor:
                  theme.palette.mode !== "dark" ? "#1b162b" : "#f5f5f5",
                padding: "16px",
                borderRadius: "8px",
                position: "relative",
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  color: "#e3e0e6",
                  position: "absolute",
                  left: "10px",
                  top: "10px",
                }}
              >
                2 Oct 2024
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: "#e3e0e6",
                  position: "absolute",
                  right: "10px",
                  top: "10px",
                }}
              >
                getmycollege.com: 18.5
              </Typography>

              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={data}>
                  <XAxis dataKey="date" hide />
                  <YAxis hide domain={[0, 20]} />
                  <Tooltip />
                  <Line
                    type="monotone"
                    dataKey="position"
                    stroke="#9b87f2"
                    strokeWidth={2}
                    dot={{ r: 4, fill: "#9b87f2" }}
                  />
                </LineChart>
              </ResponsiveContainer>
            </Box>

            {/* Graph Bottom Details */}
            <Box sx={{ mt: 3 }}>
              <Typography
                variant="body2"
                sx={{
                  color: theme.palette.mode !== "dark" ? "#7e7c86" : "#666666",
                }}
              >
                1 Oct - 10 Oct
              </Typography>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default AveragePosition;
