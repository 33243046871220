import React from 'react';
import { Grid, Typography, LinearProgress } from '@mui/material';
import FlagIcon from '@mui/icons-material/Flag';

const KeywordVolumeSearch = () => {
  return (
    <Grid
      sx={{
        padding: 3,
        backgroundColor: '#2a252f',
        borderRadius: 2,
        boxShadow: 1,
        border: '1px solid #4c4452'
      }}
    >
      {/* Section 1: Search Volume and Location Breakdown */}
      <Grid>
        {/* Search Volume Header */}
        <Typography variant="h6" fontWeight="bold" sx={{ color: '#d1d1d1' }}>
          SEARCH VOLUME
        </Typography>

        {/* Main Volume Number */}
        <Typography variant="h3" fontWeight="bold" sx={{ mt: 1, color: '#d1d1d1' }}>
          390
        </Typography>

        <Grid container display={'flex'} flexDirection={'row'}>
          {/* Left Section - Location Breakdown Header */}
          <Grid item xs={10} sx={{border: '1px solid #4c4452', borderRadius: 2, padding: 2,backgroundColor:'' }}>
            <Typography variant="h6" fontWeight="bold" sx={{ mt: 4, color: '#d1d1d1' }}>
              LOCATION BREAKDOWN
            </Typography>

            <Grid container justifyContent="space-between" alignItems="center" sx={{ mt: 2 }}>
              <Grid item xs={10}>
                {/* India Breakdown */}
                <Grid container alignItems="center">
                  <Grid item xs={1}>
                    <img
                      src="https://upload.wikimedia.org/wikipedia/en/4/41/Flag_of_India.svg"
                      alt="India Flag"
                      style={{ width: 24, height: 24 }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body1" fontWeight="bold" sx={{ color: '#1A73E8' }}>
                      India
                    </Typography>
                  </Grid>
                  <Grid item xs={2} textAlign="right">
                    <Typography variant="body1" fontWeight="bold" sx={{ color: '#d1d1d1' }}>
                      390
                    </Typography>
                  </Grid>
                  <Grid item xs={3} textAlign="right">
                    <Typography variant="body1" fontWeight="bold" sx={{ color: '#d1d1d1' }}>
                      81%
                    </Typography>
                  </Grid>
                </Grid>
                <LinearProgress
                  variant="determinate"
                  value={81}
                  sx={{
                    mt: 1,
                    height: 6,
                    borderRadius: 5,
                    backgroundColor: '#f0f0f0',
                    '& .MuiLinearProgress-bar': { backgroundColor: '#1A73E8' },
                  }}
                />

                {/* Others Breakdown */}
                <Grid container alignItems="center" sx={{ mt: 3 }}>
                  <Grid item xs={1}>
                    <FlagIcon sx={{ color: '#808080' }} />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body1" fontWeight="bold" sx={{ color: '#808080' }}>
                      Others
                    </Typography>
                  </Grid>
                  <Grid item xs={2} textAlign="right">
                    <Typography variant="body1" fontWeight="bold" sx={{ color: '#d1d1d1' }}>
                      90
                    </Typography>
                  </Grid>
                  <Grid item xs={3} textAlign="right">
                    <Typography variant="body1" fontWeight="bold" sx={{ color: '#d1d1d1' }}>
                      19%
                    </Typography>
                  </Grid>
                </Grid>

                <LinearProgress
                  variant="determinate"
                  value={19}
                  sx={{
                    mt: 1,
                    height: 4,
                    borderRadius: 5,
                    backgroundColor: '#f0f0f0',
                    '& .MuiLinearProgress-bar': { backgroundColor: '#808080' },
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default KeywordVolumeSearch;
