import React, { useState, useRef } from 'react';
import {
  Button,
  TextField,
  Box,
  Typography,
  Paper,
  Grid,
  Card,
  CardContent,
} from '@mui/material';
import {
  ContentCopy,
  GetApp,
  UploadFile,
  Print,
  Clear,
  Undo,
  Redo,
} from '@mui/icons-material';

const WordCount = () => {
  const [text, setText] = useState('');
  const history = useRef([]);
  const [historyIndex, setHistoryIndex] = useState(-1);

  const handleTextChange = (e) => {
    const newText = e.target.value;

    // Save current text to history before changing it
    if (historyIndex === history.current.length - 1) {
      history.current.push(text);
    } else {
      history.current = history.current.slice(0, historyIndex + 1);
      history.current.push(text);
    }

    setText(newText);
    setHistoryIndex(history.current.length - 1);
  };

  const wordCount = text ? text.trim().split(/\s+/).length : 0;
  const charCount = text.length;

  const handleCopy = () => {
    navigator.clipboard.writeText(text);
  };

  const handleDownload = () => {
    const blob = new Blob([text], { type: 'text/plain;charset=utf-8' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'text.txt';
    link.click();
  };

  const handleClear = () => {
    setText('');
    // Reset history when clearing
    history.current = [];
    setHistoryIndex(-1);
  };

  // Placeholder for upload functionality
  const handleUpload = () => {
    alert('Upload functionality is not implemented.');
  };

  const handleUndo = () => {
    if (historyIndex > 0) {
      setHistoryIndex(historyIndex - 1);
      setText(history.current[historyIndex - 1]);
    }
  };

  const handleRedo = () => {
    if (historyIndex < history.current.length - 1) {
      setHistoryIndex(historyIndex + 1);
      setText(history.current[historyIndex + 1]);
    }
  };

  return (
    <Box sx={{ backgroundColor: '#1c191f', p: 3 }}>
      <Typography variant="h4" align="center" gutterBottom color="#d1d1d1" sx={{fontWeight:'bold'}}>
        Word Counter
      </Typography>

      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} md={9}>
          <Paper elevation={3} sx={{ p: 3, border: '1px solid #4c4452', backgroundColor: '#1c191f' }}>
            <TextField
              sx={{
                '& .MuiInputBase-root': {
                  color: '#d1d1d1',
                },
                '& .MuiFormLabel-root': {
                  color: '#d1d1d1',
                },
                '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#4c4452',
                },
                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#d1d1d1',
                },
                '& .MuiOutlinedInput-root.Mui-focused .MuiFormLabel-root': {
                  color: '#d1d1d1',
                },
              }}
              label="Type or paste your text here..."
              multiline
              fullWidth
              rows={8}
              value={text}
              onChange={handleTextChange}
              variant="outlined"
              InputLabelProps={{
                shrink: !!text,
              }}
            />
          </Paper>
        </Grid>

        <Grid item xs={12} md={3}>
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="flex-end" mt={2}>
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item xs={6}>
                <Button onClick={handleCopy} color="primary" startIcon={<ContentCopy />}>
                  Copy
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button onClick={handleDownload} color="info" startIcon={<GetApp />}>
                  Download
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button onClick={handleUpload} color="warning" startIcon={<UploadFile />}>
                  Upload
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button onClick={() => window.print()} color="success" startIcon={<Print />}>
                  Print
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button onClick={handleClear} color="error" startIcon={<Clear />}>
                  Clear
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button onClick={handleUndo} color="secondary" startIcon={<Undo />}>
                  Undo
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button onClick={handleRedo} color="warning" startIcon={<Redo />}>
                  Redo
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" align="center" mt={1} color="#d1d1d1">
            Words: {wordCount} | Characters: {charCount}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Card sx={{ mt: 3, backgroundColor: '#2e2c2f' }}>
            <CardContent>
              <Typography variant="h6" gutterBottom color="#d1d1d1">
                What is WordCounter?
              </Typography>
              <Typography variant="body2" color="#d1d1d1">
                Apart from counting words and characters, our online editor can help you improve word choice and writing style, and optionally help you detect grammar mistakes and plagiarism.
                Knowing the word count of a text can be important for articles, essays, stories, books, and more. WordCounter will help ensure your word count meets specific requirements or stays within a certain limit.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default WordCount;
