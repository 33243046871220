import React, { useState } from 'react';
import { Box, Button, Grid, Paper, Typography, Snackbar } from '@mui/material';
import ReactQuill from 'react-quill';
import DataGrid from 'react-data-grid';
import { CSVLink } from 'react-csv'; 
import { parse } from 'papaparse'; 
import { Bar } from 'react-chartjs-2'; 
import 'react-quill/dist/quill.snow.css'; 
import 'react-data-grid/lib/styles.css';

const columns = [
  { key: 'id', name: 'ID', editable: false },
  { key: 'title', name: 'Title', editable: true },
  { key: 'description', name: 'Description', editable: true },
];

const initialRows = [
  { id: 1, title: 'Sample Title 1', description: 'Sample Description 1' },
  { id: 2, title: 'Sample Title 2', description: 'Sample Description 2' },
];

const Workspace = () => {
  const [paraphrasedText, setParaphrasedText] = useState('');
  const [plagiarismPercentage, setPlagiarismPercentage] = useState(0);
  const [aiPercentage, setAiPercentage] = useState(0);
  const [readabilityPercentage, setReadabilityPercentage] = useState(0);
  const [documentContent, setDocumentContent] = useState(''); 
  const [rows, setRows] = useState(initialRows); 
  const [openSnackbar, setOpenSnackbar] = useState(false); 

  const handleParaphrase = () => {
    setParaphrasedText('Sample paraphrased content.');
    setPlagiarismPercentage(5);
    setAiPercentage(80);
    setReadabilityPercentage(90);
  };

  const handleGridRowsChange = (updatedRows) => {
    setRows(updatedRows);
  };

  const handleImportCSV = (event) => {
    const file = event.target.files[0];
    if (file) {
      parse(file, {
        complete: (results) => {
          const newRows = results.data.map((item, index) => ({
            id: index + 1,
            title: item[0],
            description: item[1],
          }));
          setRows(newRows);
          setOpenSnackbar(true);
        },
        header: false,
      });
    }
  };

  // Data for the charts
  const chartData = {
    labels: ['Plagiarism', 'AI Enhanced', 'Readability'],
    datasets: [
      {
        label: 'Percentage',
        data: [plagiarismPercentage, aiPercentage, readabilityPercentage],
        backgroundColor: [
          'rgba(255, 99, 132, 0.6)', 
          'rgba(54, 162, 235, 0.6)', 
          'rgba(75, 192, 192, 0.6)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(75, 192, 192, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <Grid sx={{ padding: 4, height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Grid container spacing={2}>
        {/* Left Side - Document Section */}
        <Grid item xs={9}>
          <Paper elevation={3} sx={{ padding: 2, height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h6" gutterBottom>
              Document & Excel
            </Typography>

            {/* Word Document Section */}
            <Box sx={{ padding: 2, border: '1px solid #ddd', borderRadius: 1, marginBottom: 2, flexGrow: 1 }}>
              <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
                Word Document
              </Typography>
              <ReactQuill
                value={documentContent}
                onChange={setDocumentContent}
                style={{ width: '100%', border: '1px solid #ccc', borderRadius: '4px' }}
                modules={{
                  toolbar: [
                    [{ 'header': [1, 2, false] }],
                    ['bold', 'italic', 'underline'],
                    ['link', 'image', 'code-block'],
                    ['clean'], 
                  ],
                }}
                formats={['header', 'bold', 'italic', 'underline', 'link', 'image', 'code-block']}
              />
            </Box>

            {/* Import and Submit Buttons */}
            <Box sx={{ marginTop: 2, display: 'flex', justifyContent: 'space-between' }}>
              <Button variant="outlined" component="label" color="primary">
                Import CSV
                <input type="file" accept=".csv" hidden onChange={handleImportCSV} />
              </Button>
              <CSVLink
                data={rows}
                headers={columns}
                filename="data.csv"
                style={{ textDecoration: 'none' }}>
                <Button variant="contained" color="success">Export to CSV</Button>
              </CSVLink>
              <Button variant="contained" color="success" onClick={handleParaphrase}>Submit</Button>
            </Box>
          </Paper>
        </Grid>

        {/* Right Side - Results */}
        <Grid item xs={3}>
          <Paper elevation={3} sx={{ padding: 2, height: '100%' }}>
            <Typography variant="h6" gutterBottom>
              Results
            </Typography>

            {/* Bar Chart for Results */}
            <Box sx={{ marginTop: 2 }}>
              <Bar
                data={chartData}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  scales: {
                    y: {
                      beginAtZero: true,
                      ticks: {
                        precision: 0, 
                      },
                    },
                  },
                  plugins: {
                    legend: {
                      position: 'top',
                    },
                    title: {
                      display: true,
                      text: 'Results Overview',
                    },
                  },
                }}
                height={300} 
              />
            </Box>
          </Paper>
        </Grid>
      </Grid>

      {/* Snackbar for Import Success */}
      <Snackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        message="CSV Imported Successfully!"
        autoHideDuration={3000}
      />
    </Grid>
  );
};

export default Workspace;

