import { Grid } from '@mui/material';
import React from 'react';
import Keywordcontent from './Keywordcontent';
import Positionchanges from './Positionchanges';
import Averageposition from './Averageposition';
import Estimatedtraffic from './Estimatedtraffic';
import Firstposition from './Firstposition';
import KeywordTable from './KeywordTable';

const KeywordHome = () => {
  return (
    <Grid container spacing={0}>
      {/* Keywardcontent at the top */}
      <Grid item xs={12} position={'sticky'}>
        <Keywordcontent />
      </Grid>
      {/* Four Positioncards components side by side */}
      <Grid container item xs={12} spacing={2}>
        <Grid item xs={2.5}>
          <Positionchanges />
        </Grid>
        <Grid item xs={2.5}>
          <Averageposition />
        </Grid>
        <Grid item xs={2.5}>
          <Estimatedtraffic />
        </Grid>
        <Grid item xs={4.5}>
          <Firstposition />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <KeywordTable/>
      </Grid>
    </Grid>
  );
};

export default KeywordHome;
