
// import React, { useState } from 'react';
// import { Box, Typography, List, ListItemButton, ListItemIcon, ListItemText, LinearProgress, Button } from '@mui/material';
// import {
//     Home as HomeIcon,
//     DriveFolderUpload as DriveIcon,
//     Laptop as ComputerIcon,
//     Group as SharedIcon,
//     AccessTime as RecentIcon,
// } from '@mui/icons-material';

// const sidebarItems = [
//     { label: 'Home', icon: <HomeIcon /> },
//     { label: 'Workspace', icon: <DriveIcon /> },
//     { label: 'Users', icon: <ComputerIcon /> },
//     { label: 'Files', icon: <SharedIcon /> },
// ];

// const SidebarFirst = () => {
//     const [selectedIndex, setSelectedIndex] = useState(0); // Default selection is "Home"

//     return (
//         <Box sx={{
//             mt: 10,
//             flex: 1,
//             width: 220,
//             backgroundColor: '#ffffff',
//             boxSizing: 'border-box',
//             padding: '1.5rem',
//             boxShadow: '2px 0 10px rgba(0, 0, 0, 0.1)',
//             display: 'flex',
//             flexDirection: 'column',
//             alignItems: 'center',
//             position:'sticky',
//             // height:'100%'
//         }}>
//             {/* Sidebar List */}
//             <List >
//                 {sidebarItems.map((item, index) => (
//                     <ListItemButton
//                         key={index}
//                         sx={{
//                             width: '100%',
//                             color: selectedIndex === index ? '#007bff' : 'black', // Change color if selected
//                             borderRadius: 2,
//                             marginBottom: 1,
//                             mt: 1,
//                             '&:hover': {
//                                 backgroundColor: '#f1f1f1',
//                                 transform: 'translateX(5px)',
//                                 transition: 'transform 0.3s ease-in-out, background-color 0.3s ease-in-out',
//                             },
//                         }}
//                         onClick={() => setSelectedIndex(index)} // Set selected item
//                     >
//                         <ListItemIcon sx={{ color: selectedIndex === index ? '#007bff' : 'black' }}>
//                             {item.icon}
//                         </ListItemIcon>
//                         <ListItemText primary={item.label} />
//                     </ListItemButton>
//                 ))}
//             </List>

//             {/* Storage Section */}
//             <Box sx={{ paddingX: 1, width: '100%' }}>
//                 <Typography
//                     variant="body1"
//                     sx={{
//                         fontWeight: 'bold',
//                         fontSize: '1rem',
//                         color: 'black',
//                         marginBottom: 1,
//                         textAlign: 'center',
//                     }}
//                 >
//                     Storage
//                 </Typography>
//                 <LinearProgress
//                     variant="determinate"
//                     value={(1.25 / 15) * 100}
//                     sx={{
//                         height: 8,
//                         borderRadius: 5,
//                         backgroundColor: '#e0e0e0',
//                         '& .MuiLinearProgress-bar': {
//                             backgroundColor: '#007bff',
//                         },
//                     }}
//                 />
//                 <Typography
//                     variant="body2"
//                     sx={{
//                         marginTop: 1,
//                         fontSize: '0.875rem',
//                         color: 'black',
//                         textAlign: 'center',
//                     }}
//                 >
//                     1.25 GB of 15 GB used
//                 </Typography>
//                 <Button
//                     variant="contained"
//                     fullWidth
//                     sx={{
//                         marginTop: 1,
//                         textTransform: 'none',
//                         borderRadius: 2,
//                         fontSize: '0.875rem',
//                         backgroundColor: '#007bff',
//                         color: '#fff',
//                         '&:hover': {
//                             backgroundColor: '#0056b3', // Darker hover effect
//                         },
//                     }}
//                 >
//                     Get more storage
//                 </Button>
//             </Box>
//         </Box>
//     );
// };

// export default SidebarFirst;











import React, { useState } from 'react';
import { Box, Typography, List, ListItemButton, ListItemIcon, ListItemText, LinearProgress, Button } from '@mui/material';
import {
    Home as HomeIcon,
    DriveFolderUpload as DriveIcon,
    Laptop as ComputerIcon,
    Group as SharedIcon,
} from '@mui/icons-material';
import { useTheme } from '../../common/ThemeContext';
import { useNavigate } from 'react-router-dom';

const sidebarItems = [
    { label: 'Home', icon: <HomeIcon />, path: "/user-dashboard" },
    { label: 'Workspace', icon: <DriveIcon />, path: "/workspace" },
    { label: 'Users', icon: <ComputerIcon /> },
    { label: 'Files', icon: <SharedIcon /> },
];

const SidebarFirst = () => {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const { theme } = useTheme();
    const navigate = useNavigate();


    return (
        <Box
            sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: 220,
                height: '100vh',
                backgroundColor: '#ffffff',
                boxSizing: 'border-box',
                padding: '1.5rem',
                boxShadow: '2px 0 10px rgba(0, 0, 0, 0.1)',
                display: 'flex',
                flexDirection: 'column',
                border:
                    theme.palette.mode !== "dark" ? "1px solid #4c4452" : "none",
                alignItems: 'center',
                justifyContent: 'space-between',
            }}
        >
            {/* Sidebar List */}
            <List sx={{ width: '100%', mt: 9 }}>
                {sidebarItems.map((item, index) => (
                    <ListItemButton
                        key={index}
                        sx={{
                            width: '100%',
                            color: selectedIndex === index ? '#fe8a00' : 'black',
                            borderRadius: 2,
                            marginBottom: 1,
                            mt: 1,
                            '&:hover': {
                                backgroundColor: '#f1f1f1',
                                transform: 'translateX(5px)',
                                transition: 'transform 0.3s ease-in-out, background-color 0.3s ease-in-out',
                            },
                        }}
                        onClick={() => {
                            setSelectedIndex(index);
                            item.path && navigate(item.path);
                        }}
                    >
                        <ListItemIcon sx={{ color: selectedIndex === index ? '#fe8a00' : 'black' }}>
                            {item.icon}
                        </ListItemIcon>
                        <ListItemText primary={item.label} />
                    </ListItemButton>
                ))}
            </List>

            {/* Storage Section */}
            <Box sx={{ width: '100%' }}>
                <Typography
                    variant="body1"
                    sx={{
                        fontWeight: 'bold',
                        fontSize: '1rem',
                        color: 'black',
                        marginBottom: 1,
                        textAlign: 'center',
                    }}
                >
                    Storage
                </Typography>
                <LinearProgress
                    variant="determinate"
                    value={(1.25 / 15) * 100}
                    sx={{
                        height: 8,
                        borderRadius: 5,
                        backgroundColor: '#e0e0e0',
                        '& .MuiLinearProgress-bar': {
                            backgroundColor: '#1ac042',
                        },
                    }}
                />
                <Typography
                    variant="body2"
                    sx={{
                        marginTop: 1,
                        fontSize: '0.875rem',
                        color: 'black',
                        textAlign: 'center',
                    }}
                >
                    1.25 GB of 15 GB used
                </Typography>
                <Button
                    variant="contained"
                    fullWidth
                    sx={{
                        marginTop: 1,
                        textTransform: 'none',
                        borderRadius: 2,
                        fontSize: '0.875rem',
                        backgroundColor: '#fe8a00',
                        color: '#fff',
                        '&:hover': {
                            backgroundColor: '#fe8a00',
                        },
                    }}
                >
                    Get more storage
                </Button>
            </Box>
        </Box>
    );
};

export default SidebarFirst;

