import React, { useEffect, useState } from "react";
import {
    Box,
    Grid,
    Typography,
    Button,
    Card,
    CardContent,
    LinearProgress,
} from "@mui/material";
import Header from "../../../common/home/Header";
import Footer from "../../../common/Footer";
import { useNavigate } from "react-router-dom";

const pricingPlans = [
    {
        title: "Starter",
        price: "$29/mo",
        description:
            "Limited access to Site Explorer, Keywords Explorer, and Site Audit.",
        buttonText: "Get started",
        isNew: true,
        backgroundColor: "#002966",
        textColor: "#ffffff",
        buttonColor: "#FF9800",
        progress: 0,
    },
    {
        title: "Lite",
        price: "$129/mo",
        description: "Essential data for small businesses and hobby projects.",
        buttonText: "Get started",
        backgroundColor: "#ffffff",
        textColor: "#000000",
        buttonColor: "#FF9800",
        progress: 50,
        showCompare: true,
    },
    {
        title: "Standard",
        price: "$249/mo",
        description:
            "Perfect for freelance SEOs, marketing consultants and hobby projects.",
        buttonText: "Get started",
        backgroundColor: "#ffffff",
        textColor: "#000000",
        buttonColor: "#FF9800",
        progress: 75,
    },
    {
        title: "Advanced",
        price: "$449/mo",
        description:
            "More tools and data for lean in-house marketing teams and hobby projects.",
        buttonText: "Get started",
        backgroundColor: "#ffffff",
        textColor: "#000000",
        buttonColor: "#FF9800",
        progress: 85,
    },
    {
        title: "Enterprise",
        price: "$14,990/yr",
        description: "Ideal for agencies and enterprises and hobby projects.",
        buttonText: "Talk to us",
        backgroundColor: "#ffffff",
        textColor: "#000000",
        buttonColor: "#FF9800",
        progress: 95,
    },
];


const AnimatedText = ({ text }) => {
    const [displayedText, setDisplayedText] = useState("");

    useEffect(() => {
        let currentIndex = 0;
        const interval = setInterval(() => {
            setDisplayedText((prev) => prev + text[currentIndex]);
            currentIndex += 1;
            if (currentIndex >= text.length) {
                clearInterval(interval);
            }
        }, 100); 
        return () => clearInterval(interval);
    }, [text]);

    return (
        <Typography variant="h3" fontWeight="bold">
            <span style={{ color: "#ffffff" }}>{displayedText}</span>
        </Typography>
    );
};

const PricingCard = ({ plan }) => {
    return (
        <Card
            sx={{
                backgroundColor: plan.backgroundColor,
                color: plan.textColor,
                height: "300px",
                borderRadius: "0px",
                boxShadow: "0 4px 15px rgba(0, 0, 0, 0.3)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                position: "relative",

            }}
        >
            <CardContent>
                {plan.isNew && (
                    <Box
                        sx={{
                            position: "absolute",
                            ml: "10rem",
                            backgroundColor: "#FFC107",
                            padding: "3px 10px",
                            borderRadius: "5px",
                            fontSize: "12px",
                            fontWeight: "bold",
                            color: "#fff",
                        }}
                    >
                        New
                    </Box>
                )}
                <Typography variant="h6" fontWeight="bold" sx={{ mt: 2 }}>
                    {plan.title}
                </Typography>
                <Typography variant="h4" fontWeight="bold">
                    {plan.price}
                </Typography>
                <Box sx={{ paddingTop: "3px", paddingBottom: "10px" }}>
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: plan.buttonColor,
                            color: "#ffffff",
                            width: "70%",
                            padding: "10px",
                            borderRadius: "5px",
                            textTransform: "none",
                            textAlign: "left",
                            fontWeight: "bold",
                            ":hover": { backgroundColor: "#FFB74D" },
                        }}
                    >
                        {plan.buttonText}
                    </Button>
                </Box>
                <Typography
                    sx={{
                        fontSize: "16px",
                        lineHeight: "24px",
                        color: "#737373",
                        mb: "20px",
                    }}
                >
                    {plan.description}
                </Typography>

                <Box sx={{ mt: 2 }}>
                    <LinearProgress
                        variant="determinate"
                        value={plan.progress}
                        sx={{
                            backgroundColor: "#E0E0E0",
                            "& .MuiLinearProgress-bar": { backgroundColor: "#00C853" },
                        }}
                    />
                </Box>
            </CardContent>
        </Card>
    );
};

const PricingSection = () => {

    const navigate = useNavigate();

    return (
        <>
            <Header />
            <Box
                sx={{
                    background: "linear-gradient(135deg, #002966 30%, #005AE2 90%)",
                    padding: "50px 0",
                    color: "#ffffff",
                }}
            >
                <Box sx={{ mx: "auto", maxWidth: "1200px", px: 3, mb: 5 }}>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Typography variant="h3" fontWeight="bold">
                            <span style={{ color: "#ffffff" }}>Get Started:</span>{" "}
                            <span style={{ color: "#7F7F7F" }}>
                                Pick a plan that suits  your needs
                            </span>
                        </Typography>

                    </Grid>
                </Box>

                <Box sx={{ mx: "auto", maxWidth: "1200px", px: 3 }}>
                    <Grid container justifyContent="center" alignItems="stretch">
                        {pricingPlans.map((plan, index) => (
                            <Grid item xs={12} sm={6} md={2.4} key={index}>
                                <PricingCard plan={plan} />
                            </Grid>
                        ))}
                    </Grid>
                    {/* Compare Plans Button Outside Card Section */}
                    <Box sx={{ mt: 4, textAlign: "left" }}>
                        <Box
                            sx={{
            
                                borderRadius: "5px",
                                display: "inline-block",
                            }}
                        >
                            <Button
                                variant="text"
                                sx={{
                                    color: "#FFFFFF",
                                    textTransform: "none",
                                    fontSize: "14px",
                                    ":hover": { textDecoration: "none", backgroundColor: "#003B8C" },
                                    padding: "8px 16px",
                                }}
                                onClick={() => navigate("/Pricing-detail")}
                            >
                                Compare plans →
                            </Button>
                        </Box>

                    </Box>
                </Box>
            </Box>
            <Footer />
        </>
    );
};

export default PricingSection;