import React, { useEffect, useState } from 'react';
import { Container, TextField, Button, Typography, Box, Paper, Tabs, Tab, Grid } from '@mui/material';
import { styled } from '@mui/system';
import CryptoJS from 'crypto-js';
import Header from '../../../common/Header';
import Banner from './Banner';
import { AiOutlinePaperClip, AiOutlineCloudUpload } from 'react-icons/ai';
import Plagiarismcontent from './Plagiarismcontent'; 

const StyledContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',
  backgroundColor: '#ffffff', 
  padding: theme.spacing(4),
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  borderRadius: '8px',
  width: '100%',
  maxWidth: '500px',
  boxShadow: '0 4px 20px rgba(255, 72, 0, 0.1)', 
}));

const MD5Generator = () => {
  const [inputText, setInputText] = useState('');
  const [md5Hash, setMd5Hash] = useState('');
  const [encryptedText, setEncryptedText] = useState('');
  const [decryptedText, setDecryptedText] = useState('');
  const [activeTab, setActiveTab] = useState(0);

  const handleInputChange = (e) => {
    setInputText(e.target.value);
  };

  const handleGenerateMD5 = () => {
    if (inputText) {
      const hash = CryptoJS.MD5(inputText).toString();
      setMd5Hash(hash);
    } else {
      setMd5Hash('');
    }
  };

  const handleEncrypt = () => {
    const key = 'my-secret-key';
    const encrypted = CryptoJS.AES.encrypt(inputText, key).toString();
    setEncryptedText(encrypted);
    setDecryptedText('');
  };

  const handleDecrypt = () => {
    const key = 'my-secret-key';
    const bytes = CryptoJS.AES.decrypt(inputText, key);
    const originalText = bytes.toString(CryptoJS.enc.Utf8);
    setDecryptedText(originalText || 'Invalid decryption');
    setEncryptedText('');
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    
    setInputText('');
    setMd5Hash('');
    setEncryptedText('');
    setDecryptedText('');
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
    
        <Grid container spacing={2} justifyContent="center" p={4}>
        
         {/* md5 section */}
          <Grid item xs={12} md={9}>
         
              <Typography variant="h4" align="center" gutterBottom fontWeight={'bold'}>
                MD5 Generator
              </Typography>
              <Tabs value={activeTab} onChange={handleTabChange} variant="fullWidth">
                <Tab label="MD5 Hash" />
                <Tab label="Encrypt/Decrypt" />
              </Tabs>

              {activeTab === 0 && (
                <>
                  <TextField
                    label="Enter text to hash"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={4}
                    value={inputText}
                    onChange={handleInputChange}
                    sx={{ marginBottom: '20px', backgroundColor: '#f5f5f5', borderRadius: '10px' }}
                  />
                  <Box textAlign="center">
                    <Button
                      variant="contained"
                      onClick={handleGenerateMD5}
                      color="primary"
                      sx={{ padding: '10px 20px', backgroundColor: '#ff6f61', color: '#fff', '&:hover': { backgroundColor: '#ff8b7f' } }}
                    >
                      Generate MD5
                    </Button>
                  </Box>
                  {md5Hash && (
                    <Typography variant="h6" align="center" mt={4}>
                      MD5 Hash: <strong>{md5Hash}</strong>
                    </Typography>
                  )}
                </>
              )}

              {activeTab === 1 && (
                <>
                  <TextField
                    label="Enter text to encrypt or decrypt"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={4}
                    value={inputText}
                    onChange={handleInputChange}
                    sx={{ marginBottom: '20px', backgroundColor: '#f5f5f5', borderRadius: '10px' }}
                  />
                  <Box textAlign="center">
                    <Button
                      variant="contained"
                      onClick={handleEncrypt}
                      color="secondary"
                      sx={{ marginRight: '10px', padding: '10px 20px', backgroundColor: '#ff6f61', color: '#fff', '&:hover': { backgroundColor: '#ff8b7f' } }}
                    >
                      Encrypt
                    </Button>
                    <Button
                      variant="contained"
                      onClick={handleDecrypt}
                      color="secondary"
                      sx={{ padding: '10px 20px', backgroundColor: '#ff6f61', color: '#fff', '&:hover': { backgroundColor: '#ff8b7f' } }}
                    >
                      Decrypt
                    </Button>
                  </Box>
                  {encryptedText && (
                    <Typography variant="h6" align="center" mt={4}>
                      Encrypted Text: <strong>{encryptedText}</strong>
                    </Typography>
                  )}
                  {decryptedText && (
                    <Typography variant="h6" align="center" mt={4}>
                      Decrypted Text: <strong>{decryptedText}</strong>
                    </Typography>
                  )}
                </>
              )}
           
          </Grid>

          {/* Ad Section */}
          <Grid item xs={12} md={3} >
            <Paper elevation={6} sx={{ padding: '20px', textAlign: 'center', boxShadow: '0 4px 20px rgba(255, 72, 0, 0.1)' }}>
              <Typography variant="h6">Register To Get Your Pass</Typography>
              <Typography variant="body2" color="textSecondary">
                Global leaders: Tetra Pak, Givaudan, Apical, Cargill, Krones, IFFCO exhibit in 2024
              </Typography>
              <Button variant="contained" color="primary" sx={{ mt: 2 }}>
                Sign Up
              </Button>
            </Paper>
          </Grid>
        </Grid>
     
      <Plagiarismcontent />
    </>
  );
};

export default MD5Generator;
