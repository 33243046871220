// import React, { useState, useEffect } from "react";
// import { Modal, Box, Backdrop, Fade, CircularProgress, Typography, Grow, Divider, Accordion, AccordionSummary, AccordionDetails, TextField, IconButton } from "@mui/material";
// import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
// import SearchIcon from "@mui/icons-material/Search";
// import CloseIcon from "@mui/icons-material/Close";  // Import Close Icon
// import google from "../../../assets/celebrating-lake-xochimilco-6753651837110104.2-s.png";
// import chatgpt from "../../../assets/chatgpt.png";

// const GoogleStyledPopUp = ({ open, handleClose }) => {
//     const [searchQuery, setSearchQuery] = useState("");
//     const [animationComplete, setAnimationComplete] = useState(false);
//     const [showContent, setShowContent] = useState(false);

//     const initialSearch = "getmycollege";

//     const data = {
//         googleResult: {
//             results: "96,60,000",
//             timeTaken: "0.41",
//         },
//         googleOrganic: [

//             {
//                 position: 1,
//                 siteTitle: "Justdial",
//                 title: "Popular House Painters in Btm Layout 1St Stage, Bangalore",
//                 link: "https://www.justdial.com/Bangalore/House-Painters-in-Btm-Layout-1St-Stage/nct-11017366",
//                 displayedLink: "https://www.justdial.com",
//                 snippet: "Top 5 House Painters in Btm Layout 1st Stage · Kansai Nerolac Paints Limited · Nippon Paint (India) Pvt Ltd · Ajit Painting Contractor · Mokshita Painting Services.",
//             },
//             {
//                 position: 1,
//                 siteTitle: "Justdial",
//                 title: "Popular House Painters in Btm Layout 1St Stage, Bangalore",
//                 link: "https://www.justdial.com/Bangalore/House-Painters-in-Btm-Layout-1St-Stage/nct-11017366",
//                 displayedLink: "https://www.justdial.com",
//                 snippet: "Top 5 House Painters in Btm Layout 1st Stage · Kansai Nerolac Paints Limited · Nippon Paint (India) Pvt Ltd · Ajit Painting Contractor · Mokshita Painting Services.",
//             },
//             {
//                 position: 1,
//                 siteTitle: "Justdial",
//                 title: "Popular House Painters in Btm Layout 1St Stage, Bangalore",
//                 link: "https://www.justdial.com/Bangalore/House-Painters-in-Btm-Layout-1St-Stage/nct-11017366",
//                 displayedLink: "https://www.justdial.com",
//                 snippet: "Top 5 House Painters in Btm Layout 1st Stage · Kansai Nerolac Paints Limited · Nippon Paint (India) Pvt Ltd · Ajit Painting Contractor · Mokshita Painting Services.",
//             },
//             {
//                 position: 1,
//                 siteTitle: "Justdial",
//                 title: "Popular House Painters in Btm Layout 1St Stage, Bangalore",
//                 link: "https://www.justdial.com/Bangalore/House-Painters-in-Btm-Layout-1St-Stage/nct-11017366",
//                 displayedLink: "https://www.justdial.com",
//                 snippet: "Top 5 House Painters in Btm Layout 1st Stage · Kansai Nerolac Paints Limited · Nippon Paint (India) Pvt Ltd · Ajit Painting Contractor · Mokshita Painting Services.",
//             },
//             {
//                 position: 1,
//                 siteTitle: "Justdial",
//                 title: "Popular House Painters in Btm Layout 1St Stage, Bangalore",
//                 link: "https://www.justdial.com/Bangalore/House-Painters-in-Btm-Layout-1St-Stage/nct-11017366",
//                 displayedLink: "https://www.justdial.com",
//                 snippet: "Top 5 House Painters in Btm Layout 1st Stage · Kansai Nerolac Paints Limited · Nippon Paint (India) Pvt Ltd · Ajit Painting Contractor · Mokshita Painting Services.",
//             },
//             {
//                 position: 1,
//                 siteTitle: "Justdial",
//                 title: "Popular House Painters in Btm Layout 1St Stage, Bangalore",
//                 link: "https://www.justdial.com/Bangalore/House-Painters-in-Btm-Layout-1St-Stage/nct-11017366",
//                 displayedLink: "https://www.justdial.com",
//                 snippet: "Top 5 House Painters in Btm Layout 1st Stage · Kansai Nerolac Paints Limited · Nippon Paint (India) Pvt Ltd · Ajit Painting Contractor · Mokshita Painting Services.",
//             },

//         ],
//         googleRelated: ["Book painter online", "Asian Paints painting"],
//     };

//     useEffect(() => {
//         if (open) {
//             setSearchQuery("");
//             setAnimationComplete(false);
//             setShowContent(false);

//             let i = 0;
//             const interval = setInterval(() => {
//                 if (i < initialSearch.length) {
//                     setSearchQuery((prev) => prev + initialSearch[i]);
//                     i++;
//                 } else {
//                     clearInterval(interval);
//                     setTimeout(() => {
//                         setAnimationComplete(true);
//                         setTimeout(() => setShowContent(true), 500);
//                     }, 1000);
//                 }
//             }, 150);
//         }
//     }, [open]);

//     const modalStyle = {
//         position: "absolute",
//         top: "50%",
//         left: "50%",
//         transform: "translate(-50%, -50%)",
//         width: "80%",
//         maxHeight: "90%",
//         bgcolor: "#ffffff",
//         boxShadow: 24,
//         overflowY: "auto",
//         borderRadius: "8px",
//         p: 4,
//     };

//     const linkColor = "#1a0dab";
//     const textColor = "#4d5156";

//     const fadeInAnimation = {
//         "@keyframes fadeIn": {
//             from: { opacity: 0, transform: "translateY(-20px)" },
//             to: { opacity: 1, transform: "translateY(0)" },
//         },
//         animation: "fadeIn 1s ease-out",
//     };

//     return (
//         <Modal
//             open={open}
//             onClose={handleClose}
//             closeAfterTransition
//             BackdropComponent={Backdrop}
//             BackdropProps={{ timeout: 500 }}
//         >
//             <Fade in={open}>
//                 <Box sx={modalStyle}>
//                     {/* Close Button */}
//                     <IconButton
//                         onClick={handleClose}
//                         sx={{
//                             position: "absolute",
//                             top: 10,
//                             right: 10,
//                             color: "#000",
//                         }}
//                     >
//                         <CloseIcon />
//                     </IconButton>

//                     <Box sx={{ display: "flex", alignItems: "center", mb: 4 }}>
//                         <Box component="img" src={google} alt="Google Logo" sx={{ width: 90, height: 30, mr: 2 }} />
//                         <TextField

//                             value={searchQuery}
//                             variant="outlined"
//                             sx={{
//                                 width: '50%',
//                                 "& .MuiOutlinedInput-root": {
//                                     borderRadius: 20,
//                                     paddingLeft: "10px",
//                                 },
//                             }}
//                             InputProps={{ readOnly: true }}
//                         />
//                         <IconButton color="primary" sx={{ ml: 2 }}>
//                             <SearchIcon />
//                         </IconButton>
//                     </Box>

//                     {!animationComplete && (
//                         <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
//                             <CircularProgress />
//                         </Box>
//                     )}

//                     {animationComplete && showContent && (
//                         <Box sx={{ ...fadeInAnimation }}>

//                             <Typography gutterBottom>
//                                 Results for <strong> Bengaluru,Karnataka 570008</strong>
//                             </Typography>
//                             <Typography variant="body2" sx={{ mb: 1, color: "#70757a", fontSize: "14px" }}>
//                                 About {data.googleResult.results} results ({data.googleResult.timeTaken} seconds)
//                             </Typography>

//                             {data.googleOrganic.map((item, index) => (
//                                 <Grow
//                                     key={index}
//                                     in={showContent}
//                                     style={{ transformOrigin: "top center" }}
//                                     timeout={500}
//                                 >
//                                     <Box sx={{ mb: 3 }}>
//                                         {/* Site Title, Displayed Link, and Icon */}
//                                         <Box sx={{ display: "flex", alignItems: "flex-start", mb: 1 }}>
//                                             {/* Left Icon */}
//                                             <Box
//                                                 component="img"
//                                                 src={chatgpt} // Replace with actual logo
//                                                 alt="Site Logo"
//                                                 sx={{
//                                                     width: 30,
//                                                     height: 30,
//                                                     borderRadius: "50%",
//                                                     mr: 2,
//                                                     mt: 0.5, // Align icon with text
//                                                 }}
//                                             />

//                                             {/* Text Section */}
//                                             <Box>
//                                                 {/* Site Title */}
//                                                 <Typography
//                                                     variant="body2"
//                                                     sx={{
//                                                         color: "#70757a",
//                                                         fontSize: "14px",
//                                                         lineHeight: 1.5,
//                                                     }}
//                                                 >
//                                                     {item.siteTitle}
//                                                 </Typography>

//                                                 {/* Displayed Link */}
//                                                 <Typography
//                                                     variant="body2"
//                                                     sx={{
//                                                         color: "#202124",
//                                                         fontSize: "14px",
//                                                         lineHeight: 1.5,
//                                                     }}
//                                                 >
//                                                     {item.displayedLink}
//                                                 </Typography>
//                                             </Box>
//                                         </Box>

//                                         {/* Title */}
//                                         <Typography
//                                             variant="h6"
//                                             sx={{
//                                                 color: "#1a0dab",
//                                                 fontWeight: "400",
//                                                 fontSize: "18px",
//                                                 cursor: "pointer",
//                                                 textDecoration: "none",
//                                                 '&:hover': { textDecoration: "underline" },
//                                             }}
//                                             component="a"
//                                             href={item.link}
//                                             target="_blank"
//                                             rel="noopener noreferrer"
//                                         >
//                                             {item.title}
//                                         </Typography>

//                                         {/* Snippet */}
//                                         <Typography
//                                             variant="body2"
//                                             sx={{
//                                                 color: "#4d5156",
//                                                 fontSize: "14px",
//                                             }}
//                                         >
//                                             {item.snippet}
//                                         </Typography>

//                                         <Divider sx={{ mt: 2 }} />
//                                     </Box>
//                                 </Grow>
//                             ))}


//                             <Box sx={{ mt: 4 }}>
//                                 <Typography variant="h6" sx={{ mb: 1, fontWeight: "bold" }}>
//                                     People also ask
//                                 </Typography>
//                                 {data.googleRelated.map((question, index) => (
//                                     <Accordion key={index}>
//                                         <AccordionSummary expandIcon={<ArrowDownwardIcon />}>
//                                             <Typography variant="body2">{question}</Typography>
//                                         </AccordionSummary>
//                                         <AccordionDetails>
//                                             <Typography variant="body2">Related information for: {question}</Typography>
//                                         </AccordionDetails>
//                                     </Accordion>
//                                 ))}
//                             </Box>
//                         </Box>
//                     )}
//                 </Box>
//             </Fade>
//         </Modal>
//     );
// };

// export default GoogleStyledPopUp;









import React from "react";
import { Modal, Box, Backdrop, Fade, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const GoogleStyledPopUp = ({ open, handleClose, url }) => {
    const modalStyle = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "90%",
        height: "90%",
        bgcolor: "#ffffff",
        boxShadow: 24,
        borderRadius: "8px",
        overflow: "hidden",
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{ timeout: 500 }}
        >
            <Fade in={open}>
                <Box sx={modalStyle}>
                    {/* Close Button */}
                    <IconButton
                        sx={{ position: "absolute", top: 8, right: 8, zIndex: 1 }}
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </IconButton>

                    {/* Embed External Page */}
                    <iframe
                        src={url}
                        title="Embedded Page"
                        style={{
                            width: "100%",
                            height: "100%",
                            border: "none",
                        }}
                    />
                </Box>
            </Fade>
        </Modal>
    );
};

export default GoogleStyledPopUp;





// import React, { useRef, useEffect } from "react";
// import { Modal, Box, Backdrop, Fade, IconButton } from "@mui/material";
// import CloseIcon from "@mui/icons-material/Close";

// const GoogleStyledPopUp = ({ open, handleClose }) => {
//     const iframeRef = useRef(null);

//     // Disable interactions in iframe
//     const disableIframeInteractions = () => {
//         try {
//             const iframeDocument = iframeRef.current?.contentDocument || iframeRef.current?.contentWindow?.document;

//             if (iframeDocument) {
//                 // Disable all anchor links
//                 iframeDocument.querySelectorAll("a").forEach((link) => {
//                     link.addEventListener("click", (e) => e.preventDefault());
//                     link.style.pointerEvents = "none";
//                 });

//                 // Disable all buttons
//                 iframeDocument.querySelectorAll("button").forEach((button) => {
//                     button.addEventListener("click", (e) => e.preventDefault());
//                     button.style.pointerEvents = "none";
//                 });

//                 // Disable inputs, text areas, and selects
//                 iframeDocument.querySelectorAll("input, textarea, select").forEach((input) => {
//                     input.disabled = true;
//                     input.style.pointerEvents = "none";
//                 });

//                 // Disable other interactive elements (e.g., SVG, clickable divs)
//                 iframeDocument.querySelectorAll("*").forEach((el) => {
//                     if (["pointer", "click"].some((event) => getComputedStyle(el).cursor.includes(event))) {
//                         el.style.pointerEvents = "none";
//                     }
//                 });
//             }
//         } catch (error) {
//             console.warn("Unable to modify iframe content due to cross-origin restrictions.", error);
//         }
//     };

//     // Add event listener on iframe load
//     useEffect(() => {
//         if (open && iframeRef.current) {
//             const iframe = iframeRef.current;

//             const handleIframeLoad = () => {
//                 disableIframeInteractions();
//             };

//             iframe.addEventListener("load", handleIframeLoad);

//             // Cleanup event listener
//             return () => iframe.removeEventListener("load", handleIframeLoad);
//         }
//     }, [open]);

//     const modalStyle = {
//         position: "absolute",
//         top: "50%",
//         left: "50%",
//         transform: "translate(-50%, -50%)",
//         width: "90%",
//         height: "90%",
//         bgcolor: "#ffffff",
//         boxShadow: 24,
//         borderRadius: "8px",
//         overflow: "hidden",
//     };

//     const iframeStyle = {
//         width: "100%",
//         height: "100%",
//         border: "none",
//     };

//     return (
//         <Modal
//             open={open}
//             onClose={handleClose}
//             closeAfterTransition
//             BackdropComponent={Backdrop}
//             BackdropProps={{ timeout: 500 }}
//         >
//             <Fade in={open}>
//                 <Box sx={modalStyle}>
//                     {/* Close Button */}
//                     <IconButton
//                         sx={{ position: "absolute", top: 8, right: 8, zIndex: 3 }}
//                         onClick={handleClose}
//                     >
//                         <CloseIcon />
//                     </IconButton>

//                     {/* Iframe displaying the webpage */}
//                     <iframe
//                         ref={iframeRef}
//                         src="https://image-upload.seomitra.com/new-uploads/workspace-files/157223074.html"
//                         title="Web Page Content"
//                         style={iframeStyle}
//                         scrolling="yes" // Enable scrolling within iframe
//                     />
//                 </Box>
//             </Fade>
//         </Modal>
//     );
// };

// export default GoogleStyledPopUp;










// import React from "react";
// import { Modal, Box, Backdrop, Fade, IconButton } from "@mui/material";
// import CloseIcon from "@mui/icons-material/Close";

// const GoogleStyledPopUp = ({ open, handleClose,url }) => {
//     const modalStyle = {
//         position: "absolute",
//         top: "50%",
//         left: "50%",
//         transform: "translate(-50%, -50%)",
//         width: "90%",
//         height: "90%",
//         bgcolor: "#ffffff",
//         boxShadow: 24,
//         borderRadius: "8px",
//         overflow: "hidden",
//     };

//     const iframeStyle = {
//         width: "100%",
//         height: "100%",
//         border: "none",
//     };

//     const overlayStyle = {
//         position: "absolute",
//         top: 0,
//         left: 0,
//         width: "100%",
//         height: "100%",
//         zIndex: 2, // Ensure the overlay is above the iframe
//         pointerEvents: "none", // Allow scrolling but block all other interactions
//     };

//     return (
//         <Modal
//             open={open}
//             onClose={handleClose}
//             closeAfterTransition
//             BackdropComponent={Backdrop}
//             BackdropProps={{ timeout: 500 }}
//         >
//             <Fade in={open}>
//                 <Box sx={modalStyle}>
//                     {/* Close Button */}
//                     <IconButton
//                         sx={{ position: "absolute", top: 8, right: 8, zIndex: 3 }}
//                         onClick={handleClose}
//                     >
//                         <CloseIcon />
//                     </IconButton>

//                     {/* Iframe displaying the webpage */}
//                     <iframe
//                         src={url}
//                         title="Web Page Content"
//                         style={iframeStyle}
//                         scrolling="yes" // Enable scrolling within iframe
//                     />

//                     {/* Transparent overlay */}
//                     <div
//                         style={{
//                             position: "absolute",
//                             top: 0,
//                             left: 0,
//                             width: "100%",
//                             height: "100%",
//                             backgroundColor: "transparent",
//                             zIndex: 2, // Ensure the overlay is above the iframe
//                         }}
//                         // Prevent interactions by capturing clicks and keyboard events
//                         onClick={(e) => e.stopPropagation()}
//                         onKeyDown={(e) => e.preventDefault()}
//                     ></div>
//                 </Box>
//             </Fade>
//         </Modal>
//     );
// };

// export default GoogleStyledPopUp;
