import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  IconButton,
  Typography,
  TableSortLabel,
  Paper,
  Grid,
  Link,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import SettingsIcon from "@mui/icons-material/Settings";
import StarIcon from "@mui/icons-material/Star";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import { useTheme } from "../../common/ThemeContext";
import { format, subDays } from "date-fns";
import GoogleStyledPopUp from './GoogleStyledPopUp';
import { apiList, invokeApi } from '../../../services/apiServices';
import { config } from '../../../config/config';
import { useCookies } from 'react-cookie';
import { useParams } from 'react-router-dom';


const KeywordsTable = () => {
  const { theme } = useTheme();
  const [openModal, setOpenModal] = useState(false);
  // const [selectedDateContent, setSelectedDateContent] = useState(null);
  const [keywordsData, setKeywordsData] = useState([]);
  const [htmlPath, setHtmlPath] = useState("");
  const [cookies] = useCookies();
  const { id } = useParams();

  const handleDateClick = (id,date) => {
    // setSelectedDateContent(content); // Set the content for the selected date
    fetchHTMLFileData(id,date);
    // setOpenModal(true); // Open the popup
  };

  const handleClosePopup = () => {
    setOpenModal(false); // Close the popup
    // setSelectedDateContent(null); // Reset the content
  };


  const dates = Array.from({ length: 5 }, (_, i) =>
    subDays(new Date(), i).toISOString()
  );

  const formatDate = (dateString) => {
    if (!dateString) return "";

    const date = new Date(dateString);

    const dateOptions = {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
    };

    const formattedDate = date.toLocaleDateString("en-US", dateOptions);

    return `${formattedDate}`;
  };

  const formatTime = (dateString) => {
    if (!dateString) return "";

    const date = new Date(dateString);
    const timeOptions = {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    const formattedTime = date.toLocaleTimeString("en-US", timeOptions);

    return `${formattedTime}`;
  };

  const fetchKeywordData = async () => {
    try {
      const params = {
        domainId: id,
      };
      const response = await invokeApi(
        config.getMyCollege + apiList.getAllKeywordTracking,
        params,
        cookies
      );
      if (response?.status === 200) {
        setKeywordsData(response.data.keywords);
      } else {
        console.error("Failed to get projects:", response);
      }
    } catch (error) {
      console.error("Error get projects:", error);
    }
  };

  const fetchHTMLFileData = async (id, date) => {
    try {
      const params = {
        keywordId: id,
        createdDate: date

      };
      const response = await invokeApi(
        config.getMyCollege + apiList.getHtmlFile,
        params,
        cookies
      );
      if (response?.status === 200) {
        setHtmlPath(response.data.htmlFiles[0].path);
        setOpenModal(true); // Open the popup
      } else {
        console.error("Failed to get projects:", response);
      }
    } catch (error) {
      console.error("Error get projects:", error);
    }
  };

  useEffect(() => {
    fetchKeywordData();
  }, [])

  // Find the object with the maximum length of the position array
  const keywordPositionsLength = keywordsData.reduce((maxObj, currentObj) => {
    // Compare the length of the position array
    return currentObj.positions.length > maxObj.positions.length ? currentObj : maxObj;
  }, keywordsData[0]); // Initialize with the first object

  console.log("djfsdkflklkl", keywordPositionsLength); // Outputs the object with the largest position array
  // Safely render table
  if (keywordsData.length === 0) {
    return <Typography>Loading keywords...</Typography>; // Placeholder while data loads
  }

  return (
    <Paper
      elevation={0}
      sx={{
        backgroundColor: theme.palette.mode !== "dark" ? "#2a252f" : "#fbf9fb",
        color: "#fff",
        padding: 2,
        border:
          theme.palette.mode !== "dark"
            ? "1px solid #4a4a4a"
            : "1px solid #f2ebf0",
        my: 2,
        borderRadius: 3,
      }}
    >
      <Grid sx={{ display: "flex", justifyContent: "flex-end", gap: "5px" }}>
        {/* Keywords Text on the left */}
        <Typography
          variant="body1"
          sx={{
            backgroundColor:
              theme.palette.mode !== "dark" ? "#2a252f" : "#ffffff",
            color: "#bbb6bf",
            marginRight: "auto",
            border:
              theme.palette.mode !== "dark"
                ? "1px solid #4a4a4a"
                : "1px solid #f2ebf0",
            px: 2,
            py: "5px",
            borderRadius: 2,
            fontWeight: "bold",
          }}
        >
          {keywordsData.length} Keywords
        </Typography>

        <IconButton
          sx={{
            color: "#bbb6bf",
            backgroundColor:
              theme.palette.mode !== "dark" ? "#2a252f" : "#ffffff",
            borderRadius: "12px",
            p: "8px",
            border:
              theme.palette.mode !== "dark"
                ? "1px solid #4a4a4a"
                : "1px solid #f2ebf0",
            "&:hover": {
              backgroundColor: "#ae89ff",
              color: "#fff",
            },
          }}
        >
          <DownloadIcon />
        </IconButton>
        <IconButton
          sx={{
            color: "#bbb6bf",
            backgroundColor:
              theme.palette.mode !== "dark" ? "#2a252f" : "#ffffff",
            borderRadius: "12px",
            p: "8px",
            border:
              theme.palette.mode !== "dark"
                ? "1px solid #4a4a4a"
                : "1px solid #f2ebf0",
            "&:hover": {
              backgroundColor: "#ae89ff",
              color: "#fff",
            },
          }}
        >
          <SettingsIcon />
        </IconButton>
      </Grid>

      {/* Table with horizontal scroll */}
      <TableContainer sx={{ mt: 1, overflowX: "auto", maxWidth: "100%" }}>
        <Table
          stickyHeader
          sx={{
            border:
              theme.palette.mode !== "dark"
                ? "1px solid #4a4a4a"
                : "1px solid #f2ebf0",
          }}
        >
          <TableHead>
            <TableRow>
              {/* Keyword with Sticky Position */}
              <TableCell
                sx={{
                  backgroundColor:
                    theme.palette.mode !== "dark" ? "#2a252f" : "#ffffff",
                  color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000",
                  position: "sticky",
                  left: 0,
                  zIndex: 2,
                  whiteSpace: "nowrap",
                  border:
                    theme.palette.mode !== "dark"
                      ? "1px solid #4a4a4a"
                      : "1px solid #f2ebf0",
                }}
              >
                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                  Keyword
                </Typography>
              </TableCell>

              {/* Ranking Page */}
              <TableCell
                sx={{
                  backgroundColor:
                    theme.palette.mode !== "dark" ? "#2a252f" : "#ffffff",
                  color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000",
                  position: "sticky",
                  left: 0,
                  zIndex: 2,
                  whiteSpace: "nowrap",
                  border:
                    theme.palette.mode !== "dark"
                      ? "1px solid #4a4a4a"
                      : "1px solid #f2ebf0",
                }}
              >
                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                  Ranking Page
                </Typography>
              </TableCell>

              {/* Features */}
              <TableCell
                sx={{
                  backgroundColor:
                    theme.palette.mode !== "dark" ? "#2a252f" : "#ffffff",
                  color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000",
                  border:
                    theme.palette.mode !== "dark"
                      ? "1px solid #4a4a4a"
                      : "1px solid #f2ebf0",
                  whiteSpace: "nowrap",
                }}
              >
                <TableSortLabel
                  sx={{
                    color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000",
                    fontWeight: "bold",
                  }}
                >
                  Best Position
                </TableSortLabel>
              </TableCell>

              {/* Dynamic Date Columns */}
              {keywordPositionsLength.positions.map((date, index) => (
                <TableCell
                  key={index}
                  sx={{
                    color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000",
                    border:
                      theme.palette.mode !== "dark"
                        ? "1px solid #4a4a4a"
                        : "1px solid #f2ebf0",
                    whiteSpace: "nowrap",
                    backgroundColor:
                      theme.palette.mode !== "dark" ? "#2a252f" : "#ffffff",
                    textAlign: "center",
                  }}
                >
                  <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                    {formatDate(date.createdDate)}
                  </Typography>
                  <Typography variant="body2" sx={{ fontSize: "12px" }}>
                    {formatTime(date.createdDate)}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {keywordsData.map((row, index) => (
              <TableRow key={index}>
                {/* Keyword with Sticky Column */}
                <TableCell
                  sx={{
                    backgroundColor: theme.palette.mode !== 'dark' ? '#2a252f' : '#ffffff',
                    color: theme.palette.mode !== 'dark' ? '#e3e0e6' : '#000',
                    position: 'sticky',
                    left: 0,
                    zIndex: 1,
                    border: theme.palette.mode !== 'dark' ? '1px solid #4a4a4a' : '1px solid #f2ebf0',
                  }}
                >
                  <Grid container alignItems="center" spacing={1}>
                    {/* <Grid item>
                      <StarIcon sx={{ color: '#ff8f3b', fontSize: '18px' }} />
                    </Grid> */}
                    <Grid item>{row.keyword}</Grid>
                  </Grid>
                </TableCell>

                {/* Ranking Page */}
                { }
                <TableCell
                  sx={{
                    backgroundColor: theme.palette.mode !== 'dark' ? '#2a252f' : '#ffffff',
                    color: theme.palette.mode !== 'dark' ? '#e3e0e6' : '#000',
                    border: theme.palette.mode !== 'dark' ? '1px solid #4a4a4a' : '1px solid #f2ebf0',
                  }}
                >
                  {
                    row.page === null ? "-" : (
                      <Link
                        href={row.page}
                        target="_blank"
                        rel="noopener"
                        sx={{
                          color: theme.palette.mode !== 'dark' ? '#e3e0e6' : '#000',
                        }}
                      >
                        {row.page.trim().substring(0, 24)}....
                      </Link>
                    )
                  }

                </TableCell>

                {/* Best Position */}
                <TableCell
                  sx={{
                    backgroundColor: theme.palette.mode !== 'dark' ? '#2a252f' : '#ffffff',
                    color: theme.palette.mode !== 'dark' ? '#e3e0e6' : '#000',
                    border: theme.palette.mode !== 'dark' ? '1px solid #4a4a4a' : '1px solid #f2ebf0',
                  }}
                >
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item>
                      <EmojiEventsIcon sx={{ color: '#ff8f3b', fontSize: '18px' }} />
                    </Grid>
                    <Grid item>{row.bestPosition}</Grid>
                  </Grid>
                </TableCell>

                {/* Dynamic Date Values */}
                {row.positions.length < 1 ?
                  (keywordPositionsLength.positions.map((index) => (
                    <TableCell
                      key={index}
                      sx={{
                        backgroundColor: theme.palette.mode !== 'dark' ? '#2a252f' : '#ffffff',
                        color: theme.palette.mode !== 'dark' ? '#e3e0e6' : '#000',
                        border: theme.palette.mode !== 'dark' ? '1px solid #4a4a4a' : '1px solid #f2ebf0',
                        textAlign: 'center',
                        cursor: 'pointer',
                      }}
                    >
                      -
                    </TableCell>
                  )))
                  : row.positions.map((dateKey, index) => (
                    <TableCell
                      key={index}
                      sx={{
                        backgroundColor: theme.palette.mode !== 'dark' ? '#2a252f' : '#ffffff',
                        color: theme.palette.mode !== 'dark' ? '#e3e0e6' : '#000',
                        border: theme.palette.mode !== 'dark' ? '1px solid #4a4a4a' : '1px solid #f2ebf0',
                        textAlign: 'center',
                        cursor: 'pointer',
                      }}
                      onClick={() =>
                        handleDateClick(row.id,dateKey.createdDate)
                      }
                    >
                      {dateKey.position}
                    </TableCell>
                  ))}
              </TableRow>
            ))}

          </TableBody>

          {/* Date Popup */}
          <GoogleStyledPopUp open={openModal} handleClose={() => {setOpenModal(false); setHtmlPath("")}} url={htmlPath} />
        </Table>
      </TableContainer>

    </Paper>
  );
};

export default KeywordsTable;

