import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Grid,
  InputLabel,
  Container,
  Snackbar,
  Alert,
  CircularProgress,
  Typography,
  Pagination,
  PaginationItem,
  MenuItem,
  Select,
  InputAdornment,
  useMediaQuery,
  Autocomplete,
} from "@mui/material";
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from "@mui/icons-material";
import { useCookies } from "react-cookie";
import { useLocation } from "react-router-dom";
import { config } from "../../../../../config/config";
import { apiList, invokeApi } from "../../../../../services/apiServices";
import Header from "../../../../common/Header";
import FirstPageRoundedIcon from "@mui/icons-material/FirstPageRounded";
import LastPageRoundedIcon from "@mui/icons-material/LastPageRounded";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import SearchIcon from "@mui/icons-material/Search";
import { DropzoneArea } from "mui-file-dropzone";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Dashboardheader from "../../DashboardMainPages/dashboarddetails/Dashboardheader";
import Dashboardsideicon from "../../DashboardMainPages/dashboarddetails/Dashboardsideicon";
import { ArrowDropDownIcon } from "@mui/x-date-pickers-pro";

function Packeges() {
  const [cookies] = useCookies();

  const location = useLocation();
  const isUsersPage = location.pathname === "/packages";

  const [page, setPage] = useState(0);
  const isMobileScreen = useMediaQuery(
    "(min-width:360px) and (max-width:500px)"
  );
  const isSmallScreen = useMediaQuery(
    "(min-width:1024px) and (max-width:1440px)"
  );
  const [rowsPerPage, setRowsPerPage] = useState(
    isMobileScreen ? 5 : isSmallScreen ? 5 : 10
  );
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogEdit, setOpenDialogEdit] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [rows, setRows] = useState([]);
  const [packageName, setpackageName] = useState("");
  const [price, setPrice] = useState(null);
  const [priceType, setPriceType] = useState("Monthly");
  const [keywords, setKeywords] = useState("");
  const [features, setFeatures] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [loading, setLoading] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [showSideNav, setShowSideNav] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [amenityImage, setAmenityImage] = useState(null);
  const [amenityLogoChanged, setAmenityLogoChanged] = useState(false);
  const [selectedAmenityLogo, setSelectedAmenityLogo] = useState(
    rows?.imageUrl
  );
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value.trimStart());
  };

  console.log("hgsdsdkklskdlskdlskd====>", rows);
  const filteredRows = rows.filter((row) => {
    const { name } = row;
    const lowerCaseQuery = searchQuery.toLowerCase();
    return name && name.toLowerCase().includes(lowerCaseQuery);
  });

  const toggleSideNav = () => {
    setShowSideNav(!showSideNav);
  };
  useEffect(() => {
    setLoading(false);
    fetchTableData();
  }, []);

  const handleAmenityLogo = (files) => {
    if (files && files.length > 0) {
      setAmenityLogoChanged(true);
      const selectedCollegeFile = files[0];
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedAmenityLogo(reader.result);
        setAmenityImage(selectedCollegeFile);
      };
      reader.readAsDataURL(selectedCollegeFile);
    } else {
      setSelectedAmenityLogo("");
      setAmenityImage(null);
      setAmenityLogoChanged(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await handleAddPackages();
      showSnackbar("User Added successfully", "success");
      setOpenDialog(false);
      setpackageName("");
      setPrice(null);
      setKeywords("");
      setPriceType("");
      setFeatures("");
    } catch (error) {
      console.error("Error while adding user:", error);
      showSnackbar(error.message, "error");
    }
  };

  const handleFileChange = (files) => {
    if (files && files.length > 0) {
      const selectedFile = files[0];
      setAmenityImage(selectedFile);
    } else {
      setAmenityImage(null);
    }
  };
  const handleAmenityImage = async () => {
    const formData = new FormData();
    formData.append("image", amenityImage);
    try {
      const response = await fetch(
        "https://image-upload.getmycollege.com/addFacilityImage",
        {
          method: "POST",
          body: formData,
        }
      );

      if (response?.status >= 200 && response?.status < 300) {
        const responseData = await response.json();

        return responseData.imageUrl || "";
      } else {
        showSnackbar("Failed to add college logo", "error");
      }
    } catch (error) {
      showSnackbar("Something went wrong. Please try again later.", "error");
    }
  };
  const fetchTableData = async () => {
    const params = {};
    try {
      const response = await invokeApi(
        config.getMyCollege + apiList.getPackages,
        params,
        cookies
      );
      console.log(params);
      if (response?.status === 200) {
        setRows(response.data.packages);
        setLoading(false);
        console.log(response.data);
      } else {
        console.error("Failed to fetch data:", response);
      }
    } catch (error) {
      console.error("Error during data fetch:", error);
    }
  };

  const handleViewEdit = async (id) => {
    const params = {
      id: id,
    };
    try {
      const response = await invokeApi(
        config.getMyCollege + apiList.getPackage,
        params,
        cookies
      );

      if (response?.status === 200) {
        const userData = response.data.packages;
        setSelectedRow({
          id: userData.id,
          name: userData.name,
          features: userData.features,
          keywords: userData.keywords,
        });
        setOpenDialogEdit(true);
      } else {
        showSnackbar("Something went wrong. Please try again later!!");
      }
    } catch (error) {
      console.error("Error during data fetch:", error);
      showSnackbar("Something went wrong. Please try again later!!");
    }
  };

  const handleSaveChanges = async () => {
    try {
      const params = {
        id: selectedRow.id,
        name: selectedRow.name,
        features: selectedRow.features,
        keywords: selectedRow.keywords,
      };
      const response = await invokeApi(
        config.getMyCollege + apiList.updatePackage,
        params,
        cookies
      );
      if (response?.status === 200) {
        setOpenDialogEdit(false);
        fetchTableData();
        showSnackbar("User updated successfully");
      } else if (response?.status === 400) {
        showSnackbar("Failed to update. Please try again later!!");
      }
    } catch (error) {
      showSnackbar("Failed to update. Please try again later!!");
    }
  };

  const handlePackageDelete = async () => {
    try {
      const response = await invokeApi(
        config.getMyCollege + apiList.deletePackage,
        { id: deleteItemId },
        cookies
      );
      if (response?.status >= 200 && response?.status < 300) {
        if (response.data.responseCode === "200") {
          showSnackbar("Package deleted successfully");
          setOpenDeleteDialog(false);
          setRows(rows.filter((row) => row.id !== deleteItemId));
          fetchTableData();
        } else if (response.data.responseCode === "400") {
          showSnackbar("Failed to delete. Please try again later!!");
        }
      }
    } catch (error) {
      showSnackbar("Failed to delete. Please try again later!!");
    }
  };

  const handleAddPackages = async () => {
    const params = {
      name: packageName,
      price: price,
      priceType: priceType,
      keywords: keywords,
      features: features,
    };
    try {
      const response = await invokeApi(
        config.getMyCollege + apiList.addPackage,
        params,
        cookies
      );
      console.log(response);
      if (response?.status >= 200 && response?.status < 300) {
        if (response.data.responseCode === "200") {
          fetchTableData();
        } else if (response.data.responseCode === "400") {
          alert(response.data.responseCode);
        }
      }
    } catch (error) {
      console.error("Error during login:", error);
      alert("Something went wrong. Please try again later!!");
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleCloseDialog = () => {
    setpackageName("");
    setPrice(null);
    setKeywords("");
    setPriceType("");
    setFeatures("");
    setOpenDialog(false);
  };

  const handleCloseDialogEdit = () => {
    setOpenDialogEdit(false);
  };

  const columns = [
    { id: "item1", label: "Sl. No" },
    { id: "item2", label: "Name" },
    { id: "item3", label: "Price" },
    { id: "item4", label: "Price Type" },
    { id: "item5", label: "Keywords" },
    { id: "item6", label: "Features" },
    { id: "item7", label: "Action" },
  ];

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleOpenDeleteDialog = (id) => {
    setOpenDeleteDialog(true);
    setDeleteItemId(id);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  return (
    <>
      <Grid sx={{ display: "flex", width: "100%", px: 1 }}>
        <Dashboardheader />
        <Dashboardsideicon />

        {/* <NavigatedComponent pathname={location.pathname} /> */}
        <Grid
          component="main"
          sx={{
            width: "100%",
            flexGrow: 1,
            // bgcolor:theme.palette.mode !== 'dark' ? '#1c191f':'#f8f3f7',
            p: 3,
            marginTop: 13,
            px: isMobileScreen ? 1 : isSmallScreen ? 2 : 5,
          }}
        >
          <Grid
            container
            md={12}
            xs={12}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              my: 2,
            }}
          >
            <Grid item md={12} xs={12}>
              <TextField
                type="text"
                size="small"
                placeholder="search"
                name="searchQuery"
                value={searchQuery}
                onChange={handleSearchChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon sx={{ color: "#1D1A57" }} />
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  style: {
                    height: isMobileScreen ? 10 : isSmallScreen ? 13 : 15,
                    fontSize: isMobileScreen ? 12 : isSmallScreen ? 12 : 14,
                  },
                }}
                sx={{
                  width: isMobileScreen
                    ? "250px"
                    : isSmallScreen
                    ? "300px"
                    : "400px",
                  background: "#f0f8ff",
                  borderRadius: "20px",

                  border: "1px solid #1D1A57",
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                }}
              />
            </Grid>
          </Grid>
          <Box
            sx={{
              display: { md: "flex", xs: "flex" },
              flexDirection: { xs: "column", md: "row" },
              flexWrap: { md: "wrap" },
              gap: { md: 2, xs: 2 },
              my: { md: 2, xs: 2 },
              width: isMobileScreen ? 175 : "auto",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              startIcon={
                <AddIcon style={{ fontSize: isMobileScreen ? 12 : 14 }} />
              }
              onClick={handleOpenDialog}
              sx={{
                height: isMobileScreen ? "20px" : "25px",
                whiteSpace: "nowrap",
                fontSize: isMobileScreen ? 8 : isSmallScreen ? 10 : 12,
              }}
            >
              Add New packages
            </Button>
          </Box>

          <Grid
            sx={{
              my: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {isUsersPage && (
              <>
                {loading ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: isMobileScreen
                        ? "100px"
                        : isSmallScreen
                        ? "100px"
                        : "200px",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  <>
                    <Paper
                      sx={{
                        width: "100%",
                        boxShadow: 3,
                        py: 1,
                        maxWidth: isMobileScreen ? 275 : "none",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          px: 2,
                          py: 1,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",

                            alignItems: "center",
                            gap: isMobileScreen ? 1 : isSmallScreen ? 5 : 10,
                          }}
                        >
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <InputLabel
                              sx={{
                                mx: 1,
                                fontSize: isMobileScreen
                                  ? 10
                                  : isSmallScreen
                                  ? 10
                                  : 12,
                              }}
                            >
                              Row per page
                            </InputLabel>
                            <Select
                              value={rowsPerPage}
                              onChange={handleChangeRowsPerPage}
                              sx={{
                                height: 25,
                                fontSize: isMobileScreen
                                  ? 10
                                  : isSmallScreen
                                  ? 10
                                  : 12,
                              }}
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: "100px",
                                  },
                                },
                              }}
                            >
                              <MenuItem
                                value={
                                  isMobileScreen ? 5 : isSmallScreen ? 5 : 10
                                }
                                sx={{
                                  fontSize: isMobileScreen
                                    ? 10
                                    : isSmallScreen
                                    ? 10
                                    : 12,
                                  "&.MuiButtonBase-root": {
                                    minHeight: 0,
                                  },
                                }}
                              >
                                {isMobileScreen ? 5 : isSmallScreen ? 5 : 10}
                              </MenuItem>
                              <MenuItem
                                value={
                                  isMobileScreen ? 10 : isSmallScreen ? 10 : 20
                                }
                                sx={{
                                  fontSize: isMobileScreen
                                    ? 10
                                    : isSmallScreen
                                    ? 10
                                    : 12,
                                  "&.MuiButtonBase-root": {
                                    minHeight: 0,
                                  },
                                }}
                              >
                                {isMobileScreen ? 10 : isSmallScreen ? 10 : 20}
                              </MenuItem>
                              <MenuItem
                                value={
                                  isMobileScreen ? 15 : isSmallScreen ? 15 : 30
                                }
                                sx={{
                                  fontSize: isMobileScreen
                                    ? 10
                                    : isSmallScreen
                                    ? 10
                                    : 12,
                                  "&.MuiButtonBase-root": {
                                    minHeight: 0,
                                  },
                                }}
                              >
                                {isMobileScreen ? 15 : isSmallScreen ? 15 : 30}
                              </MenuItem>
                            </Select>
                          </Box>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <InputLabel
                              sx={{
                                mx: 1,
                                fontSize: isMobileScreen
                                  ? 10
                                  : isSmallScreen
                                  ? 10
                                  : 12,
                              }}
                            >
                              Go to page
                            </InputLabel>
                            <TextField
                              type="number"
                              variant="outlined"
                              value={page + 1}
                              onChange={(event) => {
                                const pageNumber = parseInt(
                                  event.target.value,
                                  10
                                );
                                handleChangePage(event, pageNumber - 1);
                              }}
                              inputProps={{
                                min: 1,
                                max: Math.ceil(
                                  filteredRows.length / rowsPerPage
                                ),
                                style: {
                                  height: "auto",

                                  fontSize: isMobileScreen
                                    ? 10
                                    : isSmallScreen
                                    ? 10
                                    : 12,
                                  padding: "1px 7px",
                                },
                              }}
                              sx={{
                                width: isMobileScreen
                                  ? 40
                                  : isSmallScreen
                                  ? 40
                                  : 60,
                              }}
                            />
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: isMobileScreen ? "none" : "flex",
                          }}
                        >
                          <Pagination
                            count={Math.ceil(filteredRows.length / rowsPerPage)}
                            page={page + 1}
                            onChange={(event, value) =>
                              handleChangePage(event, value - 1)
                            }
                            color="primary"
                            renderItem={(item) => (
                              <PaginationItem
                                {...item}
                                style={{
                                  fontSize: isMobileScreen
                                    ? 10
                                    : isSmallScreen
                                    ? 10
                                    : 12,
                                }}
                              />
                            )}
                            shape="rounded"
                            boundaryCount={1}
                            siblingCount={1}
                            showFirstButton
                            showLastButton
                            firstIcon={<FirstPageRoundedIcon />}
                            lastIcon={<LastPageRoundedIcon />}
                            prevIcon={<ChevronLeftRoundedIcon />}
                            nextIcon={<ChevronRightRoundedIcon />}
                          />
                        </Box>
                      </Box>

                      <TableContainer
                        id="table-container"
                        sx={{
                          maxHeight: isMobileScreen ? 440 : 440,
                          overflowY: "auto",
                        }}
                      >
                        <Table aria-label="sticky table">
                          <TableHead
                            style={{
                              background: "white",
                              position: isMobileScreen ? "sticky" : "sticky",
                              top: 0,
                              zIndex: 5,
                              textAlign: "left",
                            }}
                          >
                            <TableRow>
                              {columns.map((column, index) => (
                                <TableCell
                                  key={index}
                                  style={{
                                    width: isSmallScreen
                                      ? "auto"
                                      : `${100 / columns.length}%`,
                                    background: "#fff",
                                    zIndex: 100,
                                    backgroundColor: "#f0f8ff",
                                    fontWeight: 600,
                                    boxShadow: 2,
                                    whiteSpace: "nowrap",
                                    textAlign: "left",
                                  }}
                                >
                                  <Grid
                                    sx={{
                                      display: "flex",

                                      gap: 0,
                                      fontSize: isSmallScreen ? 12 : 13,
                                    }}
                                  >
                                    {column.label}
                                  </Grid>
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {filteredRows.length === 0 ? (
                              <TableRow>
                                <TableCell
                                  colSpan={columns.length}
                                  align="center"
                                  style={{ py: "6px" }}
                                >
                                  No data found
                                </TableCell>
                              </TableRow>
                            ) : (
                              filteredRows
                                .slice(
                                  page * rowsPerPage,
                                  page * rowsPerPage + rowsPerPage
                                )
                                .map((row, index) => (
                                  <TableRow key={index}>
                                    <TableCell
                                      sx={{
                                        py: "4px",
                                        position: isMobileScreen
                                          ? "sticky"
                                          : "none",
                                        left: isMobileScreen ? 0 : "none",

                                        background: isMobileScreen
                                          ? "#f0f8ff"
                                          : "none",
                                        px: 2,
                                        fontSize: isSmallScreen ? 11 : 12,
                                        textAlign: "left",
                                      }}
                                    >
                                      {index + 1 + page * rowsPerPage}
                                    </TableCell>

                                    <TableCell
                                      sx={{
                                        py: "6px",
                                        textAlign: "left",
                                        "@media (min-width: 1440px) and (max-width:2000px)":
                                          {
                                            whiteSpace: "nowrap",
                                          },
                                        fontSize: isSmallScreen ? 11 : 12,
                                      }}
                                    >
                                      {row.name}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        py: "6px",
                                        textAlign: "left",
                                        "@media (min-width: 1440px) and (max-width:2000px)":
                                          {
                                            whiteSpace: "nowrap",
                                          },
                                        fontSize: isSmallScreen ? 11 : 12,
                                      }}
                                    >
                                      {row.price}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        py: "6px",
                                        textAlign: "left",
                                        "@media (min-width: 1440px) and (max-width:2000px)":
                                          {
                                            whiteSpace: "nowrap",
                                          },
                                        fontSize: isSmallScreen ? 11 : 12,
                                      }}
                                    >
                                      {row.priceType}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        py: "6px",
                                        textAlign: "left",
                                        "@media (min-width: 1440px) and (max-width:2000px)":
                                          {
                                            whiteSpace: "nowrap",
                                          },
                                        fontSize: isSmallScreen ? 11 : 12,
                                      }}
                                    >
                                      {row.keywords}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        py: "6px",
                                        textAlign: "left",
                                        "@media (min-width: 1440px) and (max-width:2000px)":
                                          {
                                            whiteSpace: "nowrap",
                                          },
                                        fontSize: isSmallScreen ? 11 : 12,
                                      }}
                                    >
                                      {row.features}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        py: "6px",
                                        textAlign: "left",
                                        "@media (min-width: 1440px) and (max-width:2000px)":
                                          {
                                            whiteSpace: "nowrap",
                                          },
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      <Button
                                        sx={{
                                          marginRight: isSmallScreen ? 1 : 2,
                                          height: isSmallScreen
                                            ? "15px"
                                            : "20px",
                                          my: "3px",
                                          fontSize: 11,
                                          "&.MuiButtonBase-root": {
                                            border: "1px solid #1b5e20",
                                            minWidth: 30,
                                            py: 1,
                                          },
                                          "@media (min-width: 1440px) and (max-width:2000px)":
                                            {
                                              my: 0,
                                            },
                                        }}
                                        onClick={() => handleViewEdit(row.id)}
                                      >
                                        <EditIcon
                                          style={{
                                            fontSize: isSmallScreen ? 13 : 14,
                                            color: "#1b5e20",
                                          }}
                                        />
                                      </Button>
                                      <Button
                                        sx={{
                                          height: isSmallScreen
                                            ? "15px"
                                            : "20px",
                                          my: "3px",
                                          fontSize: 11,
                                          "&.MuiButtonBase-root": {
                                            border: "1px solid #c62828",
                                            minWidth: 30,
                                            py: 1,
                                          },
                                          "@media (min-width: 1440px) and (max-width:2000px)":
                                            {
                                              my: 0,
                                            },
                                        }}
                                        onClick={() =>
                                          handleOpenDeleteDialog(row.id)
                                        }
                                      >
                                        <DeleteIcon
                                          style={{
                                            fontSize: isSmallScreen ? 13 : 14,
                                            color: "#c62828",
                                          }}
                                        />
                                      </Button>
                                    </TableCell>
                                  </TableRow>
                                ))
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Paper>
                  </>
                )}
                <Dialog
                  open={openDialog}
                  onClose={handleCloseDialog}
                  fullWidth
                  maxWidth="sm"
                >
                  <Container>
                    <DialogTitle
                      sx={{
                        mb: 1,
                        mx: 8,
                        fontSize: 18,
                        fontWeight: 600,
                        textAlign: "center",
                      }}
                    >
                      Add packages
                    </DialogTitle>
                    <DialogContent>
                      <Grid container>
                        <Grid item md={12} sx={{ my: 1 }}>
                          <InputLabel
                            sx={{
                              fontWeight: "bold",
                              my: 1,
                              fontSize: 15,
                            }}
                          >
                            Name*:
                          </InputLabel>
                          <TextField
                            placeholder="Enter the Name"
                            autoFocus
                            fullWidth
                            value={packageName}
                            onChange={(e) => {
                              setpackageName(e.target.value);
                            }}
                            inputProps={{
                              style: { height: "8px", fontSize: 14 },
                            }}
                          />
                        </Grid>
                        <Grid item md={12} sx={{ my: 1 }}>
                          <InputLabel
                            sx={{
                              fontWeight: "bold",
                              my: 1,
                              fontSize: 15,
                            }}
                          >
                            Price*:
                          </InputLabel>
                          <TextField
                            placeholder="Enter the Price"
                            fullWidth
                            value={price}
                            onChange={(e) => {
                              setPrice(e.target.value);
                            }}
                            inputProps={{
                              style: { height: "8px", fontSize: 14 },
                            }}
                          />
                        </Grid>
                        <Grid item md={12} sx={{ my: 1 }}>
                          <InputLabel
                            sx={{
                              fontWeight: "bold",
                              my: 1,
                              fontSize: 15,
                            }}
                          >
                            Price Type*:
                          </InputLabel>
                          <TextField
                            select
                            fullWidth
                            size="small"
                            value={priceType}
                            onChange={(e) => {
                              setPriceType(e.target.value);
                            }}
                            inputProps={{
                              style: { height: "8px", fontSize: 14 },
                            }}
                          >
                            <MenuItem value="Monthly">Monthly</MenuItem>
                            <MenuItem value="Yearly">Yearly</MenuItem>
                          </TextField>
                        </Grid>

                        <Grid item md={12} sx={{ my: 1 }}>
                          <InputLabel
                            sx={{
                              fontWeight: "bold",
                              my: 1,
                              fontSize: 15,
                            }}
                          >
                            Keywords*:
                          </InputLabel>
                          <TextField
                            placeholder="Enter the Keywords"
                            autoFocus
                            fullWidth
                            value={keywords}
                            onChange={(e) => {
                              setKeywords(e.target.value);
                            }}
                            inputProps={{
                              style: { height: "8px", fontSize: 14 },
                            }}
                          />
                        </Grid>

                        <Grid item md={12} sx={{ my: 1 }}>
                          <InputLabel
                            sx={{
                              fontWeight: "bold",
                              my: 1,
                              fontSize: 15,
                            }}
                          >
                            Features*:
                          </InputLabel>
                          <Autocomplete
                            multiple
                            freeSolo
                            options={[]} // No predefined options; users can enter custom values
                            value={features ? features.split(", ") : []}
                            onChange={(e, newValue) => {
                              setFeatures(newValue.join(", "));
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Features"
                                fullWidth
                                size="small"
                                onKeyDown={(e) => {
                                  if (e.key === "Enter" && e.target.value) {
                                    e.preventDefault();
                                    const newValue = [
                                      ...(features ? features.split(", ") : []),
                                      e.target.value.trim(),
                                    ];
                                    setFeatures(newValue.join(", "));
                                  }
                                }}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </DialogContent>
                    <DialogActions
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: isMobileScreen ? "center" : "flex-end",
                      }}
                    >
                      <Button
                        onClick={handleCloseDialog}
                        sx={{
                          my: 2,
                          px: 2,
                          fontSize: 12,
                          border: "1px solid #1d1a57",
                          color: "#1d1a57",
                          fontWeight: 600,
                          "&:hover": {
                            border: "1px solid #1d1a57",
                            color: "#1d1a57",
                          },
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        color="primary"
                        onClick={handleSubmit}
                        sx={{
                          my: 2,
                          px: 2,
                          fontSize: 12,
                          background: "#1d1a57",
                          color: "#FFF",
                          fontWeight: 600,
                          "&:hover": {
                            background: "#1d1a57",
                            color: "#FFF",
                          },
                        }}
                      >
                        Add Packeges
                      </Button>
                    </DialogActions>
                  </Container>
                </Dialog>
                <Dialog
                  open={openDialogEdit}
                  onClose={handleCloseDialogEdit}
                  fullWidth
                  maxWidth="sm"
                >
                  <Container>
                    <DialogTitle
                      sx={{
                        mb: 1,
                        mx: 8,
                        fontSize: 18,
                        fontWeight: 600,
                        textAlign: "center",
                      }}
                    >
                      Edit Packages
                    </DialogTitle>
                    <DialogContent>
                      <Grid container>
                        <Grid item md={12} xs={12} sx={{ my: 1 }}>
                          <InputLabel
                            sx={{
                              fontWeight: "bold",
                              my: 1,
                              fontSize: 15,
                            }}
                          >
                            Name*:
                          </InputLabel>

                          <TextField
                            placeholder="Enter the Name"
                            autoFocus
                            fullWidth
                            value={selectedRow?.name}
                            onChange={(e) => {
                              setSelectedRow({
                                ...selectedRow,
                                name: e.target.value,
                              });
                            }}
                            inputProps={{
                              style: { height: "8px", fontSize: 14 },
                            }}
                          />
                        </Grid>

                        <Grid item md={12} xs={12} sx={{ my: 1 }}>
                          <InputLabel
                            sx={{
                              fontWeight: "bold",
                              my: 1,
                              fontSize: 15,
                            }}
                          >
                            Keywords*:
                          </InputLabel>

                          <TextField
                            placeholder="Enter the Keywords"
                            fullWidth
                            value={selectedRow?.keywords}
                            onChange={(e) => {
                              setSelectedRow({
                                ...selectedRow,
                                keywords: e.target.value,
                              });
                            }}
                            inputProps={{
                              style: { height: "8px", fontSize: 14 },
                            }}
                          />
                        </Grid>
                        <Grid item md={12} xs={12} sx={{ my: 1 }}>
                          <InputLabel
                            sx={{
                              fontWeight: "bold",
                              my: 1,
                              fontSize: 15,
                            }}
                          >
                            Features*:
                          </InputLabel>
                          <Autocomplete
                            multiple
                            freeSolo
                            options={[]} // No predefined options; users can enter custom values
                            value={
                              selectedRow.features
                                ? selectedRow.features.split(", ")
                                : []
                            }
                            onChange={(e, newValue) => {
                              setSelectedRow({
                                ...selectedRow,
                                features: newValue.join(", "),
                              });
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Features"
                                fullWidth
                                size="small"
                                onKeyDown={(e) => {
                                  if (e.key === "Enter" && e.target.value) {
                                    e.preventDefault();
                                    const newValue = [
                                      ...(selectedRow.features
                                        ? selectedRow.features.split(", ")
                                        : []),
                                      e.target.value.trim(),
                                    ];
                                    setSelectedRow({
                                      ...selectedRow,
                                      features: newValue.join(", "),
                                    });
                                  }
                                }}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </DialogContent>
                    <DialogActions
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: isMobileScreen ? "center" : "flex-end",
                      }}
                    >
                      <Button
                        onClick={handleCloseDialogEdit}
                        sx={{
                          my: 2,
                          px: 2,
                          fontSize: 12,
                          border: "1px solid #1d1a57",
                          color: "#1d1a57",
                          fontWeight: 600,
                          "&:hover": {
                            border: "1px solid #1d1a57",
                            color: "#1d1a57",
                          },
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={handleSaveChanges}
                        sx={{
                          my: 2,
                          px: 2,
                          fontSize: 12,
                          background: "#1d1a57",
                          color: "#FFF",
                          fontWeight: 600,
                          "&:hover": {
                            background: "#1d1a57",
                            color: "#FFF",
                          },
                        }}
                      >
                        Save Changes
                      </Button>
                    </DialogActions>
                  </Container>
                </Dialog>
                <Dialog
                  open={openDeleteDialog}
                  onClose={handleCloseDeleteDialog}
                  fullWidth
                  maxWidth="xs"
                >
                  <Container>
                    <DialogTitle
                      sx={{
                        fontWeight: "600",
                        fontSize: "25px",
                        my: 1,
                        textAlign: "center",
                      }}
                    >
                      Confirm Delete
                    </DialogTitle>
                    <DialogContent>
                      <Grid container>
                        <Grid item md={12} sx={{ my: 1 }}>
                          <Typography>
                            Are you sure you want to delete?
                          </Typography>
                        </Grid>
                      </Grid>
                    </DialogContent>
                    <DialogActions>
                      <Grid
                        sx={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-around",
                        }}
                      >
                        <Button
                          onClick={handleCloseDeleteDialog}
                          sx={{
                            mb: 2,

                            fontSize: 12,
                            border: "1px solid #d11a2a",
                            color: "#d11a2a",
                            fontWeight: 600,
                            "&:hover": {
                              border: "1px solid #d11a2a",
                              color: "#d11a2a",
                            },
                            width: "40%",
                          }}
                        >
                          No
                        </Button>
                        <Button
                          onClick={handlePackageDelete}
                          color="primary"
                          sx={{
                            mb: 2,

                            fontSize: 12,
                            background: "#d11a2a",
                            color: "#fff",
                            fontWeight: 600,
                            "&:hover": {
                              background: "#d11a2a",
                              color: "#fff",
                            },
                            width: "40%",
                          }}
                        >
                          Yes
                        </Button>
                      </Grid>
                    </DialogActions>
                  </Container>
                </Dialog>

                <Snackbar
                  open={openSnackbar}
                  autoHideDuration={4000}
                  onClose={handleCloseSnackbar}
                  anchorOrigin={{ vertical: "top", horizontal: "center" }}
                  sx={{ width: "auto" }}
                >
                  <Alert
                    onClose={handleCloseSnackbar}
                    severity={snackbarSeverity}
                    sx={{ width: "auto" }}
                  >
                    {snackbarMessage}
                  </Alert>
                </Snackbar>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default Packeges;
