import React, { useEffect } from 'react';
import { Grid, Card, CardContent, Typography, Box, Paper } from '@mui/material';
import { FaRetweet, FaCheckCircle, FaCalculator, FaFileAlt, FaSpellCheck, FaParagraph, FaCog, FaImage, FaLanguage, FaSyncAlt, FaRegKeyboard, FaPenAlt, FaClipboardCheck, FaLightbulb, FaBook, FaEnvelopeOpenText, FaEdit, FaClipboardList, FaTools, FaCommentAlt, FaMicrophone, FaKeyboard, FaBookOpen } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useTheme } from "../../../../../common/ThemeContext";


const tools = [
    { name: 'Plagiarism Checker', icon: <FaFileAlt />, link: '/plagiarism-checker' },
    { name: 'Article Rewriter', icon: <FaRetweet />, link:'/article-rewriter' },
    { name: 'Free Grammar Checker', icon: <FaCheckCircle />, link:'/Grammar-Checker' },
    { name: 'Word Counter', icon: <FaCalculator />, link:'/word-counter' },
    { name: 'Spell Checker', icon: <FaSpellCheck />, link:'/spell-checker' },
    { name: 'Paraphrasing Tool', icon: <FaParagraph />, link:'/paraphrasing-tool' },
    { name: 'MD5 Generator', icon: <FaCog />, link:'/md5-generator' },
    { name: 'Uppercase To Lowercase', icon: <FaRegKeyboard />, link:'/upper-to-lower' },
    { name: 'Word Combiner', icon: <FaClipboardList />, link:'/word-combiner' },
    { name: 'Image To Text Converter', icon: <FaImage />, link:'/image-to-text' },
    { name: 'Translate English To English', icon: <FaLanguage />, link:'/translate' },
    { name: 'Text To Image', icon: <FaImage />, link:'/text-to-image' },
    { name: 'Text To Speech', icon: <FaMicrophone />, link:'/text-to-speech' },
    { name: 'Small Text Generator', icon: <FaEdit />, link:'/small-text-generator' },
    { name: 'Online Text Editor', icon: <FaRetweet />, link:'/online-text-editor' },
    { name: 'Reverse Text Generator', icon: <FaSyncAlt />, link:'/reverse-text-generator' },
    { name: 'Sentence Rephraser', icon: <FaParagraph />, link:'/sentence-rephraser' },
    { name: 'AI Content Detector', icon: <FaTools />, link:'/ai-content-detector' },
    { name: 'Paragraph Rewriter', icon: <FaParagraph />, link:'/paragraph-rewriter' },
    { name: 'Sentence Checker', icon: <FaCheckCircle />, link:'/sentence-checker' },
    { name: 'Rewording Tool', icon: <FaSyncAlt />, link:'/rewording-tool' },
    { name: 'Punctuation Checker', icon: <FaSpellCheck />, link:'/punctuation-checker' },
    { name: 'Essay Checker', icon: <FaFileAlt />, link:'/essay-checker' },
    { name: 'Paper Checker', icon: <FaFileAlt />, link:'/paper-checker' },
    { name: 'Online Proofreader', icon: <FaCheckCircle />, link:'/online-proofreader' },
    { name: 'Word Changer', icon: <FaSyncAlt />, link:'/word-changer' },
    { name: 'Sentence Rewriter', icon: <FaSyncAlt />, link:'/sentence-rewriter' },
    { name: 'Essay Rewriter', icon: <FaRetweet />, link:'/essay-rewriter' },
    { name: 'Text Summarizer', icon: <FaClipboardCheck />, link:'/text-summarizer' },
    { name: 'Paragraph Rephrase Tool', icon: <FaParagraph />, link:'/paragraph-rephrase-tool' },
    { name: 'Paraphrase Generator', icon: <FaRetweet />, link:'/paraphrase-generator' },
    { name: 'Sentence Changer', icon: <FaSyncAlt />, link:'/sentence-changer' },
    { name: 'OCR', icon: <FaImage />, link:'/ocr' },
    { name: 'Image Translator', icon: <FaLanguage />, link:'/image-translator' },
    { name: 'ChatGPT Detector', icon: <FaCog />, link:'/chatgpt-detector' },
    { name: 'Citation Generator', icon: <FaClipboardList />, link:'/citation-generator' },
    { name: 'AI Essay Writer', icon: <FaPenAlt />, link:'/ai-essay-writer' },
    { name: 'AI Writer', icon: <FaKeyboard />, link:'/ai-writer' },
    { name: 'AI Text Generator', icon: <FaEdit />, link:'/ai-text-generator' },
    { name: 'Title Generator', icon: <FaCommentAlt />, link:'/title-generator' },
    { name: 'Paragraph Generator', icon: <FaParagraph />, link:'/paragraph-generator' },
    { name: 'Essay Title Generator', icon: <FaClipboardCheck />, link:'/essay-title-generator' },
    { name: 'Plot Generator', icon: <FaLightbulb />, link:'/plot-generator' },
    { name: 'Thesis Statement Generator', icon: <FaBook />, link:'/thesis-statement-generator' },
    { name: 'AI Story Generator', icon: <FaBookOpen />, link:'/ai-story-generator' },
    { name: 'Conclusion Generator', icon: <FaCheckCircle />, link:'/conclusion-generator' },
    { name: 'AI Email Writer', icon: <FaEnvelopeOpenText />, link:'/ai-email-writer' },
];

function DashboardTool() {
    const { theme } = useTheme();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
                <Paper elevation={3} sx={{ padding: 4, boxShadow: '0 4px 20px rgba(255, 72, 0, 0.1)' }}>
                    <Grid container spacing={3}>
                        {tools.map((tool, index) => (
                            <Grid item xs={6} sm={4} md={2.4} key={index}>
                                <Link to={tool.link || '#'} style={{ textDecoration: 'none' }}>
                                    <Card
                                        sx={{
                                            textAlign: 'center',
                                            padding: 1,
                                            cursor: 'pointer',
                                            borderRadius: '0px 20px 0px 20px',
                                            boxShadow: '0 4px 20px rgba(255, 72, 0, 0.1)',
                                            height: '70px',
                                            width: '70px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            margin: '0 auto',
                                            position: 'relative',
                                            '&::before, &::after': {
                                                content: '""',
                                                position: 'absolute',
                                                borderRadius: '0px 20px 0px 20px',
                                                width: '0',
                                                height: '0',
                                                background: 'transparent',
                                                border: '4px solid transparent',
                                                transition: 'width 0.8s ease, height 0.8s ease, border-color 0.8s ease',
                                            },
                                            '&::before': {
                                                top: 0,
                                                left: 0,
                                                borderTopColor: 'transparent',
                                                borderLeftColor: 'transparent',
                                            },
                                            '&::after': {
                                                bottom: 0,
                                                right: 0,
                                                borderBottomColor: 'transparent',
                                                borderRightColor: 'transparent',
                                            },
                                            '&:hover::before': {
                                                width: '100%',
                                                height: '100%',
                                                borderTopColor: '#f75c20a6',
                                                borderLeftColor: '#f75c20a6',
                                            },
                                            '&:hover::after': {
                                                width: '100%',
                                                height: '100%',
                                                borderBottomColor: '#f75c20a6',
                                                borderRightColor: '#f75c20a6',
                                            },
                                        }}
                                    >
                                        <CardContent sx={{ padding: 0, borderRadius: 2 }}>
                                            <Box sx={{ fontSize: 30, color: '#f75c20a6', mt: 4 }}>
                                                {tool.icon}
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Link>
                                <Typography variant="subtitle1" sx={{ textAlign: 'center', fontSize: '14px', mt: 1, fontWeight: 'bold' }}>
                                    {tool.name}
                                </Typography>
                            </Grid>
                        ))}
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
}

export default DashboardTool;
