import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  TextField,
  InputLabel,
  ToggleButton,
  ToggleButtonGroup,
  IconButton,
  Paper,
  CircularProgress,
  FormControl,
  Select,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import { styled } from "@mui/system";
import { TimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { motion, AnimatePresence } from "framer-motion";
import DesktopWindowsRoundedIcon from '@mui/icons-material/DesktopWindowsRounded';
import PhoneAndroidRoundedIcon from '@mui/icons-material/PhoneAndroidRounded';
import { config } from "../../../../../config/config";
import { apiList, invokeApi } from "../../../../../services/apiServices";
import { useCookies } from "react-cookie";
import { useParams } from "react-router-dom";

const steps = ["Add Website", "Add Website"];

const AnimatedTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  "& .MuiOutlinedInput-root": {
    borderRadius: "5px",
    "& fieldset": {
      borderColor: "#9e9e9e",
    },
    "&:hover fieldset": {
      borderColor: "#ed6c02",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#ed6c02",
      transition: "border-color 0.3s ease-in-out",
    },
  },
  "& .MuiInputLabel-root": {
    color: "#9e9e9e",
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "#ed6c02",
  },
}));

const defaultDays = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

const HomeTab = () => {
  const [cookies] = useCookies();
  const [activeStep, setActiveStep] = useState(0);
  const { id } = useParams();

  const [formData, setFormData] = useState({
    projectName: "",
    domainName: "",
    keyword: "",
    searchEngineName: "google.com",
    language: "en",
    device: "desktop",
    location: "",
  });
  const [schedulesState, setSchedulesState] = useState(
    {
      scheduleId: 1,
      selectedDays: defaultDays,
      time: dayjs().hour(8).minute(0), // Default to 08:00 AM
    }
  );
  const keywordsArray = formData.keyword.split("\n").filter(Boolean);
  const [locationData, setLocationData] = useState([]);
  const [loading, setLoading] = useState(false); // Loading state for spinner
  const [typingTimeout, setTypingTimeout] = useState(null); // Timeout for debounce
  const [errors, setErrors] = useState({});
  const [projectId, setProjectId] = useState(null);



  // Handle input change
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });

    if (name === "location") {
      // Debounce API calls
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }
      setTypingTimeout(
        setTimeout(() => {
          fetchLocationsData(value); // Fetch data after typing stops
        }, 500)
      );
    }
  };

  const validateForm = (step) => {
    const newErrors = {};

    if (step === 0) {
      // Case 0: Validate Step 0 (Add Website)
      // Validate project name
      if (!formData.projectName.trim()) {
        newErrors.projectName = "Project name is required.";
      }

      // Validate domain name
      const domainRegex = /^[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/;
      if (!formData.domainName.trim()) {
        newErrors.domainName = "Domain name is required.";
      } else if (!domainRegex.test(formData.domainName.trim())) {
        newErrors.domainName =
          "Invalid domain. Remove 'www.' and include a valid extension (e.g., domain.com).";
      }

      // Validate schedules
      const hasValidSchedule = schedulesState.selectedDays.length > 0 && schedulesState.time != null

      if (!hasValidSchedule) {
        newErrors.schedule = "At least one schedule with selected days and time is required.";
      }
    } else if (step === 1) {
      // Case 1: Validate Step 1 (Add Columns)
      // Validate keywords
      if (!formData.keyword || !formData.keyword.trim()) {
        newErrors.keywords = "Keywords are required.";
      }

      // Validate search engine name
      if (!formData.searchEngineName.trim()) {
        newErrors.searchEngineName = "Search engine is required.";
      }

      // Validate language
      if (!formData.language.trim()) {
        newErrors.language = "Language is required.";
      }

      // Validate device
      if (!formData.device) {
        newErrors.device = "Device selection is required.";
      }

      // Validate location
      if (!formData.location.trim()) {
        newErrors.location = "Location is required.";
      }
    }

    setErrors(newErrors);

    // Return true if no errors
    return Object.keys(newErrors).length === 0;
  };


  const handleNext = () => {
    if (activeStep === 0) {
      if (validateForm(0)) {
        setActiveStep(1); // Proceed to the next step if validation passes
      }
    }
  };

  const handleFinish = () => {
    if (validateForm(1)) {
      handleAddProject();
      console.log("Form submission data:", formData, schedulesState);
      alert("Form submitted successfully!");

    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prevStep) => prevStep - 1);
    }
  };

  // const handleChange = (event) => {
  //   setFormData({ ...formData, [event.target.name]: event.target.value });
  // };

  const convertDaysToNumbers = (selectedDays) =>
    selectedDays.map((day) => defaultDays.indexOf(day) + 1);

  const handleDayChange = (day) => {
    setSchedulesState((prevSchedule) => {
      const isSelected = prevSchedule.selectedDays.includes(day);
      return {
        ...prevSchedule,
        selectedDays: isSelected
          ? prevSchedule.selectedDays.filter((d) => d !== day) // Remove if already selected
          : [...prevSchedule.selectedDays, day], // Add if not selected
      };
    });
  };

  const handleTimeChange = (scheduleId, newTime) => {
    setSchedulesState((prevSchedule) => ({
      ...prevSchedule,
      time: newTime, // Update time in state
    }));
  };


  const fetchLocationsData = async (query) => {
    if (!query) {
      setLocationData([]); // Clear data if query is empty
      return;
    }
    setLoading(true); // Show loading spinner
    try {
      const params = { type: "google", querry: query }; // Replace 'query' with actual API key
      const response = await invokeApi(
        config.getMyCollege + apiList.getLocations,
        params,
        cookies
      );
      if (response?.status === 200) {
        setLocationData(response.data.locations || []); // Update location data
      } else {
        console.error("Failed to fetch locations:", response);
        setLocationData([]);
      }
    } catch (error) {
      console.error("Error fetching locations:", error);
      setLocationData([]);
    } finally {
      setLoading(false); // Hide loading spinner
    }
  };

  const handleAddProject = async () => {
    try {
      const params = {
        wsId: id,
        name: formData.projectName
      };
      const response = await invokeApi(
        config.getMyCollege + apiList.addProject,
        params,
        cookies
      );
      if (response?.status === 200) {
        handleAddDomain(response.data.id);
      } else {
        console.error("Failed to add project:", response);
      }
    } catch (error) {
      console.error("Error add project:", error);
    }
  };


  const handleAddDomain = async (projectId) => {
    try {
      const params = {
        projectId: projectId,
        domainName: formData.domainName
      };
      const response = await invokeApi(
        config.getMyCollege + apiList.addDomain,
        params,
        cookies
      );
      if (response?.status === 200) {
        handleAddSchedule(response.data.id);
      } else {
        console.error("Failed to get projects:", response);
      }
    } catch (error) {
      console.error("Error get projects:", error);
    }
  };



  const handleAddSchedule = async (domainId) => {
    try {
      const params = {
        domainId: domainId,
        days: convertDaysToNumbers(schedulesState.selectedDays).toString(),
        time: schedulesState.time.format("HH:mm")
      };
      const response = await invokeApi(
        config.getMyCollege + apiList.addSchedule,
        params,
        cookies
      );
      if (response?.status === 200) {
        for (var i = 0; i < keywordsArray.length; i++) {
          handleAddKeywords(keywordsArray[i], domainId);
        }
      } else {
        console.error("Failed to get projects:", response);
      }
    } catch (error) {
      console.error("Error get projects:", error);
    }
  };


  const handleAddKeywords = async (keyword, domainId) => {
    try {
      const params = {
        domainId: domainId,
        keywordName: keyword,
        domain: "google.com",
        lang: "en",
        device: formData.device,
        serpType: "web",
        loc: formData.location
      };
      const response = await invokeApi(
        config.getMyCollege + apiList.addKeyword,
        params,
        cookies
      );
      if (response?.status === 200) {
        console.log("updated Successfully hip hip urray");
      } else {
        console.error("Failed to get projects:", response);
      }
    } catch (error) {
      console.error("Error get projects:", error);
    }
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <InputLabel sx={{ fontSize: "16px", fontWeight: "500" }}>
              Project Name
            </InputLabel>
            <AnimatedTextField
              size="small"
              label="Name"
              name="projectName"
              value={formData.projectName}
              onChange={handleChange}
              error={Boolean(errors.projectName)}
              helperText={errors.projectName}
              fullWidth
            />
            <InputLabel sx={{ fontSize: "16px", fontWeight: "500" }}>
              Domain
            </InputLabel>
            <AnimatedTextField
              size="small"
              label="Domain"
              name="domainName"
              value={formData.domainName}
              onChange={handleChange}
              error={Boolean(errors.domainName)}
              helperText={errors.domainName}
              fullWidth
            />
            <Box>
              <Box display="flex" flexDirection="column" gap={2}>
                <ToggleButtonGroup
                  value={schedulesState.selectedDays}
                  aria-label="days selection"
                  sx={{
                    flexWrap: "wrap",
                    gap: 1,
                    justifyContent:'center',
                    "& .MuiToggleButton-root": {
                      borderRadius: "5px",
                      px: 1,
                      py: "7px",
                    },
                  }}
                >
                  {defaultDays.map((day) => (
                    <ToggleButton
                      key={day}
                      value={day}
                      aria-label={day}
                      selected={schedulesState.selectedDays.includes(day)} // Use selected prop for toggling
                      onClick={() => handleDayChange(day)} // Correctly toggles the day
                      sx={{
                        width:"49.7px",
                        textTransform: "uppercase",
                        fontWeight: "500",
                        borderRadius: "5px",
                        backgroundColor: schedulesState.selectedDays.includes(day)
                          ? "#ed6c02"
                          : "#f5f5f5",
                        color: schedulesState.selectedDays.includes(day) ? "#fff" : "#000",
                        "&:hover": {
                          backgroundColor: schedulesState.selectedDays.includes(day)
                            ? "#ff8c42"
                            : "#e0e0e0",
                        },
                        "&.Mui-selected": {
                          backgroundColor: "#ed6c02",
                          color: "#fff",
                        },
                      }}
                    >
                      {day}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    label="Select Time"
                    value={schedulesState.time}
                    onChange={(newTime) => handleTimeChange(schedulesState.scheduleId, newTime)}
                    renderInput={(params) => (
                      <AnimatedTextField {...params} size="small" fullWidth />
                    )}
                  />
                </LocalizationProvider>
              </Box>
            </Box>
            {errors.schedule && (
              <Typography color="error" variant="body2">
                {errors.schedule}
              </Typography>
            )}
          </Box>
        );


      case 1:
        return (
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <InputLabel sx={{ fontSize: "16px", fontWeight: "500" }}>
                Type keywords, one per line
              </InputLabel>
              <Box sx={{ position: "relative" }}>
                {/* Multiline Text Field */}
                <AnimatedTextField
                  size="small"
                  name="keywords"
                  multiline
                  minRows={5}
                  value={formData.keyword || ""}
                  onChange={(event) =>
                    setFormData({ ...formData, keyword: event.target.value })
                  }
                  placeholder="Enter keywords"
                  error={Boolean(errors.keywords)}
                  helperText={errors.keywords}
                  fullWidth
                  sx={{
                    "& textarea": {
                      fontFamily: "monospace",
                      lineHeight: "1.3",
                      resize: "none",
                      paddingLeft: "40px", // Padding for the line numbers
                    },
                  
                  }}
                />
                {/* Line numbers */}
                <Box
                  sx={{
                    position: "absolute",
                    top: 10,
                    left: 10,
                    bottom: 10,
                    width: "30px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    justifyContent: "start",
                    color: "#9e9e9e",
                    pointerEvents: "none",
                  }}
                >
                  {Array.from(
                    { length: (formData.keyword?.split("\n").length || 5) },
                    (_, index) => (
                      <Typography
                        key={index}
                        sx={{
                          fontSize: "14px",
                          lineHeight: "1.5",
                          textAlign: "right",
                          paddingRight: "5px",
                        }}
                      >
                        {index + 1}.
                      </Typography>
                    )
                  )}
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
              <InputLabel sx={{ fontSize: "16px", fontWeight: "500" }}>
                Search Engine
              </InputLabel>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {/* Search Engine and Language Dropdowns */}
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Box>
                    <AnimatedTextField
                      select
                      size="small"
                      label="Search Engine"
                      value={formData.searchEngineName || "google.com"}
                      name="searchEngineName"
                      onChange={handleChange}
                      error={Boolean(errors.searchEngineName)}
                      helperText={errors.searchEngineName}
                      SelectProps={{
                        native: true,
                      }}
                      sx={{ minWidth: "150px" }}
                    >
                      <option value="google.com">google.com</option>
                      <option value="bing.com">bing.com</option>
                      <option value="yahoo.com">yahoo.com</option>
                    </AnimatedTextField>
                  </Box>
                  <Box>
                    <AnimatedTextField
                      select
                      size="small"
                      label="Language"
                      value={formData.language || "en"}
                      name="language"
                      onChange={handleChange}
                      error={Boolean(errors.language)}
                      helperText={errors.language}
                      SelectProps={{
                        native: true,
                      }}
                      sx={{ minWidth: "120px" }}
                    >
                      <option value="en">English</option>
                      <option value="Spanish">Spanish</option>
                      <option value="French">French</option>
                    </AnimatedTextField>
                  </Box>
                  <Box>
                    {/* Device Toggle Buttons */}
                    <ToggleButtonGroup
                      value={formData.device || "desktop"}
                      exclusive
                      onChange={(event, newDevice) =>
                        setFormData({ ...formData, device: newDevice || "desktop" })
                      }
                      aria-label="device"
                      sx={{
                        mx: "1px",
                        "& .MuiToggleButton-root": {
                          borderRadius: "5px",
                          textTransform: "capitalize",
                          px: 1,
                          py: "7px",
                          mt: -4,
                          width: '100%'
                        },
                        "& .MuiToggleButton-root.Mui-selected": {
                          backgroundColor: "#FF8C42",
                          color: "#fff",
                          "&:hover": {
                            backgroundColor: "#FF7A1C",
                          },
                        },
                      }}
                    >
                      <ToggleButton value="desktop" aria-label="desktop" sx={{ pr: 1 }}>
                        <DesktopWindowsRoundedIcon />
                      </ToggleButton>
                      <ToggleButton value="mobile" aria-label="mobile">
                        <PhoneAndroidRoundedIcon />
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Box>
                </Box>
              </Box>

              {/* Location Input with Dropdown */}
              <Box>
                <Autocomplete
                  freeSolo
                  options={locationData.map((location) => location.loc)}
                  inputValue={formData.location || ""}
                  onInputChange={(event, newValue) => {
                    setFormData({ ...formData, location: newValue });
                    fetchLocationsData(newValue); // Fetch location suggestions dynamically
                  }}
                  onChange={(event, newValue) => {
                    setFormData({ ...formData, location: newValue });
                  }}
                  renderInput={(params) => (
                    <AnimatedTextField
                      {...params}
                      size="small"
                      label="Enter Location"
                      fullWidth
                      error={Boolean(errors.location)}
                      helperText={errors.location}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: loading && (
                          <CircularProgress size={20} color="inherit" />
                        ),
                      }}
                    />
                  )}
                />
              </Box>
            </Box>
          </Box>
        );



      default:
        return <Typography>Unknown Step</Typography>;
    }
  };

  return (
    // <Box sx={{ display: "flex", justifyContent: "center", p: 2 }}>
    <Box
      sx={{
        maxWidth: "500px",
        bgcolor: "background.paper",
        p: 3,
        borderRadius: 2,
        boxShadow: 3,
      }}
    >
      <AnimatePresence mode="wait">
        <motion.div
          key={activeStep}
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -30 }}
          transition={{ duration: 0.2, ease: "easeInOut" }}
        >
          <Typography
            gutterBottom
            sx={{
              color: "#FF8C42",
              fontSize: "23px",
              fontWeight: "bold",
              mb: 3,
              textAlign: "center",
            }}
          >
            {steps[activeStep]}
          </Typography>
          {renderStepContent(activeStep)}
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 4 }}>
            <Button
              variant="outlined"
              color="warning"
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              Back
            </Button>
            <Button variant="contained" color="warning" onClick={() => activeStep === 0 ? handleNext() : handleFinish()}>
              {activeStep === steps.length - 1 ? "Finish" : "Next"}
            </Button>
          </Box>
        </motion.div>
      </AnimatePresence>
    </Box>
    // </Box>
  );
};

export default HomeTab;
