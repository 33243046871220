import React, { useEffect, useState } from 'react';
import { Box, Card, CardContent, Typography, Button, Divider, IconButton, Tooltip, Table, TableContainer, TableHead, TableRow, TableCell, Paper, TableBody, TextField, ListItem, List, Dialog, InputLabel, styled, Select, MenuItem } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { apiList, invokeApi } from '../../../../services/apiServices';
import { config } from '../../../../config/config';
import { useCookies } from 'react-cookie';
import { Grid } from '@mui/system';
import Cookies from "js-cookie";
import {
    Add as AddIcon,
    Delete as DeleteIcon,
    Edit as EditIcon,
} from "@mui/icons-material";
import { useNavigate } from 'react-router-dom';
import Header1 from '../../../setup/common/Header';
import Header2 from '../../../common/home/Header';
import useFetch from '../../useFetch';
import UpiImage from '../../../../assets/upi-img.png';
import QrCode from '../../../../assets/QR-code.png';


function PaymentBacklinks() {
    const { name, roles } = useFetch();
    const [cookies] = useCookies();
    const [packageData, setPackageData] = useState({});
    const packageId = sessionStorage.getItem("packageId");
    const [totalAmt, setTotalAmt] = useState(0);
    const [gstAmount, setGstAmount] = useState(0);
    const [payId, setPayId] = useState(0);
    const [errors, setErrors] = useState({ totalAmt: "", payId: "" });
    const existingCart = Cookies.get("cart") ? JSON.parse(Cookies.get("cart")) : [];
    const navigate = useNavigate();

    const handleVerifyAndPay = () => {
        let hasError = false;
        const newErrors = { totalAmt: "", payId: "" };

        // Validate Amount
        if (!totalAmt) {
            newErrors.totalAmt = "Amount is required.";
            hasError = true;
        }

        // Validate Payment ID
        if (!payId) {
            newErrors.payId = "Payment ID is required.";
            hasError = true;
        }

        setErrors(newErrors);

        if (hasError) return; // Stop if validation errors exist

        // Call the payment function if validation passes
        handleAddPayment(payId, totalAmt);
    };

    // Calculate total amount when the component mounts or cart data changes
    useEffect(() => {
        const totalPrice = existingCart.reduce((total, item) => {
            // Ensure the price is numeric, removing currency symbols or special characters if necessary
            const numericPrice = parseFloat(item.price.replace(/[^0-9.-]+/g, ""));
            return total + (isNaN(numericPrice) ? 0 : numericPrice);
        }, 0);

        setTotalAmt(totalPrice);

        // Calculate GST (18%)
        const gst = (totalPrice * (18 / 100)).toFixed(3); // Ensures two decimal places
        setGstAmount(parseFloat(gst)); // Convert it back to a number if needed
    }, [existingCart]);


    // Function to remove an item by index
    const removeCartItem = (index) => {
        const updatedCart = [...existingCart]; // Create a copy of the cart
        updatedCart.splice(index, 1); // Remove the item at the given index
        // setCart(updatedCart); // Update the state
        Cookies.set("cart", JSON.stringify(updatedCart)); // Save the updated cart back to cookies
    };


    //for payment gateway
    // Function to dynamically load the Razorpay script
    const loadRazorpayScript = () => {
        return new Promise((resolve, reject) => {
            const script = document.createElement("script");
            script.src = "https://checkout.razorpay.com/v1/checkout.js";
            script.onload = resolve;
            script.onerror = reject;
            document.body.appendChild(script);
        });
    };

    useEffect(() => {
        const handlePackageData = async () => {
            const params = {
                id: packageId,
            };
            try {
                const response = await invokeApi(
                    config.getMyCollege + apiList.getPackage,
                    params,
                    cookies
                );

                if (response?.status === 200) {
                    const userData = response.data.packages;
                    setPackageData(userData);
                } else {
                    console.error("Something went wrong. Please try again later!!");
                }
            } catch (error) {
                console.error("Error during data fetch:", error);
                console.error("Something went wrong. Please try again later!!");
            }
        };
        handlePackageData();
    }, [])

    const paidAmt = totalAmt + gstAmount;
    // generating order
    const handleCompletePayment = async () => {
        const params = {
            paidAmt: totalAmt,

        };

        try {
            const response = await invokeApi(
                config.getMyCollege + apiList.generateOrder,
                params,
                cookies
            );

            if (response?.status === 200) {
                const paymentData = response.data.payment[0];
                await initiateRazorpayPayment(paymentData);
            } else {
                console.error("Something went wrong. Please try again later!!");
                alert("Something went wrong. Please try again later!!");
            }
        } catch (error) {
            console.error("Error during data fetch:", error);
            alert("Something went wrong. Please try again later!!");
        }
    };

    //payment gateway
    const initiateRazorpayPayment = async (paymentData) => {
        try {
            await loadRazorpayScript();

            const options = {
                key: "rzp_live_fgITphfrAGKnyj",
                amount: paymentData.amount,
                currency: paymentData.currency,
                name: "SEO Mitra",
                description: "Package Payment",
                order_id: paymentData.orderId,
                receipt: paymentData.receipt,

                handler: function (response) {
                    if (response?.razorpay_payment_id) {
                        handleAddPayment(paymentData.orderId, paymentData.amount);
                    } else {
                        alert("Payment failed. Please try again.");
                    }
                },

                prefill: {
                    name: "SEO Mitra",
                    email: "support@seomitra.com",
                    contact: "+919876543210",
                },

                notes: {
                    address: "SEO Mitra, Noida, Delhi",
                },
            };

            const rzp1 = new window.Razorpay(options);
            rzp1.open();

            rzp1.on("payment.failed", function (response) {
                alert("Payment failed. Please try again.");
            });
        } catch (error) {
            alert("Payment initiation failed. Please try again.");
        }
    };


    //completing payment
    const handleAddPayment = async (orderId, amount) => {

        const amountInPaisa = (amount / 100).toFixed(2);

        const params = {
            orderId: orderId,
            // packageId: packageId,
            paidAmt: amount,
        };

        try {
            const response = await invokeApi(
                config.getMyCollege + apiList.addPayment,
                params,
                cookies
            );

            if (response?.status === 200) {
                alert("Paid Successfully!")
                // navigate("/done-payment");
                navigate("/my-order");
            } else {
                console.error("Something went wrong. Please try again later!!");
                alert("Something went wrong. Please try again later!!");
            }
        } catch (error) {
            console.error("Error during data fetch:", error);
            alert("Something went wrong. Please try again later!!");
        }
    };

    const renderHeader = () => {
        if (roles && roles.length > 0) {
            if (roles.includes("Admin")) {
                return <Header1 />;
            } else {
                return <Header2 />;
            }
        }
        // Optional fallback in case `roles` is undefined or empty
        return <Header2 />;
    };


    return (
        <>
            {renderHeader()}
            <Box display="flex" gap={3} padding={2} flexWrap="wrap" sx={{ mt: 1 }}>
                {/* Left Side Cards */}
                <Box display="flex" flexDirection="column" gap={2} flex={1} minWidth={300}>
                    {/* Card 1 - Professional Content */}
                    <Card
                        variant="outlined"
                        sx={{
                            padding: 3,
                            backgroundColor: "#f9f9fb",
                            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                            borderRadius: 4,
                            maxWidth: 400,
                            margin: "auto",
                            overflow: "hidden",
                            position: "relative",
                        }}
                    >
                        <CardContent>
                            <Typography
                                variant="h5"
                                fontWeight="bold"
                                gutterBottom
                                sx={{ color: "primary.main", textAlign: "center" }}
                            >
                                Your Selected Backlinks
                            </Typography>

                            <Box sx={{ mt: 4 }}>
                                <TableContainer
                                    component={Paper}
                                    sx={{
                                        maxWidth: "100%", marginLeft: 0, borderRadius: 2, boxShadow: '0 4px 20px rgba(255, 72, 0, 0.1)',
                                    }}
                                >
                                    <Table >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ fontWeight: "bold", fontSize: "1rem", color: "#333" }}>
                                                    Name
                                                </TableCell>
                                                <TableCell sx={{ fontWeight: "bold", fontSize: "1rem", color: "#333" }}>
                                                    Price
                                                </TableCell>
                                                <TableCell sx={{ fontWeight: "bold", fontSize: "1rem", color: "#333" }}>
                                                    Action
                                                </TableCell>

                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {existingCart.map((row, index) => (
                                                <TableRow key={index} sx={{ "&:hover": { backgroundColor: "#f5f5f5" } }}>
                                                    <TableCell>
                                                        <Box display="flex" alignItems="center">
                                                            {/* {row.icon} */}
                                                            <Typography ml={1} sx={{ fontSize: "0.875rem", color: "#333" }}>
                                                                {row.title}
                                                            </Typography>
                                                        </Box>
                                                    </TableCell>
                                                    {/* <TableCell sx={{ color: "#333" }}>{row.reason}</TableCell> */}
                                                    <TableCell>
                                                        <Typography>{row.price}</Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Button
                                                            sx={{
                                                                height: "20px",
                                                                my: "3px",
                                                                fontSize: 13,
                                                                // "&.MuiButtonBase-root": {
                                                                //     border: "1px solid #c62828",
                                                                //     minWidth: 30,
                                                                //     py: 1,
                                                                // },
                                                                "@media (min-width: 1440px) and (max-width:2000px)":
                                                                {
                                                                    my: 0,
                                                                },
                                                            }}
                                                            onClick={() =>
                                                                removeCartItem(index)
                                                            }
                                                        >
                                                            <DeleteIcon
                                                                style={{
                                                                    fontSize: 18,
                                                                    color: "#c62828",
                                                                }}
                                                            />
                                                        </Button>
                                                    </TableCell>

                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>

                        </CardContent>
                    </Card>
                </Box>

                {/* Right Side - Payment Summary */}
                <Card variant="outlined" sx={{ flex: 1.5, padding: 2, backgroundColor: "#f9fafc", }}>
                    <CardContent>
                        <Grid sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                            <Grid>
                                <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                                    <Typography variant="body1" fontWeight="bold">Backlinks</Typography>
                                    <Box display="flex" alignItems="center">
                                        <Typography variant="body1">₹ {totalAmt}</Typography>

                                    </Box>
                                </Box>
                                <Box display="flex" justifyContent="space-between" alignItems="center" mt={1} mb={1}>
                                    <Typography variant="body1" fontWeight="bold">Taxes & Fees</Typography>
                                    <Box display="flex" alignItems="center">
                                        <Typography variant="body1">₹ 0
                                            {/* {gstAmount} */}

                                        </Typography>
                                        <Tooltip title="Includes all applicable taxes and fees">
                                            <IconButton size="small" sx={{ marginLeft: 0.5 }}>
                                                <InfoIcon fontSize="small" color="action" />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                </Box>

                                <Divider />
                                <Box display="flex" justifyContent="space-between" alignItems="center" mt={2} mb={1}>
                                    <Typography variant="body1" fontWeight="bold">Total</Typography>
                                    <Typography variant="body1" fontWeight="bold">₹ {totalAmt}</Typography>
                                </Box>
                            </Grid>
                            <Grid>
                                <Typography variant="body2" color="text.secondary" gutterBottom>
                                    By checking out, you agree with our <a href="#" style={{ color: "purple", textDecoration: 'none' }}>Terms of Service</a> and confirm that
                                    you have read our <a href="#" style={{ color: "purple", textDecoration: 'none' }}>Privacy Policy</a>. You can cancel recurring payments at any time.
                                </Typography>
                                <Box
                                    sx={{
                                        display: "flex",
                                        margin: "auto",
                                        backgroundColor: "#fff",
                                        borderRadius: "8px",
                                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                        overflow: "hidden",
                                    }}
                                >
                                    {/* Sidebar */}
                                    <Box
                                        sx={{
                                            width: "200px",
                                            backgroundColor: "#f8f9fa",
                                            padding: "20px 10px",
                                            borderRight: "1px solid #ddd",
                                        }}
                                    >
                                        <List disablePadding>
                                            <ListItem
                                                sx={{
                                                    padding: "10px 15px",
                                                    fontSize: "14px",
                                                    borderRadius: "4px",
                                                    marginBottom: "5px",
                                                    backgroundColor: "#e6f7ff",
                                                    color: "#007bff",
                                                    fontWeight: "bold",
                                                    cursor: "pointer",
                                                }}
                                            >
                                                UPI
                                            </ListItem>
                                            {/* {["UPI", "Cards", "EMI", "Netbanking", "Wallet"].map((item) => ( */}
                                            <ListItem
                                                //   key={item}
                                                sx={{
                                                    padding: "10px 15px",
                                                    backgroundColor: "#ffe6cc",
                                                    color: "#ff6600",
                                                    fontSize: "14px",
                                                    borderRadius: "4px",
                                                    marginBottom: "5px",
                                                    cursor: "pointer",
                                                    "&:hover": {
                                                        backgroundColor: "#ff6600",
                                                        color: "#fff",

                                                    },
                                                }}
                                                onClick={
                                                    handleCompletePayment
                                                }
                                            >
                                                Payment Gateway
                                            </ListItem>
                                            {/* ))} */}
                                        </List>
                                    </Box>



                                    {/* Main Content */}
                                    <Box
                                        sx={{
                                            flex: 1,
                                            padding: "20px",
                                        }}
                                    >
                                        {/* Header */}
                                        <Box
                                            sx={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                marginBottom: "15px",
                                            }}
                                        >
                                            <Typography variant="h6" sx={{ margin: 0 }}>
                                                UPI QR
                                            </Typography>
                                        </Box>
                                        <Divider sx={{ marginBottom: "15px" }} />

                                        {/* QR and Input Section */}
                                        <Box
                                            sx={{
                                                display: "flex",
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                gap: "20px",
                                            }}
                                        >


                                            {/* QR Code Section */}
                                            <Box
                                                sx={{
                                                    textAlign: "center",
                                                }}
                                            >
                                                <img
                                                    src={QrCode}
                                                    alt="QR Code"
                                                    style={{ width: "120px", height: "120px", marginBottom: "10px" }}
                                                />
                                                <Typography variant="body2">SCAN WITH ANY APP</Typography>
                                                {/* <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: "10px",
                marginTop: "10px",
              }}
            >
              <img
                src="https://via.placeholder.com/30"
                alt="App 1"
                style={{ width: "30px" }}
              />
              <img
                src="https://via.placeholder.com/30"
                alt="App 2"
                style={{ width: "30px" }}
              />
              <img
                src="https://via.placeholder.com/30"
                alt="App 3"
                style={{ width: "30px" }}
              />
            </Box> */}
                                            </Box>

                                            {/* Input Section */}
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    gap: "10px",
                                                }}
                                            >
                                                {/* <Box>
                                                    <img
                                                        src={UpiImage}
                                                        alt="QR Code"
                                                        style={{ width: "250px", height: "100px", marginBottom: "10px" }}
                                                    />
                                                </Box> */}

                                                <TextField
                                                    variant="outlined"
                                                    size="small"
                                                    // label="Amount"
                                                    placeholder="Amount"
                                                    sx={{
                                                        width: "250px",
                                                    }}
                                                    value={totalAmt}
                                                    error={!!errors.totalAmt}
                                                    helperText={errors.totalAmt}
                                                />

                                                <TextField
                                                    variant="outlined"
                                                    size="small"
                                                    placeholder="Payment ID"
                                                    value={payId}
                                                    onChange={(e) => setPayId(e.target.value)}
                                                    sx={{
                                                        width: "250px",
                                                    }}
                                                    error={!!errors.payId}
                                                    helperText={errors.payId}
                                                />
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    size='small'
                                                    sx={{
                                                        textTransform: "none",
                                                        padding: "10px",
                                                        backgroundColor: "#ff6600",
                                                        fontWeight: "bold",
                                                        "&:hover": {
                                                            backgroundColor: "#cc5200",
                                                        },
                                                    }}
                                                    onClick={handleVerifyAndPay}
                                                >
                                                    Verify and Pay
                                                </Button>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                                {/* <Box display="flex" justifyContent="space-between" mt={3}>
                                    <Button variant="contained" sx={{ background: "#ff8c00", color: '#fff' }} color="inherit" onClick={handleCompletePayment}>Complete payment</Button>
                                </Box> */}
                            </Grid>
                        </Grid>

                    </CardContent>
                </Card>
            </Box>
        </>
    );
}

export default PaymentBacklinks;