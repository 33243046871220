
import React, { useState } from 'react';
import { Box, Typography, List, ListItemButton, ListItemIcon, ListItemText, LinearProgress, Button } from '@mui/material';
import {
  Home as HomeIcon,
  DriveFolderUpload as DriveIcon,
  Laptop as ComputerIcon,
  Group as SharedIcon,
  AccessTime as RecentIcon,
} from '@mui/icons-material';

const sidebarItems = [
  { label: 'Home', icon: <HomeIcon /> },
  { label: 'Workspace', icon: <DriveIcon /> },
  { label: 'Users', icon: <ComputerIcon /> },
  { label: 'Files', icon: <SharedIcon /> },
];

const WorkspaceSidebar = () => {
  const [selectedIndex, setSelectedIndex] = useState(0); // Default selection is "Home"

  return (
    <Box
      sx={{
        mt: 10,
        width: 220,
        height:'auto',
        backgroundColor: '#ffffff',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '1.5rem',
        boxShadow: '2px 0 10px rgba(0, 0, 0, 0.1)',
        
      }}
    >
      {/* Sidebar List */}
      <List sx={{ width: '100%' }}>
        {sidebarItems.map((item, index) => (
          <ListItemButton
            key={index}
            sx={{
              width: '100%',
              color: selectedIndex === index ? '#007bff' : 'black', // Change color if selected
              borderRadius: 2,
              marginBottom: 1,
               mt:1,
              '&:hover': {
                backgroundColor: '#f1f1f1',
                transform: 'translateX(5px)',
                transition: 'transform 0.3s ease-in-out, background-color 0.3s ease-in-out',
              },
            }}
            onClick={() => setSelectedIndex(index)} // Set selected item
          >
            <ListItemIcon sx={{ color: selectedIndex === index ? '#007bff' : 'black' }}>
              {item.icon}
            </ListItemIcon>
            <ListItemText primary={item.label} />
          </ListItemButton>
        ))}
      </List>

      {/* Storage Section */}
      <Box sx={{ paddingX: 1, width: '100%' }}>
        <Typography
          variant="body1"
          sx={{
            fontWeight: 'bold',
            fontSize: '1rem',
            color: 'black',
            marginBottom: 1,
            textAlign: 'center',
          }}
        >
          Storage
        </Typography>
        <LinearProgress
          variant="determinate"
          value={(1.25 / 15) * 100}
          sx={{
            height: 8,
            borderRadius: 5,
            backgroundColor: '#e0e0e0',
            '& .MuiLinearProgress-bar': {
              backgroundColor: '#007bff', 
            },
          }}
        />
        <Typography
          variant="body2"
          sx={{
            marginTop: 1,
            fontSize: '0.875rem',
            color: 'black',
            textAlign: 'center',
          }}
        >
          1.25 GB of 15 GB used
        </Typography>
        <Button
          variant="contained"
          fullWidth
          sx={{
            marginTop: 1,
            textTransform: 'none',
            borderRadius: 2,
            fontSize: '0.875rem',
            backgroundColor: '#007bff',
            color: '#fff',
            '&:hover': {
              backgroundColor: '#0056b3', // Darker hover effect
            },
          }}
        >
          Get more storage
        </Button>
      </Box>
    </Box>
  );
};

export default WorkspaceSidebar;

