import React from 'react';
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Paper,
    Avatar,
    Stack,
    IconButton,
    Grid,
} from '@mui/material';
import { styled } from '@mui/system';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useTheme } from "../../../../../common/ThemeContext";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    fontWeight: 600,
    backgroundColor: '#f5f5f5',
    borderBottom: '1px solid #e0e0e0',
    padding: theme.spacing(1.5),
}));

const ProjectRow = styled(TableRow)({
    '&:hover': {
        backgroundColor: '#f9f9f9',
    },
});

const data = [
    {
        projectName: 'Tech sites',
        avgPos: '-',
        traffic: '-',
        websites: 2,
        keywords: 0,
        participants: ['YB', 'SS'],
    },
    {
        projectName: 'Marketing insights',
        avgPos: 2,
        traffic: 1250,
        websites: 5,
        keywords: 120,
        participants: ['AL', 'KT', 'NP'],
    },
];

function ProjectUser({ rows = data }) {

    const { theme } = useTheme();


    return (
        <Grid container justifyContent="center" sx={{ p: 2, backgroundColor: theme.palette.mode !== "dark" ? "#1c1c1e" : "#fbf9fb" }}>
            <TableContainer component={Paper} elevation={1} sx={{ borderRadius: '8px' }}>
                <Table >
                    <TableHead sx={{}}>
                        <TableRow sx={{  }}>
                            <StyledTableCell>Project Name</StyledTableCell>
                            <StyledTableCell align="center">
                                <Stack direction="row" alignItems="center" spacing={0.5}>
                                    <Typography>Avg. Pos.</Typography>
                                    <HelpOutlineIcon fontSize="small" color="disabled" />
                                </Stack>
                            </StyledTableCell>
                            <StyledTableCell align="center">Traffic</StyledTableCell>
                            <StyledTableCell align="center">Websites</StyledTableCell>
                            <StyledTableCell align="center">Keywords</StyledTableCell>
                            <StyledTableCell align="center">Participants</StyledTableCell>
                            <StyledTableCell align="right">Actions</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, index) => (
                            <ProjectRow key={index}>
                                <TableCell component="th" scope="row">{row.projectName}</TableCell>
                                <TableCell align="center">{row.avgPos}</TableCell>
                                <TableCell align="center">{row.traffic}</TableCell>
                                <TableCell align="center">{row.websites}</TableCell>
                                <TableCell align="center">{row.keywords}</TableCell>
                                <TableCell align="center">
                                    <Stack direction="row" spacing={1}>
                                        {row.participants.map((initials, i) => (
                                            <Avatar
                                                key={i}
                                                sx={{ bgcolor: '#b39ddb', width: 32, height: 32, fontSize: '0.875rem' }}
                                            >
                                                {initials}
                                            </Avatar>
                                        ))}
                                    </Stack>
                                </TableCell>
                                <TableCell align="right">
                                    <IconButton size="small">
                                        <MoreVertIcon />
                                    </IconButton>
                                </TableCell>
                            </ProjectRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    );
}

export default ProjectUser;
