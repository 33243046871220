import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useFetch from "../../pages/useFetch";

const CommonHomePage = () => {
  const { name, roles } = useFetch();
  const navigate = useNavigate();

  useEffect(() => {
    // Navigate based on user roles
    if (roles && roles.length > 0) {
        if (roles.includes("Admin")) {
          navigate("/user-dashboard");
        } else {
          navigate("/");
        }
      }
  }, [roles, navigate]);

  return null; // Return nothing since navigation happens immediately
};

export default CommonHomePage;
