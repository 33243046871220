import React from 'react';
import { motion } from 'framer-motion';
import { Box, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import Donepaymentsidebar from './Donepaymentsidebar';
import { useTheme } from '../../../common/ThemeContext';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Dashboardheader from '../../common/DashboardHeader';

const DonePayment = () => {
    const { theme } = useTheme();
    const rows = [];

    const containerVariants = {
        hidden: { opacity: 0, y: 50 },
        visible: {
            opacity: 1,
            y: 0,
            transition: { type: 'spring', stiffness: 100, damping: 15, staggerChildren: 0.3 },
        },
    };

    const itemVariants = {
        hidden: { opacity: 0, scale: 0.8 },
        visible: { opacity: 1, scale: 1, transition: { type: 'spring', stiffness: 100 } },
    };

    return (
        <>
            <Grid container>
                {/* Sidebar */}
                <Grid
                    item
                    xs={12}
                    sm={3}
                    md={2}
                    sx={{
                        position: 'sticky',
                        top: 0,
                        height: '100vh',
                        zIndex: 1,
                    }}
                >
                    <Dashboardheader />
                    <Donepaymentsidebar />
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={9}
                    md={10}
                    sx={{ padding: { xs: 2, sm: 3, md: 4 } }}
                >
                    {/* Animated Header */}
                    <motion.div
                        initial={{ opacity: 0, x: -100 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ type: 'spring', stiffness: 100, damping: 10, delay: 0.3 }}
                    >
                        <Box display="flex" flexDirection="column" gap={2} minWidth={300}>
                            <Typography
                                variant="h4"
                                fontWeight="bold"
                                gutterBottom
                                sx={{ textAlign: 'left', marginTop: { xs: 2, sm: 8, md: 12 } }}
                            >
                                Welcome to SEO Mitra
                            </Typography>
                            <Typography
                                sx={{
                                    textAlign: 'left',
                                    fontSize:23,
                                    fontFamily:'inherit'
                                }}
                            >
                                Your backlink document will be updated within 24 hours, and you will also receive an email.
                            </Typography>
                        </Box>
                    </motion.div>
                    {/* Animated Table */}
                    <motion.div
                        variants={containerVariants}
                        initial="hidden"
                        animate="visible"
                        style={{ marginTop: '2rem' }}
                    >
                        <TableContainer
                            component={motion.div}
                            variants={itemVariants}
                            sx={{
                                width: '100%',
                                borderRadius: 2,
                                boxShadow: '0 4px 20px rgba(255, 72, 0, 0.1)',
                            }}
                        >
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ fontWeight: 'bold', fontSize: '1rem', color: '#333' }}>Name</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold', fontSize: '1rem', color: '#333' }}>Size</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold', fontSize: '1rem', color: '#333' }}>Workspace</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold', fontSize: '1rem', color: '#333' }}>Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.length === 0 ? (
                                        <TableRow>
                                            <TableCell colSpan={4} align="center" sx={{ color: '#999', fontStyle: 'italic' }}>
                                                No data found
                                            </TableCell>
                                        </TableRow>
                                    ) : (
                                        rows.map((row, index) => (
                                            <TableRow
                                                key={index}
                                                sx={{ '&:hover': { backgroundColor: '#f5f5f5' } }}
                                            >
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell>
                                                    <IconButton
                                                        aria-label="more"
                                                        sx={{
                                                            color: '#fe8a00',
                                                            '&:hover': { backgroundColor: '#f0f0f0' },
                                                        }}
                                                    >
                                                        <MoreVertIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </motion.div>
                </Grid>
            </Grid>
        </>
    );
};

export default DonePayment;