import React from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Paper, Link, IconButton, Button, Box,
  Grid
} from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

// Sample data based on the image
const rows = [
  { title: 'GetMyCollege Gains Attention for Its Comprehensive ...', url: 'issuewire.com', visits: 1, keywords: 'Keywords', backlinks: 0, fb: 0, pinterest: 0, reddit: 0 },
  { title: 'GetMyCollege Ahmedabad Review by 1 Employee 2024', url: 'ambitionbox.com', visits: 1, keywords: 'Keywords', backlinks: 0, fb: 0, pinterest: 0, reddit: 0 },
  { title: 'B.Pharma colleges in Gujarat | Getmycollege', url: 'reverbnation.com', visits: 0, keywords: 'Keywords', backlinks: 0, fb: 0, pinterest: 0, reddit: 0 },
  { title: 'Does GetMyCollege provide information on college ...', url: 'thestudentroom.co.uk', visits: 0, keywords: 'Keywords', backlinks: 0, fb: 0, pinterest: 0, reddit: 0 },
  { title: 'Find Top B.PHARM Colleges in India - GetMyCollege', url: 'getmycollege.org', visits: 0, keywords: 'Keywords', backlinks: 0, fb: 0, pinterest: 0, reddit: 0 },
  { title: 'Getmycollege • Ads of the World™ | Part of The Clio Network', url: 'adsoftheworld.com', visits: 0, keywords: 'Keywords', backlinks: 0, fb: 0, pinterest: 0, reddit: 0 },
];

const ContentIdeasTable = () => {
  return (
    <Grid sx={{p:6}}>
    <TableContainer  sx={{ }}>
      <Typography variant="h5" sx={{ py:1, fontWeight: 'bold', color: '#d1d1d1' }}>
        CONTENT IDEAS
      </Typography>
      <Table>
        <TableHead sx={{ border: '1px solid #4c4452' }}>
          <TableRow sx={{  }}>
            <TableCell sx={{ fontWeight: 'bold', color: '#d1d1d1',border: '1px solid #4c4452' }}>PAGE TITLE URL</TableCell>
            <TableCell align="center" sx={{ fontWeight: 'bold', color: '#d1d1d1',border: '1px solid #4c4452' }}>EST. VISITS</TableCell>
            <TableCell align="center" sx={{ fontWeight: 'bold', color: '#d1d1d1',border: '1px solid #4c4452' }}>BACKLINKS</TableCell>
            <TableCell align="center" sx={{ fontWeight: 'bold', color: '#d1d1d1',border: '1px solid #4c4452' }}>FB</TableCell>
            <TableCell align="center" sx={{ fontWeight: 'bold', color: '#d1d1d1',border: '1px solid #4c4452' }}>Pinterest</TableCell>
            <TableCell align="center" sx={{ fontWeight: 'bold', color: '#d1d1d1',border: '1px solid #4c4452', }}>Reddit</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row" sx={{border: '1px solid #4c4452'}}>
                <Typography sx={{ fontWeight: 'bold', color: '#f76b1c', }}>{row.title}</Typography>
                <Link href={`https://${row.url}`} target="_blank" sx={{ color: '#999', textDecoration: 'none' }}>
                  {row.url} 
                  <IconButton size="small" sx={{ color: '#333' }}>
                    <OpenInNewIcon fontSize="small" />
                  </IconButton>
                </Link>
              </TableCell>
              <TableCell align="center" sx={{border: '1px solid #4c4452'}}>
                <Button variant="outlined" size="small" sx={{ color: '#f76b1c', borderColor: '#f76b1c', fontWeight: 'bold',border: '1px solid #4c4452' }}>
                  {row.visits} {row.visits > 0 ? row.keywords : ''}
                </Button>
              </TableCell>
              <TableCell align="center" sx={{border: '1px solid #4c4452'}}>
                <Button variant="outlined" size="small" sx={{ color: '#999', borderColor: '#ccc', fontWeight: 'bold' }}>
                  {row.backlinks} Links
                </Button>
              </TableCell>
              <TableCell align="center" sx={{ color: '#999',border: '1px solid #4c4452' }}>{row.fb}</TableCell>
              <TableCell align="center" sx={{ color: '#999',border: '1px solid #4c4452' }}>{row.pinterest}</TableCell>
              <TableCell align="center" sx={{ color: '#999',border: '1px solid #4c4452' }}>{row.reddit}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    <Grid textAlign={'center'} sx={{mt:5}}>
        <Button sx={{border: '1px solid #da611e',color:'#da611e',fontWeight:'bold'}}>
            VIEW ALL CONTENT IDEAS
        </Button>
    </Grid>
    </Grid>
  );
};

export default ContentIdeasTable;
