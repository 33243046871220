import React, { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  Paper,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Box,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const PlagiarismChecker = () => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
    
      <Grid
       
        sx={{
          backgroundColor: '#f5f5f5',
          minHeight: '100vh',
          padding: 4,
           backgroundColor:'#fff9f7'
        }}
      >
        <Grid container spacing={3} >
          {/* Main Content Section */}
          <Grid item xs={12} md={9}>
            <Paper
              elevation={6}
              sx={{
                padding: '20px',
                backgroundColor: '#fff',
                boxShadow: '0 4px 20px rgba(255, 72, 0, 0.1)'
              }}
            >        

              <Typography variant="body1" color="textSecondary" paragraph sx={{fontWeight:'bold'}}>
                To use this plagiarism checker, please copy and paste your text in the box above, 
                then click on the big blue button that says “Check Plagiarism” and sit back as your 
                article is scanned for duplicate content.
              </Typography>

              {/* Accordion Section */}
              <Accordion
                expanded={expanded === 'panel1'}
                onChange={handleChange('panel1')}
                sx={{boxShadow: '0 4px 20px rgba(255, 72, 0, 0.1)'}}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="h8" sx={{fontWeight:'bold'}}>Benefits of Our Plagiarism Detector</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2" paragraph >
                    Our plagiarism checker is second to none due to the advantages it provides to 
                    its users. Whether you're a student, teacher, or professional writer, you can 
                    gain significant benefits using our tool.
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === 'panel2'}
                onChange={handleChange('panel2')}
                sx={{boxShadow: '0 4px 20px rgba(255, 72, 0, 0.1)'}}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="h8" sx={{fontWeight:'bold'}}>Quick and Hassle-Free</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2" paragraph>
                    Our online tool generates results within a few seconds. The user-friendly 
                    interface ensures a seamless process, free of convoluted steps.
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === 'panel3'}
                onChange={handleChange('panel3')}
                sx={{boxShadow: '0 4px 20px rgba(255, 72, 0, 0.1)'}}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="h8" sx={{fontWeight:'bold'}}>Multi-Platform Supportive</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2" paragraph>
                    Our plagiarism detector is accessible across all devices and platforms. Whether 
                    you use Android, iOS, Windows, Linux, or Mac, our tool ensures compatibility 
                    and ease of use.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === 'panel3'}
                onChange={handleChange('panel3')}
                sx={{boxShadow: '0 4px 20px rgba(255, 72, 0, 0.1)'}}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="h8" sx={{fontWeight:'bold'}}>Multi-Platform Supportive</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2" paragraph>
                    Our plagiarism detector is accessible across all devices and platforms. Whether 
                    you use Android, iOS, Windows, Linux, or Mac, our tool ensures compatibility 
                    and ease of use.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === 'panel3'}
                onChange={handleChange('panel3')}
                sx={{boxShadow: '0 4px 20px rgba(255, 72, 0, 0.1)'}}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="h8" sx={{fontWeight:'bold'}}>Multi-Platform Supportive</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2" paragraph>
                    Our plagiarism detector is accessible across all devices and platforms. Whether 
                    you use Android, iOS, Windows, Linux, or Mac, our tool ensures compatibility 
                    and ease of use.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === 'panel3'}
                onChange={handleChange('panel3')}
                sx={{boxShadow: '0 4px 20px rgba(255, 72, 0, 0.1)'}}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="h8" sx={{fontWeight:'bold'}}>Multi-Platform Supportive</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2" paragraph>
                    Our plagiarism detector is accessible across all devices and platforms. Whether 
                    you use Android, iOS, Windows, Linux, or Mac, our tool ensures compatibility 
                    and ease of use.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Paper>
          </Grid>          
        </Grid>
      </Grid>
    </>
  );
};

export default PlagiarismChecker;
