
import React, { useEffect, useState } from 'react';
import {
    Box,
    Typography,
    Card,
    CardContent,
    IconButton,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Menu,
    MenuItem,
    Avatar,
    Button,
    Dialog,
    TextField,
    InputLabel,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Folder, People } from '@mui/icons-material';
import { motion, AnimatePresence } from "framer-motion";
import { styled } from '@mui/system';
import { apiList, invokeApi } from '../../../services/apiServices';
import { config } from '../../../config/config';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import SidebarFirst from '../common/SidebarFirst';
import Dashboardheader from '../common/DashboardHeader';
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { useTheme } from '../../common/ThemeContext';



const AnimatedTextField = styled(TextField)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    "& .MuiOutlinedInput-root": {
        borderRadius: "5px",
        "& fieldset": {
            borderColor: "#9e9e9e",
        },
        "&:hover fieldset": {
            borderColor: "#ed6c02",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#ed6c02",
            transition: "border-color 0.3s ease-in-out",
        },
    },
    "& .MuiInputLabel-root": {
        color: "#9e9e9e",
    },
    "& .MuiInputLabel-root.Mui-focused": {
        color: "#ed6c02",
    },
}));

const WorkspaceFirst = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);
    const [open, setOpen] = useState(false);
    const [workspaceName, setWorkspaceName] = useState("");
    const [workspaces, setWorkspaces] = useState([]);
    const [cookies] = useCookies();
    const navigate = useNavigate();
    const { theme } = useTheme();


    const handleMenuOpen = (event, rowName) => {
        setAnchorEl(event.currentTarget);
        setSelectedRow(rowName); // Save the unique identifier of the row
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setSelectedRow(null); // Reset the selected row
    };

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleNavigateWorkspace = (id) => {
        navigate(`/projects/${id}`)
    }

    const handleAddWorkspace = async (workspace) => {
        try {
            const params = {
                name: workspace,
            };
            const response = await invokeApi(
                config.getMyCollege + apiList.addWorkspace,
                params,
                cookies
            );
            if (response?.status === 200) {
                console.log("workspace Added Succesfully");
                fetchWorkspaceData();
                handleClose();
            } else {
                console.error("Failed to add workspace:", response);
            }
        } catch (error) {
            console.error("Error add workspace:", error);
        }
    };

    const fetchWorkspaceData = async () => {
        try {
            const params = {};
            const response = await invokeApi(
                config.getMyCollege + apiList.getWorkspaces,
                params,
                cookies
            );
            if (response?.status === 200) {
                // console.log("workspace Added Succesfully");
                setWorkspaces(response.data.workspaces)
            } else {
                console.error("Failed to get workspace:", response);
            }
        } catch (error) {
            console.error("Error get workspace:", error);
        }
    };

    useEffect(() => {
        fetchWorkspaceData();
    }, [])

    const rows = [
        {
            name: 'File1',
            icon: <Folder fontSize="small" color="success" />,
            reason: '1.12MB',
            owner: 'Space1',
        },
        {
            name: 'PU Colleges Report Sheet',
            icon: <People fontSize="small" color="primary" />,
            reason: 'You modified • 15:13',
            owner: 'me',
        },
    ];

    return (
        <>
            <Grid container sx={{ backgroundColor: theme.palette.mode !== "dark" ? '#1d1922' : '#f8f3f7', height: '100vh' }}>
                <Grid item md={2}>
                    <Dashboardheader />
                    <SidebarFirst />
                </Grid>
                <Grid item md={9.8} sx={{ backgroundColor: theme.palette.mode !== "dark" ? '#1d1922' : '#f8f3f7', }}>
                    {/* Welcome Text */}
                    <Typography
                        variant="h4"
                        fontWeight="bold"
                        gutterBottom
                        sx={{ color: theme.palette.mode !== "dark" ? '#d1d1d1' : "#202020", textAlign: "left", mt: 16 }}
                    >
                        Welcome to SEO Mitra
                    </Typography>

                    {/* Card Section */}
                    <Grid
                        container
                        spacing={2}
                        sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                        }}
                    >
                        {/* Add File Card */}
                        <Grid item xs={12} sm={6} md={3}>
                            <Card
                                onClick={handleOpen}
                                sx={{
                                    boxShadow: '0 4px 20px rgba(255, 72, 0, 0.1)',
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: 150,
                                    transition: "transform 0.3s ease-in-out",
                                    "&:hover": {
                                        transform: "scale(1.05)",
                                    },
                                }}
                            >
                                <CardContent
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "100%",
                                        padding: 3,
                                    }}
                                >
                                    <IconButton sx={{ fontSize: "75px", color: "black" }}>
                                        <AddCircleOutlineOutlinedIcon sx={{ fontSize: "80px", color: "#757575" }} />
                                    </IconButton>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth sx={{ borderRadius: "8px", boxShadow: 3 }}>
                            <Box
                                sx={{
                                    maxWidth: "500px",
                                    bgcolor: "background.paper",
                                    p: 3,
                                    borderRadius: 2,
                                    boxShadow: 3,
                                }}
                            >
                                <AnimatePresence mode="wait">
                                    <motion.div
                                        initial={{ opacity: 0, y: 30 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        exit={{ opacity: 0, y: -30 }}
                                        transition={{ duration: 0.2, ease: "easeInOut" }}
                                    >
                                        <Typography
                                            gutterBottom
                                            sx={{
                                                color: "#FF8C42",
                                                fontSize: "23px",
                                                fontWeight: "bold",
                                                mb: 3,
                                                textAlign: "center",
                                            }}
                                        >
                                            Add Workspace
                                        </Typography>
                                        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                                            <InputLabel sx={{ fontSize: "16px", fontWeight: "500" }}>
                                                Workspace
                                            </InputLabel>
                                            <AnimatedTextField
                                                size="small"
                                                label="Name"
                                                name="workspaceName"
                                                value={workspaceName}
                                                onChange={(e) => { setWorkspaceName(e.target.value) }}
                                                fullWidth
                                            />
                                        </Box>
                                        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 4 }}>
                                            <Button variant="contained" color="warning" onClick={() => { handleClose() }}>
                                                Close
                                            </Button>
                                            <Button variant="contained" color="warning" onClick={() => { handleAddWorkspace(workspaceName) }}>
                                                Add
                                            </Button>
                                        </Box>
                                    </motion.div>
                                </AnimatePresence>
                            </Box>
                        </Dialog>

                        {/* Workspace Cards */}
                        {workspaces.map((workspace, index) => (
                            <Grid item xs={12} sm={6} md={3} key={index}>
                                <Card
                                    onClick={() => handleNavigateWorkspace(workspace.id)}
                                    sx={{

                                        boxShadow: '0 4px 20px rgba(255, 72, 0, 0.1)',
                                        height: 150,
                                        transition: "transform 0.3s ease-in-out",
                                        "&:hover": {
                                            transform: "scale(1.05)",
                                        },
                                    }}
                                >
                                    <CardContent
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            height: "100%",
                                            padding: 0,
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontWeight: "bold",
                                                fontSize: 18,
                                                textAlign: "center",
                                                overflowWrap: "break-word",
                                            }}
                                        >
                                            {workspace.name}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                    {/* Add File Button Section */}
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', marginTop: 4 }}>
                        <Button
                            variant="contained"
                            startIcon={<AddIcon />}
                            sx={{
                                textTransform: 'none',
                                fontSize: '0.875rem',
                                borderRadius: 2,
                                fontWeight: 'bold',
                                padding: '6px 16px',
                                backgroundColor: '#fe8a00',
                                '&:hover': {
                                    backgroundColor: '#fe8a00',
                                },
                            }}
                        >
                            Add File
                        </Button>
                    </Box>


                    {/* Suggested Files Table */}
                    <Box sx={{ mt: 4 }}>
                        <TableContainer
                            component={Paper}
                            sx={{
                                maxWidth: "100%", marginLeft: 0, borderRadius: 2, boxShadow: '0 4px 20px rgba(255, 72, 0, 0.1)',
                            }}
                        >
                            <Table sx={{ minWidth: 650 }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ fontWeight: "bold", fontSize: "1rem", color: "#333" }}>
                                            Name
                                        </TableCell>
                                        <TableCell sx={{ fontWeight: "bold", fontSize: "1rem", color: "#333" }}>
                                            Size
                                        </TableCell>
                                        <TableCell sx={{ fontWeight: "bold", fontSize: "1rem", color: "#333" }}>
                                            Workspace
                                        </TableCell>
                                        <TableCell sx={{ fontWeight: "bold", fontSize: "1rem", color: "#333" }}>
                                            Actions
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row, index) => (
                                        <TableRow key={index} sx={{ "&:hover": { backgroundColor: "#f5f5f5" } }}>
                                            <TableCell>
                                                <Box display="flex" alignItems="center">
                                                    {/* {row.icon} */}
                                                    <Typography ml={1} sx={{ fontSize: "0.875rem", color: "#333" }}>
                                                        {row.name}
                                                    </Typography>
                                                </Box>
                                            </TableCell>
                                            <TableCell sx={{ color: "#333" }}>{row.reason}</TableCell>
                                            <TableCell>
                                                <Typography>11</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <IconButton
                                                    aria-label="more"
                                                    onClick={(event) => handleMenuOpen(event, row.name)}
                                                    sx={{
                                                        color: "#fe8a00",
                                                        "&:hover": { backgroundColor: "#f0f0f0" },
                                                    }}
                                                >
                                                    <MoreVertIcon />
                                                </IconButton>
                                                {/* Menu */}
                                                <Menu
                                                    anchorEl={anchorEl}
                                                    open={Boolean(anchorEl) && selectedRow === row.name}
                                                    onClose={handleMenuClose}
                                                >
                                                    <MenuItem onClick={handleMenuClose}>Share</MenuItem>
                                                    <MenuItem onClick={handleMenuClose}>Download</MenuItem>
                                                </Menu>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Grid>


            </Grid>


        </>
    );
};

export default WorkspaceFirst;
