// import React, { useEffect, useState } from "react";
// import {
//   Box,
//   Typography,
//   Button,
//   IconButton,
//   ToggleButton,
//   ToggleButtonGroup,
//   Switch,
// } from "@mui/material";
// import { Add, Delete, AccessTime } from "@mui/icons-material";
// import { TimePicker, LocalizationProvider } from "@mui/x-date-pickers";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { useTheme } from "../../../../../common/ThemeContext";
// import dayjs from "dayjs";
// import { apiList, invokeApi } from "../../../../../../services/apiServices";
// import { config } from "../../../../../../config/config";
// import { useCookies } from "react-cookie";

// const defaultDays = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
// const defaultTime = dayjs().hour(8).minute(0); // Default time set to 08:00 AM

// const SetUpScheduleTab = ({
//   schedules,
//   setSchedules,
//   addSchedule,
//   handleNext,
//   handleBack,
// }) => {
//   const { theme } = useTheme();
//   const [cookies] = useCookies();

//   const [schedulesState, setSchedulesState] = useState([]);
//   const [schedulesDays,setSchedulesDays]=useState([])

//   useEffect(() => {
//     if (schedules.length === 0) {
//       const initialSchedule = {
//         id: Date.now(),
//         selectedDays: defaultDays,
//         time: defaultTime,
//         isActive: true,
//       };
//       setSchedules([initialSchedule]);
//     }
//     setSchedulesState(schedules);
//   }, [schedules, setSchedules]);

//   const fetchAutoSchedulesData = async (domainName) => {
//     const params = {};
//     try {
//       const response = await invokeApi(
//         config.getMyCollege + apiList.getAutoSchedules,
//         params,
//         cookies
//       );
//       if (response?.status === 200) {
//         console.log("Auto Schedules Successfully");
//         // navigate('/addweb-tabhome');
//       } else {
//         console.error("Failed to fetch data:", response);
//       }
//     } catch (error) {
//       console.error("Error during data fetch:", error);
//     }
//   };

//   useEffect(()=>{
//     fetchAutoSchedulesData();
//   },[])

//   const handleDayChange = (id, newDays) => {
//     setSchedulesState((prevSchedules) =>
//       prevSchedules.map((schedule) =>
//         schedule.id === id ? { ...schedule, selectedDays: newDays } : schedule
//       )
//     );
//   };

//   const handleTimeChange = (id, newTime) => {
//     setSchedulesState((prevSchedules) =>
//       prevSchedules.map((schedule) =>
//         schedule.id === id ? { ...schedule, time: newTime } : schedule
//       )
//     );
//   };

//   const handleToggleChange = (id) => {
//     setSchedulesState((prevSchedules) =>
//       prevSchedules.map((schedule) =>
//         schedule.id === id
//           ? { ...schedule, isActive: !schedule.isActive }
//           : schedule
//       )
//     );
//   };

//   const deleteSchedule = (id) => {
//     setSchedulesState((prevSchedules) =>
//       prevSchedules.filter((schedule) => schedule.id !== id)
//     );
//   };

//   const saveSchedules = () => {
//     setSchedules(schedulesState); // Call this only when saving
//   };

//   const handleAddSchedule = () => {
//     const newSchedule = {
//       id: Date.now(),
//       selectedDays: defaultDays,
//       time: defaultTime,
//       isActive: true,
//     };
//     setSchedulesState((prevSchedules) => [...prevSchedules, newSchedule]);
//   };

//   return (
//     <Box
//       sx={{
//         p: 3,
//         backgroundColor: theme.palette.mode !== "dark" ? "#312b36" : "#fff",
//         border:
//           theme.palette.mode !== "dark"
//             ? "1px solid #4a4a4a"
//             : "1px solid #f2ebf0",
//         borderRadius: 2,
//         boxShadow: 1,
//       }}
//     >
//       <Box
//         display="flex"
//         justifyContent="space-between"
//         alignItems="center"
//         mb={2}
//       >
//         <Typography
//           variant="body1"
//           sx={{
//             fontWeight: "bold",
//             color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000",
//           }}
//         >
//           Schedules ({schedulesState.length})
//         </Typography>
//         <Button
//           onClick={handleAddSchedule}
//           variant="text"
//           startIcon={<Add />}
//           sx={{
//             textTransform: "uppercase",
//             fontWeight: "bold",
//             color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000",
//             backgroundColor: theme.palette.mode !== "dark" ? "#312b36" : "#fff",
//             border:
//               theme.palette.mode !== "dark"
//                 ? "1px solid #4a4a4a"
//                 : "1px solid #f2ebf0",
//           }}
//         >
//           Add Schedule
//         </Button>
//       </Box>

//       {schedulesState.map((schedule) => (
//         <Box
//           key={schedule.id}
//           mt={2}
//           sx={{
//             pb: 2,
//             color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000",
//             backgroundColor: theme.palette.mode !== "dark" ? "#312b36" : "#fff",
//             border:
//               theme.palette.mode !== "dark"
//                 ? "1px solid #4a4a4a"
//                 : "1px solid #f2ebf0",
//             borderRadius: 2,
//             mt: 1,
//           }}
//         >
//           <Box display="flex" alignItems="center" mt={2}>
//             <ToggleButtonGroup
//               value={schedule.selectedDays}
//               onChange={(event, newDays) =>
//                 handleDayChange(schedule.id, newDays)
//               }
//               aria-label="days selection"
//               sx={{
//                 flexWrap: "wrap",
//                 ml: 2,
//                 color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000",
//                 backgroundColor:
//                   theme.palette.mode !== "dark" ? "#1c1c1e" : "#fff",
//                 border:
//                   theme.palette.mode !== "dark"
//                     ? "1px solid #4a4a4a"
//                     : "1px solid #f2ebf0",
//                 borderRadius: "8px",
//               }}
//             >
//               {defaultDays.map((day) => (
//                 <ToggleButton
//                   key={day}
//                   value={day}
//                   aria-label={day}
//                   sx={{
//                     width: 50,
//                     height: 40,
//                     color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000",
//                     borderColor:
//                       theme.palette.mode !== "dark" ? "#4a4a4a" : "#f2ebf0",
//                     "&.Mui-selected": {
//                       color: theme.palette.mode === "dark" ? "#fff" : "#000",
//                       backgroundColor:
//                         theme.palette.mode !== "dark" ? "#4a4a4a" : "#ddd",
//                       borderColor:
//                         theme.palette.mode === "dark" ? "#000" : "#4a4a4a",
//                     },
//                     "&:hover": {
//                       backgroundColor:
//                         theme.palette.mode === "dark" ? "#5a5a5a" : "#3b3b3b",
//                     },
//                   }}
//                 >
//                   {day}
//                 </ToggleButton>
//               ))}
//             </ToggleButtonGroup>

//             <Box display="flex" alignItems="center" ml={2}>
//               <LocalizationProvider dateAdapter={AdapterDayjs}>
//                 <TimePicker
//                   slotProps={{ textField: { size: "small" } }}
//                   value={schedule.time}
//                   onChange={(newTime) => handleTimeChange(schedule.id, newTime)}
//                   componentsProps={{
//                     // Hide the text input completely
//                     textField: { sx: { display: "none" } },
//                   }}
//                   renderInput={() => (
//                     <Box
//                       display="flex"
//                       alignItems="center"
//                       sx={{
//                         cursor: "pointer",
//                         p: 1,
//                         border: `1px solid ${theme.palette.mode === "dark" ? "#4a4a4a" : "#f2ebf0"
//                           }`,
//                         borderRadius: "8px",
//                       }}
//                     >
//                       <IconButton
//                         onClick={() =>
//                           handleTimeChange(schedule.id, schedule.time)
//                         } // Open picker on button click
//                         sx={{
//                           color:
//                             theme.palette.mode !== "dark" ? "#e3e0e6" : "#000",
//                           transition: "transform 0.3s ease-in-out",
//                           "&:hover": {
//                             transform: "scale(1.1)",
//                             color: theme.palette.primary.main,
//                           },
//                         }}
//                       >
//                         <AccessTime />
//                       </IconButton>
//                       <Typography
//                         variant="body2"
//                         sx={{
//                           ml: 1,
//                           color:
//                             theme.palette.mode !== "dark" ? "#e3e0e6" : "#000",
//                           fontWeight: "bold",
//                         }}
//                       >
//                         {schedule.time.format("hh:mm A")}
//                       </Typography>
//                     </Box>
//                   )}
//                 />
//               </LocalizationProvider>
//             </Box>

//             <Switch
//               checked={schedule.isActive}
//               onChange={() => handleToggleChange(schedule.id)}
//               color="primary"
//               sx={{ ml: 2 }}
//             />
//             <IconButton
//               color="error"
//               onClick={() => deleteSchedule(schedule.id)}
//             >
//               <Delete />
//             </IconButton>
//           </Box>
//         </Box>
//       ))}

//       <Box display="flex" justifyContent="space-between" mt={2}>
//         <Button
//           variant="text"
//           color="primary"
//           onClick={handleBack}
//           sx={{
//             fontWeight: "bold",
//             color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000",
//             backgroundColor: theme.palette.mode !== "dark" ? "#312b36" : "#fff",
//             border:
//               theme.palette.mode !== "dark"
//                 ? "1px solid #4a4a4a"
//                 : "1px solid #f2ebf0",
//           }}
//         >
//           Back
//         </Button>
//         <Button
//           variant="contained"
//           color="primary"
//           onClick={() => {
//             saveSchedules();
//             handleNext();
//           }}
//           sx={{ fontWeight: "bold" }}
//         >
//           Save & Continue
//         </Button>
//       </Box>
//     </Box>
//   );
// };

// export default SetUpScheduleTab;





import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  Switch,
} from "@mui/material";
import { Add, Delete, AccessTime } from "@mui/icons-material";
import { TimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useTheme } from "../../../../../common/ThemeContext";
import dayjs from "dayjs";
import { apiList, invokeApi } from "../../../../../../services/apiServices";
import { config } from "../../../../../../config/config";
import { useCookies } from "react-cookie";

const defaultDays = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
const defaultTime = dayjs().hour(8).minute(0); // Default time set to 08:00 AM

const SetUpScheduleTab = ({
  schedules,
  setSchedules,
  addSchedule,
  handleNext,
  handleBack,
}) => {
  const { theme } = useTheme();
  const [cookies] = useCookies();
  const [schedulesState, setSchedulesState] = useState([]);

  const mapDays = (dayNumbers) =>
    dayNumbers.split(",").map((dayNum) => defaultDays[parseInt(dayNum) - 1]);

  const convertDaysToNumbers = (selectedDays) =>
    selectedDays.map((day) => defaultDays.indexOf(day) + 1);

  // Fetch all schedules from API
  const fetchAutoSchedulesData = async () => {
    try {
      const response = await invokeApi(
        config.getMyCollege + apiList.getAutoSchedules,
        {},
        cookies
      );
      if (response?.status === 200) {
        const fetchedSchedules = response.data.autoSchedules.map((schedule) => ({
          id: schedule.id,
          scheduleId: schedule.scheduleId,
          selectedDays: mapDays(schedule.days),
          time: dayjs(schedule.time, "HH:mm"),
          isActive: schedule.status === "Active",
        }));
        setSchedulesState(fetchedSchedules);
      } else {
        console.error("Failed to fetch data:", response);
      }
    } catch (error) {
      console.error("Error during data fetch:", error);
    }
  };

  // Function to update an existing schedule via API call
  const updateAutoSchedule = async (schedule) => {
    const params = {
      projectId: "121913",
      scheduleId: schedule.scheduleId,
      scheduleDays: convertDaysToNumbers(schedule.selectedDays),
      time: schedule.time.format("HH:mm"),
    };
    try {
      const response = await invokeApi(
        config.getMyCollege + apiList.updateAutoSchedule,
        params,
        cookies
      );
      if (response?.status === 200) {
        console.log("Schedule updated successfully");
        fetchAutoSchedulesData(); // Refresh list after adding
      } else {
        console.error("Failed to update schedule:", response);
      }
    } catch (error) {
      console.error("Error during schedule update:", error);
    }
  };

  // Function to add a new schedule via API call
  const addAutoSchedule = async (schedule) => {
    const params = {
      projectId: "121913", // replace with your actual projectId
      scheduleDays: convertDaysToNumbers(schedule.selectedDays),
      time: schedule.time.format("HH:mm"),
    };
    try {
      const response = await invokeApi(
        config.getMyCollege + apiList.addAutoSchedule,
        params,
        cookies
      );
      if (response?.status === 200) {
        console.log("Schedule added successfully");
        const newScheduleId = response.data.scheduleId;
        setSchedulesState((prevSchedules) =>
          prevSchedules.map((s) =>
            s.scheduleId === schedule.scheduleId ? { ...s, scheduleId: newScheduleId } : s
          )
        );
        fetchAutoSchedulesData(); // Refresh list after adding
      } else {
        console.error("Failed to add schedule:", response);
      }
    } catch (error) {
      console.error("Error during schedule addition:", error);
    }
  };

  // Handle day change for a schedule
  const handleDayChange = (id, newDays) => {
    setSchedulesState((prevSchedules) => {
      const updatedSchedules = prevSchedules.map((schedule) =>
        schedule.scheduleId === id
          ? { ...schedule, selectedDays: newDays || [] }
          : schedule
      );
      return updatedSchedules;
    });

    // Defer API call to ensure state is updated
    const updatedSchedule = schedulesState.find((schedule) => schedule.scheduleId === id);
    if (updatedSchedule && newDays) {
      const updatedCopy = { ...updatedSchedule, selectedDays: newDays };
      updateAutoSchedule(updatedCopy); // Pass the updated schedule to API
    }
  };


  // Handle time change for a schedule
  const handleTimeChange = (id, newTime) => {
    setSchedulesState((prevSchedules) =>
      prevSchedules.map((schedule) =>
        schedule.scheduleId === id ? { ...schedule, time: newTime } : schedule
      )
    );
    // Trigger API update immediately
    const updatedSchedule = schedulesState.find((schedule) => schedule.scheduleId === id);
   
    if (updatedSchedule && newTime) {
      const updatedCopy = { ...updatedSchedule, time: newTime };
      updateAutoSchedule(updatedCopy); // Pass the updated schedule to API
    }
  };

  // Toggle schedule active/inactive
  const handleToggleChange = (id) => {
    setSchedulesState((prevSchedules) =>
      prevSchedules.map((schedule) =>
        schedule.scheduleId === id
          ? { ...schedule, isActive: !schedule.isActive }
          : schedule
      )
    );
    // Trigger API update immediately for active status change
    const updatedSchedule = schedulesState.find((schedule) => schedule.scheduleId === id);
    if (updatedSchedule) {
      console.log("API call triggered for active status change"); // Debug log
      updateAutoSchedule(schedulesState);
    }
  };

  // Delete a schedule
  const deleteSchedule = (id) => {
    setSchedulesState((prevSchedules) =>
      prevSchedules.filter((schedule) => schedule.scheduleId !== id)
    );
  };

  // Save schedules to parent component
  const saveSchedules = () => {
    setSchedules(schedulesState);
  };

  // Handle adding a new schedule
  const handleAddSchedule = () => {
    const newSchedule = {
      scheduleId: Date.now(),
      selectedDays: ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"],
      time: defaultTime,
      isActive: true,
    };
    setSchedulesState((prevSchedules) => [...prevSchedules, newSchedule]);
    addAutoSchedule(newSchedule); // Send to API immediately
  };

  // Fetch schedules when the component mounts
  useEffect(() => {
    fetchAutoSchedulesData();
  }, []);

  return (
    <Box
      sx={{
        p: 3,
        backgroundColor: theme.palette.mode !== "dark" ? "#312b36" : "#fff",
        border: theme.palette.mode !== "dark" ? "1px solid #4a4a4a" : "1px solid #f2ebf0",
        borderRadius: 2,
        boxShadow: 1,
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography
          variant="body1"
          sx={{
            fontWeight: "bold",
            color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000",
          }}
        >
          Schedules ({schedulesState.length})
        </Typography>
        <Button
          onClick={handleAddSchedule}
          variant="text"
          startIcon={<Add />}
          sx={{
            textTransform: "uppercase",
            fontWeight: "bold",
            color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000",
            backgroundColor: theme.palette.mode !== "dark" ? "#312b36" : "#fff",
            border: theme.palette.mode !== "dark" ? "1px solid #4a4a4a" : "1px solid #f2ebf0",
          }}
        >
          Add Schedule
        </Button>
      </Box>

      {schedulesState.map((schedule) => (
        <Box
          key={schedule.scheduleId}
          sx={{
            p: 2,
            mb: 2,
            backgroundColor: theme.palette.mode !== "dark" ? "#312b36" : "#fff",
            border: theme.palette.mode !== "dark" ? "1px solid #4a4a4a" : "1px solid #f2ebf0",
            borderRadius: 2,
          }}
        >
          <Box display="flex" alignItems="center" mt={2}>
            <ToggleButtonGroup
              value={schedule.selectedDays}
              onChange={(event, newDays) =>
                handleDayChange(schedule.scheduleId, newDays)
              }
              aria-label="days selection"
              sx={{
                flexWrap: "wrap",
                ml: 2,
                color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000",
                backgroundColor: theme.palette.mode !== "dark" ? "#1c1c1e" : "#fff",
                border: theme.palette.mode !== "dark" ? "1px solid #4a4a4a" : "1px solid #f2ebf0",
                borderRadius: "8px",
              }}
            >
              {defaultDays.map((day) => (
                <ToggleButton
                  key={day}
                  value={day}
                  aria-label={day}
                  sx={{
                    width: 50,
                    height: 40,
                    color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000",
                    "&.Mui-selected": {
                      color: theme.palette.mode !== "dark" ? "#312b36" : "#f2ebf0",
                    },
                  }}
                >
                  {day.charAt(0)}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                value={schedule.time}
                onChange={(newTime) => handleTimeChange(schedule.scheduleId, newTime)}
                renderInput={(params) => (
                  <IconButton {...params}>
                    <AccessTime />
                  </IconButton>
                )}
              />
            </LocalizationProvider>

            <Switch
              checked={schedule.isActive}
              onChange={() => handleToggleChange(schedule.scheduleId)}
              color="primary"
            />
            <IconButton onClick={() => deleteSchedule(schedule.scheduleId)}>
              <Delete />
            </IconButton>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default SetUpScheduleTab;
