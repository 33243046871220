import React from 'react';
import { Box, Typography, Grid } from '@mui/material';

const PeopleBox = () => {
  return (
    <Grid container spacing={2} sx={{p:4}}>
      {/* First Box */}
      <Grid item xs={12} md={6}>
        <Box
          sx={{
            border: '1px solid #4c4452',
            borderRadius: '4px',
            padding: '16px',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography variant="subtitle2" sx={{ fontWeight: 'bold',color:'#d1d1d1' }}>
            PEOPLE CLICK ON SEO RESULTS
          </Typography>
          <Typography sx={{color:'#d1d1d1'}}>No data to display</Typography>
        </Box>
      </Grid>

      {/* Second Box */}
      <Grid item xs={12} md={6}>
        <Box
          sx={{
            border: '1px solid #4c4452',
            borderRadius: '4px',
            padding: '16px',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography variant="subtitle2" sx={{ fontWeight: 'bold',color:'#d1d1d1' }}>
            SEARCHERS' AGE RANGE
          </Typography>
          <Typography sx={{color:'#d1d1d1'}}>No data to display</Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default PeopleBox;
