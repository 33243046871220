import React from 'react';
import { Box, CssBaseline, Grid } from '@mui/material';
import Dashboardheader from '../../../DashboardMainPages/dashboarddetails/Dashboardheader';
import Dashboardsideicon from '../../../DashboardMainPages/dashboarddetails/Dashboardsideicon';
import DashBoardFooter from '../../../../../common/DashBoardFooter';
import ParaphraseTool from './ParaphraseTool';

const ParaphraseHome = () => {
    return (
        <>
            <CssBaseline /> 
            <Grid container>
                <Grid item xs={12} sx={{ marginTop: 2,flexGrow:2 }}> 
                    <Dashboardheader />
                </Grid>

            
                <Grid item xs={12} sx={{ display: 'flex', flexGrow: 1 }}> 
                    {/* Sidebar */}
                    <Grid item sx={{ width: '80px' }}>
                        <Dashboardsideicon />
                    </Grid>

                    {/* Main Content */}
                    <Box
                        component="main"
                        sx={{
                            flexGrow: 1,
                            // bgcolor:theme.palette.mode !== 'dark' ? '#1c191f':'#f8f3f7',
                            color: '#fff',
                          
                            ml:-2,
                            marginTop: 13, 
                        }}
                    >
                       <ParaphraseTool/>
                       <DashBoardFooter/>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default ParaphraseHome;
