// import React, { useState } from 'react';
// import { AppBar, Toolbar, Typography, Box, IconButton, MenuItem, Select, FormControl, Popover, List, Button, ListItem } from '@mui/material';
// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// import SearchIcon from '@mui/icons-material/Search';



// import logo from '../../../../../assets/GMC-logo.15fdaee886680b06fcfd.png'
// import Header from '../../../../common/Header';
// import { useTheme } from '../../../../common/ThemeContext';

// const Dashboardheader = () => {
//     const [anchorEl, setAnchorEl] = useState(null);
//     const [selectedLanguage, setSelectedLanguage] = useState("google.com - English");
//     const [selectedDevice, setSelectedDevice] = useState("Mobile");
//     const {theme}= useTheme();

//     const handlePopoverOpen = (event) => {
//         setAnchorEl(event.currentTarget);
//     };

//     const handlePopoverClose = () => {
//         setAnchorEl(null);
//     };

//     const isPopoverOpen = Boolean(anchorEl);

//     const handleLanguageChange = (event) => {
//         setSelectedLanguage(event.target.value);
//     };

//     const handleDeviceChange = (event) => {
//         setSelectedDevice(event.target.value);
//         // Logic to handle responsiveness can go here
//     };

//     return (
//         <AppBar position="fixed" sx={{ backgroundColor:theme.palette.mode !== "dark" ? '#312b36':'#ffffff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
//             <Header />
//             <Toolbar sx={{ minHeight: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
//                 {/* Left Section: Logo and Website Name */}
//                 <Box sx={{ display: 'flex', alignItems: 'center' }}>
//                     <IconButton edge="start" color="inherit" sx={{ marginRight: 1 }}>
//                         <img src={logo} alt="Logo" style={{ width: '30px', borderRadius: '50%' }} />
//                     </IconButton>
//                     <Typography variant="body1" component="div" sx={{ color: '#fff', marginRight: 1 }}>
//                         getmycollege.com
//                     </Typography>

//                     {/* Arrow Dropdown with Popup */}
//                     <IconButton onClick={handlePopoverOpen}>
//                         <ArrowDropDownIcon sx={{ color: '#fff' }} />
//                     </IconButton>

//                     {/* Popover content */}
//                     <Popover
//                         open={isPopoverOpen}
//                         anchorEl={anchorEl}
//                         onClose={handlePopoverClose}
//                         anchorOrigin={{
//                             vertical: 'bottom', 
//                             horizontal: 'center', 
//                         }}
//                         transformOrigin={{
//                             vertical: 'top', 
//                             horizontal: 'center', 
//                         }}
//                         PaperProps={{
//                             sx: {
//                                 backgroundColor: '#312b36',
//                                 padding: '15px',
//                                 borderRadius: '10px',
//                                 color: '#fff',
//                                 width: '250px',
//                             },
//                         }}
//                     >
//                         {/* Popover inner content */}
//                         <Box>
//                             {/* Search Bar */}
//                             <Box sx={{ display: 'flex', alignItems: 'center', padding: '10px 0', borderBottom: '1px solid #4f465a' }}>
//                                 <SearchIcon sx={{ color: '#8a859f', marginRight: 1 }} />
//                                 <Typography variant="body2" sx={{ color: '#8a859f' }}>
//                                     Search...
//                                 </Typography>
//                             </Box>

//                             {/* Recently Visited */}
//                             <Typography variant="body2" sx={{ marginTop: 2, marginBottom: 1, color: '#8a859f' }}>
//                                 Recently visited
//                             </Typography>
//                             <List>
//                                 <ListItem button sx={{ padding: '5px 0', color: '#fff', backgroundColor: '#1f1b2e', borderRadius: '5px' }}>
//                                     <img src={logo} alt="Website Icon" style={{ width: '20px', marginRight: '10px' }} />
//                                     getmycollege.com
//                                 </ListItem>
//                             </List>

//                             {/* Add Website Button */}
//                             <Button
//                                 variant="contained"
//                                 sx={{
//                                     backgroundColor: '#F76D1E',
//                                     marginTop: 2,
//                                     color: '#fff',
//                                     width: '100%',
//                                     padding: '10px',
//                                     textTransform: 'none',
//                                     '&:hover': {
//                                         backgroundColor: '#d05e1b',
//                                     },
//                                 }}
//                             >
//                                 Add website
//                             </Button>

//                             {/* Go to Overview */}
//                             <Typography
//                                 variant="body2"
//                                 sx={{
//                                     marginTop: 2,
//                                     textAlign: 'center',
//                                     color: '#8a859f',
//                                     cursor: 'pointer',
//                                 }}
//                                 onClick={handlePopoverClose}
//                             >
//                                 Go to overview
//                             </Typography>
//                         </Box>
//                     </Popover>

//                     {/* Middle Section: Language and Region */}
//                     <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
//                         <FormControl variant="standard" sx={{ marginRight: 2 }}>
//                             <Select
//                                 value={selectedLanguage}
//                                 onChange={handleLanguageChange}
//                                 variant="standard"
//                                 disableUnderline
//                                 sx={{ color: '#fff', display: 'flex', alignItems: 'center' }}
//                                 IconComponent={ArrowDropDownIcon}
//                             >
//                                 <MenuItem value="google.com - English">
//                                     <img src="/assets/flag.png" alt="flag" style={{ width: '20px', marginRight: '8px' }} />
//                                     google.com - English
//                                 </MenuItem>
//                             </Select>
//                         </FormControl>
//                     </Box>

//                     {/* Right Section: Device Dropdown */}
//                     <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
//                         <FormControl variant="standard">
//                             <Select
//                                 value={selectedDevice}
//                                 onChange={handleDeviceChange}
//                                 variant="standard"
//                                 disableUnderline
//                                 sx={{ color: '#fff', display: 'flex', alignItems: 'center' }}
//                                 IconComponent={ArrowDropDownIcon}
//                             >
//                                 <MenuItem value="Mobile">Mobile</MenuItem>
//                                 <MenuItem value="Desktop">Desktop</MenuItem>
//                             </Select>
//                         </FormControl>
//                     </Box>
//                 </Box>
//             </Toolbar>
//         </AppBar>
//     );
// }

// export default Dashboardheader;




import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Box, IconButton, MenuItem, Select, FormControl, Popover, List, Button, ListItem } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SearchIcon from '@mui/icons-material/Search';
import logo from '../../../../../assets/GMC-logo.15fdaee886680b06fcfd.png';
import Header from '../../../../common/Header';
import { useTheme } from '../../../../common/ThemeContext';
import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows';
import LanguageIcon from '@mui/icons-material/Language';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { Breadcrumb } from 'antd';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { height } from '@mui/system';

const Dashboardheader = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedLanguage, setSelectedLanguage] = useState('google.com - English');
    const [selectedDevice, setSelectedDevice] = useState('Desktop');
    const isPopoverOpen = Boolean(anchorEl);
    const { theme, toggleTheme } = useTheme();


    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const handleLanguageChange = (event) => {
        setSelectedLanguage(event.target.value);
    };

    const handleDeviceChange = (event) => {
        setSelectedDevice(event.target.value);
    };

    return (
        <AppBar
            position="fixed"
            sx={{
                backgroundColor: theme.palette.mode !== "dark" ? '#312b36' : '#ffffff',
                color: theme.palette.mode !== "dark" ? '#fff' : '#000',
                zIndex: (theme) => theme.zIndex.drawer + 1,

            }}
        >
            <Header />


            <Breadcrumb
                sx={{
                    height: '100px', 
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    backgroundColor: '#fff',
                    padding: '0 20px',
                    border: '1px solid #ddd',
                }}
            >
                {/* Left Section: Breadcrumb Items */}
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2,ml:2,p:1 }}>
                    {/* Website Name */}
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography
                            variant="body2"
                            sx={{
                                color: '#000',
                                fontWeight: 600,
                            }}
                        >
                            getmycollege.com
                        </Typography>
                    </Box>

                    {/* Arrow Separator */}
                    <ArrowForwardIosIcon
                        sx={{
                            fontSize: '28px',
                            color: '#ddd',
                            marginX: '8px',
                        }}
                    />

                    {/* Language Selector */}
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography
                            variant="body2"
                            sx={{
                                color: '#000',
                                marginRight: '8px',
                                fontWeight: 600,
                            }}
                        >
                            google.com - English
                        </Typography>
                    </Box>

                    {/* Arrow Separator */}
                    <ArrowForwardIosIcon
                        sx={{
                            fontSize: '28px',
                            color: '#ddd',
                            marginX: '8px',
                        }}
                    />

                    {/* Device Dropdown */}
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography
                            variant="body2"
                            sx={{
                                color: '#000',
                                fontWeight: 600,
                            }}
                        >
                            Desktop
                        </Typography>

                        <ArrowForwardIosIcon
                            sx={{
                                fontSize: '28px',
                                color: '#ddd',
                                marginX: '8px',
                            }}
                        />
                    </Box>
                </Box>

                {/* Right Section: Date Range Picker */}
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        color: '#000',
                        gap: 1,
                        
                    }}
                >
                    <Typography variant="body2" sx={{ fontSize: '14px',fontWeight: 600, }}>
                        Oct 19, 2024 - Nov 19, 2024
                    </Typography>
                </Box>
            </Breadcrumb>

        </AppBar>
    );
}

export default Dashboardheader;
