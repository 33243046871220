import React, { useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Box,
  Divider,
  Modal,
  Fade,
  Backdrop,
} from "@mui/material";
import { BarChart, XAxis, Tooltip, Bar, ResponsiveContainer } from "recharts";
import { useTheme } from "../../common/ThemeContext";

// Sample data for the stacked bar chart
const data = [
  {
    name: "1",
    pos2_3: 30,
    pos4_10: 50,
    pos11_30: 34,
    pos31_100: 7,
    pos100: 13,
  },
  {
    name: "2",
    pos2_3: 28,
    pos4_10: 45,
    pos11_30: 29,
    pos31_100: 8,
    pos100: 12,
  },
  {
    name: "3",
    pos2_3: 35,
    pos4_10: 48,
    pos11_30: 30,
    pos31_100: 9,
    pos100: 10,
  },
  {
    name: "4",
    pos2_3: 32,
    pos4_10: 44,
    pos11_30: 28,
    pos31_100: 10,
    pos100: 15,
  },
  {
    name: "5",
    pos2_3: 33,
    pos4_10: 50,
    pos11_30: 33,
    pos31_100: 7,
    pos100: 13,
  },
  {
    name: "6",
    pos2_3: 30,
    pos4_10: 49,
    pos11_30: 35,
    pos31_100: 6,
    pos100: 14,
  },
  {
    name: "7",
    pos2_3: 31,
    pos4_10: 51,
    pos11_30: 34,
    pos31_100: 8,
    pos100: 12,
  },
];

const FirstPosition = () => {
  const [open, setOpen] = useState(false);
  const { theme } = useTheme();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const cardStyle = {
    backgroundColor: theme.palette.mode !== "dark" ? "#2a252f" : "#ffffff",
    border:theme.palette.mode !== "dark" ? '1px solid #4c4452':"none",
    borderRadius: 2,
    color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000",
    padding: 0,
    height: "25vh",
    cursor: "pointer",
    transition: "transform 0.3s ease",
    "&:hover": {
      transform: "translateY(-5px)",
    },
  };

  const modalStyle = {
    backgroundColor: theme.palette.mode !== "dark" ? "#2d2839" : "#fff",
    color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000",
  };

  const chartContainerStyle = {
    backgroundColor: theme.palette.mode !== "dark" ? "#1b162b" : "#f0f0f0",
    padding: "16px",
    borderRadius: "8px",
    position: "relative",
  };

  return (
    <>
      <Grid item xs={12} sm={12} md={12}>
        <Card sx={cardStyle} onClick={handleOpen}>
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ flex: 1, paddingRight: 2 }}>
              <Typography
                variant="body2"
                sx={{ fontSize: "16px", marginBottom: "8px" }}
              >
                First positions
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "16px",
                }}
              >
                <Typography variant="h3" sx={{ fontSize: "32px" }}>
                  14
                </Typography>
                <Box
                  sx={{
                    backgroundColor: "#1abc9c",
                    borderRadius: "4px",
                    padding: "2px 6px",
                    marginLeft: "8px",
                    display: "flex",
                    alignItems: "center",
                    color:'#fff'
                  }}
                >
                  <Typography variant="body2" sx={{ marginLeft: "4px" }}>
                    13
                  </Typography>
                </Box>
              </Box>

              {/* Stacked Bar Chart */}
              <Box sx={{ height: 100, marginTop: "16px" }}>
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart data={data}>
                    <XAxis dataKey="name" hide />
                    <Tooltip />
                    <Bar
                      dataKey="pos2_3"
                      stackId="a"
                      fill="#6FE493"
                      barSize={10}
                    />
                    <Bar
                      dataKey="pos4_10"
                      stackId="a"
                      fill="#94E6AC"
                      barSize={10}
                    />
                    <Bar
                      dataKey="pos11_30"
                      stackId="a"
                      fill="#F3C882"
                      barSize={10}
                    />
                    <Bar
                      dataKey="pos31_100"
                      stackId="a"
                      fill="#FFAB71"
                      barSize={10}
                    />
                    <Bar
                      dataKey="pos100"
                      stackId="a"
                      fill="#FF7F66"
                      barSize={10}
                    />
                  </BarChart>
                </ResponsiveContainer>
              </Box>
            </Box>

            <Divider
              orientation="vertical"
              flexItem
              sx={{ borderColor: "#444" }}
            />

            <Box sx={{ flex: 0.5, paddingLeft: 2 }}>
              {[
                { label: "Pos. 2-3", value: 30, color: "#6FE493" },
                { label: "Pos. 4-10", value: 50, color: "#94E6AC" },
                { label: "Pos. 11-20", value: 34, color: "#F3C882" },
                { label: "Pos. 21-50", value: 34, color: "#F3C882" },
                { label: "Pos. 51-100", value: 7, color: "#FFAB71" },
                { label: "Pos. >100", value: 13, color: "#FF7F66" },
              ].map((item, idx) => (
                <Box
                  key={idx}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "8px",
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "14px", color: item.color }}
                  >
                    {item.label}
                  </Typography>
                  <Typography variant="body2" sx={{ fontSize: "14px" }}>
                    {item.value}
                  </Typography>
                </Box>
              ))}
            </Box>
          </CardContent>
        </Card>
      </Grid>

      {/* Modal for Detailed View */}
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "80%",
              ...modalStyle,
              boxShadow: 24,
              p: 4,
            }}
          >
            {/* Modal Header */}
            <Typography variant="h6" sx={{ mb: 2 }}>
              First Positions Details
            </Typography>

            {/* Detailed Graph Content */}
            <Box sx={{ height: 400 }}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={data}>
                  <XAxis dataKey="name" hide />
                  <Tooltip />
                  <Bar
                    dataKey="pos2_3"
                    stackId="a"
                    fill="#6FE493"
                    barSize={10}
                  />
                  <Bar
                    dataKey="pos4_10"
                    stackId="a"
                    fill="#94E6AC"
                    barSize={10}
                  />
                  <Bar
                    dataKey="pos11_30"
                    stackId="a"
                    fill="#F3C882"
                    barSize={10}
                  />
                  <Bar
                    dataKey="pos31_100"
                    stackId="a"
                    fill="#FFAB71"
                    barSize={10}
                  />
                  <Bar
                    dataKey="pos100"
                    stackId="a"
                    fill="#FF7F66"
                    barSize={10}
                  />
                </BarChart>
              </ResponsiveContainer>
            </Box>

            {/* Graph Bottom Details */}
            <Box sx={{ mt: 3 }}>
              <Typography
                variant="body2"
                sx={{
                  color: theme.palette.mode !== "dark" ? "#7e7c86" : "#444",
                }}
              >
                Data Overview
              </Typography>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default FirstPosition;
