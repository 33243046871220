import React, { useEffect } from 'react';
import { Container, Typography, Paper, List, ListItem, ListItemText, Divider, Grid } from '@mui/material';
import Footer from '../../common/Footer';
import Header from '../../common/Header';

const ShippingDeliveryPolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

  return (
    <>
      <Header />
      <Grid sx={{ backgroundColor: '#fff9f7' }}>
        <Container maxWidth="md" sx={{ p: 4 }}>
          <Paper elevation={3} sx={{ p: 4, boxShadow: '0 4px 20px rgba(255, 72, 0, 0.1)' }}>
            <Typography variant="h4" gutterBottom align="center" sx={{ fontWeight: 'bold' }}>
              Shipping and Delivery Policy
            </Typography>
            <Divider sx={{ mb: 2 }} />

            <Typography variant="body1" paragraph>
              Since we offer digital tools and services at Seomitra, there aren't any tangible goods that need to be shipped. Nonetheless, we want to make sure you comprehend how using our services operates.
            </Typography>

            {/* Obtaining Services */}
            <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
              Obtaining Services
            </Typography>
            <List>
              <ListItem>
                <ListItemText primary="Quick Access:" />
              </ListItem>
              <ListItemText primary="You will have instant access to all of the tools on our site, such as the content changer, paraphraser, and plagiarism detector, after completing your subscription or purchase of our services." />
              
              <ListItem>
                <ListItemText primary="Account Creation:" />
              </ListItem>
              <ListItemText primary="You need to create an account to utilize our services. An email confirmation including your account information and setup instructions will be sent to you following a successful payment." />
            </List>

            {/* Specifications for Technology */}
            <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
              Specifications for Technology
            </Typography>
            <Typography variant="body1" paragraph>
              You will need a suitable device and a dependable internet connection to use our services. No further program installation is necessary, and our tools are available through any online browser.
            </Typography>

            {/* Customer Service */}
            <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
              Customer Service
            </Typography>
            <Typography variant="body1" paragraph>
              If you have any queries concerning your account or experience any problems using our services, please contact our support staff:
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>Email:</strong> seomitraofficial@gmail.com
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>Address:</strong> #2 1st Floor, Svs Apartment, 12A Main Road, BTM 1st Stage, BTM Layout, Near Metro Station, Bangalore - 560029
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>Contact Number:</strong> +91 8073979129
            </Typography>
          </Paper>
        </Container>
      </Grid>
      <Footer />
    </>
  );
};

export default ShippingDeliveryPolicy;
