import React from 'react';
import { Box, Typography, Container, Grid } from '@mui/material';
import image from '../../../../../../assets/Backlinks Banners-03.jpg';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const PremiumTier1image = () => {
    return (
        <Container sx={{}}>
            <Grid container spacing={4} alignItems="center" sx={{ mt: 1, mb: 3 }}>
                <Grid container justifyContent="center">
                    <Typography variant="h3" fontWeight="bold" textAlign="center">
                        <span style={{ color: "#F1F1F1" }}>
                            1 Tier Backlinks Pricing and Packages
                        </span>
                    </Typography>
                </Grid>

                {/* Left Text Section */}
                <Grid item xs={12} md={6}>
                    <Typography variant="overline" gutterBottom sx={{ color: '#FFD700', fontWeight: 700 }}>
                        Limited Time Only, Hurry Up!
                    </Typography>
                    <Typography variant="h3" component="h3" gutterBottom sx={{ fontWeight: 700, color: '#ffffff', fontSize: { xs: 'h4.fontSize', sm: 'h3.fontSize' } }}>
                        Enjoy Flat 30% Off
                    </Typography>
                    <Typography variant="body1" color="text.secondary" paragraph sx={{ color: '#E0E0E0', width: { xs: '100%', sm: '450px' }, mb: 3 }}>
                        Best Home Painting Services in Bangalore. We are offering professional painters with high-quality services for residential, commercial, and industrial properties.
                    </Typography>

                    {/* Custom CheckCircleIcon with circular background */}
                    <Box display="flex" flexDirection="column" mb={2}>
                        <Box display="flex" alignItems="center" mb={2}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: 50,
                                    height: 50,
                                    borderRadius: '50%',
                                    backgroundColor: '#E6F7FF',
                                }}
                            >
                                <CheckCircleIcon style={{ color: '#002966', fontSize: 30 }} />
                            </Box>
                            <Box ml={2}>
                                <Typography variant="h6" sx={{ fontWeight: 600, color: '#ffffff', fontSize: { xs: 'h6.fontSize', sm: 'h6.fontSize' } }}>
                                    Professional Expertise
                                </Typography>
                                <Typography variant="body2" color="text.secondary" sx={{ color: '#C2C2C2', width: { xs: '100%', sm: '350px' } }}>
                                    Demonstrated skill and knowledge in a specific field, ensuring high-quality and effective results.
                                </Typography>
                            </Box>
                        </Box>

                        <Box display="flex" alignItems="center" mb={2}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: 50,
                                    height: 50,
                                    borderRadius: '50%',
                                    backgroundColor: '#E6F7FF',
                                }}
                            >
                                <CheckCircleIcon style={{ color: '#002966', fontSize: 30 }} />
                            </Box>
                            <Box ml={2}>
                                <Typography variant="h6" sx={{ fontWeight: 600, color: '#ffffff', fontSize: { xs: 'h6.fontSize', sm: 'h6.fontSize' } }}>
                                    Superior Quality
                                </Typography>
                                <Typography variant="body2" color="text.secondary" sx={{ color: '#C2C2C2', width: { xs: '100%', sm: '350px' } }}>
                                    Exceeding standard expectations through exceptional materials, workmanship, and attention to detail.
                                </Typography>
                            </Box>
                        </Box>

                        <Box display="flex" alignItems="center">
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: 50,
                                    height: 50,
                                    borderRadius: '50%',
                                    backgroundColor: '#E6F7FF',
                                }}
                            >
                                <CheckCircleIcon style={{ color: '#002966', fontSize: 30 }} />
                            </Box>
                            <Box ml={2}>
                                <Typography variant="h6" sx={{ fontWeight: 600, color: '#ffffff', fontSize: { xs: 'h6.fontSize', sm: 'h6.fontSize' } }}>
                                    Clean Work Areas
                                </Typography>
                                <Typography variant="body2" color="text.secondary" sx={{ color: '#C2C2C2', width: { xs: '100%', sm: '350px' } }}>
                                    Maintaining an organized and hygienic workspace to promote safety, efficiency, and professionalism.
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Grid>

                {/* Right Image Section with Counter */}
                <Grid item xs={12} md={6} sx={{ position: 'relative' }}>
                    <Box
                        component="img"
                        sx={{
                            width: '100%',
                            borderRadius: 2,
                            mb: 3
                        }}
                        alt="Painter working"
                        src={image} 
                    />
                    {/* Counter overlay at the corner */}
                    {/* <Box
                        sx={{
                            position: 'absolute',
                            bottom: -30,
                            right: 120,
                            backgroundColor: '#f39639',
                            border: '2px solid transparent',
                            borderRadius: '12px',
                            padding: '16px',
                            fontSize: { xs: '20px', sm: '20px' },
                            fontWeight: 'bold',
                            textAlign: 'center',
                            boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Typography variant="h4" component="div" sx={{ fontWeight: 700, color: '#ffffff', fontSize: { xs: 'h5.fontSize', sm: 'h6.fontSize' } }}>
                            12k+
                        </Typography>
                        <Typography variant="body2" sx={{ marginTop: 1, fontWeight: 700, color: '#ffffff',fontSize:10 }}>
                        1432 users Purchased <br/>in the last 7 days
                        </Typography>
                    </Box> */}
                </Grid>
            </Grid>
        </Container>
    );
};

export default PremiumTier1image;
