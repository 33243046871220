import React from 'react';
import { Grid, Box, Typography, List, ListItem, useMediaQuery } from '@mui/material';
import Footer from '../../common/Footer';
import Header from '../../common/home/Header';

const PrivacyPolicy = () => {
  const isMobile = useMediaQuery("(min-width:360px) and (max-width:500px)");

  return (
    <Grid sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', overflowY: 'auto', overflowX: 'hidden', background: 'linear-gradient(to right, rgba(226, 255, 255, 0.26), rgba(0, 180, 194, 0.16))' }}>
      <Header />
      <Box sx={{ flexGrow: 1, px: 10, marginTop: "50px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>

            <Typography variant="h2" sx={{ marginBottom: 4, fontSize: { xs: '1.5rem', md: '2.25rem', fontWeight: 700, textAlign: 'center', color: '#343f52' } }}>
              Privacy Policy for SEOMitra
            </Typography>

            <Typography variant="h3" sx={{ marginBottom: 2, fontSize: { xs: '1rem', md: '1.5rem', fontWeight: 500, color: '#60697b' } }}>
              Effective Date: [Insert Date]
            </Typography>

            <Typography variant="h4" sx={{ marginBottom: 4, fontSize: { xs: '0.8rem', md: '1rem', fontWeight: 400, color: '#60697b', lineHeight: '1.5rem' } }}>
              At SEOMitra, we respect your privacy and are committed to protecting your personal data. The Privacy Policy outlines the types of information we collect, how we use and protect it, and your rights concerning your data. By accessing or using our website, you agree to the collection and use of your information as described in this policy.
            </Typography>

            <Typography variant="h3" sx={{ marginBottom: 2, fontSize: { xs: '1.2rem', md: '1.325rem', fontWeight: 700, color: '#343f52' } }}>
              1. Information We Collect
            </Typography>
            <Typography variant="h4" sx={{ marginBottom: 4, fontSize: { xs: '0.6rem', md: '1rem', fontWeight: 400, color: '#60697b', lineHeight: '1.5rem' } }}>
              <List sx={{ marginLeft: 2, marginTop: 1, listStyleType: 'disc', paddingLeft: 2 }}>
                <ListItem sx={{ display: 'list-item', padding: 0 }}>
                  <Typography component="span" sx={{ fontWeight: 'bold' }}>Personal Information:</Typography> We collect personal information that you provide directly to us when you use our services or communicate with us, including but not limited to:
                  <List sx={{ paddingLeft: 4 }}>
                    <ListItem>Name</ListItem>
                    <ListItem>Email address</ListItem>
                    <ListItem>Phone number</ListItem>
                    <ListItem>Company name</ListItem>
                    <ListItem>Billing information</ListItem>
                  </List>
                </ListItem>
                <ListItem sx={{ display: 'list-item', padding: 0 }}>
                  <Typography component="span" sx={{ fontWeight: 'bold' }}>Non-Personal Information:</Typography> We also collect non-personally identifiable information automatically through your use of our website, such as:
                  <List sx={{ paddingLeft: 4 }}>
                    <ListItem>IP address</ListItem>
                    <ListItem>Browser type</ListItem>
                    <ListItem>Device type</ListItem>
                    <ListItem>Pages visited</ListItem>
                    <ListItem>Time spent on our website</ListItem>
                    <ListItem>Referring/exit pages</ListItem>
                    <ListItem>Cookies and similar tracking technologies</ListItem>
                  </List>
                </ListItem>
              </List>
            </Typography>

            <Typography variant="h3" sx={{ marginBottom: 2, fontSize: { xs: '1.2rem', md: '1.325rem', fontWeight: 700, color: '#343f52' } }}>
              2. Use of Your Information
            </Typography>
            <Typography variant="h4" sx={{ marginBottom: 4, fontSize: { xs: '0.6rem', md: '1rem', fontWeight: 400, color: '#60697b', lineHeight: '1.5rem' } }}>
              We use the information collected to:
              <List sx={{ marginLeft: 2, marginTop: 1, listStyleType: 'disc', paddingLeft: 2 }}>
                <ListItem>Provide and improve our SEO services</ListItem>
                <ListItem>Communicate with you regarding updates, service changes, and marketing communications (with your consent)</ListItem>
                <ListItem>Respond to inquiries, support requests, and provide customer service</ListItem>
                <ListItem>Personalize your experience and offer targeted content</ListItem>
                <ListItem>Monitor and analyze the effectiveness of our website and services</ListItem>
                <ListItem>Comply with legal obligations</ListItem>
              </List>
            </Typography>

            <Typography variant="h4" sx={{ marginBottom: 4, fontSize: { xs: '0.6rem', md: '1rem', fontWeight: 400, color: '#60697b', lineHeight: '1.5rem' } }}>
              <strong>Marketing Communications:</strong> You can opt-in to receive promotional materials or newsletters from SEOMitra. You can withdraw your consent at any time by following the unsubscribe link in the emails or by contacting us directly at [contact@seomitra.com].
            </Typography>

            {/* Section 3 */}
            <Typography variant="h3" sx={{ marginBottom: 2, fontSize: { xs: '1.2rem', md: '1.325rem', fontWeight: 700, color: '#343f52' } }}>
              3. Sharing of Information
            </Typography>

            <Typography variant="h4" sx={{ marginBottom: 4, fontSize: { xs: '0.6rem', md: '1rem', fontWeight: 400, color: '#60697b', lineHeight: '1.5rem' } }}>
              We may share your information in the following situations:
              <List sx={{ marginLeft: 2, marginTop: 1, listStyleType: 'disc', paddingLeft: 2 }}>
                <ListItem sx={{ display: 'list-item', padding: 0 }}>
                  <Typography component="span" sx={{ fontWeight: 'bold' }}>
                    With service providers:
                  </Typography>
                  {' '}We may share your personal information with third-party service providers to facilitate our services, such as payment processing or email marketing.
                </ListItem>
                <ListItem sx={{ display: 'list-item', padding: 0 }}>
                  <Typography component="span" sx={{ fontWeight: 'bold' }}>
                    For legal reasons:
                  </Typography>
                  {' '}We may disclose your personal information if required by law or to protect the rights, property, and safety of SEOMitra, our customers, or others.
                </ListItem>
                <ListItem sx={{ display: 'list-item', padding: 0 }}>
                  <Typography component="span" sx={{ fontWeight: 'bold' }}>
                    Business transfers:
                  </Typography>
                  {' '}In the event of a merger, acquisition, or sale of assets, your information may be transferred as part of the transaction.
                </ListItem>

              </List>
              We do not sell or rent your data to third parties for marketing purposes.
            </Typography>

            {/* Section 4 */}
            <Typography variant="h3" sx={{ marginBottom: 2, fontSize: { xs: '1.2rem', md: '1.325rem', fontWeight: 700, color: '#343f52' } }}>
              4. Data Security
            </Typography>

            <Typography variant="h4" sx={{ marginBottom: 4, fontSize: { xs: '0.6rem', md: '1rem', fontWeight: 400, color: '#60697b', lineHeight: '1.5rem' } }}>
              We employ industry-standard security measures to protect your personal information, including:
              <List sx={{ marginLeft: 2, marginTop: 1, listStyleType: 'disc', paddingLeft: 2 }}>
                <ListItem sx={{ display: 'list-item', padding: 0 }}>
                  <Typography component="span" sx={{ fontWeight: 'bold' }}>
                    Encryption:
                  </Typography>
                  {' '}We use SSL (Secure Socket Layer) encryption to ensure the security of sensitive data during transmission.
                </ListItem>
                <ListItem sx={{ display: 'list-item', padding: 0 }}>
                  <Typography component="span" sx={{ fontWeight: 'bold' }}>
                    Firewalls and Secure Servers:
                  </Typography>
                  {' '}We use firewalls, intrusion detection systems, and secure servers to protect against unauthorized access.
                </ListItem>
                <ListItem sx={{ display: 'list-item', padding: 0 }}>
                  <Typography component="span" sx={{ fontWeight: 'bold' }}>
                    Access Control:
                  </Typography>
                  {' '}We limit access to personal data to authorized employees and contractors who need access to perform their duties.
                </ListItem>

              </List>
              However, no data transmission over the internet or electronic storage method can be guaranteed 100% secure.
            </Typography>

            {/* Section 5 */}
            <Typography variant="h3" sx={{ marginBottom: 2, fontSize: { xs: '1.2rem', md: '1.325rem' }, fontWeight: 700, color: '#343f52' }}>
              5. Data Retention
            </Typography>
            <Typography variant="h4" sx={{ marginBottom: 4, fontSize: { xs: '0.8rem', md: '1rem' }, fontWeight: 400, color: '#60697b', lineHeight: '1.5rem' }}>
              SEOMitra retains your personal information only as long as necessary to fulfill the purposes for which it was collected, including compliance with legal obligations, resolving disputes, and enforcing agreements. Once the information is no longer needed, we will securely delete or anonymize it.
            </Typography>

            {/* Section 6 */}
            <Typography variant="h3" sx={{ marginBottom: 2, fontSize: { xs: '1.2rem', md: '1.325rem' }, fontWeight: 700, color: '#343f52' }}>
              6. Third-Party Links
            </Typography>
            <Typography variant="h4" sx={{ marginBottom: 4, fontSize: { xs: '0.8rem', md: '1rem' }, fontWeight: 400, color: '#60697b', lineHeight: '1.5rem' }}>
              Our website may contain links to third-party websites or services that SEOMitra does not operate. We are not responsible for the privacy practices of these external sites. We encourage you to review their privacy policies before providing any personal data.
            </Typography>

            {/* Section 7 */}
            <Typography variant="h3" sx={{ marginBottom: 2, fontSize: { xs: '1.2rem', md: '1.325rem' }, fontWeight: 700, color: '#343f52' }}>
              7. Children’s Privacy
            </Typography>
            <Typography variant="h4" sx={{ marginBottom: 4, fontSize: { xs: '0.8rem', md: '1rem' }, fontWeight: 400, color: '#60697b', lineHeight: '1.5rem' }}>
              SEOMitra does not knowingly collect or solicit personal data from anyone under the age of 13. If we learn that we have collected personal data from a child under age 13, we will delete that information as quickly as possible.
            </Typography>

            {/* Section 8 */}
            <Typography variant="h3" sx={{ marginBottom: 2, fontSize: { xs: '1.2rem', md: '1.325rem' }, fontWeight: 700, color: '#343f52' }}>
              8. Updates to This Privacy Policy
            </Typography>
            <Typography variant="h4" sx={{ marginBottom: 4, fontSize: { xs: '0.8rem', md: '1rem' }, fontWeight: 400, color: '#60697b', lineHeight: '1.5rem' }}>
              SEOMitra may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated “Effective Date.” We encourage you to review this policy periodically for any updates.
            </Typography>

            {/* Section 9 */}
            <Typography variant="h3" sx={{ marginBottom: 2, fontSize: { xs: '1.2rem', md: '1.325rem' }, fontWeight: 700, color: '#343f52' }}>
              9. Contact Us
            </Typography>
            <Typography variant="h4" sx={{ marginBottom: 4, fontSize: { xs: '0.8rem', md: '1rem' }, fontWeight: 400, color: '#60697b', lineHeight: '1.5rem' }}>
              If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at <strong>Email: contact@seomitra.com</strong>.
            </Typography>

          </Grid>
        </Grid>
      </Box>
      <Footer />
    </Grid>
  );
}

export default PrivacyPolicy;
