// import React from 'react';
// import { AppBar, Typography, Box } from '@mui/material';
// import { Breadcrumb } from 'antd';
// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
// import Header from './Header';
// import { useTheme } from '../../common/ThemeContext';
// import { useLocation, useNavigate } from 'react-router-dom';

// const Dashboardheader = () => {
//     const { theme } = useTheme();
//     const location = useLocation();
//     const navigate = useNavigate();

//     // Define breadcrumb mapping
//     const breadcrumbMap = {
//         '/user-dashboard': ['Workspace'],
//         '/projects': ['Workspace', 'Projects'],
//         '/domain': ['Workspace', 'Projects', 'Domain'],
//         '/keywords': ['Workspace', 'Projects', 'Domain', 'Detailed History'],
//     };

//     const breadcrumbPaths = {
//         Workspace: '/user-dashboard',
//         Projects: '/projects',
//         Domain: '/domain',
//         'Detailed History': '/keywords',
//     };

//     // Determine the current breadcrumb based on the pathname
//     let currentBreadcrumb = [];
//     if (location.pathname.startsWith('/keywords')) {
//         currentBreadcrumb = breadcrumbMap['/keywords'];
//     } else if (location.pathname.startsWith('/domain')) {
//         currentBreadcrumb = breadcrumbMap['/domain'];
//     } else if (location.pathname.startsWith('/projects')) {
//         currentBreadcrumb = breadcrumbMap['/projects'];
//     } else {
//         currentBreadcrumb = breadcrumbMap['/user-dashboard'];
//     }

//     return (
//         <AppBar
//             position="fixed"
//             sx={{
//                 backgroundColor: theme.palette.mode !== 'dark' ? '#312b36' : '#ffffff',
//                 color: theme.palette.mode !== 'dark' ? '#fff' : '#000',
//                 zIndex: (theme) => theme.zIndex.drawer + 1,
//             }}
//         >
//             <Header />

//             <Breadcrumb
//                 separator={
//                     <ArrowForwardIosIcon
//                         sx={{
//                             fontSize: '14px',
//                             color: '#ddd',
//                         }}
//                     />
//                 }
//                 sx={{
//                     height: '100px',
//                     display: 'flex',
//                     justifyContent: 'space-between',
//                     alignItems: 'center',
//                     backgroundColor: '#fff',
//                     padding: '0 20px',
//                     border: '1px solid #ddd',
//                 }}
//             >
//                 {/* Breadcrumb Items */}
//                 <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, ml: 2, p: 1 }}>
//                     {currentBreadcrumb.map((label, index) => {
//                         const isLast = index === currentBreadcrumb.length - 1;
//                         return (
//                             <Box
//                                 key={label}
//                                 sx={{
//                                     display: 'flex',
//                                     alignItems: 'center',
//                                     cursor: isLast ? 'default' : 'pointer',
//                                 }}
//                                 onClick={() => {
//                                     if (!isLast) {
//                                         navigate(breadcrumbPaths[label]);
//                                     }
//                                 }}
//                             >
//                                 <Typography
//                                     variant="body2"
//                                     sx={{
//                                         color: isLast ? '#000' : '#007bff',
//                                         fontWeight: isLast ? 600 : 400,
//                                     }}
//                                 >
//                                     {label}
//                                 </Typography>
//                                 {!isLast && (
//                                     <ArrowForwardIosIcon
//                                         sx={{
//                                             fontSize: '14px',
//                                             color: '#ddd',
//                                             marginX: '8px',
//                                         }}
//                                     />
//                                 )}
//                             </Box>
//                         );
//                     })}
//                 </Box>
//             </Breadcrumb>
//         </AppBar>
//     );
// };

// export default Dashboardheader;












import React from 'react';
import { AppBar, Typography, Box } from '@mui/material';
import { Breadcrumb } from 'antd';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Header from './Header';
import { useTheme } from '../../common/ThemeContext';
import { useLocation } from 'react-router-dom';

const Dashboardheader = () => {
    const { theme } = useTheme();
    const location = useLocation();

    // Define breadcrumb mapping
    const breadcrumbMap = {
        '/user-dashboard': ['Workspace'],
        '/projects': ['Workspace', 'Projects'],
        '/domain': ['Workspace', 'Projects', 'Domain'],
        '/keywords': ['Workspace', 'Projects', 'Domain', 'Detailed History'],
    };

    // Determine the current breadcrumb based on the pathname
    let currentBreadcrumb = [];
    if (location.pathname.startsWith('/keywords')) {
        currentBreadcrumb = breadcrumbMap['/keywords'];
    } else if (location.pathname.startsWith('/domain')) {
        currentBreadcrumb = breadcrumbMap['/domain'];
    } else if (location.pathname.startsWith('/projects')) {
        currentBreadcrumb = breadcrumbMap['/projects'];
    } else {
        currentBreadcrumb = breadcrumbMap['/user-dashboard'];
    }

    return (
        <AppBar
            position="fixed"
            sx={{
                backgroundColor: theme.palette.mode !== 'dark' ? '#312b36' : '#ffffff',
                color: theme.palette.mode !== 'dark' ? '#fff' : '#000',
                zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
        >
            <Header />

            <Breadcrumb
                separator={
                    <ArrowForwardIosIcon
                        sx={{
                            fontSize: '14px',
                            color: '#ddd',
                        }}
                    />
                }
                sx={{
                    height: '100px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    backgroundColor: '#fff',
                    padding: '0 20px',
                    border: '1px solid #ddd',
                }}
            >
                {/* Breadcrumb Items */}
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, ml: 2, p: 1 }}>
                    {currentBreadcrumb.map((label, index) => {
                        const isLast = index === currentBreadcrumb.length - 1;
                        return (
                            <Box key={label} sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography
                                    variant="body2"
                                    sx={{
                                        color: isLast ? '#000' : '#007bff',
                                        fontWeight: isLast ? 600 : 400,
                                    }}
                                >
                                    {label}
                                </Typography>
                                {!isLast && (
                                    <ArrowForwardIosIcon
                                        sx={{
                                            fontSize: '14px',
                                            color: '#ddd',
                                            marginX: '8px',
                                        }}
                                    />
                                )}
                            </Box>
                        );
                    })}
                </Box>
            </Breadcrumb>
        </AppBar>
    );
};

export default Dashboardheader;





