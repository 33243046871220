import React, { useEffect, useState } from 'react'
import Footer from '../../../../common/Footer'
import Tier1BacklinksHome from '../premium-backlinks/tier1backlinks/PremiumTier1BacklinksHome';
import Tier2BacklinksHome from '../premium-backlinks/tier2backlinks/PremiumTier2BacklinksHome';
import Tier3BacklinksHome from '../premium-backlinks/tier3backlinks/PremiumTier3BacklinksHome';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Button, TextField, Dialog, InputLabel, styled, MenuItem } from '@mui/material';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import Header1 from '../../../../setup/common/Header';
import PremiumTier4BacklinksHome from '../premium-backlinks/tier4backlinks/PremiumTier4BacklinksHome';
import useFetch from '../../../useFetch';
import Header2 from '../../../../common/home/Header';
import { motion, AnimatePresence } from 'framer-motion';


const AnimatedTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  "& .MuiOutlinedInput-root": {
    borderRadius: "5px",
    "& fieldset": {
      borderColor: "#9e9e9e",
    },
    "&:hover fieldset": {
      borderColor: "#ed6c02",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#ed6c02",
      transition: "border-color 0.3s ease-in-out",
    },
  },
  "& .MuiInputLabel-root": {
    color: "#9e9e9e",
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "#ed6c02",
  },
}));

const MonthlyBacklinks = () => {
  const { name, roles } = useFetch();
  const [open, setOpen] = useState(false);
  const [webUrl, setWebUrl] = useState("");
  const [keywordsCount, setKeywordsCount] = useState(1);
  const [keywordsString, setKeywordsString] = useState("");
  const navigate = useNavigate();
  const [imageUrl, setImageUrl] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [uploadedFile, setUploadedFile] = useState(null);
  const [errors, setErrors] = useState({
    webUrl: "",
    keywords: "",
  });



  // Handle updating the number of text fields dynamically
  const handleKeywordsCountChange = (e) => {
    const count = parseInt(e.target.value, 10);
    setKeywordsCount(count);

    // Adjust the comma-separated keywords string
    const keywordsArray = keywordsString.split(",").slice(0, count); // Keep only required entries
    setKeywordsString(keywordsArray.join(",")); // Update state with trimmed entries
  };

  // Handle individual keyword input
  const handleKeywordChange = (index, value) => {
    const keywordsArray = keywordsString.split(","); // Convert string to array
    keywordsArray[index] = value || ""; // Update the specific index
    setKeywordsString(keywordsArray.join(",")); // Convert back to a comma-separated string
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);




  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleNavigate = () => {
    if (validateFields()) {
      navigate("/payment-backlinks");
    }
  }

  const renderHeader = () => {
    if (roles && roles.length > 0) {
      if (roles.includes("Admin")) {
        return <Header1 />;
      } else {
        return <Header2 />;
      }
    }
    // Optional fallback in case `roles` is undefined or empty
    return <Header2 />;
  };

  const validateFields = () => {
    let isValid = true;
    const newErrors = { webUrl: "", keywords: "" };

    if (!webUrl.trim()) {
      newErrors.webUrl = "Website URL is required.";
      isValid = false;
    }

    const firstKeyword = keywordsString.split(",")[0]?.trim();
    if (!keywordsString.trim()) {
      newErrors.keywords = "At least one keyword is required.";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };


  return (
    <>
      {renderHeader()}
      <Tier1BacklinksHome />
      <Tier2BacklinksHome />
      <Tier3BacklinksHome />
      <PremiumTier4BacklinksHome />
      <Box sx={{
        background: "linear-gradient(135deg, #00398e 30%, #005ae2 90%)",
        padding: "40px 0",
        color: "#ffffff",
        display: 'flex',
        justifyContent: "center",
      }}>
        <Button
          sx={{
            backgroundColor: '#ff9800',
            color: "#ffffff",
            width: "170px",
            padding: "10px",
            borderRadius: "5px",
            textTransform: "none",
            textAlign: "center",
            fontWeight: "bold",

          }}
          variant='contained'
          onClick={() => {
            handleOpen();
            //  handleNavigate()
          }}
        >
          Make Payment   <ArrowForwardRoundedIcon fontSize='small' />
        </Button>
      </Box>

      <Dialog open={open} maxWidth="sm" fullWidth sx={{ borderRadius: "8px", boxShadow: 3,  scrollbarWidth: "thin" }}>
        <Box
          sx={{
            maxWidth: "600px",
            bgcolor: "background.paper",
            p: 3,
            borderRadius: 2,
            boxShadow: 3,
          }}
        >
          <AnimatePresence mode="wait">
            <motion.div
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -30 }}
              transition={{ duration: 0.2, ease: "easeInOut" }}
            >
              <Typography
                gutterBottom
                sx={{
                  color: "#FF8C42",
                  fontSize: "23px",
                  fontWeight: "bold",
                  mb: 3,
                  textAlign: "center",
                }}
              >
                Enter Details Below
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                <InputLabel sx={{ fontSize: "16px", fontWeight: "500" }}>
                  Website URL / Page URL*
                </InputLabel>
                <AnimatedTextField
                  size="small"
                  label="Name"
                  name="workspaceName"
                  value={webUrl}
                  onChange={(e) => { setWebUrl(e.target.value) }}
                  error={!!errors.webUrl}
                  helperText={errors.webUrl}
                  fullWidth
                />
              </Box>

              {/* Select Keywords Count */}
              <Box sx={{ display: "flex", flexDirection: "column", gap: 1, mb: 3 }}>
                <InputLabel sx={{ fontSize: "16px", fontWeight: "500" }}>
                  Total Number of Keywords?*
                </InputLabel>
                <AnimatedTextField
                  select
                  size="small"
                  value={keywordsCount}
                  onChange={handleKeywordsCountChange}
                  fullWidth
                >
                  {Array.from({ length: 10 }, (_, index) => (
                    <MenuItem key={index + 1} value={index + 1}>
                      {index + 1}
                    </MenuItem>
                  ))}
                </AnimatedTextField>
              </Box>

              {/* Dynamic Keyword Inputs */}
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
                {Array.from({ length: keywordsCount }).map((_, index) => (
                  <AnimatedTextField
                    key={index}
                    size="small"
                    label={`${index + 1} Keyword ${index === 0 ? "(required)" : "(Optional)"
                      }`}
                    required={index === 0}
                    value={keywordsString.split(",")[index] || ""}
                    onChange={(e) => handleKeywordChange(index, e.target.value)}
                    sx={{ flex: "1 1 calc(50% - 10px)" }} // Adjust for two inputs per row
                    error={index === 0 && !!errors.keywords}
                    helperText={index === 0 ? errors.keywords : ""}
                  />
                ))}
              </Box>

              <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                <InputLabel sx={{ fontSize: "16px", fontWeight: "500" }}>
                  Image URL (we will added within the blog) (Optional)
                </InputLabel>
                <AnimatedTextField
                  size="small"
                  label="Image URL"
                  value={imageUrl}
                  onChange={(e) => setImageUrl(e.target.value)}
                  fullWidth
                />
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                <InputLabel sx={{ fontSize: "16px", fontWeight: "500" }}>
                  YouTube URL (we will added within the blog) (Optional)
                </InputLabel>
                <AnimatedTextField
                  size="small"
                  label="YouTube URL"
                  value={videoUrl}
                  onChange={(e) => setVideoUrl(e.target.value)}
                  fullWidth
                />
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                <InputLabel sx={{ fontSize: "16px", fontWeight: "500" }}>
                  Article Upload (Optional - We will use this article for creating the backlinks)
                </InputLabel>
                <AnimatedTextField
                  size="small"
                  // label="Name"
                  type='file'
                  onChange={(e) => setUploadedFile(e.target.files[0])}
                  fullWidth
                />
              </Box>

              <Box sx={{ display: "flex", justifyContent: "space-between", mt: 4 }}>
                <Button variant="contained" color="warning" onClick={() => { handleClose() }}>
                  Close
                </Button>
                <Button variant="contained" color="warning"
                  //  onClick={() => { handleAddWorkspace(workspaceName) }}
                  onClick={handleNavigate}
                >
                  Buy
                </Button>
              </Box>
            </motion.div>
          </AnimatePresence>
        </Box>
      </Dialog>
      <Footer />

    </>
  )
}

export default MonthlyBacklinks

