import React from "react";
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  Divider,
  Stack,
  Grid,
} from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google";
import { useTheme } from "../../../../../common/ThemeContext";


const ProjectSummaryCard = ({
  ownDomains,
  competitorDomains,
  keywords,
  schedules = [],
  handleBack,
}) => {

  const { theme } = useTheme();

  return (

    <Box
      sx={{
        mx:'auto',
        p: 3,
        borderRadius: 2,
        boxShadow: 1,
        width: '42%',
        flexDirection: 'column',
        textAlign: 'left',
        backgroundColor: theme.palette.mode !== "dark" ? "#312b36" : "#fff",
        border: theme.palette.mode !== "dark" ? "1px solid #4a4a4a" : "1px solid #f2ebf0",
      }}
    >
      <Card
        sx={{
         
          padding: 2,
          boxShadow: 3,
          borderRadius: 1,
          backgroundColor: theme.palette.mode !== "dark" ? "#131314" : "#fbf9fb", border:
            theme.palette.mode !== "dark"
              ? "1px solid #4a4a4a"
              : "1px solid #f2ebf0",
        }}
      >
        <CardContent sx={{ paddingBottom: "16px !important" }}>
          <Stack spacing={2}>
            <Stack direction="row" justifyContent="space-between" sx={{ border: theme.palette.mode !== "dark" ? "2px solid #4a4a4a" : "2px solid #f2ebf0", p: 2 }}>
              <Typography variant="body1" color="text.secondary" sx={{ color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000", }}>
                Project
              </Typography>
              <Typography variant="body1" color="text.primary" sx={{ color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000", }}>
                MyApplication
              </Typography>
            </Stack>

            <Stack direction="row" justifyContent="space-between" sx={{ border: theme.palette.mode !== "dark" ? "2px solid #4a4a4a" : "2px solid #f2ebf0", p: 2 }}>
              <Typography variant="body1" color="text.secondary" sx={{ color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000", }}>
                Own Domains
              </Typography>
              <Typography variant="body1" color="text.primary" sx={{ color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000", }}>
                {ownDomains}
              </Typography>
            </Stack>

            <Stack direction="row" justifyContent="space-between" sx={{ border: theme.palette.mode !== "dark" ? "2px solid #4a4a4a" : "2px solid #f2ebf0", p: 2 }}>
              <Typography variant="body1" color="text.secondary" sx={{ color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000", }}>
                Competitor Domains
              </Typography>
              <Typography variant="body1" color="text.primary" sx={{ color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000", }}>
                {competitorDomains}
              </Typography>
            </Stack>

            <Stack direction="row" justifyContent="space-between" sx={{ border: theme.palette.mode !== "dark" ? "2px solid #4a4a4a" : "2px solid #f2ebf0", p: 2 }}>
              <Typography variant="body1" color="text.secondary" sx={{ color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000", }}>
                Keyword Count
              </Typography>
              <Typography variant="body1" color="text.primary" sx={{ color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000", }}>
                {keywords}
              </Typography>
            </Stack>

            <Stack direction="row" justifyContent="space-between" sx={{ border: theme.palette.mode !== "dark" ? "2px solid #4a4a4a" : "2px solid #f2ebf0", p: 2 }}>
              <Typography variant="body1" color="text.secondary" sx={{ color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000", }}>
                Monthly Keyword Lookups
              </Typography>
              <Typography variant="body1" color="text.primary" sx={{ color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000", }}>
                52
              </Typography>
            </Stack>



            <Stack direction="row" alignItems="center" spacing={1} sx={{ border: theme.palette.mode !== "dark" ? "2px solid #4a4a4a" : "2px solid #f2ebf0", p: 2 }}>
              <GoogleIcon color="primary" fontSize="small" sx={{ color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000", }} />
              <Box>
                <Typography variant="body1" fontWeight="bold" sx={{ color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000", }}>
                  Google Desktop
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000", }}>
                  Karnataka, India
                </Typography>
              </Box>
            </Stack>



            <Stack direction="row" justifyContent="space-between" sx={{ border: theme.palette.mode !== "dark" ? "2px solid #4a4a4a" : "2px solid #f2ebf0", p: 2 }}>
              <Typography variant="body1" color="text.secondary" sx={{ color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000", }}>
                Schedule
              </Typography>
              {schedules &&
                schedules.length > 0 &&
                schedules.map((schedule) => (
                  <Box key={schedule.id} mb={1}>
                    <Typography variant="body2" sx={{ color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000", }}>
                      Days: {schedule.selectedDays.join(", ")}
                    </Typography>
                    <Typography variant="body1" color="text.primary">{schedule.time ? new Date(schedule.time).toLocaleTimeString() : "N/A"}</Typography>


                    <Typography variant="body2" sx={{ color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000", }}>
                      Status: {schedule.isActive ? "Active" : "Inactive"}
                    </Typography>
                  </Box>
                ))}
            </Stack>
          </Stack>
        </CardContent>

        <Box display="flex" justifyContent="space-between" mt={2}>
          <Button
            variant="text"
            color="primary"
            onClick={() => { handleBack() }}
            sx={{
              fontWeight: 'bold',
              color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000",
              backgroundColor: theme.palette.mode !== "dark" ? "#312b36" : "#fff",
              border: theme.palette.mode !== "dark" ? "1px solid #4a4a4a" : "1px solid #f2ebf0",
            }}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"

            sx={{
              fontWeight: 'bold',
              color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000",
              backgroundColor: theme.palette.mode !== "dark" ? "#312b36" : "#fff",
              border: theme.palette.mode !== "dark" ? "1px solid #4a4a4a" : "1px solid #f2ebf0",
            }}
          >
            Save and Continue
          </Button>
        </Box>
      </Card>
    </Box>
  );
};

export default ProjectSummaryCard;
