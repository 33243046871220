import React from 'react';
import { Box, Button, Dialog, DialogTitle, DialogContent, TextField, Typography, MenuItem, Select, InputLabel, Chip, Stack, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EmailIcon from '@mui/icons-material/Email';
import PersonIcon from '@mui/icons-material/Person';
import WorkIcon from '@mui/icons-material/Work';

const roles = ['Project Member', 'Admin', 'Viewer'];
const projects = ['Tech sites', 'Marketing', 'Sales'];

const UserForm = ({ open, onClose }) => {
    const [role, setRole] = React.useState('');
    const [projectTags, setProjectTags] = React.useState([]);

    const handleRoleChange = (event) => {
        setRole(event.target.value);
    };

    const handleProjectChange = (event) => {
        setProjectTags(event.target.value);
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
            <DialogTitle>
                Invite a user
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{ position: 'absolute', right: 8, top: 8 }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                    The invitee will receive an e-mail with instructions.
                </Typography>
                <Stack spacing={2} mt={2}>
                    <TextField label="First Name" defaultValue="Sam" fullWidth />
                    <TextField label="Last Name" defaultValue="Smith" fullWidth />
                    <TextField
                        label="Email"
                        defaultValue="sam.smith@wincher.com"
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <EmailIcon sx={{ mr: 1, color: 'text.secondary' }} />
                            ),
                        }}
                    />
                    <InputLabel id="role-label">Role</InputLabel>
                    <Select
                        labelId="role-label"
                        value={role}
                        onChange={handleRoleChange}
                        fullWidth
                        displayEmpty
                        startAdornment={<PersonIcon sx={{ color: 'text.secondary', mr: 1 }} />}
                    >
                        {roles.map((roleOption) => (
                            <MenuItem key={roleOption} value={roleOption}>
                                {roleOption}
                            </MenuItem>
                        ))}
                    </Select>
                    <InputLabel id="project-label">Projects</InputLabel>
                    <Select
                        labelId="project-label"
                        multiple
                        value={projectTags}
                        onChange={handleProjectChange}
                        renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => (
                                    <Chip key={value} label={value} />
                                ))}
                            </Box>
                        )}
                        fullWidth
                        startAdornment={<WorkIcon sx={{ color: 'text.secondary', mr: 1 }} />}
                    >
                        {projects.map((project) => (
                            <MenuItem key={project} value={project}>
                                {project}
                            </MenuItem>
                        ))}
                    </Select>
                </Stack>
            </DialogContent>
            <Box display="flex" justifyContent="space-between" p={2}>
                <Button onClick={onClose} color="secondary">
                    Cancel
                </Button>
                <Button variant="contained" color="warning">
                    Invite user
                </Button>
            </Box>
        </Dialog>
    );
};

export default UserForm;
