import React, { useState } from 'react';
import { Box, Typography, Paper, Button, Chip } from '@mui/material';
import { styled } from '@mui/system';

const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  minWidth: 100,
  color: '#555',
  border: '1px solid #ccc',
  borderRadius: '20px',
  marginRight: theme.spacing(2),
  '&.active': {
    backgroundColor: '#f0f0f0',
    color: '#000',
    border: '1px solid #000',
  },
}));

// Recommendations Data
const recommendations = {
  Usability: [
    {
      title: 'Optimize for Core Web Vitals',
      type: 'Usability',
      priority: 'Medium Priority',
      priorityColor: 'warning',
    },
  ],
  Performance: [
    {
      title: 'Reduce your total page file size',
      type: 'Performance',
      priority: 'Medium Priority',
      priorityColor: 'warning',
    },
  ],
  Links: [
    {
      title: 'Execute a Link Building Strategy',
      type: 'Links',
      priority: 'High Priority',
      priorityColor: 'error',
    },
  ],
  'On-page SEO': [
    {
      title: 'Improve Title Tags and Meta Descriptions',
      type: 'On-page SEO',
      priority: 'High Priority',
      priorityColor: 'error',
    },
  ],
  Social: [
    {
      title: 'Engage with Your Audience',
      type: 'Social',
      priority: 'Medium Priority',
      priorityColor: 'warning',
    },
  ],
  Others: [
    {
      title: 'Update Content Regularly',
      type: 'Others',
      priority: 'Medium Priority',
      priorityColor: 'warning',
    },
  ],
};

// Component to display individual recommendations
const TabContent = ({ title, type, priority, priorityColor }) => {
  return (
    <Paper
      elevation={0}
      style={{
        padding: '16px',
        marginBottom: '16px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: '1px solid #ccc',
      }}
    >
      <Box>
        <Typography variant="h6" style={{ fontSize: '18px', fontWeight: '500' }}>
          {title}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <Chip label={type} variant="outlined" style={{ marginRight: '16px' }} />
        <Chip label={priority} color={priorityColor} />
      </Box>
    </Paper>
  );
};

const TabContentSeo = () => {
  const [activeCategory, setActiveCategory] = useState('Usability');

  const handleCategoryChange = (category) => {
    setActiveCategory(category);
  };

  return (
    <Box p={4}>
      {/* Recommendations Title */}
      <Typography variant="h5" gutterBottom>
        Recommendations
      </Typography>

      {/* Toggle for categories */}
      <Box display="flex" alignItems="center" mb={2}>
        <Typography variant="body1" style={{ marginRight: '8px' }}>
          Toggle:
        </Typography>
        {Object.keys(recommendations).map((category) => (
          <StyledButton
            key={category}
            className={activeCategory === category ? 'active' : ''}
            onClick={() => handleCategoryChange(category)}
          >
            {category}
          </StyledButton>
        ))}
      </Box>

      {/* Content for selected category */}
      <Box mt={2}>
        {recommendations[activeCategory]?.length > 0 ? (
          recommendations[activeCategory].map((rec, index) => (
            <TabContent key={index} {...rec} />
          ))
        ) : (
          <Typography variant="body1">No recommendations available.</Typography>
        )}
      </Box>
    </Box>
  );
};

export default TabContentSeo;
