

// import React, { useState, useEffect } from "react";
// import { Box, Typography, Grid, Button, IconButton } from "@mui/material";
// import AveragePosition from "./dashboarddetails/dashboardcontent/Averageposition";
// import EstimatedTraffic from "./dashboarddetails/dashboardcontent/Estimatedtraffic";
// import FirstPosition from "./dashboarddetails/dashboardcontent/Firstposition";
// import Positionchanges from "./dashboarddetails/dashboardcontent/Positionchanges";
// import Header from "../../../common/Header";
// import ResponsiveToolbar from "../../../common/Toolbar";
// import FiltersContent from "./dashboarddetails/FiltersContent";
// import { useNavigate, useParams } from "react-router-dom";
// import MobileTableWithChart from "./MobileTableWithChart";
// import logo from "../../../../assets/GMC-logo.15fdaee886680b06fcfd.png";
// import { useTheme } from "../../../common/ThemeContext";
// import Dashboardheader from "../DashboardMainPages/dashboarddetails/Dashboardheader";
// import { apiList, invokeApi } from "../../../../services/apiServices";
// import { config } from "../../../../config/config";
// import { useCookies } from "react-cookie";

// const HomePage = () => {
//   const { theme } = useTheme();
//   const [cookies] = useCookies();
//   const [isCompactView, setIsCompactView] = useState(true);
//   const navigate = useNavigate();
//   const { id } = useParams();
//   const [analyticsData, setAnalyticsData] = useState([]);


//   const handleKeywordAnalytics = async () => {
//     try {
//       const params = {
//         projectId: 1,
//         startDate: "2024-11-20"
//       };
//       const response = await invokeApi(
//         config.getMyCollege + apiList.getKeywordAnalytics,
//         params,
//         cookies
//       );
//       if (response?.status === 200) {
//         setAnalyticsData(response.data.analytics);
//       } else {
//         console.error("Failed to get projects:", response);
//       }
//     } catch (error) {
//       console.error("Error get projects:", error);
//     }
//   };

//   useEffect(() => {
//     handleKeywordAnalytics();
//   }, [])

// const handleNavigateDashboard=(id)=>{
//   navigate(`/keywords/${id}`);
// }


//   return (
//     <Grid>
//       {/* Main content of HomePage */}
//       <Box
//         sx={{
//           height: "100vh",
//           background: theme.palette.background.homeBackground,
//           display: "flex",
//           flexDirection: "column",
//           zIndex: 2,
//         }}
//       >
//         <Dashboardheader />
//         <Box sx={{ flexGrow: 1, overflow: "auto", p: "20px", mt: 13 }}>

//           {analyticsData.map((analytics, index) => (
//             <Box
//               key={index}
//               sx={{
//                 p: 3,
//                 bgcolor: theme.palette.mode !== "dark" ? "#2a252f" : "#fbf9fb",
//                 border: theme.palette.mode !== "dark" ? "1px solid #4a4a4a" : "none",
//                 color: theme.palette.text.primary,
//                 borderRadius: 2,
//                 boxShadow: 2,
//                 minHeight: "calc(100vh - 160px)",
//               }}
//             >
//               {/* Top Header Section */}
//               <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
//                 <Box
//                   width="100%"
//                   display="flex"
//                   alignItems="center"
//                   mb={3}
//                   onClick={() => {handleNavigateDashboard(analytics.id) }}
//                 >
//                   {/* <IconButton edge="start" color="inherit" sx={{ marginRight: 1 }}>
//                   <img
//                     src={logo}
//                     alt="Logo"
//                     style={{ width: "30px", borderRadius: "50%" }}
//                   />
//                 </IconButton> */}
//                   <Typography
//                     variant="h6"
//                     sx={{
//                       fontWeight: "bold",
//                       fontSize: "1.5rem",
//                       color: "#0077C8",
//                       // color: theme.palette.text.primary,
//                       cursor: 'pointer',
//                     }}
//                   >
//                     {analytics.domain	}
//                   </Typography>
//                 </Box>
//                 <Box>
//                   <Typography variant="subtitle1" sx={{ color: '#373737', fontWeight: "bold" }}>
//                     Last Updated :
//                   </Typography>
//                   <Typography sx={{}}>{analytics.lastUpdated}</Typography>
//                 </Box>
//               </Box>


//               {/* Desktop Main Metrics Section */}
//               <Box
//                 mb={3}
//                 sx={{
//                   borderRadius: 2,
//                   bgcolor: theme.palette.mode !== "dark" ? "#2B2B3F" : "#ffffff",
//                   border: theme.palette.mode !== "dark" ? "1px solid #4a4a4a" : "1px solid #f2ebf0",
//                   overflow: "hidden",
//                 }}
//               >
//                 <Box
//                   mb={3}
//                   sx={{
//                     bgcolor: theme.palette.mode !== "dark" ? "#2B2B3F" : "#ffffff",
//                     borderBottom: theme.palette.mode !== "dark" ? "1px solid #4a4a4a" : "1px solid #f2ebf0",
//                   }}
//                 >
//                   <Grid container spacing={2} alignItems="center">
//                     <Grid item>
//                       <Box
//                         sx={{
//                           borderRight: theme.palette.mode !== "dark" ? "1px solid #4a4a4a" : "1px solid #f2ebf0",
//                           py: 2,
//                           textAlign: "center",
//                           width: "200px",
//                         }}
//                       >
//                         <Typography variant="body2">google.com - English</Typography>
//                       </Box>
//                     </Grid>
//                     <Grid item>
//                       <Box
//                         sx={{
//                           borderRight: theme.palette.mode !== "dark" ? "1px solid #4a4a4a" : "1px solid #f2ebf0",
//                           py: 2,
//                           textAlign: "center",
//                           width: "100px",
//                         }}
//                       >
//                         <Typography variant="body2">United States</Typography>
//                       </Box>
//                     </Grid>
//                     <Grid item>
//                       <Box
//                         sx={{
//                           borderRight: theme.palette.mode !== "dark" ? "1px solid #4a4a4a" : "1px solid #f2ebf0",
//                           py: 2,
//                           textAlign: "center",
//                           width: "80px",
//                         }}
//                         onClick={() => { navigate('/dashboardHome') }}
//                       >
//                         <Typography variant="body2">Desktop</Typography>
//                       </Box>
//                     </Grid>
//                     <Grid item>
//                       <Box
//                         sx={{
//                           borderRight: theme.palette.mode !== "dark" ? "1px solid #4a4a4a" : "1px solid #f2ebf0",
//                           py: 2,
//                           textAlign: "center",
//                           width: "130px",
//                         }}
//                       >
//                         <Typography variant="body2">152 keywords</Typography>
//                       </Box>
//                     </Grid>
//                     <Grid item>
//                       <Box
//                         sx={{
//                           borderRight: theme.palette.mode !== "dark" ? "1px solid #4a4a4a" : "1px solid #f2ebf0",
//                           py: 2,
//                           textAlign: "center",
//                           width: "130px",
//                         }}
//                       >
//                         <Typography variant="body2">0 competitors</Typography>
//                       </Box>
//                     </Grid>
//                     <Grid item xs sx={{ display: "flex", justifyContent: "flex-end", pr: "10px" }}>
//                       <Button variant="contained" color="warning" sx={{ borderRadius: "20px", px: 4 }}>
//                         Dashboard
//                       </Button>
//                     </Grid>
//                   </Grid>
//                 </Box>
//                 <Grid container item xs={12} spacing={2} sx={{ px: "10px", pb: "10px" }}>
//                   <Grid item xs={2.5}>
//                     <Positionchanges />
//                   </Grid>
//                   <Grid item xs={2.5}>
//                     <AveragePosition />
//                   </Grid>
//                   <Grid item xs={2.5}>
//                     <EstimatedTraffic />
//                   </Grid>
//                   <Grid item xs={4.5}>
//                     <FirstPosition />
//                   </Grid>
//                 </Grid>
//               </Box>


//             </Box>
//           ))}


//         </Box>
//       </Box>
//     </Grid>
//   );
// };

// export default HomePage;






// import React, { useState } from "react";
// import { Box, Typography, Grid, IconButton } from "@mui/material";
// import RefreshIcon from "@mui/icons-material/Refresh";
// import PublicIcon from "@mui/icons-material/Public";
// import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
// import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
// import image from "../../../../assets/Screenshot.png";
// import { useTheme } from "../../../common/ThemeContext";
// import Dashboardheader from "../DashboardMainPages/dashboarddetails/Dashboardheader";
// import { useNavigate, useParams } from "react-router-dom";
// import { useCookies } from "react-cookie";


// const HomePage = () => {
//   const { theme } = useTheme();
//   const [cookies] = useCookies();
//   const navigate = useNavigate();
//   const { id } = useParams();
//   const [analyticsData, setAnalyticsData] = useState([]);


//   const handleKeywordAnalytics = async () => {
//     try {
//       const params = {
//         projectId: 1,
//         startDate: "2024-11-20"
//       };
//       const response = await invokeApi(
//         config.getMyCollege + apiList.getKeywordAnalytics,
//         params,
//         cookies
//       );
//       if (response?.status === 200) {
//         setAnalyticsData(response.data.analytics);
//       } else {
//         console.error("Failed to get projects:", response);
//       }
//     } catch (error) {
//       console.error("Error get projects:", error);
//     }
//   };

//   useEffect(() => {
//     handleKeywordAnalytics();
//   }, [])

// const handleNavigateDashboard=(id)=>{
//   navigate(`/keywords/${id}`);
// }


//   return (
//     <Grid>
//       {/* Main content of HomePage */}
//       <Box
//         sx={{
//           height: "100vh",
//           background: theme.palette.background.homeBackground,
//           display: "flex",
//           flexDirection: "column",
//           zIndex: 2,
//         }}
//       >
//         <Dashboardheader />
//         <Box sx={{ flexGrow: 1, overflow: "auto", p: "20px", mt: 13 }}>

//           {analyticsData.map((analytics, index) => (
//             <Box
//               sx={{
//                 display: "flex",
//                 flexDirection: "row",
//                 width: "100%",
//                 padding: "20px",
//                 gap: "20px",
//                 cursor: 'pointer',
//                 // border: theme.palette.mode !== "dark" ? "1px solid #4a4a4a" : "1px solid #f2ebf0",
//                 borderRadius: "8px",
//                 boxSizing: "border-box",

//               }}
//             >
//               {/* Left Section - Image */}
//               <Box
//                 sx={{
//                   width: "30%",
//                   height: "140px",
//                   borderRadius: "8px",
//                   overflow: "hidden",
//                   backgroundColor: "#f9f9f9",
//                 }}
//               >
//                 <img
//                   src={image}
//                   alt="Browse by category"
//                   style={{
//                     width: "100%",
//                     height: "100%",
//                     objectFit: "cover",
//                     borderRadius: "8px",
//                   }}
//                 />
//               </Box>

//               {/* Right Section - Content */}
//               <Box
//                 sx={{
//                   width: "60%",
//                   display: "flex",
//                   flexDirection: "column",
//                   justifyContent: "space-between",
//                 }}
//               >
//                 {/* Statistics Grid */}
//                 <Grid container spacing={2}>
//                   {[
//                     { label: "Top 1-10", color: "gold", arrow: true },
//                     { label: "Top 11-20", color: "orange", arrow: false },
//                     { label: "Top 21-50", color: "gray", arrow: false },
//                     { label: "Top 51-100", color: "gray", arrow: false },
//                     { label: "Top 100+", color: "gray", arrow: false },
//                   ].map((item, index) => (
//                     <Grid item xs={12} sm={6} md={2.4} key={index}>
//                       <Box
//                         sx={{
//                           display: "flex",
//                           flexDirection: "column",
//                           alignItems: "center",
//                           justifyContent: "center",
//                           padding: "10px",

//                           borderRadius: "8px",
//                           height: "100px",

//                         }}
//                       >
//                         {/* Label */}
//                         <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
//                           <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
//                             {item.label}
//                           </Typography>
//                           <EmojiEventsIcon sx={{ fontSize: 20, color: item.color }} />
//                         </Box>
//                         {/* Score */}
//                         <Typography variant="h4" sx={{ marginTop: "10px" }}>
//                           0
//                         </Typography>
//                       </Box>
//                     </Grid>
//                   ))}
//                 </Grid>
//               </Box>
//             </Box>
//           ))
//           }
//         </Box>
//       </Box>
//     </Grid>

//   );
// };

// export default HomePage;










import React, { useState, useEffect } from "react";
import { Box, Typography, Grid, Button, IconButton, TextField } from "@mui/material";
import DateRangeIcon from '@mui/icons-material/DateRange';
import SearchIcon from '@mui/icons-material/Search';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTheme } from "../../common/ThemeContext";
import { useCookies } from "react-cookie";
import { apiList, invokeApi } from "../../../services/apiServices";
import { config } from "../../../config/config";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import image from "../../../assets/Screenshot.png";
import Dashboardheader from "../common/DashboardHeader";

const Domain = () => {
  const { theme } = useTheme();
  const [cookies] = useCookies();
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const [analyticsData, setAnalyticsData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(() => {
    // Initialize with the current date in YYYY-MM-DD format
    const today = new Date();
    return today.toISOString().split("T")[0];
  });

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };


  const handleKeywordAnalytics = async () => {
    try {
      const params = {
        projectId: id,
      };
      const response = await invokeApi(
        config.getMyCollege + apiList.getAllDomainTracking,
        params,
        cookies
      );
      if (response?.status === 200) {
        setAnalyticsData(response.data.domains);
      } else {
        console.error("Failed to get projects:", response);
      }
    } catch (error) {
      console.error("Error get projects:", error);
    }
  };

  useEffect(() => {
    handleKeywordAnalytics();
  }, [])

  const handleNavigateDashboard = (id) => {
    navigate(`/keywords/${id}`);
  }

  const formatDateTime = (dateString) => {
    if (!dateString) return "";

    const date = new Date(dateString);

    const dateOptions = {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    const timeOptions = {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    const formattedDate = date.toLocaleDateString("en-US", dateOptions);
    const formattedTime = date.toLocaleTimeString("en-US", timeOptions);

    return `${formattedDate}, ${formattedTime}`;
  };

  return (
    <Grid>
      {/* Main content of HomePage */}
      <Box
        sx={{
          height: "100vh",
          background: theme.palette.background.homeBackground,
          display: "flex",
          flexDirection: "column",
          zIndex: 2,
        }}
      >
        <Dashboardheader pathname={location.pathname} />
        <Box sx={{ flexGrow: 1, overflow: "auto", p: "20px", mt: 13 }}>
          {analyticsData.map((analytics, index) => (
            /* Desktop Main Metrics Section */
            <Box
              key={index}
              mb={3}
              sx={{
                borderRadius: 2,
                bgcolor: theme.palette.mode !== "dark" ? "#2B2B3F" : "#ffffff",
                border: theme.palette.mode !== "dark" ? "1px solid #4a4a4a" : "1px solid #f2ebf0",
                overflow: "hidden",
              }}
            >
              {/* Header Section */}
              <Box
                mb={3}
                sx={{
                  bgcolor: theme.palette.mode !== "dark" ? "#2B2B3F" : "#ffffff",
                  borderBottom: theme.palette.mode !== "dark" ? "1px solid #4a4a4a" : "1px solid #f2ebf0",
                }}
              >
                <Grid container spacing={2} alignItems="center">
                  {/* Left: Website Name */}
                  <Grid item>
                    <Box
                      sx={{
                        borderRight: theme.palette.mode !== "dark" ? "1px solid #4a4a4a" : "1px solid #f2ebf0",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        py: 2,
                        textAlign: "center",
                        width: "200px",
                      }}
                      onClick={() => { handleNavigateDashboard(analytics.id) }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          cursor: "pointer",
                          fontWeight: "bold",
                          fontSize: 16,
                          color: "#0077C8",
                          pl: 1,
                        }}
                      >
                        {analytics.domain}
                      </Typography>
                    </Box>
                  </Grid>

                  {/* <Grid item xs>
                    <Box
                      sx={{
                        borderRight: theme.palette.mode !== "dark" ? "1px solid #4a4a4a" : "1px solid #f2ebf0",
                        width: "400px",
                        display: "flex",
                        justifyContent: "left",
                        alignItems: "left",
                        py: 2,
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <DateRangeIcon sx={{ color: "#0077C8", mr: 1 }} />
                        <Typography
                          variant="subtitle1"
                          sx={{
                            color: "#373737",
                            fontWeight: "bold",
                            mr: 1,
                          }}
                        >
                          Last Updated:
                        </Typography>
                        <Typography>{formatDateTime(analytics.lastUpdated)}</Typography>
                      </Box>
                    </Box>
                  </Grid> */}

                  {/* Right: Search Icon */}
                  <Grid item xs>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        gap: 1,
                      }}
                    >
                      <Box
                        sx={{
                          borderRight: theme.palette.mode !== "dark" ? "1px solid #4a4a4a" : "1px solid #f2ebf0",
                          width: "400px",
                          display: "flex",
                          justifyContent: "left",
                          alignItems: "left",
                          py: 2,
                        }}
                      >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <DateRangeIcon sx={{ color: "#0077C8", mr: 1 }} />
                          {/* Date Content */}
                          <Typography
                            variant="subtitle1"
                            sx={{
                              color: "#373737",
                              fontWeight: "bold",
                              mr: 1,
                            }}
                          >
                            Last Updated:
                          </Typography>
                          {analytics.lastUpdated === null ? 'Not updated yet' :
                            <Typography>{formatDateTime(analytics.lastUpdated)}</Typography>
                          }
                        </Box>
                      </Box>
                      {/* Select Date Text and Icon */}
                      {/* <Typography
                        variant="subtitle1"
                        sx={{
                          color: "#373737",
                          fontWeight: "bold",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        Select Date:
                      </Typography> */}

                      {/* Date Input Field */}
                      {/* <TextField
                        type="date"
                        size="small"
                        value={selectedDate}
                        onChange={handleDateChange}
                        sx={{ width: "200px" }}
                        inputProps={{
                          style: { fontSize: 14 },
                        }}
                      /> */}

                      {/* Search Icon */}
                      {/* <SearchIcon
                        sx={{
                          color: "#0077C8",
                          cursor: "pointer",
                          mr: 2,
                        }}
                      /> */}
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              {/* Main Content */}
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      padding: "20px",
                      gap: "20px",
                      cursor: 'pointer',
                      // border: theme.palette.mode !== "dark" ? "1px solid #4a4a4a" : "1px solid #f2ebf0",
                      borderRadius: "8px",
                      boxSizing: "border-box",

                    }}
                  >
                    {/* Left Section - Image */}
                    <Box
                      sx={{
                        width: "30%",
                        height: "140px",
                        borderRadius: "8px",
                        overflow: "hidden",
                        backgroundColor: "#f9f9f9",
                      }}
                    >
                      <img
                        src={image}
                        alt="Browse by category"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          borderRadius: "8px",
                        }}
                      />
                    </Box>

                    {/* Right Section - Content */}
                    <Box
                      sx={{
                        width: "70%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      {/* Statistics Grid */}
                      <Grid container spacing={2}>
                        {[
                          { label: "Total", color: "gray", arrow: false, rank: analytics.totalKeywords },
                          { label: "Top 1-3", color: "gold", arrow: true, rank: analytics.rankings.topThree },
                          { label: "Top 4-10", color: "gold", arrow: true, rank: analytics.rankings.topTen },
                          { label: "Top 11-20", color: "orange", arrow: false, rank: analytics.rankings.topTwenty },
                          { label: "Top 21-50", color: "gray", arrow: false, rank: analytics.rankings.topFifty },
                          { label: "Top 51-100", color: "gray", arrow: false, rank: analytics.rankings.topHundred },
                        ].map((item, index) => (
                          <Grid item xs={12} sm={6} md={2} key={index}>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                padding: "10px",

                                borderRadius: "8px",
                                height: "100px",

                              }}
                            >
                              {/* Label */}
                              <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                                  {item.label}
                                </Typography>
                                {item.arrow && <EmojiEventsIcon sx={{ fontSize: 20, color: item.color }} />}
                              </Box>
                              {/* Score */}
                              <Typography variant="h4" sx={{ marginTop: "10px" }}>
                                {item.rank}
                              </Typography>
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          ))}

        </Box>

      </Box>

    </Grid >
  );
};

export default Domain;