import React, { useEffect, useState } from 'react';
import { Container, TextField, Button, Typography, CircularProgress, Box, Paper, Grid } from '@mui/material';
import { AiOutlinePaperClip, AiOutlineCloudUpload } from 'react-icons/ai';
import Header from '../../../common/Header';
import Plagiarismcontent from './Plagiarismcontent';

const TextToImage = () => {
  const [inputText, setInputText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);

  const handleInputChange = (e) => {
    setInputText(e.target.value);
  };

  const handleGenerateImage = async () => {
    setIsLoading(true);
    setImageUrl(null);

    try {
      await new Promise((resolve) => setTimeout(resolve, 2000));

      // Mock response simulating generated image URL
      const mockResponse = {
        imageUrl: 'https://via.placeholder.com/500', // Placeholder for generated image
      };

      setImageUrl(mockResponse.imageUrl);
    } catch (error) {
      setImageUrl(null);
      alert('An error occurred while generating the image');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <Grid
        sx={{
          background: '#fff',
          p: 4,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff9f7',
        }}
      >
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} md={9}>
            <Paper elevation={6} sx={{ padding: '20px', boxShadow: '0 4px 20px rgba(255, 72, 0, 0.1)' }}>
              <Typography variant="h5" color="primary" align="center" gutterBottom>
                Text to Image Generator
              </Typography>

              <TextField
                label="Enter text to generate image"
                variant="outlined"
                fullWidth
                multiline
                rows={4}
                value={inputText}
                onChange={handleInputChange}
                sx={{
                  backgroundColor: '#f5f5f5',
                  borderRadius: '10px',
                }}
              />

              <Box textAlign="center" mt={2}>
                <Button
                  variant="contained"
                  onClick={handleGenerateImage}
                  disabled={!inputText || isLoading}
                  size="large"
                  sx={{
                    backgroundColor: '#ff6f61',
                    color: '#fff',
                    '&:hover': {
                      backgroundColor: '#ff8b7f',
                    },
                  }}
                >
                  {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Generate Image'}
                </Button>
              </Box>

              {imageUrl && (
                <Box textAlign="center" mt={4}>
                  <Typography variant="h6" color="success.main" gutterBottom>
                    Generated Image:
                  </Typography>
                  <Box component="img" src={imageUrl} alt="Generated" sx={{ maxWidth: '100%', borderRadius: '8px' }} />
                </Box>
              )}
            </Paper>
          </Grid>

          {/* Ad Section */}
          <Grid item xs={12} md={3}>
            <Paper elevation={6} sx={{ padding: '20px', textAlign: 'center', boxShadow: '0 4px 20px rgba(255, 72, 0, 0.1)' }}>
              <Typography variant="h6">Register To Get Your Pass</Typography>
              <Typography variant="body2" color="textSecondary">
                Global leaders: Tetra Pak, Givaudan, Apical, Cargill, Krones, IFFCO exhibit in 2024
              </Typography>
              <Button variant="contained" color="primary" sx={{ mt: 2 }}>
                Sign Up
              </Button>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <Plagiarismcontent />
    </>
  );
};

export default TextToImage;
