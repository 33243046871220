import React, { useState, useEffect } from 'react';
import {
  CircularProgress,
  Typography,
  Grid,
  Box
} from '@mui/material';
import { Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer, Tooltip, Dot } from 'recharts';

const SeoReport = () => {
  const radarData = [
    { subject: 'Links', A: 30, fullMark: 100 },
    { subject: 'Performance', A: 60, fullMark: 100 },
    { subject: 'On-Page SEO', A: 90, fullMark: 100 },
    { subject: 'Social', A: 20, fullMark: 100 },
    { subject: 'Usability', A: 40, fullMark: 100 },
  ];

  const metrics = [
    { label: 'On-Page SEO', value: 90, color: '#4CAF50' }, // A
    { label: 'Links', value: 30, color: '#F44336' }, // F
    { label: 'Usability', value: 60, color: '#FFC107' }, // D
    { label: 'Performance', value: 70, color: '#00BCD4' }, // C-
    { label: 'Social', value: 10, color: '#9C27B0' }, // F
  ];

  const [progress, setProgress] = useState(
    metrics.map(() => 0) // Initialize progress for all metrics to 0
  );

  // Animate the circular progress for each metric
  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress.map((value, index) =>
          value < metrics[index].value ? value + 1 : value // Increment progress up to the metric's value
        )
      );
    }, 20); // Adjust speed of the animation (ms per step)

    return () => {
      clearInterval(timer); // Clear the interval when the component unmounts
    };
  }, [metrics]);

  return (
    <Box sx={{ padding: 4 }}>
      <Grid container spacing={3} justifyContent="center" alignItems="center">
        {/* Left Section: Circular Counters */}
        <Grid item xs={12} md={8}>
          <Grid container spacing={2} justifyContent="center">
            {metrics.map((metric, index) => (
              <Grid item xs={6} sm={2} key={index}>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Box position="relative" display="inline-flex">
                    <CircularProgress
                      variant="determinate"
                      value={progress[index]} // Use animated progress value
                      size={100}
                      thickness={4}
                      sx={{ color: metric.color }}
                    />
                    <Box
                      position="absolute"
                      top={0}
                      left={0}
                      right={0}
                      bottom={0}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Typography variant="h5" component="div" color={metric.color}>
                        {metric.label.charAt(0)} {/* First letter */}
                      </Typography>
                    </Box>
                  </Box>
                  <Typography variant="subtitle1" sx={{ marginTop: 1 }}>
                    {metric.label}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Grid>

        {/* Right Section: Radar Chart */}
        <Grid item xs={12} md={4}>
          <ResponsiveContainer width="100%" height={400}>
            <RadarChart outerRadius={90} data={radarData}>
              <PolarGrid />
              <PolarAngleAxis dataKey="subject" />
              <PolarRadiusAxis angle={30} domain={[0, 100]} />
              <Radar
                name="SEO Metrics"
                dataKey="A"
                stroke="#2196F3"
                fill="#2196F3"
                fillOpacity={0.6}
                dot={{ r: 4 }} // Add dots to the chart
                activeDot={{ r: 8 }} // Larger dot when hovered
              />
              <Tooltip /> {/* Add tooltip to show values on hover */}
            </RadarChart>
          </ResponsiveContainer>
        </Grid>
      </Grid>

      {/* Footer */}
      <Typography
        variant="caption"
        color="textSecondary"
        align="center"
        display="block"
        sx={{ marginTop: 4 }}
      >
        Report Generated: 18 October 4:34AM UTC | <a href="#">Refresh Results Now</a>
      </Typography>
    </Box>
  );
};

export default SeoReport;
