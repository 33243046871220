import { Grid, Typography } from '@mui/material';
import React from 'react';
import img from '../../../assets/about-image.png';
import { Box, } from '@mui/system';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export const About = () => {
    return (
        <>
            <Grid container spacing={2} sx={{ paddingX: 4 }}>
                {/* Left Image Section */}
                <Grid item md={5} xs={12}>
                    <img
                        src={img}
                        alt="About Us"
                        style={{ width: '100%', height: 'auto' }}
                    />
                </Grid>

                {/* Right Text Section */}
                <Grid
                    item
                    md={7}
                    xs={12}
                    container
                    alignItems="center" // Centers vertically
                    justifyContent="center" // Centers horizontally if needed
                >
                    <Grid container spacing={3}>
                        {/* About Section */}
                        <Grid item xs={12}>
                            <Typography variant="h4" gutterBottom sx={{ fontSize: '40px', fontWeight: 700 }}>
                                About
                            </Typography>
                            <Typography sx={{ color: '#717173', fontSize: '17px', fontWeight: 300, lineHeight: '30px' }}>
                                At SEO Mitra, we specialize in empowering businesses with tailored backlink services and advanced keyword tracking solutions. We are your go-to partner for boosting online visibility and driving measurable results.
                            </Typography>
                        </Grid>

                        {/* Who We Are Section */}
                        <Grid item xs={6}>
                            <Typography variant="h5" gutterBottom sx={{ fontSize: '40px', fontWeight: 500 }}>
                                Who we are
                            </Typography>
                            <Typography sx={{ color: '#717173', fontSize: '17px', fontWeight: 300, lineHeight: '30px' }}>
                                Founded in 2016 by a team of seasoned SEO experts and digital marketers, SEO Mitra is driven by a passion for helping businesses succeed online through data-driven strategies and cutting-edge SEO tools.
                            </Typography>
                        </Grid>

                        {/* Why Haaps Section */}
                        <Grid item xs={6}>
                            <Typography variant="h5" gutterBottom sx={{ fontSize: '40px', fontWeight: 500 }}>
                                Why SEO Mitra?
                            </Typography>
                            <Typography sx={{ color: '#717173', fontSize: '16px', fontWeight: 300, lineHeight: '25px' }}>
                                We enable businesses to unlock their true potential with our expertise in backlink building, keyword tracking, and proven strategies to enhance search engine rankings, website traffic, and conversion rates.                     </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

             {/* Content Section - Features */}
             <Grid sx={{ mb: 6 }}>
                <Grid container spacing={4} justifyContent="space-around" alignItems="center">
                    {/* Item 1 */}
                    <Grid item display="flex" textAlign={'left'}>
                        <CheckCircleIcon sx={{ fontSize: { xs: '30px', md: '40px' }, color: '#FF8C32', mr: 2 }} />
                        <Box>
                            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                Improve your rankings
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Boost your positions in the SERP
                            </Typography>
                        </Box>
                    </Grid>

                    {/* Item 2 */}
                    <Grid item display="flex" alignItems="center">
                        <CheckCircleIcon sx={{ fontSize: { xs: '30px', md: '40px' }, color: '#FF8C32', mr: 2 }} />
                        <Box>
                            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                Track your performance
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Daily reports by e-mail
                            </Typography>
                        </Box>
                    </Grid>

                    {/* Item 3 */}
                    <Grid item display="flex" alignItems="center">
                        <CheckCircleIcon sx={{ fontSize: { xs: '30px', md: '40px' }, color: '#FF8C32', mr: 2 }} />
                        <Box>
                            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                Outrank competitors
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Know their rankings and beat them
                            </Typography>
                        </Box>
                    </Grid>

                    {/* Item 4 */}
                    <Grid item display="flex" alignItems="center">
                        <CheckCircleIcon sx={{ fontSize: { xs: '30px', md: '40px' }, color: '#FF8C32', mr: 2 }} />
                        <Box>
                            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                Snowball organic volumes
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Discover new important keywords
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>

        </>
    );
};
