// Import necessary modules
import React from 'react';
import { Box, Typography, Card, CardContent, Divider, Button, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTheme } from "../../../../../common/ThemeContext";


// Define custom styles
const StyledCard = styled(Card)(({ theme }) => ({
    backgroundColor: theme.palette.grey[900],
    color: theme.palette.grey[50],
    borderRadius: theme.spacing(1),
    boxShadow: 'none',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
}));

const EditButton = styled(Button)({
    color: '#FFA726',
    textTransform: 'none',
    fontSize: '0.875rem',
    padding: 0,
});

function BillingSection() {
    const { theme } = useTheme();

    return (
        <Grid sx={{ p: 4, color: 'white' }}>
            {/* Payment Methods Card */}
            <StyledCard sx={{
                backgroundColor: theme.palette.mode !== "dark" ? "#312b36" : "#fff", color: '#fff', border:
                    theme.palette.mode !== "dark"
                        ? "1px solid #4a4a4a"
                        : "1px solid #f2ebf0", borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.3)'
            }}>
                <Typography variant="h6" sx={{ fontSize: '1rem', fontWeight: 'bold', color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000", }}>Payment methods</Typography>
                <Divider sx={{ my: 1, borderColor: '#2c2c2c' }} />
                <Typography variant="body2" color="textSecondary" sx={{ color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000",}}>
                    You do not have any saved payment methods.
                </Typography>
            </StyledCard>

            {/* Billing Details Card */}
            <StyledCard sx={{
                backgroundColor: theme.palette.mode !== "dark" ? "#312b36" : "#fff", color: '#fff', border:
                    theme.palette.mode !== "dark"
                        ? "1px solid #4a4a4a"
                        : "1px solid #f2ebf0", borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.3)'
            }}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6" sx={{ fontSize: '1rem', fontWeight: 'bold', color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000", }}>Billing details</Typography>
                    <EditButton variant="text">Edit</EditButton>
                </Box>
                <Divider sx={{ my: 1, borderColor: '#2c2c2c' }} />
                <Typography variant="body2" color="textSecondary" sx={{ color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000",}}>
                    You do not have any saved billing details.
                </Typography>
            </StyledCard>
        </Grid>
    );
}

export default BillingSection;
