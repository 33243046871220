import React, { useEffect, useState } from "react";
import { Container, TextField, Button, Typography, CircularProgress, Box, Paper, Grid, MenuItem, Select } from '@mui/material';
import { AiOutlinePaperClip, AiOutlineCloudUpload } from 'react-icons/ai';
import Plagiarismcontent from './Plagiarismcontent';
import Header from "../../../common/home/Header";
import Footer from "../../../common/Footer";

const ArticleRewriter = () => {
  const [inputText, setInputText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState(null);

  const handleInputChange = (e) => {
    setInputText(e.target.value);
  };

  const handleRewriteArticle = async () => {
    setIsLoading(true);
    setResult(null);

   
    try {
      await new Promise((resolve) => setTimeout(resolve, 2000));

      // Mock response simulating rewritten content
      const mockResponse = {
        rewrittenText: `This is a rewritten version of your text: "${inputText.slice(0, 50)}..."`, // Preview the first 50 characters
      };

      setResult(mockResponse);
    } catch (error) {
      setResult({ error: 'An error occurred while rewriting the article' });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (  
    <>
      <Header />
      <Grid
        sx={{
          background: '#fff',
          p: 4,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff9f7',
        }}
      >
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} md={9}>
            <Paper elevation={6} sx={{ padding: '20px', boxShadow: '0 4px 20px rgba(255, 72, 0, 0.1)' }}>
              <Typography variant="h5" color="primary" align="center" gutterBottom>
                Article Rewriter
              </Typography>

              <TextField
                label="Copy and paste your text here to rewrite..."
                variant="outlined"
                fullWidth
                multiline
                rows={10}
                value={inputText}
                onChange={handleInputChange}
                sx={{
                  backgroundColor: '#f5f5f5',
                  borderRadius: '10px',
                }}
              />

              <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
                <Box display="flex" gap={1}>
                  <Button startIcon={<AiOutlinePaperClip />} />
                  <Button startIcon={<AiOutlineCloudUpload />} />
                </Box>

                <Typography variant="body2">Words Limit/Search: {inputText.split(' ').length}/1000</Typography>
                <Select value="Text" variant="outlined" sx={{ minWidth: 120 }}>
                  <MenuItem value="Text">Rewrite Text</MenuItem>
                </Select>
              </Box>

              <Box textAlign="center" mt={2}>
                <Button
                  variant="contained"
                  onClick={handleRewriteArticle}
                  disabled={!inputText || isLoading}
                  size="large"
                  sx={{
                    backgroundColor: '#ff6f61',
                    color: '#fff',
                    '&:hover': {
                      backgroundColor: '#ff8b7f',
                    },
                  }}
                >
                  {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Rewrite Now'}
                </Button>
              </Box>

              {result && (
                <Box textAlign="center" mt={4}>
                  {result.error ? (
                    <Typography
                      variant="h6"
                      color="error"
                      gutterBottom
                      sx={{
                        fontWeight: 'bold',
                        backgroundColor: '#ffe0e0',
                        padding: '10px',
                        borderRadius: '8px',
                      }}
                    >
                      {result.error}
                    </Typography>
                  ) : (
                    <>
                      <Typography variant="h6" color="success.main" gutterBottom>
                        Rewritten Text:
                      </Typography>
                      <Typography variant="body2" color="textSecondary" sx={{ backgroundColor: '#e0ffe0', padding: '10px', borderRadius: '8px' }}>
                        {result.rewrittenText}
                      </Typography>
                    </>
                  )}
                </Box>
              )}
            </Paper>
          </Grid>

          {/* Ad Section */}
          <Grid item xs={12} md={3}>
            <Paper elevation={6} sx={{ padding: '20px', textAlign: 'center', boxShadow: '0 4px 20px rgba(255, 72, 0, 0.1)' }}>
              <Typography variant="h6">Register To Get Your Pass</Typography>
              <Typography variant="body2" color="textSecondary">
                Global leaders: Tetra Pak, Givaudan, Apical, Cargill, Krones, IFFCO exhibit in 2024
              </Typography>
              <Button variant="contained" color="primary" sx={{ mt: 2 }}>
                Sign Up
              </Button>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <Plagiarismcontent/>
      <Footer/>
    </>
  );
};

export default ArticleRewriter;
