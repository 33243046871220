import React from 'react';
import { Box, CssBaseline, Grid } from '@mui/material';
import Dashboardheader from '../../../DashboardMainPages/dashboarddetails/Dashboardheader';
import Dashboardsideicon from '../../../DashboardMainPages/dashboarddetails/Dashboardsideicon';
import DashBoardFooter from '../../../../../common/DashBoardFooter';
import CurrentPlan from './CurrentPlan';
import BillingSection from './BillingSection';
import { useTheme } from "../../../../../common/ThemeContext";



const PlanBillingHome = () => {
    const { theme } = useTheme();


    return (
        <>
            <CssBaseline /> 
            <Grid container>
                <Grid item xs={12} sx={{ marginTop: 2, flexGrow: 2 }}> 
                    <Dashboardheader />
                </Grid>
            
                <Grid item xs={12} sx={{ display: 'flex', flexGrow: 1 }}> 
                    {/* Sidebar */}
                    <Grid item sx={{ width: '80px' }}>
                        <Dashboardsideicon />
                    </Grid>

                    {/* Main Content */}
                    <Grid
                        component="main"
                        sx={{
                            backgroundColor: theme.palette.mode !== "dark" ? "#1c1c1e" : "#fbf9fb",
                            flexGrow: 1,
                            color: '#fff',
                            ml: -2,
                            marginTop: 13, 
                            p:4
                        }}
                    >
                        <Grid container spacing={4}>
                            {/* Left side - Current Plan */}
                            <Grid item xs={12} md={8}>
                                <CurrentPlan />
                            </Grid>

                            {/* Right side - Billing Section */}
                            <Grid item xs={12} md={4}>
                                <BillingSection />
                            </Grid>    
                        </Grid>
                    </Grid>
                </Grid>
                
            </Grid>
        </>
    );
};

export default PlanBillingHome;
