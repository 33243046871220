import React, { useEffect, useRef, useState } from 'react';
import { Box, Container, Grid, Typography, Paper } from '@mui/material';
import { styled } from '@mui/system';
import { motion } from 'framer-motion';

const data = [
  { title: 'Reach', description: '91% of people use the internet to find information about their interest.find information about their interest.' },
  { title: 'Online Shopping', description: '79% of Americans now do their shopping online.find information about their interest.find information about their interest.' },
  { title: 'Time Spent Online', description: 'People spend more time online than ever before, using 25% of their day browsing the web.find information about their interest.' },
  { title: 'Social Media Engagement', description: 'Over 3.6 billion people worldwide are using social media, and this number is projected to increase to almost 4.41 billion in 2025.' },
  { title: 'Mobile Users', description: 'Over 50% of global web traffic comes from mobile devices, highlighting the importance of mobile-friendly websites.' },
  { title: 'Digital Marketing Growth', description: 'Digital marketing spending is expected to reach $450 billion by 2025, showcasing the industrys find information about their interest.' },
];

// Styled components
const ServicesGrid = styled(Grid)({
  marginTop: '32px',
});

const StatisticCard = styled(Paper)({
  padding: '24px',
  textAlign: 'center',
  transition: 'transform 0.3s',
  '&:hover': {
    transform: 'scale(1.05)',
  },
  borderRadius: '12px',
  boxShadow: '0px 3px 5px rgba(0,0,0,0.3)',
});

// Animation variants
const cardVariants = {
  hidden: { opacity: 0, y: -30 },
  visible: { opacity: 1, y: 0 },
};

const WhyChooseUs = () => {
  const [visibleCards, setVisibleCards] = useState(Array(data.length).fill(false));
  const cardRefs = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        let updated = [...visibleCards];
        let shouldUpdate = false;

        entries.forEach((entry) => {
          const index = parseInt(entry.target.dataset.index);
          if (entry.isIntersecting) {
            if (!updated[index]) {
              updated[index] = true;
              shouldUpdate = true;
            }
          } else {
            if (updated[index]) {
              updated[index] = false; 
              shouldUpdate = true;
            }
          }
        });

        if (shouldUpdate) {
          setVisibleCards(updated); 
        }
      },
      {
        threshold: 0.1, 
      }
    );

    cardRefs.current.forEach((card) => {
      if (card) {
        observer.observe(card);
      }
    });

    return () => {
      observer.disconnect();
    };
  }, [visibleCards]);

  return (
    <Box sx={{ backgroundColor: 'white', py: 6 }}>
      <Container>
        {/* Section Title */}
        <Box sx={{ textAlign: 'center', mb: 4 }}>
          <Typography variant="h4" component="h2" sx={{ fontWeight: 'bold', mb: 2, color: '#333' }}>
            Why Choose Us
          </Typography>
          <Typography variant="body1" sx={{ color: '#666', maxWidth: 600, margin: '0 auto' }}>
          We pride ourselves on delivering exceptional service and value to our customers, ensuring you have the best experience possible.        
          </Typography>
        </Box>

        {/* Statistics Grid */}
        <ServicesGrid container spacing={4} justifyContent="center" sx={{backgroundColor:'#fffff'}}>
          {data.map((item, index) => (
            <Grid item key={index} xs={12} sm={4}>
              <motion.div
                ref={(el) => (cardRefs.current[index] = el)}
                data-index={index}
                initial="hidden"
                animate={visibleCards[index] ? "visible" : "hidden"}
                variants={cardVariants}
                transition={{ duration: 0.5, delay: index * 0.2 }} 
              >
                <StatisticCard elevation={3} sx={{  boxShadow: '0 4px 20px rgba(255, 72, 0, 0.1)',backgroundColor:'#ffffff'}}>
                  <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
                    {item.title}
                  </Typography>
                  <Typography variant="body2" sx={{ color: '#666' }}>
                    {item.description}
                  </Typography>
                </StatisticCard>
              </motion.div>
            </Grid>
          ))}
        </ServicesGrid>
      </Container>
    </Box>
  );
};

export default WhyChooseUs;
