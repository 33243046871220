import React, { useState } from "react";
import {
  Box, Typography, Button, List, ListItem, Switch, Divider, IconButton, Modal, TextField
} from "@mui/material";
import { useTheme } from "../../../../../common/ThemeContext";
import SettingsIcon from '@mui/icons-material/Settings';
import GoogleIcon from '@mui/icons-material/Google';

const AddSearchEnginesTab = ({handleNext,handleBack}) => {
  const { theme } = useTheme();
  const [searchEngines, setSearchEngines] = useState([
    { name: "Google Desktop", location: "Karnataka, India", active: true },
  ]);
  const [openSettings, setOpenSettings] = useState(null);
  const [openPopup, setOpenPopup] = useState(null);
  const [newSearchEngine, setNewSearchEngine] = useState("");

  const toggleSearchEngine = (index) => {
    const updatedSearchEngines = [...searchEngines];
    updatedSearchEngines[index].active = !updatedSearchEngines[index].active;
    setSearchEngines(updatedSearchEngines);
  };

  const handleSettingsClick = (index) => {
    setOpenSettings(index);
  };

  const handleSearchEngineClick = (engine) => {
    setOpenPopup(engine);
  };

  const handleAddSearchEngine = () => {
    setSearchEngines([...searchEngines, { name: newSearchEngine || "New Search Engine", location: "Location", active: true }]);
    setNewSearchEngine("");
  };

  return (
    <Box
      sx={{
        padding: 3,
        backgroundColor: theme.palette.mode !== "dark" ? "#312b36" : "#fff",
        border: theme.palette.mode !== "dark" ? "1px solid #4a4a4a" : "1px solid #f2ebf0",
        borderRadius: "8px",
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h6" sx={{ fontWeight: "bold",color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000" }}>
          Search Engines
        </Typography>
        <Button
          variant="text"
          color="primary"
          onClick={handleAddSearchEngine}
          sx={{backgroundColor: theme.palette.mode !== "dark" ? "#312b36" : "#fff",
            border: theme.palette.mode !== "dark" ? "1px solid #4a4a4a" : "1px solid #f2ebf0",
            fontWeight: "bold",color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000"}}
        >
          + Add Search Engine
        </Button>
      </Box>

      <List>
        {searchEngines.map((engine, index) => (
          <React.Fragment key={index}>
            <ListItem
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: 2,
                border: theme.palette.mode !== "dark" ? "1px solid #4a4a4a" : "1px solid #f2ebf0",
                borderRadius: "8px",
                backgroundColor: theme.palette.mode !== "dark" ? "#312b36" : "#fff",
                cursor: "pointer",
              }}
              onClick={() => handleSearchEngineClick(engine)}
            >
              <Box display="flex" alignItems="center">
                <GoogleIcon sx={{ mr: 2, color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000" }} />
                <Box>
                  <Typography variant="body1" fontWeight="bold" sx={{ color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000" }}>
                    {engine.name}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" sx={{ color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000" }}>
                    {engine.location}
                  </Typography>
                </Box>
              </Box>

              <Box display="flex" alignItems="center">
                <Switch
                  checked={engine.active}
                  onChange={(e) => {
                    e.stopPropagation();
                    toggleSearchEngine(index);
                  }}
                  color="primary"
                />
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    handleSettingsClick(index);
                  }}
                  sx={{ color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000" }}
                >
                  <SettingsIcon />
                </IconButton>
              </Box>
            </ListItem>
            {index < searchEngines.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </List>

      {/* Modal for Settings */}
      <Modal
        open={openSettings !== null}
        onClose={() => setOpenSettings(null)}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            borderRadius: 1,
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6" mb={2}>Edit Search Engine</Typography>
          <TextField
            fullWidth
            label="Name"
            value={searchEngines[openSettings]?.name || ""}
            onChange={(e) => {
              const updatedEngines = [...searchEngines];
              updatedEngines[openSettings].name = e.target.value;
              setSearchEngines(updatedEngines);
            }}
            margin="dense"
          />
          <TextField
            fullWidth
            label="Location"
            value={searchEngines[openSettings]?.location || ""}
            onChange={(e) => {
              const updatedEngines = [...searchEngines];
              updatedEngines[openSettings].location = e.target.value;
              setSearchEngines(updatedEngines);
            }}
            margin="dense"
          />
        </Box>
      </Modal>
      <Box display="flex" justifyContent="space-between" mt={2}>
        <Button
          variant="text"
          color="primary"
          onClick={()=>{handleBack()}}
          sx={{
            fontWeight: 'bold',
            color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000",
            backgroundColor: theme.palette.mode !== "dark" ? "#312b36" : "#fff",
            border: theme.palette.mode !== "dark" ? "1px solid #4a4a4a" : "1px solid #f2ebf0",
          }}
        >
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={()=>{handleNext()}}
          sx={{
            fontWeight: 'bold',
            color: theme.palette.mode !== "dark" ? "#e3e0e6" : "#000",
            backgroundColor: theme.palette.mode !== "dark" ? "#312b36" : "#fff",
            border: theme.palette.mode !== "dark" ? "1px solid #4a4a4a" : "1px solid #f2ebf0",
          }}
        >
          Save and Continue
        </Button>
      </Box>

      {/* Popup Modal for Search Engine Details */}
      <Modal
        open={openPopup !== null}
        onClose={() => setOpenPopup(null)}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            borderRadius: 1,
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6" mb={2}>{openPopup?.name}</Typography>
          <Typography variant="body1">Location: {openPopup?.location}</Typography>
          <Typography variant="body1">Status: {openPopup?.active ? "Active" : "Inactive"}</Typography>
        </Box>
      </Modal>
    </Box>
  );
};

export default AddSearchEnginesTab;